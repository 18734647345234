import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { colors, months } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import {
  checkMonthIsInSubscription,
  generateMonthYearArray,
} from '../../../assets/utils/funcsUtils';
import { helperGenerateRandomPicture } from '../../../assets/utils/helperGenerateRandomPicture';
import { Toast, toast_loader } from '../../../assets/utils/toast';
import { useAppSelector } from '../../../hooks/useReduxHook';
import {
  removePictureIdFromAllAttributionAction,
  updateCustomerAllAttributionsAction,
  updateCustomerDiariesAction,
} from '../../../redux/customer';
import {
  replayDiaryModalAction,
  warningAttrImgModalAction,
} from '../../../redux/modals';
import store from '../../../redux/storeConfig';
import { Button } from '../../atoms/Buttons';
import { Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';
import { helperFindSamePicturesIdByPicture } from '../../../assets/utils/helperFindSamePictures';

export const ReplayDiaryModal = memo(({ bgWidth = '100%' }) => {
  //
  const currentDate = new Date();
  const mois = currentDate.getMonth() + 1;
  const annee = currentDate.getFullYear();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState({
    month: months[mois],
    year: String(annee),
  });

  const [endDate, setEndDate] = useState({
    month: months[mois],
    year: String(annee + 1),
  });
  const customer = useAppSelector((state) => state.customer.customer);
  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );
  const currentSubscription = customer?.subscriptions[currentSubscriptionIndex];
  const isUpdateSubscription = useAppSelector(
    (state) => state.customer.isUpdateSubscription
  );

  const generateNewPicture = async ({ month, errors }) => {
    const subscriptionIndex =
      store.getState().customer.currentSubscriptionIndex;

    const _customer = store.getState().customer.customer;
    const currentSubscription = _customer.subscriptions[subscriptionIndex];
    const allSubscriptions = _customer?.subscriptions || [];
    let allAttributions = copyObj(_customer?.allAttributions || []);

    const diaries = copyObj(currentSubscription.diaries);
    const allDiaries = allSubscriptions
      .flatMap((subscription) => subscription.diaries)
      .filter((diary) => diary);

    const strategies = currentSubscription.strategies;

    for (let i = 0; i < currentSubscription.formula; i++) {
      const currentDiaries = diaries.filter(
        (diary) => diary.month === month.month && diary.year === month.year
      );

      const formula = strategies.find(
        (_strategy) =>
          _strategy.month === month.month &&
          _strategy.formula_number === i + 1 &&
          _strategy.year === month.year
      );
      // debugger;

      const picture = await helperGenerateRandomPicture({
        formula,
        diaries: [...allDiaries, ...diaries],
        subscriptionId: currentSubscription._id,
        customerUnivers: customer.univers,
        customerId: customer._id,
        allAttributions,
      });

      const currentFormulaInDiaries = currentDiaries.find(
        (diary) => diary.formula_number === formula.formula_number
      );

      if (picture) {
        const oldPicture = currentFormulaInDiaries?.picture;
        if (currentFormulaInDiaries) {
          currentFormulaInDiaries.picture = picture;
        } else {
          diaries.push({
            formula_number: formula.formula_number,
            month: formula.month,
            year: formula.year,
            picture,
          });
        }

        const { oldsPictureIds, pictureIds } =
          await helperFindSamePicturesIdByPicture({
            oldPicture,
            picture,
          });

        if (oldsPictureIds.length > 0) {
          allAttributions = allAttributions.filter(
            (pictureId) => !oldsPictureIds.includes(pictureId)
          );
        }

        if (pictureIds.length > 0) {
          allAttributions = [...allAttributions, ...pictureIds];
        }
      } else {
        let message = '';
       
        if (picture === null) {
          message = `Aucune image n'a été trouvée pour le mois de ${month?.month} ${month?.year} formule ${
            i + 1
          }`;
        } else {
          message = `Toutes les images de la stratégie à la formule ${
            i + 1
          } ont été déjà attribué dans le mois de ${month}`;
        }

        errors.push(message);
      }
    }
    dispatch(updateCustomerAllAttributionsAction(allAttributions));
    dispatch(updateCustomerDiariesAction(diaries));
  };

  const handleGenerateGlobalAttributions = async () => {
    if (
      !currentSubscription ||
      loading ||
      !currentSubscription?.strategies?.length
    ) {
      Toast(
        'error',
        currentSubscription.strategies.length === 0
          ? "Aucune stratégie n'a été défini pour ce forfait"
          : 'Vous devez avoir un forfait pour pouvoir attribuer une image à travers les stratégies'
      );
      return;
    }

    const loader = toast.loading('Attribution des images en cours...');

    try {
      setLoading(true);

      let errors = [];

      let diaries = copyObj(currentSubscription.diaries);
      let picturesIds = [];

      const monthYearArray = generateMonthYearArray(startDate, endDate);

      for (let monthData of monthYearArray) {
        const isMonthValidInSubscription = checkMonthIsInSubscription(
          monthData.month,
          monthData.year,
          currentSubscription
        );

        if (isMonthValidInSubscription) {
          let oldPictures = diaries.filter(
            (diary) => diary.month === monthData.month && diary.picture
          );

          diaries = diaries.map((diary) => {
            if (diary.month === monthData.month) {
              return {
                ...diary,
                picture: null,
              };
            }
            return diary;
          });

          for (let oldPicture of oldPictures) {
            const { oldsPictureIds } =
              await helperFindSamePicturesIdByPicture({
                oldPicture: oldPicture.picture,
                picture: null,
              });

            picturesIds = [...picturesIds, ...oldsPictureIds];
          }
        }
      }
      
      // renitialisation des diaries(agendas)
      dispatch(updateCustomerDiariesAction(diaries));
      dispatch(
        removePictureIdFromAllAttributionAction({ pictureIds: picturesIds })
      );
      for (let monthData of monthYearArray) {
        const isMonthValidInSubscription = checkMonthIsInSubscription(
          monthData.month,
          monthData.year,
          currentSubscription
        );
        if (isMonthValidInSubscription) {
          if (startDate.month && endDate.month) {
            await generateNewPicture({
              month: monthData,
              errors,
            });

            continue;
          } else {
            await generateNewPicture({
              month: monthData,
              errors,
            });
          }
        }
      }
      setLoading(false);
      dispatch(replayDiaryModalAction(false));

      toast_loader(loader, 'Attribution des images terminée', 'success');

      if (errors.length > 0) {
        dispatch(warningAttrImgModalAction(true));
      }
    } catch (error) {
      toast_loader(loader, 'Une erreur est survenue', 'error');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isUpdateSubscription || customer) {
      const subscription = customer?.subscriptions[currentSubscriptionIndex];

      if (subscription) {
        setStartDate({
          month: subscription?.startOn?.month,
          year: subscription?.startOn?.year,
        });
        setEndDate({
          month: subscription?.endIt.month,
          year: subscription?.endIt.year,
        });
      }
    }
  }, [isUpdateSubscription, currentSubscriptionIndex, customer]);

  const generateMonthOptions = (startYear, endYear) => {
    const currentYear = new Date().getFullYear();

    const monthOptions = [];

    if (startYear === currentYear) {
      const currentMonth = new Date().getMonth() + 1;
      for (let i = currentMonth; i <= 12; i++) {
        monthOptions.push({
          name: months[i - 1],
          value: months[i - 1].toLowerCase(),
        });
      }
    } else {
      for (let i = 1; i <= 12; i++) {
        monthOptions.push({
          name: months[i - 1],
          value: months[i - 1].toLowerCase(),
        });
      }
    }

    return monthOptions;
  };

  const years = Array.from({ length: 10 }, (_, index) => annee + index);

  const startMonthOptions = generateMonthOptions(
    parseInt(startDate.year),
    parseInt(endDate.year)
  );

  const endMonthOptions = generateMonthOptions(
    parseInt(endDate.year),
    parseInt(endDate.year) + 1
  );

  return (
    <Modal
      onClose={loading ? null : () => dispatch(replayDiaryModalAction(false))}
      title="Rejouer l'attribution des images"
      hiddenBtns
      width={512}
      minHeight={250}
      bgWidth={bgWidth}
    >
      <p
        style={{
          fontFamily: 'Inter Semi Bold',
          fontSize: 14,
          textTransform: 'uppercase',
          color: colors.dark,
        }}
      >
        Souhaitez vous rejouer l'attribution des images ?
      </p>
      <div className='w-100 flex-row-center' style={{ margin: '1rem 0' }}>
        <span style={{ margin: '0 1rem 0 .5rem' }}>Début :</span>
        <Select
          width={'40%'}
          margin='0 1rem 0 0'
          defaultValue={startDate.month}
          value={startDate.month}
          onChange={(e) =>
            setStartDate((prevDate) => ({ ...prevDate, month: e.target.value }))
          }
          options={startMonthOptions}
        />

        <Select
          width={'30%'}
          margin='0 1rem 0 0'
          value={startDate.year}
          onChange={(e) =>
            setStartDate((prevDate) => ({ ...prevDate, year: e.target.value }))
          }
          options={years.map((year) => ({ name: year, value: year }))}
        />
      </div>
      <div className='w-100 flex-row-center' style={{ margin: '1rem 0' }}>
        <span style={{ margin: '0 2.4rem 0 .5rem' }}>Fin :</span>
        <Select
          width={'40%'}
          margin='0 1rem 0 0'
          defaultValue={endDate.month}
          value={endDate.month}
          onChange={(e) =>
            setEndDate((prevDate) => ({ ...prevDate, month: e.target.value }))
          }
          options={endMonthOptions}
        />
        <Select
          width={'30%'}
          margin='0 1rem 0 0'
          value={endDate.year}
          onChange={(e) =>
            setEndDate((prevDate) => ({ ...prevDate, year: e.target.value }))
          }
          options={years.map((year) => ({ name: year, value: year }))}
        />
      </div>
      <div className='modal--footer' style={{ marginTop: '2rem' }}>
        <Button
          label='NON'
          width={195}
          margin='0 .5rem'
          onClick={() => dispatch(replayDiaryModalAction(false))}
        />
        <Button
          label='OUI'
          variant='yellow'
          width={195}
          margin='0 .5rem'
          disabled={loading}
          onClick={handleGenerateGlobalAttributions}
        />
      </div>
    </Modal>
  );
});
