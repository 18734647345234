import { useAppSelector } from '../../../hooks/useReduxHook';
import { FamilyProdComposition } from './FamilyProdComposition';
import { UniversFilters } from './UniversFilters';

export const UniversPanel = () => {
  const universTabs = useAppSelector((s) => s.customer.universTabs);
  return (
    <>
      {['compositions', 'compPrimary', 'compSecondary', 'measure'].includes(
        universTabs
      ) && <FamilyProdComposition />}
      {universTabs === 'filters' && <UniversFilters />}
    </>
  );
};
