import { useMemo } from 'react';
import { Toast } from '../assets/utils/toast';
import { IComposition } from '../redux/compositions';
import {
  familySelectedImgAttrAction,
  imgCompositionIsSelectedAction,
  imgCompositionsSelectedAction,
} from '../redux/imageAttributions';
import {
  infosMainProductsAction,
  infosSecondaryProductsAction,
} from '../redux/images';
import { useAppDispatch, useAppSelector } from './useReduxHook';
import { helperFilterIngForCompose } from '../assets/utils/helperFilterIngForCompose';

export const useAttributionsImgComposition = (
  forCompose?: boolean,
  familyId?: string
) => {
  const dispatch = useAppDispatch();
  const compositions = useAppSelector((s) => s.compositions?.compositions);
  const univers = useAppSelector((s) => s.customer.customer?.univers);

  const compositionSelected = useAppSelector(
    (s) => s.compositions?.compositionSelected
  );

  const imageStore = useAppSelector((s) => s.images);

  const {
    imgCompositionsSelected,
    attributionMode,
    filteredByIngredients,
    imageAttributionsTab,
    familySelectedImgAttr,
    imgProductId,
  } = useAppSelector((s) => s.imageAttributions);

  const _familySelected = univers?.families.find((f) => f._id === familyId);

  const allCompositionsPrincipal =
    _familySelected?.products
      .reduce((allProds, prod) => {
        const compositions = prod.compositions.filter(
          (c) => c.type === 'principal'
        );
        return [...allProds, ...compositions];
      }, [])
      ?.map((c) => c._id) || [];

  const filteredCompositions =
    compositions?.length > 0 &&
    (compositionSelected?.ingredients?.length > 0 || forCompose) &&
    attributionMode === 'quiz'
      ? compositions
          .filter((comp: IComposition) => {
            if (forCompose) {
              return helperFilterIngForCompose(comp, compositionSelected);
            } else {
              return compositionSelected?.ingredients?.every((ingr) => {
                return comp.ingredients.some((compIngr) => {
                  return (
                    compIngr.quantity === ingr.quantity &&
                    compIngr.specialityId?._id ===
                      (ingr.specialityId?._id || ingr.specialityId)
                  );
                });
              });
            }
          })
          ?.filter((c) =>
            forCompose ? !allCompositionsPrincipal.includes(c._id) : true
          )
      : compositions?.length > 0 &&
        filteredByIngredients.length > 0 &&
        attributionMode === 'product'
      ? compositions.filter((comp: IComposition) => {
          return filteredByIngredients.every((selectedIngr) => {
            return comp.ingredients.some((compIngr) => {
              return compIngr.name === selectedIngr;
            });
          });
        })
      : compositions?.length > 0 && attributionMode === 'product'
      ? compositions?.filter(
          (comp: any) => comp?.productId?._id === imgProductId
        )
      : compositions;

  const maybeCompositions = compositions
    .filter((comp: IComposition) => {
      return compositionSelected?.ingredients?.every((ingr) => {
        return comp.ingredients.some((compIngr) => {
          return (
            compIngr.specialityId?._id ===
            (ingr.specialityId?._id || ingr.specialityId)
          );
        });
      });
    })
    ?.filter(
      (_comp: IComposition) =>
        !!!filteredCompositions.find((_c: IComposition) => _c._id === _comp._id)
    );

  const familiesSelected =
    imageAttributionsTab === 'product-main'
      ? imageStore?.infosMainProducts
      : imageStore?.infosSecondaryProducts;

  const compositionsAttr =
    imageAttributionsTab === 'product-main'
      ? imageStore?.attrMainCompositions?.map((x) => x.compositionId || x)
      : imageStore?.attrSecondaryCompositions?.map(
          (x) => x.compositionId || x
        ) || [];

  const count = useMemo(() => {
    const totalFamilyQuantity = familiesSelected?.reduce(
      (acc, f) => acc + (f?.quantity || 0),
      0
    );
    return totalFamilyQuantity - compositionsAttr.length;
  }, [familiesSelected, compositionsAttr.length]);

  const mainCompCount = useMemo(() => {
    const totalMainProductQuantity = imageStore?.infosMainProducts?.reduce(
      (acc, f) => acc + (f?.quantity || 0),
      0
    );
    return totalMainProductQuantity - imageStore?.attrMainCompositions.length;
  }, [imageStore]);

  const secondaryCompCount = useMemo(() => {
    const totalSecondaryProductQuantity =
      imageStore?.infosSecondaryProducts?.reduce(
        (acc, f) => acc + (f?.quantity || 0),
        0
      );
    return (
      totalSecondaryProductQuantity -
      imageStore?.attrSecondaryCompositions.length
    );
  }, [imageStore]);

  const onSelectComposition = (_composition: IComposition) => {
    const findComposition = imgCompositionsSelected.find(
      (_c: IComposition) => _c._id === _composition._id
    );

    if (
      familySelectedImgAttr.checkedNum === familySelectedImgAttr.quantity &&
      !findComposition
    ) {
      Toast(
        'warning',
        'Vous avez atteint le nombre maximum de compositions pour cette famille'
      );
      return;
    }

    const action =
      imageAttributionsTab === 'product-main'
        ? infosMainProductsAction
        : infosSecondaryProductsAction;

    if (!!findComposition) {
      const newCompositions = imgCompositionsSelected.filter(
        (_c: IComposition) => _c._id !== findComposition._id
      );

      const formatedFamilies = familiesSelected?.map((f) => {
        if (f.familyId === familySelectedImgAttr?.familyId) {
          const newFamily = {
            ...familySelectedImgAttr,
            checkedNum: familySelectedImgAttr?.checkedNum - 1,
          };
          dispatch(familySelectedImgAttrAction(newFamily));
          return newFamily;
        }
        return f;
      });
      dispatch(action(formatedFamilies));
      dispatch(imgCompositionsSelectedAction(newCompositions));
      dispatch(
        imgCompositionIsSelectedAction(
          newCompositions.length > 0 ? true : false
        )
      );
    } else {
      const formatedFamilies = familiesSelected?.map((f) => {
        if (f.familyId === familySelectedImgAttr?.familyId) {
          const newFamily = {
            ...familySelectedImgAttr,
            checkedNum: familySelectedImgAttr?.checkedNum + 1,
          };

          dispatch(familySelectedImgAttrAction(newFamily));
          return newFamily;
        }
        return f;
      });
      dispatch(action(formatedFamilies));

      dispatch(
        imgCompositionsSelectedAction([
          ...imgCompositionsSelected,
          _composition,
        ])
      );
      dispatch(imgCompositionIsSelectedAction(true));
    }
  };

  //get compositions ingredients
  const allCompIngredients =
    compositions
      ?.flatMap((comp: IComposition) => comp.ingredients?.map((i) => i?.name))
      ?.filter((ingredient, index, array) => {
        return ingredient && array.indexOf(ingredient) === index;
      }) || [];

  const sortedIngredients = allCompIngredients.sort((a, b) =>
    a.localeCompare(b)
  );

  return {
    filteredCompositions,
    maybeCompositions,
    onSelectComposition,
    allCompIngredients: sortedIngredients,
    familiesSelected,
    compositionsAttr,
    count,
    secondaryCompCount,
    mainCompCount,
  };
};
