import {useNavigate} from 'react-router-dom';
import {DotsCircleIcon} from '../../../assets/svgs/DotsCircleIcon';
import {useAppDispatch} from '../../../hooks/useReduxHook';
import {filteredSelectedItemAction} from '../../../redux/filtersSelected';
import {deleteModalAction} from '../../../redux/modals';
import {ActionModal} from '../ActionModal';

export const TableRow = ({
  item,
  filter,
  showActionModal,
  onOpenModal,
  onOpenActionModal,
  route,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSelectSpecialFilter = () => {
    dispatch(
      filteredSelectedItemAction({
        filter: filter,
        item: item,
      })
    );
  };

  const onShowModal = () => {
    handleSelectSpecialFilter();
    onOpenModal();
  };

  const onShowModalDelete = () => {
    handleSelectSpecialFilter();
    dispatch(deleteModalAction(true));
  };

  return (
    <div className='table-trow'>
      <span
        style={{cursor: 'pointer', flex: 1}}
        className='table-trow-text'
        onClick={() => navigate(`/${route}/${item?._id}`)}
      >
        {item?.label}
      </span>
      <div className='table-trow-action'>
        {showActionModal === item._id && (
          <ActionModal onEdit={onShowModal} onDelete={onShowModalDelete} />
        )}
        <DotsCircleIcon
          onClick={() =>
            onOpenActionModal(item._id === showActionModal ? null : item._id)
          }
        />
      </div>
    </div>
  );
};
