import { Button } from '../../atoms/Buttons';
import { Modal } from '../../atoms/Modal';
import { DownloadIcon } from '../../../assets/svgs/DownloadIcon';
import { InputCopy } from '../../atoms/Fields';
import { useDispatch, useSelector } from 'react-redux';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import {
  confirmDeleteAttrModalAction,
  downloadImgModalAction,
} from '../../../redux/modals';
import { createOneLog } from '../../../api/logs.api';
import { useAppSelector } from '../../../hooks/useReduxHook';
// import { helperDownloadAllImages } from '../../../assets/utils/funcsUtils';
import { regexRemoveHtmlTag } from '../../../assets/constants';

export const DownloadImgModal = ({ bgWidth = '80%' }) => {
  const dispatch = useDispatch();
  const userData = useAppSelector((state) => state.app.userData);
  const customer = useAppSelector((s) => s.customer?.customer);

  const formula = useAppSelector((s) => s.customer.formula);

  const picture = formula?.picture;

  //TODO Les parties commentés dépendent l'image du formule selectionné...
  // TODO A décommenté et adapter quand l'attribution sera gérer
  const handlePunchlineSignature = (punchline) => {
    const findDeliveryData = customer?.univers?.filters
      ?.find((el) => el?.label?.toLowerCase() === 'livraison')
      ?.filterValues?.map((el) => el?.name);

    const getValueIfEnabled = (item, key) => {
      return item?.enabled ? customer.informations[key] : '';
    };

    const data = {
      punchline,
      slogan: getValueIfEnabled(customer?.signature?.slogan, 'slogan'),
      adresse: getValueIfEnabled(
        customer?.signature?.address,
        'restaurant_address'
      ),
      phone: getValueIfEnabled(customer?.signature?.phone, 'restaurant_phone'),
      delivery: customer.signature?.delivery?.enabled
        ? findDeliveryData?.join(',')
        : '',
      hashtags: customer?.hashtags?.join(' ') || '',
      picture_hashtags: picture?.hashtags?.join(' ') || '',
    };

    const signature = `${data?.punchline}\n${data?.slogan.replace(
      regexRemoveHtmlTag,
      ''
    )}\n${data?.adresse}\n${data?.phone}\n${data?.delivery}\n${
      data?.hashtags
    }\n${data?.picture_hashtags}`;

    return signature;
  };

  const onLogSignature = (index) => {
    const logBody = {
      userId: userData?._id,
      action: `Copie de la signature ${index} de l'image ${
        picture?.name
      } du client ${
        customer.informations.lastname + ' ' + customer.informations.firstname
      } !`,
      from: 'customer',
      createdAt: new Date(),
    };
    createOneLog(logBody);
  };

  const onDeleteAttribution = () => {
    dispatch(confirmDeleteAttrModalAction(true));
    dispatch(downloadImgModalAction(false));
  };

  // const downloadAllImgs = async () => {
  //   const logBody = {
  //     userId: userData?._id,
  //     action: `Téléchargement de tout les formats de l'image ${
  //       picture?.name
  //     } du client du client ${
  //       customer.informations.lastname + ' ' + customer.informations.firstname
  //     } !`,
  //     from: 'customer',
  //     createdAt: new Date(),
  //   };

  //   helperDownloadAllImages(picture?.images);
  //   createOneLog(logBody);
  // };

  return (
    <Modal
      onClose={() => dispatch(downloadImgModalAction(false))}
      title='Télécharger mon image'
      hiddenBtns
      width={640}
      bodyPadding='1rem 3rem'
      bgWidth={bgWidth}
    >
      <div className='w-100 flex-col'>
        <div className='w-100 flex-row-center-between'>
          <ImgDownload label='' img={picture?.image} />
        </div>
        {/* <Button
          label='Tout télécharger'
          Icon={DownloadIcon}
          variant='black'
          width='100%'
          margin='1rem 0'
          onClick={downloadAllImgs}
        /> */}
      </div>

      <InputCopy
        label='punchline 1'
        value={picture?.punchlines?.punchline_1}
        copyValue={handlePunchlineSignature(picture?.punchlines?.punchline_1)}
        onClick={() => onLogSignature(1)}
      />
      <InputCopy
        label='punchline 2'
        value={picture?.punchlines?.punchline_2}
        copyValue={handlePunchlineSignature(picture?.punchlines?.punchline_2)}
        onClick={() => onLogSignature(2)}
      />
      <InputCopy
        label='punchline 3'
        value={picture?.punchlines?.punchline_3}
        copyValue={handlePunchlineSignature(picture?.punchlines?.punchline_2)}
        onClick={() => onLogSignature(3)}
      />
      <div className='modal--footer' style={{ marginTop: '2rem' }}>
        <Button
          label='Supprimer attribution'
          margin='0 .5rem'
          variant='red'
          onClick={onDeleteAttribution}
        />
        <Button
          label='Terminer'
          variant='yellow'
          width={190}
          margin='0 .5rem'
          onClick={() => {
            dispatch(downloadImgModalAction(false));
          }}
        />
      </div>
    </Modal>
  );
};

const ImgDownload = ({ label, img }) => {
  const userData = useSelector((s) => s.app?.userData);
  const customer = useSelector((s) => s.customer?.customer);

  //TODO Mettre le nom de l'imagé attribué
  const onLogDownloadImg = (e) => {
    if (!img) {
      e.preventDefault();
      return;
    }
    const logBody = {
      userId: userData?._id,
      action: `Téléchargement du format ${label} de l'image ${`le nom de l'image ici`} du client du client ${
        customer.informations.lastname + ' ' + customer.informations.firstname
      } !`,
      from: 'customer',
      createdAt: new Date(),
    };
    createOneLog(logBody);
  };
  return (
    <div className='customer--diarypanel-imgDownload'>
      <p>{label}</p>
      <div className='customer--diarypanel-imgDownload-img'>
        {img && (
          <img src={helperImageRoute(img, 'picture', false)} alt='diaryImg' />
        )}
        <a
          href={helperImageRoute(img, 'picture', false)}
          onClick={onLogDownloadImg}
          download
          target='_self'
        >
          <DownloadIcon />
        </a>
      </div>
    </div>
  );
};
