import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CardPayIcon } from '../../../../assets/svgs/CardPayIcon';
import { HashtagIcon } from '../../../../assets/svgs/HashtagIcon';
import { PencilIcon } from '../../../../assets/svgs/PencilIcon';
import { UniversIcon } from '../../../../assets/svgs/UniversIcon';
import { UserIcon } from '../../../../assets/svgs/UserIcon';
import { UsersIcon } from '../../../../assets/svgs/UsersIcon';
import { useCustomer } from '../../../../hooks/useCustomer';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { customerModalAction } from '../../../../redux/modals';
import { Button } from '../../../atoms/Buttons';
import { Loader } from '../../../atoms/Loader';
import { DiaryPanel } from '../../../organisms/Customer/DiaryPanel';
import { HashtagPanel } from '../../../organisms/Customer/HashtagPanel';
import { InformationsPanel } from '../../../organisms/Customer/InformationsPanel';
import { LogoCardPanel } from '../../../organisms/Customer/LogoCardPanel';
import { SignaturePanel } from '../../../organisms/Customer/SignaturePanel';
import { StrategyPanel } from '../../../organisms/Customer/StrategyPanel';
import { UniversPanel } from '../../../organisms/Customer/UniversPanel';
import { HeaderWithTabs } from '../../../organisms/Header';

const tabsData = [
  { label: 'Informations', link: 'informations', icon: UserIcon },
  { label: 'Logo/Carte', link: 'logo/card', icon: CardPayIcon },
  { label: 'Agenda', link: 'diary', icon: UsersIcon },
  { label: 'Hashtag', link: 'hashtag', icon: HashtagIcon },
  { label: 'Univers', link: 'univers', icon: UniversIcon },
  { label: 'Signature', link: 'signature', icon: PencilIcon },
];

export const CustomerModal = () => {
  const dispatch = useDispatch();
  const customer = useAppSelector((s) => s.customer.customer);

  const [diaryTabs, setDiaryTabs] = useState('diary');

  const { handleUpdateCustomer, loading, tabs, setTabs } = useCustomer();

  return (
    <>
      <div
        onClick={() => dispatch(customerModalAction(false))}
        className='customerModal--bg'
      />

      <div className='customerModal'>
        <HeaderWithTabs
          tabs={tabs}
          setTabs={setTabs}
          user={customer?.informations}
          tabsData={tabsData}
          othersTabs={diaryTabs}
          setOthersTabs={setDiaryTabs}
          isModal
        />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className='pages-layout--container customer'>
              {tabs === 'informations' && <InformationsPanel />}
              {tabs === 'logo/card' && <LogoCardPanel />}
              {tabs === 'diary' && diaryTabs === 'diary' && <DiaryPanel />}
              {tabs === 'diary' && diaryTabs === 'strategy' && (
                <StrategyPanel />
              )}
              {tabs === 'hashtag' && <HashtagPanel />}

              {tabs === 'univers' && <UniversPanel />}
              {tabs === 'signature' && <SignaturePanel />}
            </div>
            <div className='customer--footer'>
              <Button
                label='Enregistrer'
                variant='yellow'
                onClick={handleUpdateCustomer}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
