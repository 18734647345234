import { roles } from '../../../assets/constants';
import { Input, Select } from '../../atoms/Fields';
import { UploadImg } from '../../atoms/UploadImg';

export const InformationsPanel = ({
  setUserForm,
  handleChange,
  userForm,
  setConfirmPassword,
  confirmPassword,
}) => {
  return (
    <>
      <UploadImg
        image={userForm?.avatar}
        onChange={(file) => setUserForm({ ...userForm, avatar: file })}
        folder='avatar'
        margin='1rem 0'
      />
      <div className='user--inputBlock'>
        <Input
          label='Nom'
          variant='half'
          required
          onChange={handleChange}
          name='lastname'
          value={userForm['lastname']}
        />
        <Input
          label='Prénom'
          variant='half'
          required
          onChange={handleChange}
          name='firstname'
          value={userForm['firstname']}
        />
      </div>
      <div className='user--inputBlock'>
        <Input
          label='E-mail'
          variant='half'
          required
          onChange={handleChange}
          name='email'
          value={userForm['email']}
        />
        <Input
          label='Téléphone'
          variant='half'
          onChange={handleChange}
          name='phone'
          type='number'
          value={userForm['phone']}
        />
      </div>
      <Select
        label='Rôle'
        variant='half'
        options={roles}
        margin='.5rem auto .5rem 0'
        required
        onChange={handleChange}
        name='role'
        value={userForm['role']}
      />
      <Input
        label='Mot de passe'
        type='password'
        variant='full'
        required
        onChange={handleChange}
        name='password'
        value={userForm['password']}
      />
      <Input
        label='Confirmé mot de passe'
        type='password'
        variant='full'
        required
        onChange={(e) => setConfirmPassword(e.target.value)}
        value={confirmPassword}
      />
    </>
  );
};
