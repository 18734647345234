import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getAllCompositionsByFamilyId,
  getOneFamilyById,
} from '../../../api/families.api';
import { getOneQuiz } from '../../../api/quiz.api';
import { initQuiz } from '../../../assets/constants';
import { Loader } from '../../../components/atoms/Loader';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import { allProdsCompsAction, quizAction } from '../../../redux/familyQuiz';
import { LeftBlock } from './LeftBlock';
import { RightBlock } from './RightBlock';

export const FamilyQuizView: React.FC = withCheckAuth(() => {
  const dispatch = useAppDispatch();
  const { familyId } = useParams();

  const { data, isLoading, isFetched } = useQuery(
    ['family', familyId],
    () => getOneFamilyById(familyId),
    {
      enabled: !!familyId,
      refetchOnWindowFocus: false,
    }
  );
  const { data: allProdsCompsData } = useQuery(
    ['all-compositions-products', familyId],
    () => getAllCompositionsByFamilyId(familyId),
    {
      enabled: !!familyId,
      refetchOnWindowFocus: false,
    }
  );

  const quizId = data?.data?.family?.quiz?._id;
  const { data: quiz } = useQuery(
    ['quiz', quizId],
    () => {
      return getOneQuiz(quizId);
    },
    {
      enabled: !!quizId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!!data?.data?.family && !!quiz && isFetched) {
      const family = data?.data?.family;
      if (!!family?.quiz) {
        const payload = {
          _id: quiz?.data?._id,
          familyId: {
            _id: family?._id,
            label: family?.label,
          },
          questionsList: quiz?.data?.questionsList,
          propositionsList: quiz?.data?.propositionsList,
          responsesSaved: quiz?.data?.responsesSaved,
          update: true,
        };
        dispatch(quizAction(payload));
      } else {
        dispatch(
          quizAction({
            ...initQuiz,
            familyId: { _id: family?._id, label: family?.label },
          })
        );
      }
      dispatch(allProdsCompsAction(allProdsCompsData?.data));
    }
  }, [familyId, data, quiz, isFetched, allProdsCompsData, dispatch]);

  if (isLoading) return <Loader />;

  return (
    <div className='familyQuiz'>
      <LeftBlock />
      <RightBlock />
    </div>
  );
});
