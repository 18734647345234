import { createSlice } from '@reduxjs/toolkit';
const slice = createSlice({
  name: 'groups',
  initialState: {
    groupSelected: null,
    groups: [],
    group: '',
    updateGroup: false,
    filtersGroups: {
      label: '',
      page: 1,
      limit: 20,
      totalPages: 0,
      currentPage: 1,
      totalGroups: 0,
    },
  },

  reducers: {
    setGroupSelectedAction: (state, action) => {
      state.groupSelected = action.payload;
    },
    //filters actions
    setFileredGroupsBySearchAction: (state, action) => {
      state.filtersGroups.label = action.payload;
    },
    setFileredGroupsByPageAction: (state, action) => {
      state.filtersGroups.page = action.payload;
    },
    setFileredGroupsByLimitAction: (state, action) => {
      state.filtersGroups.limit =
        Number(state.filtersGroups.limit) + action.payload;
    },
    setGetFiltersGroups: (state, action) => {
      state.filtersGroups = {
        ...state.filtersGroups,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
        totalGroups: action.payload.totalGroups,
      };
    },
    //get groups data
    getGroupsAction: (state, action) => {
      state.groups = action.payload || [];
    },
    //update
    setUpdateGroupAction: (state, action) => {
      state.updateGroup = action.payload;
    },
    //group
    setGroupAction: (state, action) => {
      state.group = action.payload;
    },
  },
});

export const {
  setGroupSelectedAction,
  setFileredGroupsBySearchAction,
  setFileredGroupsByPageAction,
  setFileredGroupsByLimitAction,
  setGetFiltersGroups,
  getGroupsAction,
  setUpdateGroupAction,
  setGroupAction,
} = slice.actions;
export default slice.reducer;
