import {useEffect, useState} from 'react';
import {DotsCircleIcon} from '../../../assets/svgs/DotsCircleIcon';
import {ActionModal} from '../../../components/atoms/ActionModal';
import {TableHeader} from '../../../components/atoms/TableHeader';
import {withNavigation} from '../../../hoc/withNavigation';
import {
  deleteModalAction,
  productIngredientsModalAction,
} from '../../../redux/modals';
import {Loader} from '../../../components/atoms/Loader';
import {HeaderWithSearch} from '../../../components/organisms/Header';
import {compose} from 'recompose';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {getOneFamilyById} from '../../../api/families.api';
import {filteredSelectedItemAction} from '../../../redux/filtersSelected';
import {DeleteIngredient} from './DeleteIngredient';
import {sortedList} from '../../../assets/utils/sortedList';
import {copyObj} from '../../../assets/utils/copyObj';
import {useAppDispatch} from '../../../hooks/useReduxHook';

const ProductIngredientsView = ({user}) => {
  //
  const dispatch = useAppDispatch();
  const {familyId, productId} = useParams();

  const {data, isLoading} = useQuery(['family', familyId], () =>
    familyId ? getOneFamilyById(familyId) : null
  );

  const productSelected = data?.data?.family?.products.find(
    (prod) => prod._id === productId
  );

  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  useEffect(() => {
    if (data) {
      const product = data?.data?.family?.products.find(
        (prod) => prod._id === productId
      );
      if (product && data?.data?.family) {
        dispatch(
          filteredSelectedItemAction({
            filter: 'product',
            item: product,
          })
        );

        dispatch(
          filteredSelectedItemAction({
            filter: 'family',
            item: data?.data?.family,
          })
        );
      }
    }
  }, [data, productId, dispatch]);

  if (isLoading) return <Loader />;

  return (
    <>
      <DeleteIngredient />

      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            goBack
            title={`Ingrédients '${productSelected?.name}'`}
            onAdd={() => {
              dispatch(productIngredientsModalAction(true));
            }}
          />
          <div className='table'>
            {productSelected?.ingredients &&
              copyObj(productSelected?.ingredients)
                ?.sort((a, b) => sortedList(a, b, a?.label ? 'label' : 'name'))
                ?.map((ing, index) => (
                  <Trow
                    key={ing._id}
                    ing={ing}
                    index={index}
                    onOpen={onOpen}
                    showActionModal={showActionModal}
                    setShowActionModal={setShowActionModal}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

const Trow = ({showActionModal, onOpen, setShowActionModal, index, ing}) => {
  const dispatch = useAppDispatch();

  const handleSelectIng = () => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'ingredient',
        item: ing,
      })
    );
  };

  const onShowModal = () => {
    handleSelectIng();
    dispatch(productIngredientsModalAction(true));
    setShowActionModal(null);
  };
  const onShowModalDelete = () => {
    handleSelectIng();
    dispatch(deleteModalAction(true));
  };
  return (
    <div className='table-trow'>
      <span className='table-trow-text'>{ing.name}</span>
      <div className='table-trow-action'>
        {showActionModal === index && (
          <ActionModal onEdit={onShowModal} onDelete={onShowModalDelete} />
        )}
        <DotsCircleIcon onClick={() => onOpen(index)} />
      </div>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(ProductIngredientsView);
