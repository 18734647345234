import React from 'react';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { ZoomPicture } from '../../../components/atoms/ZoomPicture';
import { useAppSelector } from '../../../hooks/useReduxHook';

export const PicturesBlock: React.FC = () => {
  //
  const customer = useAppSelector((state) => state.customer?.customer);

  const cards = customer?.logo_card?.card;

  return (
    <div
      className='customerCompositionsView--picturesBlock'
      style={{ overflowX: 'hidden' }}
    >
      {cards?.map((card: string, index: number) => {
        if (card.length === 0) return null;

        return (
          <ZoomPicture picture={helperImageRoute(card, 'customer-card')} />
        );
      })}
    </div>
  );
};
