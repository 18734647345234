import { useAppDispatch, useAppSelector } from './useReduxHook';
import { questionSelectedAction } from '../redux/familyQuiz';
import { Toast } from '../assets/utils/toast';

export const useQuiz = () => {
  const dispatch = useAppDispatch();

  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const questionsList = useAppSelector(
    (s) => s.familyQuiz?.quiz?.questionsList
  );

  const findIndex = questionsList?.findIndex((_question) => {
    return _question._id === questionSelected?._id;
  });

  const onNextQuestion = () => {
    const nextQuestion = questionsList[findIndex + 1];
    if (questionsList.length === findIndex + 1) {
      Toast('warning', 'Vous avez atteint la dernière question !');
    } else {
      dispatch(questionSelectedAction(nextQuestion));
    }
  };

  const onPreviousQuestion = () => {
    const previousQuestion = questionsList[findIndex - 1];
    if (findIndex === 0) {
      Toast('warning', 'Vous avez atteint la première question !');
    } else {
      dispatch(questionSelectedAction(previousQuestion));
    }
  };
  return { onPreviousQuestion, onNextQuestion, questionIndex: findIndex };
};
