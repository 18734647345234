import { configureStore } from '@reduxjs/toolkit';
import app from './app';
import modals from './modals';
import actionsModals from './actionsModals';
import packages from './packages';
import customer from './customer';
import picture from './picture';
import groups from './groups';
import images from './images';
import filtersSelected from './filtersSelected';
import filters from './filters';
import familyQuiz from './familyQuiz';
import compositions from './compositions';
import imageAttributions from './imageAttributions';
import imageIdentical from './images/imageIdentical';

const store = configureStore({
  reducer: {
    app,
    modals,
    actionsModals,
    packages,
    customer,
    picture,
    groups,
    images,
    filtersSelected,
    filters,
    familyQuiz,
    compositions,
    imageAttributions,
    imageIdentical,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
