import { axiosInstance } from '../assets/utils/axiosInstance';
import { Toast } from '../assets/utils/toast';

//Create
export const createOnePicture = async (body) => {
  const res = await axiosInstance.post(`/picture/create`, body).catch((err) => {
    console.log('createOnePicture', err);
  });
  return res;
};

export const createSamePicture = async (pictureId, body) => {
  const res: any = await axiosInstance
    .post(`/picture/create-same-picture/${pictureId}`, body)
    .catch((err) => {
      console.log('createSamePicture', err);
    });
  return res.data;
};

//Read

export const fetchPictureById = async (id) => {
  const res = await axiosInstance.get(`/picture/by-id/${id}`).catch((err) => {
    console.log('fetchPictureById', err);
    return Toast('error', "Une erreur s'est produit !");
  });

  return res?.data;
};

export const fetchPicturesByGroupId = async (groupId) => {
  const res = await axiosInstance
    .get(`/picture/get-all/by-groupId/${groupId}`)
    .catch((err) => {
      console.log('fetchPicturesByGroupId', err);
      return Toast(
        'error',
        "Une erreur s'est produite lors de la récupération des images similaires !"
      );
    });
  return res;
};

export const fetchPicturesByFilters = async (
  { body, universResto },
  signal
) => {
  let query = '';

  if (universResto) {
    const universRestoFormated: any = dataPicture(universResto, true);
    universRestoFormated.tabs = universResto?.tabs;
    universRestoFormated.attributed = universResto?.attributed;
    const universRestoKey = Object.keys(universRestoFormated)?.filter(
      (u) => universRestoFormated[u]?.length > 0
    );
    const firstKey = universRestoKey[0];
    query = `?${firstKey}=${universRestoFormated[firstKey]}`;
    universRestoKey
      .slice(1)
      .forEach((key) => (query += `&${key}=${universRestoFormated[key]}`));
  }

  try {
    const res = await axiosInstance.post(
      `/picture/by-filter${query}`,
      {
        ...dataPicture(body),
        page: body?.page,
        limit: body?.limit,
        tabs: body?.tabs,
        attributed: body?.attributed,
        search: body?.search,
        customerId: body?.customerId,
      },
      {
        signal: signal,
      }
    );

    return res?.data;
  } catch (err) {
    if (err?.message !== 'canceled') {
      Toast('error', "Une erreur s'est produite !");
    }
    // // check if the error is a cancelation
    // if (axiosInstance.isCancel(err)) {
    //   console.log('Request canceled', err.message);
    // }

    return null;
  }
};

const dataPicture = (body, isQuery = false) => {
  const _mapElement = (el) => el._id;

  const products = body?.families?.flatMap((family) => family.products);

  const compositions = products
    ?.flatMap((prod) => prod.compositions)
    ?.filter((comp) => (isQuery ? comp.type === 'principal' : true));

  const subSpecialsFilters = body?.specialsFilters?.flatMap(
    (filter) => filter.subSpecialFilters
  );

  const filtersValues = body?.filters?.flatMap((filter) => filter.filterValues);

  return {
    families: body?.families?.filter((f) => f.selected).map(_mapElement),
    products: products?.filter((prod) => prod.selected).map(_mapElement),
    compositions: compositions?.map(_mapElement),
    styles: body?.styles?.map(_mapElement),
    moods: body?.moods,
    filters: body?.filters?.filter((f) => f.selected).map(_mapElement),
    filterValues: filtersValues?.map(_mapElement) || [],
    specialsFilters: body?.specialsFilters
      .filter((f) => f.selected)
      .map(_mapElement),
    subSpecialsFilters: subSpecialsFilters?.map(_mapElement),
  };
};

export const fetchInstaFilPictures = async (imagesIds) => {
  try {
    const res = await axiosInstance.post(`/picture/instagram-feed`, imagesIds);
    return res?.data;
  } catch (err) {
    Toast('error', "Une erreur s'est produite !");
    return null;
  }
};

export const fetchPicturesByFiltersCounter = async (
  { body, universResto },
  signal
) => {
  let query = '';

  if (universResto) {
    const universRestoFormated: any = dataPicture(universResto, true);
    universRestoFormated.tabs = universResto?.tabs;
    universRestoFormated.attributed = universResto?.attributed;
    const universRestoKey = Object.keys(universRestoFormated)?.filter(
      (u) => universRestoFormated[u]?.length > 0
    );
    const firstKey = universRestoKey[0];
    query = `?${firstKey}=${universRestoFormated[firstKey]}`;
    universRestoKey
      .slice(1)
      .forEach((key) => (query += `&${key}=${universRestoFormated[key]}`));
  }

  try {
    const res = await axiosInstance.post(
      `/picture/by-filter-counters${query}`,
      {
        ...dataPicture(body),
        tabs: body.tabs,
        attributed: body.attributed,
        customerId: body?.customerId,
      },
      {
        signal: signal,
      }
    );

    return res?.data;
  } catch (err) {
    if (err?.message !== 'canceled') {
      Toast('error', "Une erreur s'est produite !");
    }
    return null;
  }
};

export const fetchPicturesByStrategy = async (body, universResto) => {
  let query = '';

  if (universResto) {
    const universRestoFormated: any = dataPicture(universResto, true);
    universRestoFormated.tabs = universResto?.tabs;
    universRestoFormated.attributed = universResto?.attributed;
    const universRestoKey = Object.keys(universRestoFormated)?.filter(
      (u) => universRestoFormated[u]?.length > 0
    );
    const firstKey = universRestoKey[0];
    query = `?${firstKey}=${universRestoFormated[firstKey]}`;
    universRestoKey
      .slice(1)
      .forEach((key) => (query += `&${key}=${universRestoFormated[key]}`));
  }  
  const res = await axiosInstance
    .post(`/pictures/by-strategy${query}`, body)
    .catch((err) => {
      console.log('Error => fetchPicturesByStrategy', err);
      return Toast('error', "Une erreur s'est produit !");
    });
  return res?.data;
};

//Update
export const updateOnePicture = async (id: string, body) => {
  const res = await axiosInstance.put(`/picture/${id}`, body).catch((err) => {
    return Toast('error', "Une erreur s'est produit !");
  });
  return res;
};

//Delete
export const deleteOnePicture = async (id) => {
  const res = await axiosInstance
    .delete(`/picture/delete/${id}`)
    .catch((err) => {
      console.log('deleteOnePicture', err);
      return { data: null, success: false, error: err };
    });
  return { data: res.data, success: true, error: null };
};
