import { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchSpecialFilters } from '../../api/specialsFilters.api';
import { FilterImageCollapse } from '../atoms/Collapse';
import { FilterAccordion } from '../organisms/Sidebar/FilterAccordion';

export const FiltersSpecialsCollapse = ({ show, setShow }) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const { data } = useQuery('specialsFilters', () => fetchSpecialFilters(''));

  return (
    <FilterImageCollapse
      label={'Filtres spéciaux'}
      show={show}
      setShow={setShow}
      id={'specialsFilters'}
    >
      {data?.map((item, index) => {
        return (
          <FilterAccordion
            key={index}
            open={openAccordion}
            setOpen={setOpenAccordion}
            data={item}
            parentKey={'specialsFilters'}
            itemsKey={'subSpecialFilters'}
          />
        );
      })}
    </FilterImageCollapse>
  );
};
