export const useDropFile = (
  onChange: (e: any) => void,
  options: Partial<{
    type: 'target' | 'file';
    name: string;
  }> = {
    type: 'target',
  }
) => {
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
      onChange(
        options?.type === 'target'
          ? {
              target: {
                name: options.name,
                files: e.dataTransfer?.files,
              },
            }
          : e.dataTransfer?.files[0]
      );
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return {
    handleDrop,
    handleDragOver,
  };
};
