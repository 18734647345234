import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { fetchCitiesByFilters } from '../../../api/cities.api';
import { citiesLetters, colors } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { editCustomerInfoAction } from '../../../redux/customer';
import {
  citiesListModalAction,
  citiesModalAction,
} from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';
import { TableHeader } from '../../atoms/TableHeader';
import { HeaderCities } from '../../organisms/Header';
import { NotFound } from '../../atoms/NotFound';

export const CitiesListModal = () => {
  const dispatch = useDispatch();

  const customer = useAppSelector((state) => state.customer.customer);

  const [city, setCity] = useState(customer?.informations?.restaurant_city);

  const [filters, setFilters] = useState({
    letter: 'a',
    name: '',
    country: customer?.informations?.restaurant_country ?? 'france',
    page: 1,
    limit: 10,
    totalPages: 0,
    currentPage: 1,
    totalCities: 0,
  });

  const [cities, setCities] = useState([]);
  const [lastPage, setLastPage] = useState(null);

  const { data } = useInfiniteQuery(
    ['cities', filters],
    () => fetchCitiesByFilters(filters),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.currentPage > lastPage.totalPages) return;
        return lastPage?.currentPage + 1;
      },
    }
  );

  const handleHandleValidate = () => {
    dispatch(
      editCustomerInfoAction({
        tab: 'informations',
        value: {
          ...customer?.informations,
          restaurant_country: filters.country,
          restaurant_city: city,
        },
      })
    );

    handleClose();
  };

  const handleClose = () => dispatch(citiesListModalAction(false));

  useEffect(() => {
    if (data) {
      setCities(data?.pages.flatMap((page) => page.cities));
      setLastPage(data?.pages[data?.pages.length - 1]);
    }
  }, [data]);

  return (
    <Modal
      onClose={() => dispatch(citiesListModalAction(false))}
      title='Selectionner pays et ville'
      onValidate={handleHandleValidate}
      width='100%'
      minHeight='100vh'
      left='0'
      top='0'
      bgWidth='100%'
      fixedFooter
    >
      <HeaderCities
        hiddeAvatar={true}
        setFilters={setFilters}
        filters={filters}
      />

      <div className='pages-layout--container'>
        <TableHeader
          title={`Villes (${cities?.length} sur ${lastPage?.totalCities})`}
          onAdd={() => {
            dispatch(citiesModalAction(true));
          }}
        />
        <div className='cities--letters'>
          <span>Filtrer par lettre :</span>
          {citiesLetters?.map((letter) => (
            <button
              key={letter}
              className={`cities--letter cities--letter-${
                filters.letter === letter ? 'active' : ''
              }`}
              onClick={() =>
                setFilters((s) => ({
                  ...s,
                  name: '',
                  letter: letter,
                  page: 1,
                  limit: 20,
                }))
              }
            >
              {letter}
            </button>
          ))}
        </div>
        <div className='cities'>
          {cities.length === 0 ? (
            <NotFound text='Pas de ville trouvée !' />
          ) : (
            copyObj(cities)
              ?.sort((a, b) => sortedList(a, b, 'name'))
              .map((el, index) => {
                return (
                  <Trow
                    city={el}
                    key={index}
                    onSelect={() => setCity(el.name)}
                    selected={city}
                  />
                );
              })
          )}
        </div>
        <div className='cities--footer'>
          <div className='cities--footer-page'>
            <p>
              Page{' '}
              {`${lastPage?.currentPage ?? ''} sur ${
                lastPage?.totalPages ?? ''
              }`}
            </p>
            <Select
              width={120}
              options={Array(lastPage?.totalPages ?? 0)
                .fill('a')
                .map((_, index) => ({ name: index + 1, value: index + 1 }))}
              onChange={(e) =>
                setFilters((s) => ({ ...s, page: e.target.value }))
              }
              value={filters.page}
            />
          </div>
          <Button
            variant='black'
            label='Voir plus'
            onClick={() =>
              setFilters((s) => ({ ...s, limit: filters.limit + 20 }))
            }
          />
        </div>
      </div>
    </Modal>
  );
};

const Trow = ({ city, onSelect, selected }) => {
  return (
    <div
      className='table-trow'
      onClick={onSelect}
      style={{
        cursor: 'pointer',
        border: selected === city?.name && `1px solid ${colors.yellow}`,
      }}
    >
      <span className='table-trow-text'>{city?.name}</span>
    </div>
  );
};
