export const EditIcon = ({ margin }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin }}
    >
      <path
        d='M15.4142 0.585786C14.6332 -0.195262 13.3668 -0.195262 12.5858 0.585786L5 8.17157V11H7.82842L15.4142 3.41421C16.1953 2.63316 16.1953 1.36683 15.4142 0.585786Z'
        fill='#282828'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 4C0 2.89543 0.89543 2 2 2H6C6.55228 2 7 2.44772 7 3C7 3.55228 6.55228 4 6 4H2V14H12V10C12 9.44772 12.4477 9 13 9C13.5523 9 14 9.44772 14 10V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V4Z'
        fill='#282828'
      />
    </svg>
  );
};
