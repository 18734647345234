import { useQuery } from 'react-query';
import { fetchMoods } from '../../../api/moods.api';
import { colors } from '../../../assets/constants';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { imageFiltersMoodsAction } from '../../../redux/images';

export const MoodsList = ({
  disabled = false,
  justifyContent = 'flex-start',
  localMoodsSelected,
  onSelectMood,
  moodAlreadySelected,
}) => {
  //

  const dispatch = useAppDispatch();
  const role = useAppSelector((s) => s.app.role);
  const moodsRedux = useAppSelector((s) => s.images.moods);
  const { data, isLoading } = useQuery('moods', () => fetchMoods(''));
  const moods = localMoodsSelected ?? moodsRedux;

  const handleSelectMood = (mood) => {
    if (role !== 'ADMIN' && role !== 'SUPER-ADMIN') return;

    if (!!onSelectMood) {
      onSelectMood(mood);
    } else {
      dispatch(imageFiltersMoodsAction(mood));
    }
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }

  return (
    <div className='moods--container'>
      <p className='moods--title'>Selectionner son mood</p>
      <div className='moods' style={{ justifyContent }}>
        {data?.map((mood) => {
          const active = moods?.find((moodId) => moodId === mood.label);
          return (
            <button
              key={mood._id}
              disabled={disabled || moodAlreadySelected?.includes(mood.label)}
              onClick={() => handleSelectMood(mood.label)}
              className='moods--mood'
            >
              <img
                style={{
                  borderColor: active ? colors.yellow : 'transparent',
                }}
                src={helperImageRoute(mood?.image, 'mood')}
                alt={mood.label}
              />
              <p>{mood.label}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
};
