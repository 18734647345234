import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6] }],
      [{ size: ['extra-small', 'small', 'medium', 'large'] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ['link', 'image'],
    ],
  },
};

const styles = {
  width: '100%',
  overflowY: 'hidden',
  boxSizing: 'border-box',
  position: 'relative',
  border: '1px solid #ebebeb',
  fontFamily: 'Open Sans Medium',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 14,
};

const TextEditor = ({
  label,
  value = '',
  defaultValue,
  onChange,
  minHeight = 200,
  basicInput,
}) => {
  return (
    <div className='fields' style={{ margin: '1rem 0', width: '100%' }}>
      <label className='fields--label'>{label}</label>
      {basicInput ? (
        <textarea
          style={{ ...styles, minHeight, padding: '1rem', outline: 'none' }}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          defaultValue={defaultValue}
        />
      ) : (
        <ReactQuill
          style={{ ...styles, maxHeight: 400, minHeight }}
          theme='snow'
          modules={modules}
          onChange={(val) => onChange(val)}
          value={value}
          defaultValue={defaultValue}
        />
      )}
    </div>
  );
};

export default TextEditor;
