export const ArrowSmallBottom = ({
  color = '#282828',
  margin = '0 0 0 auto',
}) => {
  return (
    <svg
      width='10'
      height='4'
      viewBox='0 0 10 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin }}
    >
      <path
        d='M-1.74846e-07 4.76837e-07L5 4L10 1.32966e-07L-1.74846e-07 4.76837e-07Z'
        fill={color}
      />
    </svg>
  );
};
