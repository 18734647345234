export const CustomerIcon = ({ color = '#282828' }) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 2.85418C10.7329 2.02375 11.8053 1.5 13 1.5C15.2091 1.5 17 3.29086 17 5.5C17 7.70914 15.2091 9.5 13 9.5C11.8053 9.5 10.7329 8.97624 10 8.14582M13 19.5H1V18.5C1 15.1863 3.68629 12.5 7 12.5C10.3137 12.5 13 15.1863 13 18.5V19.5ZM13 19.5H19V18.5C19 15.1863 16.3137 12.5 13 12.5C11.9071 12.5 10.8825 12.7922 10 13.3027M11 5.5C11 7.70914 9.20914 9.5 7 9.5C4.79086 9.5 3 7.70914 3 5.5C3 3.29086 4.79086 1.5 7 1.5C9.20914 1.5 11 3.29086 11 5.5Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
