export const GridIcon = ({ color = '#282828' }) => {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 11V17M11 14H17M3 7H5C6.10457 7 7 6.10457 7 5V3C7 1.89543 6.10457 1 5 1H3C1.89543 1 1 1.89543 1 3V5C1 6.10457 1.89543 7 3 7ZM13 7H15C16.1046 7 17 6.10457 17 5V3C17 1.89543 16.1046 1 15 1H13C11.8954 1 11 1.89543 11 3V5C11 6.10457 11.8954 7 13 7ZM3 17H5C6.10457 17 7 16.1046 7 15V13C7 11.8954 6.10457 11 5 11H3C1.89543 11 1 11.8954 1 13V15C1 16.1046 1.89543 17 3 17Z'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
