import { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { useDropFile } from '../../../hooks/useDropFile';

export const UploadImg = ({
  image = null,
  onChange,
  margin,
  folder = 'mood',
}) => {
  const [loaded, setLoaded] = useState(false);

  const { handleDrop, handleDragOver } = useDropFile(onChange, {
    type: 'file',
  });

  const onLoadedImage = () => {
    setLoaded(true);
  };

  return (
    <div
      className='upload'
      style={{ margin }}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {image && (
        <>
          <img
            src={
              image?.name
                ? URL.createObjectURL(image)
                : helperImageRoute(image, folder)
            }
            alt='pic upload'
            width={60}
            onLoad={onLoadedImage}
          />
          {!loaded && (
            <div className='blurhash'>
              <Blurhash
                hash='LKN]Rv%2Tw=w]~RBVZRi};RPxuwH'
                width={60}
                height={60}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </div>
          )}
        </>
      )}

      <input
        type='file'
        name='image'
        id='image'
        accept='image/png, image/jpeg, image/gif'
        hidden
        onChange={(e) => {
          onChange(e.target.files[0]);
          e.target.value = null;
        }}
      />
      <label className='upload--label' htmlFor='image'>
        {image ? 'Changer' : 'Ajouter une image'}
      </label>
    </div>
  );
};
