import React from 'react';

export const PlusGrayIcon: React.FC<{
  onClick: (e: React.MouseEvent<SVGSVGElement>) => void;
}> = ({ onClick }) => {
  return (
    <svg
      width='58'
      height='58'
      viewBox='0 0 58 58'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      cursor='pointer'
    >
      <g filter='url(#filter0_d_0_1)'>
        <circle cx='29' cy='26' r='15' fill='#D9D9D9' />
        <circle cx='29' cy='26' r='14.5' stroke='#B9B9B9' />
      </g>
      <path
        d='M34 26.7143H30.1429V31H28.8571V26.7143H25V25.2857H28.8571V21H30.1429V25.2857H34V26.7143Z'
        fill='black'
      />
      <defs>
        <filter
          id='filter0_d_0_1'
          x='0'
          y='0'
          width='58'
          height='58'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='7' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_0_1'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_0_1'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
