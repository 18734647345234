import { CollapseItemImgFilter } from '../../../views/online/CustomersView/CollapseItemImgFilter';
import { CollapseItemImgSpecialsFilters } from '../../../views/online/CustomersView/CollapseItemImgSpecialsFilters';
import { CollapseItemImgStyles } from '../../../views/online/CustomersView/CollapseItemImgStyles';
import { MoodsList } from '../../atoms/MoodsList';

export const UniversFilters = () => {
  return (
    <>
      <div className='customer--universpanel-header'>
        <p>Selectionner les categories liées aux client</p>
      </div>
      <CollapseItemImgStyles />
      <CollapseItemImgFilter />
      <CollapseItemImgSpecialsFilters />

      {/* Ce component sert a lister les moods et les sélectionnés */}
      <MoodsList />
    </>
  );
};
