import { useEffect, useState } from 'react';
import Logo from '../../assets/images/logo.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/atoms/Buttons';
import { Input } from '../../components/atoms/Fields';
import { axiosInstance } from '../../assets/utils/axiosInstance';
import { Toast, toast_loader } from '../../assets/utils/toast';
import { toast } from 'react-toastify';
import { Version } from '../../components/atoms/Version';
import { resetPassword } from '../../api/auth.api';

export const ResetPasswordView = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [user, setUser] = useState(null);

  const [passwords, setPasswords] = useState({
    password: '',
    confirmPassword: '',
  });

  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirmPassword: false,
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (passwords.confirmPassword !== passwords.password) {
      setLoading(false);
      return Toast('warning', 'Mots de passe ne correspondent pas !');
    }
    const loader = toast.loading('Veuillez patienter...');
    await resetPassword(user?._id, passwords.password)
      .then((res) => {
        setLoading(false);
        setPasswords({ password: '', confirmPassword: '' });
        toast_loader(loader, 'Votre mot de passe a été modifié !', 'success');
        navigate('/');
      })
      .catch((err) => {
        setLoading(false);
        toast_loader(loader, "Une erreur s'est produite !", 'error');
      });
    setLoading(false);
  };

  useEffect(() => {
    axiosInstance
      .get(`/check-auth-user/${token}`)
      .then((res) => {
        setUser(res.data?.user);
      })
      .catch((err) => {
        Toast('error', "Une erreur s'est produite !");
      });
  }, [token]);

  return (
    <div className='offline'>
      <form className='offline--container' onSubmit={handleSubmit}>
        <img src={Logo} alt='logo' />
        <p className='offline--title'>Mot de passe oublié ? </p>
        <p className='offline--text' style={{ marginBottom: '2rem' }}>
          Saisissez votre e-mail !
        </p>
        <Input
          label='Mot de passe'
          type={showPasswords.password ? 'text' : 'password'}
          onChange={(e) =>
            setPasswords((s) => ({ ...s, password: e.target.value }))
          }
          value={passwords.password}
          icon
          onShowPassword={() =>
            setShowPasswords((s) => ({ ...s, password: !s.password }))
          }
        />
        <Input
          label='Confirmé mot de passe'
          type={showPasswords.confirmPassword ? 'text' : 'password'}
          onChange={(e) =>
            setPasswords((s) => ({ ...s, confirmPassword: e.target.value }))
          }
          value={passwords.confirmPassword}
          icon
          onShowPassword={() =>
            setShowPasswords((s) => ({
              ...s,
              confirmPassword: !s.confirmPassword,
            }))
          }
        />
        <button
          className='offline--text btn--neutral'
          style={{ margin: ' 0 0 2rem auto' }}
          onClick={() => navigate('/')}
          type='button'
        >
          Retour à la connexion !
        </button>
        <Button
          label={loading ? 'Modifier mot de passe...' : 'Modifier mot de passe'}
          margin='auto 0 0'
          width='100%'
          disabled={loading}
        />
      </form>
      <Version />
    </div>
  );
};
