export const PencilBlackIcon = ({ onClick = () => {}, disabled = false }) => {
  return (
    <svg
      width='62'
      height='57'
      viewBox='0 0 62 57'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      cursor='pointer'
      onClick={disabled ? () => {} : onClick}
    >
      <g filter='url(#filter0_d_0_1)'>
        <path
          d='M6 10C6 6.68629 8.68629 4 12 4H50C53.3137 4 56 6.68629 56 10V43C56 46.3137 53.3137 49 50 49H12C8.68629 49 6 46.3137 6 43V10Z'
          fill='#282828'
        />
      </g>
      <path
        d='M41.9367 15.6025L38.8699 13.6749C38.4982 13.4417 38.0491 13.3657 37.6213 13.4636C37.1935 13.5615 36.8222 13.8253 36.5889 14.1969L35.379 16.1218L41.2479 19.8057L42.4568 17.8826C42.9426 17.1078 42.7106 16.0883 41.9367 15.6025ZM24.9399 32.7433L30.807 36.4281L40.3688 21.205L34.5 17.5192L24.939 32.7442L24.9399 32.7433ZM24.0428 37.425L23.9141 40.8869L26.9772 39.2665L29.8228 37.764L24.1606 34.206L24.0428 37.425Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_d_0_1'
          x='0'
          y='0'
          width='62'
          height='57'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='2' />
          <feGaussianBlur stdDeviation='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_0_1'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_0_1'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
