import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  createRayonOneIngredientSpecialty,
  updateRayonOneIngredientSpecialty,
} from '../../../api/rayons.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useRayons } from '../../../hooks/useRayons';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { rayonIngredientSpecialtyModalAction } from '../../../redux/modals';
import { Input, Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';
import { UploadImg } from '../../atoms/UploadImg';

export const RayonIngredientSpecialtyModal = () => {
  const dispatch = useDispatch();

  const rayonIngredientSpecialty = useAppSelector(
    (state) => state.filtersSelected.rayonIngredientSpecialty
  );

  const rayonIngredient = useAppSelector(
    (state) => state.filtersSelected.rayonIngredient
  );

  const rayonSelected = useAppSelector((state) => state.filtersSelected.rayon);

  const { rayons, ingredients, rayonId, handleSelectRayon } = useRayons(
    rayonSelected?._id
  );
  const [ingredientId, setIngredientId] = useState(
    rayonIngredientSpecialty?.ingredientId || ''
  );
  const [loading, setLoading] = useState(false);

  const [specialty, setSpecialty] = useState({
    label: rayonIngredientSpecialty?.label || '',
    image: rayonIngredientSpecialty?.image || null,
    rayonId: rayonIngredientSpecialty?.rayonId || rayonId || rayonSelected?._id,
    ingredientId:
      rayonIngredientSpecialty?.ingredientId ||
      ingredientId ||
      rayonIngredient?._id,
  });

  const { resetFilters } = useResetFilters();

  const onClose = () => {
    dispatch(rayonIngredientSpecialtyModalAction(false));
    dispatch(resetFilteredSelectedItemAction('rayonIngredientSpecialty'));
  };

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      if (!specialty.label)
        return toast_loader(loader, 'Veuillez renseigner un nom', 'error');

      let res;
      let update = false;

      setLoading(true);
      if (rayonIngredientSpecialty) {
        res = await updateRayonOneIngredientSpecialty(
          rayonIngredientSpecialty?._id,
          ingredientId,
          rayonId,
          specialty
        );
        update = true;
      } else {
        res = await createRayonOneIngredientSpecialty(rayonId, specialty);
      }

      if (res.data.success) {
        resetFilters(['rayons', 'rayon']);

        toast_loader(
          loader,
          update ? 'Specialité modifié' : 'Specialité créé',
          'success'
        );

        onClose();
      } else {
        toast_loader(loader, res.data.message, 'error');
      }

      setLoading(false);
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite !", 'error');
    }
  };

  return (
    <Modal
      onClose={onClose}
      title='Specialité'
      onValidate={handleSubmit}
      loading={loading}
    >
      <UploadImg
        image={specialty.image}
        onChange={(file) => setSpecialty((s) => ({ ...s, image: file }))}
        margin='0 auto 0 0'
        folder='picture'
      />

      <Input
        label='Nom'
        type='text'
        onChange={(e) => setSpecialty((s) => ({ ...s, label: e.target.value }))}
        value={specialty.label}
      />

      <Select
        label='Rayons'
        options={rayons?.map((family) => ({ ...family, value: family._id }))}
        defaultValue={
          rayonSelected
            ? { value: rayonSelected._id, name: rayonSelected.label }
            : null
        }
        value={rayonId}
        onChange={(e) => handleSelectRayon(e.target.value)}
      />

      <Select
        label='ingredients'
        options={ingredients?.map((ing) => ({
          ...ing,
          value: ing._id,
        }))}
        defaultValue={
          rayonIngredient
            ? { value: rayonIngredient._id, name: rayonIngredient.label }
            : null
        }
        value={ingredientId}
        onChange={(e) => setIngredientId(e.target.value)}
      />
    </Modal>
  );
};
