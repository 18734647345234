import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  fetchFamilies,
  getAllCompositionsByFamilyId,
} from '../../../api/families.api';
import { Loader } from '../../../components/atoms/Loader';
import { CenterBlock } from '../../../components/organisms/ImageAttributions/CenterBlock';
import { LeftBlock } from '../../../components/organisms/ImageAttributions/LeftBlock';
import { RightBlock } from '../../../components/organisms/ImageAttributions/RightBlock';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { compositionsAction } from '../../../redux/compositions';
import { familiesImgAttrAction } from '../../../redux/imageAttributions';

export const ImageAttributionsView: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const { familySelectedImgAttr } = useAppSelector(
    (state) => state.imageAttributions
  );

  const [loading, setLoading] = useState<boolean>(false);

  const { data: compositionsData, isLoading: isLoadingCompositions } = useQuery(
    ['compositions', familySelectedImgAttr?.familyId],
    () => {
      if (familySelectedImgAttr?.familyId) {
        return getAllCompositionsByFamilyId(familySelectedImgAttr.familyId);
      }
      return Promise.resolve(null);
    }
  );

  const getFamilies = useCallback(() => {
    (async () => {
      setLoading(true);
      const res = await fetchFamilies();
      if (!!res) {
        setLoading(false);
        dispatch(familiesImgAttrAction(res));
      }
      setLoading(false);
    })();
  }, [dispatch]);

  const getCompositions = useCallback(() => {
    if (familySelectedImgAttr?.familyId && !!compositionsData?.data) {
      dispatch(compositionsAction(compositionsData?.data));
    }
  }, [dispatch, compositionsData, familySelectedImgAttr?.familyId]);

  useEffect(() => {
    getFamilies();
  }, [getFamilies]);

  useEffect(() => {
    getCompositions();
  }, [getCompositions]);

  if (loading && isLoadingCompositions) return <Loader />;

  return (
    <div className='imageAttributions'>
      <LeftBlock />
      <CenterBlock />
      <RightBlock />
    </div>
  );
};
