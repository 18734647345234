import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { protectedRoutes } from '../assets/utils/protectedRoutes';
import { useAppDispatch, useAppSelector } from '../hooks/useReduxHook';
import { getUserData, onGetUserRole } from '../redux/app';

export const withCheckAuth = (WrappedComponent) => (props) => {
  const online = useAppSelector((s) => s.app.online);
  const role = useAppSelector((s) => s.app.role);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const userData = localStorage.getItem('slm-user-data');
    dispatch(getUserData(JSON.parse(userData)));
    dispatch(
      onGetUserRole(JSON.parse(localStorage.getItem('slm-user-data'))?.role)
    );
  }, [dispatch]);

  useEffect(() => {
    if (role && online) {
      protectedRoutes(role, location?.pathname, navigate);
    }
  }, [role, location?.pathname, navigate, online]);

  return <WrappedComponent {...props} role={role} />;
};
