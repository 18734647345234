import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  createOneProduct,
  fetchFamilies,
  updateOneProduct,
} from '../../../api/families.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { familyProductsModalAction } from '../../../redux/modals';
import { Input, Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

//TODO Rendre autonome et vider les props
export const FamilyProductModal = (props) => {
  //

  const dispatch = useAppDispatch();
  const familySelected = useAppSelector(
    (state) => state.filtersSelected.family
  );
  const productSelected = useAppSelector(
    (state) => state.filtersSelected.product
  );
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState(productSelected?.name || '');
  const [familyId, setFamilyId] = useState(familySelected?._id || '');

  const { data: families } = useQuery('families', () => {
    return fetchFamilies('');
  });

  const { resetFilters } = useResetFilters();

  const handleSubmit = async () => {
    if (loading) return;
    const loader = toast.loading('Veuillez patienter...');
    try {
      setLoading(true);
      if (productSelected) {
        
        await updateOneProduct(
          productSelected?._id,
          productSelected?.familyId || productSelected?.family?._id,
          {
            name: productName ?? productSelected?.name,
            familyId,
          }
        );
        toast_loader(loader, 'Produit Modifié', 'success');
      } else {
        //create
        const res = await createOneProduct(familyId, productName);

        if (res.data.success === false) {
          toast_loader(loader, res.data.message, 'error');
          return;
        } else {
          toast_loader(loader, 'Produit ajouté', 'success');
        }
      }

      resetFilters(['family', 'product', 'customer', 'families']);
      dispatch(resetFilteredSelectedItemAction('product'));

      dispatch(familyProductsModalAction(false));
      setLoading(false);
    } catch (err) {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
      setLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(resetFilteredSelectedItemAction('product'));
    dispatch(familyProductsModalAction(false));
  };

  return (
    <Modal
      bgWidth={props?.bgWidth}
      zIndex={700}
      onClose={handleClose}
      title='Produit'
      onValidate={handleSubmit}
    >
      <Input
        label='Nom'
        type='text'
        onChange={(e) => setProductName(e.target.value)}
        value={productName}
      />
      <Select
        label='Famille'
        options={families?.map((family) => ({ ...family, value: family._id }))}
        defaultValue={
          familySelected
            ? { value: familySelected._id, name: familySelected.label }
            : null
        }
        value={familyId}
        onChange={(e) => setFamilyId(e.target.value)}
      />
    </Modal>
  );
};
