import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOneStyle, updateOneStyle } from '../../../api/styles.api';
import { Toast } from '../../../assets/utils/toast';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { stylesModalAction } from '../../../redux/modals';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

//TODO Vider les props
export const StylesModal = ({ bgWidth = '80%' }) => {
  // redux state
  const stylesModal = useSelector((state) => state.modals.stylesModal);
  const styleSelected = useSelector((state) => state.filtersSelected.style);

  // local state
  const [styleLabel, setStyleLabel] = useState(styleSelected?.label || '');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { resetFilters } = useResetFilters();

  const handleClose = () => {
    dispatch(resetFilteredSelectedItemAction('style'));
    dispatch(stylesModalAction(false));
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (styleSelected) {
      await updateOneStyle(styleSelected._id, styleLabel);
      Toast('success', 'Style modifié !');
    } else {
      await createOneStyle(styleLabel);
      Toast('success', 'Style ajouté');
    }

    resetFilters('styles');

    setLoading(false);
    setStyleLabel('');

    handleClose();
  };

  if (!stylesModal) return null;

  return (
    <Modal
      onClose={handleClose}
      title='Style'
      onValidate={handleSubmit}
      loading={loading}
      zIndex={700}
      bgWidth={bgWidth}
    >
      <Input
        label='Nom'
        type='text'
        value={styleLabel}
        onChange={(e) => setStyleLabel(e.target.value)}
      />
    </Modal>
  );
};
