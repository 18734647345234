import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateCustomerStrategyModel } from '../../../../api/customers.api';
import { copyObj } from '../../../../assets/utils/copyObj';
import { Toast } from '../../../../assets/utils/toast';
import { useFamilies } from '../../../../hooks/useFamilies';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import { useSpecialFilter } from '../../../../hooks/useSpecialFilter';
import {
  editCustomerInfoAction,
  strategyModelCompOptAction,
} from '../../../../redux/customer';
import {
  customerCompositionsModalAction,
  strategyModelModalAction,
} from '../../../../redux/modals';
import { Button } from '../../../atoms/Buttons';
import { Select } from '../../../atoms/Fields';
import { Modal } from '../../../atoms/Modal';
import { colors } from '../../../../assets/constants';
// import { arraysEqual } from '../../../../assets/utils/funcsUtils';

const generateNewStrategyModel = (strategyIndex) => {
  return {
    strategyIndex: strategyIndex,
    type: 'principal',
    familyId: null,
    productId: null,
    compositionId: null,
    specialFilterId: null,
    subSpecialFilterId: null,
  };
};

export const StrategyModelModal = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const customer = useAppSelector((state) => state.customer.customer);
  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );

  const currentSubscription = customer?.subscriptions[currentSubscriptionIndex];

  const handleAddNewStrategyModel = (strategyIndex) => {
    const newStrategiesModel = copyObj(customer?.strategyModel);

    const newStrategyModel = generateNewStrategyModel(strategyIndex);

    if (Array.isArray(newStrategiesModel[strategyIndex])) {
      newStrategiesModel[strategyIndex].push(newStrategyModel);
    } else {
      newStrategiesModel[strategyIndex] = [newStrategyModel];
    }

    dispatch(
      editCustomerInfoAction({
        tab: 'strategyModel',
        value: newStrategiesModel,
      })
    );
  };

  const handleRemoveStrategyModel = (strategyIndex, index) => {
    const newStrategiesModel = copyObj(customer?.strategyModel);

    if (isNaN(index)) {
      newStrategiesModel[strategyIndex] = [
        generateNewStrategyModel(strategyIndex),
      ];
      dispatch(
        editCustomerInfoAction({
          tab: 'strategyModel',
          value: newStrategiesModel,
        })
      );
      return;
    }

    newStrategiesModel[strategyIndex].splice(index, 1);
    dispatch(
      editCustomerInfoAction({
        tab: 'strategyModel',
        value: newStrategiesModel,
      })
    );
  };

  const handleSelectStrategyModel = ({
    strategyIndex,
    index,
    value,
    label,
  }) => {
    const newStrategiesModel = copyObj(customer?.strategyModel);

    if (isNaN(index)) {
      if (newStrategiesModel[strategyIndex]) {
        newStrategiesModel[strategyIndex][0] = {
          ...newStrategiesModel[strategyIndex][0],
          productId: newStrategiesModel[strategyIndex][0].productId,
          compositionId: newStrategiesModel[strategyIndex][0].compositionId,
          type: newStrategiesModel[strategyIndex][0].type,
          [label]: value,
        };
      } else {
        newStrategiesModel[strategyIndex] = [
          {
            ...newStrategiesModel[strategyIndex][0],
            productId: newStrategiesModel[strategyIndex][0].productId,
            compositionId: newStrategiesModel[strategyIndex][0].compositionId,
            type: newStrategiesModel[strategyIndex][0].type,
            [label]: value,
          },
        ];
      }
    } else {
      newStrategiesModel[strategyIndex][index] = {
        ...newStrategiesModel[strategyIndex][index],
        productId: newStrategiesModel[strategyIndex][index].productId,
        compositionId: newStrategiesModel[strategyIndex][index].compositionId,
        type: newStrategiesModel[strategyIndex][0].type,
        [label]: value,
      };
    }

    dispatch(
      editCustomerInfoAction({
        tab: 'strategyModel',
        value: newStrategiesModel,
      })
    );
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      await updateCustomerStrategyModel(customer._id, {
        strategyModel: customer?.strategyModel,
      });

      dispatch(
        editCustomerInfoAction({
          tab: 'strategyModel',
          value: customer?.strategyModel,
        })
      );
      Toast('success', 'Modèle de stratégie mis à jour avec succès');
      handleCloseModal();
      setLoading(false);
    } catch (error) {
      Toast('error', 'Une erreur est survenue❌');
      setLoading(false);
    }
  };

  const handleCloseModal = () => dispatch(strategyModelModalAction(false));

  useEffect(() => {
    for (let i = 0; i < currentSubscription.formula; i++) {
      const newStrategiesModel =
        customer?.strategyModel.length === currentSubscription.formula
          ? customer?.strategyModel
          : [
              ...customer?.strategyModel,
              customer.strategyModel[i]
                ? customer.strategyModel[i]
                : [generateNewStrategyModel(i)],
            ];
      dispatch(
        editCustomerInfoAction({
          tab: 'strategyModel',
          value: newStrategiesModel,
        })
      );
    }
  }, [customer?.strategyModel, currentSubscription, dispatch]);

  return (
    <Modal
      onClose={() => dispatch(strategyModelModalAction(false))}
      title='MODELE STRATEGIE'
      width='70%'
      left='25%'
      justifyContent='flex-start'
      minHeight='85%'
      hiddenBtns
      loading={loading}
      // bgWidth={bgWidth}
    >
      <div className='strategyModelModal--container'>
        {new Array(currentSubscription.formula).fill('').map((_, i) => {
          // const subStrate

          const strategies = customer?.strategyModel[i];

          return (
            <Fragment key={i + 1}>
              <Model
                number={i}
                strategy={strategies && strategies[0]}
                handleAddNewStrategyModel={handleAddNewStrategyModel}
                handleRemoveStrategyModel={handleRemoveStrategyModel}
                handleSelectStrategyModel={handleSelectStrategyModel}
              />

              {strategies?.length > 1 && (
                <>
                  {strategies.map((strategy, j) => {
                    if (j === 0) return null;
                    return (
                      <Model
                        key={i + j}
                        isDuplucate
                        strategy={strategy}
                        number={i}
                        index={j}
                        handleAddNewStrategyModel={handleAddNewStrategyModel}
                        handleRemoveStrategyModel={handleRemoveStrategyModel}
                        handleSelectStrategyModel={handleSelectStrategyModel}
                      />
                    );
                  })}
                </>
              )}
            </Fragment>
          );
        })}
      </div>
      <div className='strategyModelModal--footer'>
        <Button
          label='ANNULER'
          width={180}
          margin='0 .5rem'
          onClick={() => dispatch(strategyModelModalAction(false))}
        />
        <Button
          label={loading ? 'VALIDER...' : 'VALIDER'}
          disabled={loading}
          variant='yellow'
          width={180}
          margin='0 .5rem'
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  );
};

const Model = ({
  isDuplucate = false,
  number,
  handleAddNewStrategyModel,
  handleRemoveStrategyModel,
  handleSelectStrategyModel,
  index,
  strategy,
}) => {
  const dispatch = useAppDispatch();
  const {
    families,
    products,
    handleSelectFamily,
    handleSelectProduct,
    compositions,
  } = useFamilies(
    strategy?.familyId,
    strategy?.productId,
    false,
    strategy?.type === 'forCompose'
  );

  const { specialFilters, specialFilterValues, handleSelectSpecialFilter } =
    useSpecialFilter(strategy?.specialFilterId, false);
  //
  const strategyModel = useAppSelector(
    (state) => state.customer?.customer?.strategyModel
  );

  const familySelected = families?.find((_f) => _f?._id === strategy?.familyId);

  const composition = strategy?.compositionId
    ? compositions?.find(
        (_c: IComposition) =>
          (_c?.productId?._id || _c.productId) === strategy?.productId &&
          _c._id === strategy?.compositionId
      )
    : null;

  const handleSelectFamilies = (e) => {
    handleSelectFamily(e);
    handleSelectStrategyModel({
      strategyIndex: number,
      index,
      value: !e.target.value ? null : e.target.value,
      label: 'familyId',
    });
  };

  const handleSelectTypes = (e) => {
    handleSelectStrategyModel({
      strategyIndex: number,
      index,
      value: !e.target.value ? null : e.target.value,
      label: 'type',
    });
  };

  const handleSelectProducts = (e) => {
    handleSelectProduct(e);
    console.log('e.target.value :>> ', e.target.value);
    handleSelectStrategyModel({
      strategyIndex: number,
      index,
      value: !e.target.value ? null : e.target.value,
      label: 'productId',
    });
  };

  const handleSelectComposition = () => {
    if (!!!strategy?.productId) {
      return Toast('warning', 'Veuillez selectionner un produit avant !');
    }

    dispatch(
      editCustomerInfoAction({
        tab: 'strategyModel',
        value: strategyModel,
      })
    );
    dispatch(
      strategyModelCompOptAction({
        from: 'strategyModal',
        strategyIndex: number,
        index,
        label: 'compositionId',
        isStrategyModel: true,
      })
    );
    dispatch(customerCompositionsModalAction(true));
  };

  const handleSelectSpecialFilters = (e) => {
    handleSelectSpecialFilter(e);
    handleSelectStrategyModel({
      strategyIndex: number,
      index,
      value: !e.target.value ? null : e.target.value,
      label: 'specialFilterId',
    });
  };

  const handleSelectSpecialFilterValues = (e) => {
    handleSelectStrategyModel({
      strategyIndex: number,
      index,
      value: !e.target.value ? null : e.target.value,
      label: 'subSpecialFilterId',
    });
  };

  return (
    <div className='strategyModelModal--model'>
      <span
        className='strategyModelModal--model-number'
        style={{ fontSize: isDuplucate ? 13 : 22 }}
      >
        {isDuplucate ? 'ou' : number + 1}
      </span>
      <Select
        width='20%'
        margin='0 .5rem 0 0'
        label={isDuplucate ? '' : 'Famille'}
        options={
          families?.map((family) => ({ ...family, value: family._id })) || []
        }
        onChange={handleSelectFamilies}
        value={strategy?.familyId}
        defaultValue={{
          name: 'Toutes les familles',
        }}
      />

      <Select
        width='20%'
        margin='0 .5rem 0 0'
        label={isDuplucate ? '' : 'Types'}
        options={
          familySelected?.forCompose
            ? [
                { name: 'Principal', value: 'principal' },
                {
                  name: 'fait à composer',
                  value: 'forCompose',
                },
              ]
            : [{ name: 'Principal', value: 'principal' }]
        }
        onChange={handleSelectTypes}
        value={strategy?.type}
        defaultValue={{
          name: 'Tout les types',
        }}
      />

      <Select
        width='20%'
        margin='0 .5rem 0 0'
        label={isDuplucate ? '' : 'Produit'}
        options={products}
        value={strategy?.productId}
        onChange={handleSelectProducts}
        defaultValue={{
          name: 'Tous les produits',
        }}
      />

      <div style={{ width: '20%', display: 'flex', flexDirection: 'column' }}>
        {!isDuplucate && (
          <label className='fields--label' style={{ marginBottom: '.6rem' }}>
            Compositions
          </label>
        )}

        <button
          onClick={handleSelectComposition}
          className='customer--textData customer--textData-btn'
          style={{ borderColor: !!composition ? colors.yellow : colors.grey }}
        >
          {!!composition ? composition?.short_name : 'Toutes les compositions'}
        </button>
      </div>

      <Select
        width='20%'
        margin='0 .5rem 0 0'
        label={isDuplucate ? '' : 'Filtre spécial'}
        options={specialFilters}
        value={strategy?.specialFilterId}
        onChange={handleSelectSpecialFilters}
        defaultValue={{
          name: 'Tous les filtres',
        }}
      />
      <Select
        width='20%'
        margin='0 .5rem 0 0'
        label={isDuplucate ? '' : 'Sous filtre spécial'}
        value={strategy?.subSpecialFilterId}
        options={specialFilterValues}
        onChange={handleSelectSpecialFilterValues}
        defaultValue={{
          name: 'Tous les sous filtres',
        }}
      />
      <div className='strategyModelModal--model-btns'>
        <button
          className='strategyModelModal--model-btnAdd'
          onClick={() => handleAddNewStrategyModel(number)}
        >
          +
        </button>
        <button
          className='strategyModelModal--model-btnRemove'
          onClick={() => handleRemoveStrategyModel(number, index)}
          disabled={!isDuplucate}
          style={
            !isDuplucate
              ? {
                  backgroundColor: 'transparent',
                  border: 'none',
                  boxShadow: 'none',
                }
              : {}
          }
        >
          x
        </button>
      </div>
    </div>
  );
};
