import {useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {fetchRayons, getOneRayonById} from '../api/rayons.api';
import {useAppSelector} from './useReduxHook';

export const useRayons = (
  defaultId,
  defaultIngredientId,
  enabledFetch = true
) => {
  const customer = useAppSelector((state) => state.customer.customer);
  const [rayonId, setRayonId] = useState(defaultId || '');
  const [ingredientId, setIngredientId] = useState(defaultIngredientId || '');

  const {data: rayonsData, isLoading} = useQuery(
    ['rayons'],
    () => fetchRayons(''),
    {
      enabled: enabledFetch,
    }
  );

  const {data: rayonData} = useQuery(
    ['rayon', rayonId],
    () => (rayonId ? getOneRayonById(rayonId) : null),
    {
      enabled: !!rayonId,
    }
  );

  const ingredients =
    rayonData?.data?.rayon?.ingredients?.map((product) => {
      return {
        name: product.label,
        value: product._id,
      };
    }) || [];

  const ingredientSelected =
    rayonData?.data?.rayon?.ingredients?.find(
      (product) => product._id === ingredientId
    ) || null;

  const specialties =
    ingredientSelected?.specialties?.map((ingredient) => {
      return {
        name: ingredient.label,
        value: ingredient._id,
      };
    }) || [];

  const handleSelectRayon = (arg) => {
    if (typeof arg === 'string') {
      setRayonId(arg);
    } else {
      setRayonId(arg.target.value);
    }
  };

  const handleSelectIngredient = (arg) => {
    if (typeof arg === 'string') {
      setIngredientId(arg);
    } else {
      setIngredientId(arg.target.value);
    }
  };

  useEffect(() => {
    if (defaultId) {
      setRayonId(defaultId);
    } else if (
      (enabledFetch && rayonsData) ||
      customer?.univers?.families?.length > 0
    ) {
      setRayonId((_familyId) =>
        !_familyId
          ? enabledFetch
            ? rayonsData[0]._id
            : customer?.univers?.families[0]._id
          : _familyId
      );
    } else {
      setRayonId('');
    }
  }, [rayonsData, defaultId, enabledFetch, customer?.univers]);

  useEffect(() => {
    setIngredientId(defaultIngredientId);
  }, [defaultIngredientId]);

  return {
    rayons: rayonsData || [],
    isLoading,
    specialties,
    ingredients,
    rayonId,
    handleSelectIngredient,
    handleSelectRayon,
  };
};
