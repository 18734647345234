import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SearchIcon } from '../../../../assets/svgs/SearchIcon';
import { copyObj } from '../../../../assets/utils/copyObj';
import {
  addPictureIdToAllAttributionAction,
  updateCustomerDiariesAction,
} from '../../../../redux/customer';
import { imagesListModalAction } from '../../../../redux/modals';

import store from '../../../../redux/storeConfig';
import { Button } from '../../../atoms/Buttons';
import { FilterCustomer } from '../../../atoms/FilterCustomer';
import { ImagesList } from '../../../organisms/Images/ImagesList';
import { SidebarImgLayout } from '../../../organisms/Sidebar';
import { useDebounce } from '../../../../hooks/useDebounce';
import { helperFindSamePicturesIdByPicture } from '../../../../assets/utils/helperFindSamePictures';
import { Toast } from '../../../../assets/utils/toast';

//TODO Rendre autonome
const ImagesListModal = () => {
  const dispatch = useDispatch();
  const [showSidebar, setShowSidebar] = useState(true);
  const [search, setSearch] = useState('');

  const searchDebounce = useDebounce(search, 500);

  const handleSelectPictureForCustomer = useCallback(
    async (picture) => {
      const formula = store.getState().customer.formula;
      const customer = store.getState().customer.customer;
      const currentSubscriptionIndex =
        store.getState().customer.currentSubscriptionIndex;
      const currentSubscription =
        customer?.subscriptions[currentSubscriptionIndex];

      const allAttributions = copyObj(customer?.allAttributions || []);

      const diaries = copyObj(currentSubscription?.diaries || []);

      const findAttribution = diaries.find(
        (attribution) =>
          attribution.formula_number === formula.formula_number &&
          attribution.month === formula.month &&
          attribution.year === formula.year
      );

      const oldPicture = findAttribution?.picture;

      if (findAttribution) {
        findAttribution.picture = picture;
      } else {
        if (allAttributions.length > 0) {
          if (allAttributions.find((pictureId) => pictureId === picture._id)) {
            Toast('error', 'Cette image ou son parent est déjà attribuée ');
            return;
          }
        }

        diaries.push({
          ...formula,
          month: formula.month,
          year: formula.year,
          picture: picture,
        });
      }

      const { oldsPictureIds, pictureIds } =
        await helperFindSamePicturesIdByPicture({
          oldPicture,
          picture,
        });

      dispatch(
        addPictureIdToAllAttributionAction({
          oldsPictureIds,
          pictureIds,
        })
      );

      dispatch(updateCustomerDiariesAction(diaries));

      dispatch(imagesListModalAction(false));
    },
    [dispatch]
  );

  return (
    <>
      <div
        onClick={() => {
          dispatch(imagesListModalAction(false));
        }}
        className='imagesListModal--bg'
      />
      <div className='imagesListModal'>
        <Header
          setSearch={setSearch}
          onClose={() => {
            dispatch(imagesListModalAction(false));
          }}
        />
        <div className='imagesLayout--contain'>
          <SidebarImgLayout showSidebar={showSidebar} />
          <div
            className='imagesLayout--container'
            style={{ width: showSidebar ? 'calc(100% - 300px)' : '100%' }}
          >
            <ImagesList
              search={searchDebounce}
              onSelectPicture={handleSelectPictureForCustomer}
              setShowSidebar={setShowSidebar}
              disabledDiary={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Header = ({ search, setSearch, onClose }) => {
  const handleClose = () => {
    onClose();
  };
  return (
    <div className='imagesLayout--header'>
      <Button label='Fermer' margin='0 4rem 0 0' onClick={handleClose} />
      <FilterCustomer />
      <div
        id='input'
        className='header--input'
        style={{ width: '50%', marginLeft: 'auto' }}
      >
        <SearchIcon />
        <input
          type='text'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
};

export default ImagesListModal;
