import React from 'react';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../api/uploadFile.api';
import { Toast, toast_loader } from '../../../assets/utils/toast';
import { Button } from '../../../components/atoms/Buttons';
import { CompositionCard } from '../../../components/organisms/CompositionsGestion/CompositionCard';
import { CreateCompositionCard } from '../../../components/organisms/CompositionsGestion/CreateCompositionCard';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  compositionsAction,
  compositionSelectedAction,
  compositionsSelectedAction,
  IComposition,
} from '../../../redux/compositions';
import {
  createComposition,
  getAllCompositionsByProductId,
} from '../../../api/families.api';
import { colors, initComposition } from '../../../assets/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { editCustomerInfoAction } from '../../../redux/customer';
import { useCustomer } from '../../../hooks/useCustomer';
import { helperCompositionsEqual } from '../../../assets/utils/helperCompositionEqual';
import {
  IQuestion,
  questionSelectedAction,
  quizAction,
} from '../../../redux/familyQuiz';
import { filteredSelectedItemAction } from '../../../redux/filtersSelected';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';

export const CompositionsBlock: React.FC = () => {
  //
  const { familyId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { handleUpdateCustomer, loading } = useCustomer();
  //
  const compositions = useAppSelector((s) => s.compositions?.compositions);

  const imgCompositionsSelected = useAppSelector(
    (s) => s.imageAttributions?.imgCompositionsSelected
  );
  const { filteredCompositions, maybeCompositions, onSelectComposition } =
    useAttributionsImgComposition();

  const compositionsSelected = useAppSelector(
    (s) => s.compositions?.compositionsSelected
  );
  const compositionSelected = useAppSelector(
    (s) => s.compositions?.compositionSelected
  );

  const customer = useAppSelector((state) => state.customer.customer);
  const productId = useAppSelector((s) => s.compositions?.productId);
  const quiz = useAppSelector((s) => s.familyQuiz?.quiz);

  const totalSelected = compositionsSelected?.length;

  const { refetch: refetchCompositions } = useQuery(
    ['compositions', productId],
    () => getAllCompositionsByProductId(productId)
  );

  const onSelectProductComposition = (_composition: IComposition) => {
    const findComposition = compositionsSelected.find(
      (_c: IComposition) => _c._id === _composition._id
    );
    if (!!findComposition) {
      const newCompositions = compositionsSelected.filter(
        (_c: IComposition) => _c._id !== findComposition._id
      );
      dispatch(compositionsSelectedAction(newCompositions));
    } else {
      dispatch(
        compositionsSelectedAction([...compositionsSelected, _composition])
      );
    }
  };

  const onSelectCustomerComposition = (compositionId: string) => {
    const findComposition = customer?.univers?.compositions?.find(
      (_composition: string) => _composition === compositionId
    );
    if (!!findComposition) {
      const newCompositionsIds = customer?.univers?.compositions?.filter(
        (_composition: string) => _composition !== compositionId
      );
      dispatch(
        editCustomerInfoAction({
          tab: 'univers',
          key: 'compositions',
          value: newCompositionsIds,
        })
      );
    } else {
      const newCompositionsIds = [
        ...customer?.univers?.compositions,
        compositionId,
      ];
      dispatch(
        editCustomerInfoAction({
          tab: 'univers',
          key: 'compositions',
          value: newCompositionsIds,
        })
      );
    }
  };

  const handleCreateComposition = async (
    e: React.MouseEvent<HTMLParagraphElement>
  ) => {
    e.stopPropagation();
    const loader = toast.loading('Veuillez patienter !');
    const allAnswered = quiz?.questionsList?.every(
      (q: IQuestion) => q.answered
    );
    const allNoAnswered = quiz?.questionsList?.every(
      (q: IQuestion) => q.noAnswer
    );

    const isEqual = compositions.some((comp) =>
      helperCompositionsEqual(comp, compositionSelected)
    );

    if (!!!productId || productId?.length === 0) {
      toast_loader(loader, 'Veuillez sélectionner un produit avant !');
      return;
    }
    if (!allAnswered) {
      toast_loader(
        loader,
        'Veuillez répondre à toutes les questions !',
        'warning'
      );
      return;
    }
    if (compositionSelected.ingredients?.length === 0) {
      toast_loader(loader, 'Veuillez sélectionner au moins un ingrédient !');
      return;
    }
    if (allNoAnswered) {
      toast_loader(
        loader,
        'Au moins une des questions doit avoir une valeur différente de Aucun !',
        'warning'
      );
      return;
    }
    if (compositionSelected.short_name.length === 0) {
      toast_loader(loader, 'Veuillez saisir un nom court !', 'warning');
      return;
    }
    if (isEqual) {
      toast_loader(loader, 'Cette composition existe déjà !', 'warning');
      return;
    }

    let image = '';
    if (compositionSelected?.image) {
      image = await uploadFile(compositionSelected?.image, 'composition');
    }
    const payload = {
      short_name: compositionSelected.short_name?.trim(),
      image,
      ingredients: compositionSelected.ingredients,
      familyId: familyId,
      productId: productId,
    };
    const { error } = await createComposition(payload);
    if (!error) {
      toast_loader(loader, 'composition créée avec succès !', 'success');
      dispatch(compositionSelectedAction(initComposition));
      await refetchCompositions();
    } else {
      toast_loader(loader, "Une erreur s'est produite !", 'error');
    }
  };

  const onCancelled = () => {
    dispatch(compositionSelectedAction(initComposition));
    dispatch(compositionsAction([]));
    dispatch(quizAction(null));
    dispatch(questionSelectedAction(null));
    dispatch(
      filteredSelectedItemAction({
        filter: 'product',
        item: null,
      })
    );
    navigate(-1);
  };

  const onAttributeCompositions = async () => {
    if (
      compositionSelected.ingredients?.length > 0 ||
      compositionSelected?.short_name?.length > 0
    ) {
      Toast(
        'warning',
        "Veuillez créer la composition en cours avant d'attribuer une composition !"
      );
      return;
    }
    if (totalSelected === 0) {
      Toast(
        'warning',
        'Veuillez sélectionner au moins une composition à attribuer !'
      );
      return;
    }

    await handleUpdateCustomer();
    onCancelled();
  };

  return (
    <div className='customerCompositionsView--compositionsBlock'>
      <div className='customerCompositionsView--compositionsBlock--header'>
        <Button
          onClick={onCancelled}
          label='Annuler'
          width='35%'
          disabled={loading}
        />
        <Button
          label={`Ajouter ${totalSelected} composition${
            totalSelected > 1 ? 's' : ''
          }`}
          variant='yellow'
          width='60%'
          onClick={onAttributeCompositions}
          disabled={loading}
        />
      </div>
      <div className='customerCompositionsView--compositionsBlock--cards'>
        <CreateCompositionCard
          isAccordion
          onSaveComposition={handleCreateComposition}
        />
        <div className='customerCompositionsView--compositionsBlock--compositions'>
          <p className='customerCompositionsView--compositionsBlock--compositionsTitle'>
            Selectionner une suggestion pour attribuer
          </p>
          {filteredCompositions?.map(
            (composition: IComposition, index: number) => {
              const findComposition = customer?.univers?.compositions?.find(
                (_c: string) => _c === composition._id
              );
              const findCompositionSelected = compositionsSelected?.find(
                (_c: string) => _c === composition._id
              );

              return (
                <CompositionCard
                  onSelectComposition={
                    !!findComposition
                      ? () => {}
                      : () => {
                          onSelectCustomerComposition(composition?._id);
                          onSelectProductComposition(composition);
                        }
                  }
                  composition={composition}
                  key={index}
                  selected={!!findCompositionSelected}
                  attributed={!!findComposition}
                />
              );
            }
          )}

          {maybeCompositions.length > 0 && (
            <div className='w-100 flex-col-start-start customerCompositionsView--compositionsBlock-maybeComps'>
              <p className='customerCompositionsView--compositionsBlock--compositionsTitle'>
                Ca pourrait aussi être :
              </p>
              <div className='w-100 flex-col-start-start customerCompositionsView--compositionsBlock-maybeComps-Item'>
                {maybeCompositions.map(
                  (composition: IComposition, index: number) => {
                    const findCompositionSelected =
                      imgCompositionsSelected?.find(
                        (_c: IComposition) => _c?._id === composition?._id
                      );
                    return (
                      <CompositionCard
                        onSelectComposition={() =>
                          onSelectComposition(composition)
                        }
                        composition={composition}
                        key={index}
                        selected={!!findCompositionSelected}
                        width='95%'
                        backgroundColor={colors.white}
                        margin='.5rem auto'
                      />
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
