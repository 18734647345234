import { ISpeciality } from '../../redux/familyQuiz';

export const helperCheckIngredients = (
  ingredients: string[],
  specialities: ISpeciality[]
) => {
  let isExist = false;
  for (const speciality of specialities) {
    if (ingredients.includes(speciality.label)) {
      isExist = true;
      break;
    }
  }
  return isExist;
};
