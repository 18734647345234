import { useState } from 'react';
import { ButtonRadio } from '../../atoms/Buttons';
import { IdenticalImgDownload } from '../../atoms/Gallery';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { setIdenticalImageAction } from '../../../redux/images/imageIdentical';

export const DownloadIdenticalImg = ({ edit }) => {
  const role = useAppSelector((s) => s.app.role);
  const imageIdentical = useAppSelector((s) => s.imageIdentical);

  const image = imageIdentical.image;

  const dispatch = useAppDispatch();

  const [watermark, setWatermark] = useState(false);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    dispatch(
      setIdenticalImageAction({
        key: 'image',
        value: file,
      })
    );
    e.target.value = null;
  };

  const handleRemoveImage = (key) => {
    dispatch(
      setIdenticalImageAction({
        key: 'image',
        value: null,
      })
    );
    return;
  };

  return (
    <>
      <IdenticalImgDownload
        image={image}
        onChange={handleChangeImage}
        onRemoveImg={handleRemoveImage}
        watermarked={watermark || ['COMMERCIAL', 'PUNCHLINER'].includes(role)}
        labelId={!!imageIdentical.image ? 'image--portrait' : ''}
      />

      {['ADMIN', 'SUPER-ADMIN'].includes(role) && edit && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '1rem 0',
          }}
        >
          <ButtonRadio
            label='Avec filigrane'
            onClick={() => setWatermark(true)}
            active={watermark === true}
          />
          <ButtonRadio
            label='Sans filigrane'
            onClick={() => setWatermark(false)}
            active={watermark === false}
          />
        </div>
      )}
    </>
  );
};
