export const ArchiveIcon = ({ margin }) => {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin }}
    >
      <path
        d='M18.5714 0H1.42857C1.04969 0 0.686328 0.189642 0.418419 0.527208C0.15051 0.864773 0 1.32261 0 1.8V4.5C0 4.97739 0.15051 5.43523 0.418419 5.77279C0.686328 6.11036 1.04969 6.3 1.42857 6.3V16.2C1.42857 16.6774 1.57908 17.1352 1.84699 17.4728C2.1149 17.8104 2.47826 18 2.85714 18H17.1429C17.5217 18 17.8851 17.8104 18.153 17.4728C18.4209 17.1352 18.5714 16.6774 18.5714 16.2V6.3C18.9503 6.3 19.3137 6.11036 19.5816 5.77279C19.8495 5.43523 20 4.97739 20 4.5V1.8C20 1.32261 19.8495 0.864773 19.5816 0.527208C19.3137 0.189642 18.9503 0 18.5714 0ZM17.1429 16.2H2.85714V6.3H17.1429V16.2ZM18.5714 4.5H1.42857V1.8H18.5714V4.5ZM7.14286 9.9C7.14286 9.6613 7.21811 9.43239 7.35207 9.2636C7.48602 9.09482 7.6677 9 7.85714 9H12.1429C12.3323 9 12.514 9.09482 12.6479 9.2636C12.7819 9.43239 12.8571 9.6613 12.8571 9.9C12.8571 10.1387 12.7819 10.3676 12.6479 10.5364C12.514 10.7052 12.3323 10.8 12.1429 10.8H7.85714C7.6677 10.8 7.48602 10.7052 7.35207 10.5364C7.21811 10.3676 7.14286 10.1387 7.14286 9.9Z'
        fill='#282828'
      />
    </svg>
  );
};
