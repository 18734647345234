import {useState} from 'react';
import {useQuery} from 'react-query';
import {compose} from 'recompose';
import {fetchMoods} from '../../../api/moods.api';
import {copyObj} from '../../../assets/utils/copyObj';
import {sortedList} from '../../../assets/utils/sortedList';
import {Loader} from '../../../components/atoms/Loader';
import {TableHeader} from '../../../components/atoms/TableHeader';
import {TableSimpleRow} from '../../../components/atoms/TableSimpleRow';
import {HeaderWithSearch} from '../../../components/organisms/Header';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {withNavigation} from '../../../hoc/withNavigation';
import {useAppDispatch} from '../../../hooks/useReduxHook';
import {moodsModalAction} from '../../../redux/modals';
import {DeleteMood} from './DeleteMood';

const MoodsView = ({user}) => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const {data: moods, isLoading} = useQuery('moods', () => fetchMoods(''));

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const moodsFiltered = copyObj(moods)
    ?.sort((a, b) => sortedList(a, b, 'label'))
    ?.filter((mood) => mood.label.toLowerCase().includes(search.toLowerCase()));

  return (
    <>
      <DeleteMood />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`MOODS  (${moodsFiltered?.length ?? ''})`}
            onAdd={() => dispatch(moodsModalAction(true))}
          />
          <div className='table'>
            {isLoading ? (
              <Loader />
            ) : (
              moodsFiltered?.map((mood, index) => (
                <TableSimpleRow
                  key={index}
                  item={mood}
                  filter='moods'
                  withImage
                  onOpenModal={() => dispatch(moodsModalAction(true))}
                  onOpen={onOpen}
                  showActionModal={showActionModal}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withNavigation, withCheckAuth)(MoodsView);
