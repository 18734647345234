import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchFilters } from '../api/filters.api';

export const useFilters = (defaultFilter) => {
  const [filterId, setFilterId] = useState(defaultFilter || null);
  const { data: filtersData } = useQuery('filters', () => fetchFilters(''));

  const filters =
    filtersData?.map((item) => {
      return {
        name: item.label,
        value: item._id,
        _id: item._id,
        filterValues: item.filterValues,
      };
    }) || [];

  const filterValues =
    filtersData
      ?.find((item) => item._id === filterId)
      ?.filterValues.map((item) => {
        return {
          name: item.name,
          value: item._id,
          _id: item._id,
        };
      }) || [];

  const handleSelectFilter = (arg) => {
    if (typeof arg === 'string') {
      setFilterId(arg);
    } else {
      setFilterId(arg.target.value);
    }
  };

  useEffect(() => {
    if (defaultFilter) {
      setFilterId(defaultFilter);
    }
  }, [defaultFilter]);

  return { filters, filterValues, handleSelectFilter };
};
