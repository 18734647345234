import React, { useState } from 'react';
import { useDebounce } from '../../../../hooks/useDebounce';
import { Families } from './Families';

export const FamilyProdComposition: React.FC = () => {
  //
  const [search, setSearch] = useState('');
  const debounceSearch = useDebounce(search, 500);

  return (
    <div style={{ width: '100%' }}>
      <div>
        <h3>Recherche</h3>
        <div className='familyProdComposition--search'>
          <input
            type='text'
            placeholder='Rechercher'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <Families search={debounceSearch} />
    </div>
  );
};
