export const ChevronRightIcon = () => {
  return (
    <svg
      width='11'
      height='31'
      viewBox='0 0 11 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.714447 24.9998L7.14302 14.9998L0.714447 4.99977V0.454224L11.0002 14.9998L0.714447 30.4542V24.9998Z'
        fill='#4C747D'
        fillOpacity='0.4'
      />
    </svg>
  );
};
