import { toast } from 'react-toastify';
/**
 *
 * @param {String} type le type de toast
 * @param {String} message le text a affiché
 */

export const Toast = (type = 'warning', message, autoClose = 3000) => {
  return toast[type](message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose,
  });
};

export const toast_loader = (
  loader,
  render,
  type = 'warning',
  autoClose = 3000,
  isLoading = false
) => {
  toast.update(loader, { render, type, autoClose, isLoading });
  setTimeout(() => {
    toast.dismiss(loader);
  }, autoClose + 500);
  return;
};
