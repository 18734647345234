import React from 'react';
import { colors, initComposition } from '../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  familyChangeAction,
  familySelectedImgAttrAction,
} from '../../../redux/imageAttributions';
import {
  IQuestion,
  questionSelectedAction,
  quizAction,
} from '../../../redux/familyQuiz';
import { confirmSwitcheFamilyModalAction } from '../../../redux/modals';
import { compositionSelectedAction } from '../../../redux/compositions';
import { Toast } from '../../../assets/utils/toast';

export const FamilyCard: React.FC<{
  flex?: number;
  width?: string | number;
  family: any;
  selected?: boolean;
}> = ({ flex, width, family, selected }) => {
  //

  const dispatch = useAppDispatch();
  const imageAttributions = useAppSelector((state) => state.imageAttributions);
  const familySelectedImgAttr = imageAttributions?.familySelectedImgAttr;
  const attributionMode = imageAttributions?.attributionMode;

  const onSelect = () => {
    if (family?.quiz.questionsList.length === 0 && attributionMode === 'quiz')
      return Toast('warning', 'Aucune question pour cette famille !');
    if (
      familySelectedImgAttr &&
      familySelectedImgAttr?.checkedNum !== familySelectedImgAttr?.quantity
    ) {
      dispatch(confirmSwitcheFamilyModalAction(true));
      dispatch(familyChangeAction(family));
      return;
    }
    const quizPayload = {
      ...family?.quiz,
      questionsList: family?.quiz.questionsList.map((q: IQuestion) => {
        return {
          ...q,
          answered: false,
          noAnswer: false,
        };
      }),
    };

    dispatch(
      familySelectedImgAttrAction({
        ...family,
        checkedNum: !!family?.checkedNum ? family?.checkedNum : 0,
      })
    );
    dispatch(questionSelectedAction(quizPayload?.questionsList[0]));
    dispatch(quizAction(quizPayload));
    dispatch(familyChangeAction(null));
    dispatch(compositionSelectedAction(initComposition));
  };

  const borderColor =
    family?.checkedNum === family?.quantity
      ? colors.green
      : selected
      ? colors.yellow
      : colors.greyDark;

  return (
    <div
      className='imageAttributions--card'
      onClick={onSelect}
      style={{
        flex,
        width,
        borderColor: borderColor,
      }}
    >
      {`${family?.checkedNum || 0}/${family?.quantity} ${family?.label}`}
    </div>
  );
};
