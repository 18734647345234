import React, { useState } from 'react';
import { deleteOneQuestion } from '../../../api/quiz.api';
import { colors, initQuestion } from '../../../assets/constants';
import { PlusIcon } from '../../../assets/svgs/PlusIcon';
import { Toast } from '../../../assets/utils/toast';
import { Loader } from '../../../components/atoms/Loader';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { questionSelectedAction, quizAction } from '../../../redux/familyQuiz';
import { copyObj } from '../../../assets/utils/copyObj';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const SortableItem = SortableElement(
  ({
    question,
    onSelectQuestion,
    index,
    questionIndex,
    questionSelected,
    handleDeleteQuestion,
  }) => (
    <button
      onClick={() => onSelectQuestion(question)}
      className='familyQuiz--leftBlockContent-item'
      key={index}
      style={{
        borderColor:
          questionSelected?._id === question?._id
            ? colors.yellow
            : 'transparent',
      }}
    >
      <span>{questionIndex + 1}</span>
      <span>{question.question}</span>
      <button
        onClick={(e) => handleDeleteQuestion(e, question)}
        className='familyQuiz--leftBlockContent-deleteBtn'
      >
        X
      </button>
    </button>
  )
);

const SortableList = SortableContainer(
  ({
    questionsList,
    questionSelected,
    onSelectQuestion,
    handleDeleteQuestion,
  }) => {
    return (
      <div>
        {!!questionsList &&
          copyObj(questionsList)?.map((question, index) => {
            return (
              <SortableItem
                // @ts-ignore
                question={question}
                questionSelected={questionSelected}
                onSelectQuestion={onSelectQuestion}
                index={index}
                questionIndex={index}
                key={question._id}
                handleDeleteQuestion={handleDeleteQuestion}
              />
            );
          })}
      </div>
    );
  }
);

export const LeftBlockContent: React.FC = () => {
  const quiz = useAppSelector((s) => s.familyQuiz.quiz);
  const dispatch = useAppDispatch();
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const [loading, setLoading] = useState(false);

  const onSaveQuestion = () => {
    const newQuestionsList = quiz?.questionsList?.map((_question) => {
      if (_question?._id === questionSelected?._id) {
        return questionSelected;
      }
      return _question;
    });
    const newQuestion = {
      ...initQuestion,
      _id: String(Date.now()),
      isNew: true,
      position: quiz?.questionsList?.length,
    };
    dispatch(
      quizAction({ ...quiz, questionsList: [...newQuestionsList, newQuestion] })
    );
    dispatch(questionSelectedAction(newQuestion));
  };

  const onAddQuestion = () => {
    const newQuestion = {
      ...initQuestion,
      _id: String(Date.now()),
      isNew: true,
      position: quiz?.questionsList?.length,
    };
    dispatch(
      quizAction({
        ...quiz,
        questionsList: [...quiz?.questionsList, newQuestion],
      })
    );
    dispatch(questionSelectedAction(newQuestion));
  };

  const handleAddNewQuestion = () => {
    if (!!questionSelected) {
      onSaveQuestion();
    } else {
      onAddQuestion();
    }
  };

  const handleUpdateQuestionList = (newQuestionsList) => {
    dispatch(
      quizAction({ ...quiz, update: true, questionsList: newQuestionsList })
    );
  };

  const onSelectQuestion = (question) => {
    if (!!questionSelected) {
      const newQuestionsList = quiz?.questionsList?.map((_question) => {
        if (_question?._id === questionSelected?._id) {
          return questionSelected;
        }
        return _question;
      });
      dispatch(quizAction({ ...quiz, questionsList: newQuestionsList }));
      dispatch(questionSelectedAction(question));
    } else {
      dispatch(questionSelectedAction(question));
    }
  };

  const onDeleteQuestionInStore = async (question) => {
    const newQuestionsList = quiz?.questionsList
      ?.map((_question) =>
        _question?._id !== question?._id ? _question : null
      )
      .filter((_question) => !!_question);
    dispatch(quizAction({ ...quiz, questionsList: newQuestionsList }));
    dispatch(questionSelectedAction(quiz?.questionsList[0] || null));
  };

  const onDeleteQuestionInDb = async (question) => {
    setLoading(true);
    const { error } = await deleteOneQuestion(question?._id);
    if (!error) {
      setLoading(false);
      onDeleteQuestionInStore(question);
      Toast('success', 'Question supprimée avec succès !');
    } else {
      setLoading(false);
      Toast('error', 'Une erreur est survenue !');
    }
  };

  const handleDeleteQuestion = async (e, question) => {
    e.stopPropagation();
    if (question?.isNew) {
      onDeleteQuestionInStore(question);
    } else {
      await onDeleteQuestionInDb(question);
    }
  };

  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    const newQuestions = arrayMoveImmutable(
      quiz.questionsList,
      oldIndex,
      newIndex
    );

    handleUpdateQuestionList(
      newQuestions.map((q, posIndex) => ({
        ...q,
        position: posIndex + 1,
      }))
    );
  };

  if (loading) return <Loader />;

  return (
    <div className='familyQuiz--leftBlockContent'>
      <button
        className='familyQuiz--leftBlockContent-addQuestionBtn'
        onClick={handleAddNewQuestion}
      >
        <span>Ajouter une question</span>
        <span>
          <PlusIcon color={colors.white} />
        </span>
      </button>
      <SortableList
        //@ts-ignore
        questionsList={quiz?.questionsList}
        questionSelected={questionSelected}
        onSelectQuestion={onSelectQuestion}
        handleDeleteQuestion={handleDeleteQuestion}
        handleUpdateQuestionList={handleUpdateQuestionList}
        onSortEnd={handleSortEnd}
      />
    </div>
  );
};
