import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchFamilies } from '../../../api/families.api';
import { MinusGrayIcon } from '../../../assets/svgs/MinusGrayIcon';
import { PlusGrayIcon } from '../../../assets/svgs/PlusGrayIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  infosMainProductsAction,
  infosSecondaryProductsAction,
} from '../../../redux/images';
import { Tabs } from '../../atoms/Tabs';
import { IQuiz } from '../../../redux/familyQuiz';
import { imageAttributionsTabAction } from '../../../redux/imageAttributions';
import { useDispatch } from 'react-redux';

export interface IFamily {
  _id?: string;
  createdAt?: string;
  defaultList?: [];
  label?: string;
  products?: [];
  stylesId?: string[];
  quiz?: IQuiz;
}

const tabsData = [
  { label: 'Produit principal', id: 'product-main' },
  { label: 'Produit secondaire', id: 'product-secondary' },
];

export const ImageInfosProducts: React.FC = () => {
  const dispatch = useDispatch();
  const { infosMainProducts, infosSecondaryProducts } = useAppSelector(
    (s) => s.images
  );
  const [tab, setTab] = useState<string>('product-main');

  const { data } = useQuery('families', () => fetchFamilies());

  const products =
    tab === 'product-main' ? infosMainProducts : infosSecondaryProducts;

  useEffect(() => {
    const tabValue =
      tab === 'product-main' ? 'product-main' : 'product-secondary';
    dispatch(imageAttributionsTabAction(tabValue));
  }, [dispatch, tab]);

  return (
    <>
      <Tabs buttons={tabsData} tabs={tab} setTabs={setTab} />
      <div className='image--imageProducts'>
        {!!data &&
          copyObj(data)?.map((family: IFamily, index: number) => {
            return (
              <ImageProduct
                family={family}
                products={products}
                tab={tab}
                key={index}
              />
            );
          })}
      </div>
    </>
  );
};

const ImageProduct: React.FC<{
  family: IFamily;
  products: { familyId?: string; quantity?: number }[];
  tab: string;
}> = ({ family, products, tab }) => {
  const dispatch = useAppDispatch();
  const findProduct = products?.find(
    (_product) => _product?.familyId === family?._id
  );

  const onIncremente = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (!!findProduct) {
      const newProducts = products.map((_product) => {
        if (_product.familyId === family?._id) {
          return { ..._product, quantity: _product.quantity + 1 };
        }
        return _product;
      });
      if (tab === 'product-main') {
        dispatch(infosMainProductsAction(newProducts));
      } else if (tab === 'product-secondary') {
        dispatch(infosSecondaryProductsAction(newProducts));
      }
    } else {
      onAddIngredient();
    }
  };

  const onDecremente = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (!!findProduct && findProduct?.quantity === 1) {
      const newProducts = products?.filter(
        (_product) => _product.familyId !== family?._id
      );
      if (tab === 'product-main') {
        dispatch(infosMainProductsAction(newProducts));
      } else if (tab === 'product-secondary') {
        dispatch(infosSecondaryProductsAction(newProducts));
      }
    } else if (!!findProduct && findProduct.quantity > 1) {
      const newProducts = products?.map((_product) => {
        if (_product.familyId === family?._id) {
          return {
            ..._product,
            quantity: _product.quantity > 1 ? _product.quantity - 1 : 1,
          };
        }
        return _product;
      });
      if (tab === 'product-main') {
        dispatch(infosMainProductsAction(newProducts));
      } else if (tab === 'product-secondary') {
        dispatch(infosSecondaryProductsAction(newProducts));
      }
    }
  };

  const onAddIngredient = () => {
    if (!!findProduct) return null;
    const newProducts = [
      ...products,
      {
        familyId: family._id,
        quantity: 1,
        checkedNum: 0,
      },
    ];
    if (tab === 'product-main') {
      dispatch(infosMainProductsAction(newProducts));
    } else if (tab === 'product-secondary') {
      dispatch(infosSecondaryProductsAction(newProducts));
    }
  };

  return (
    <div className='image--imageProduct'>
      <span className='image--imageProduct-label'>{family.label}</span>
      {!!family?.quiz ? (
        <div className='image--imageProduct-btns'>
          {!!findProduct && findProduct?.quantity > 0 && (
            <MinusGrayIcon onClick={onDecremente} />
          )}
          {!!findProduct && findProduct?.quantity > 0 && (
            <span>{findProduct?.quantity}</span>
          )}

          <PlusGrayIcon onClick={onIncremente} />
        </div>
      ) : (
        <div className='image--imageProduct-quizBadge'>Pas de Quiz</div>
      )}
    </div>
  );
};
