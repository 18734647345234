import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOneRayon, updateOneRayon } from '../../../api/rayons.api';
import { Toast } from '../../../assets/utils/toast';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { rayonsModalAction } from '../../../redux/modals';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

//TODO Vider les props
export const RayonsModal = ({ bgWidth = '80%' }) => {
  // redux state
  const rayonsModal = useSelector((state) => state.modals.rayonsModal);
  const rayonSelected = useSelector((state) => state.filtersSelected.rayon);

  // local state
  const [rayonLabel, setRayonLabel] = useState(rayonSelected?.label || '');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { resetFilters } = useResetFilters();

  const handleClose = () => {
    dispatch(resetFilteredSelectedItemAction('rayon'));
    dispatch(rayonsModalAction(false));
  };

  const handleSubmit = async () => {
    setLoading(true);
    let res;
    let update = false;

    if (rayonSelected) {
      res = await updateOneRayon(rayonSelected._id, rayonLabel);
      update = true;
    } else {
      res = await createOneRayon(rayonLabel);
    }

    if (res.data?.success) {
      resetFilters('rayons');
      Toast('success', update ? 'Rayon ajouté' : 'Rayon modifié !');

      setRayonLabel('');
      handleClose();
    } else {
      Toast('error', res.data?.message || 'Error');
    }

    setLoading(false);
  };

  if (!rayonsModal) return null;

  return (
    <Modal
      onClose={handleClose}
      title='Rayon'
      onValidate={handleSubmit}
      loading={loading}
      zIndex={700}
      bgWidth={bgWidth}
    >
      <Input
        label='Nom'
        type='text'
        value={rayonLabel}
        onChange={(e) => setRayonLabel(e.target.value)}
      />
    </Modal>
  );
};
