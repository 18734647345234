import axios from 'axios';
import { env } from '../app.config';

export const axiosInstance = axios.create({
  baseURL: env.BASE_URL + env.VERSION,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth_token');

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${
      config.headers.customer_auth_key || token
    }`;
  }

  return config;
});
