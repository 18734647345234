import { months } from '../constants';
import { helperFormatMonthStringToNumber } from './helperFormatDate';
import { helperImageRoute } from './helperImageRoute';

export const helperFormatNumber = (number: number) => {
  return number < 10 ? `0${number}` : number;
};

export const generateMonthYearArray = (startDate, endDate) => {
  const startMonthIndex = months.indexOf(startDate.month);
  const endMonthIndex = months.indexOf(endDate.month);
  const startYear = Number(startDate.year);
  const endYear = Number(endDate.year);

  const result = [];

  for (let year = startYear; year <= endYear; year++) {
    const start = year === startYear ? startMonthIndex : 0;
    const end = year === endYear ? endMonthIndex : months.length - 1;

    for (let i = start; i <= end; i++) {
      result.push({ month: months[i], year });
    }
  }

  return result;
};

export function checkMonthsInPeriod(item, startDate, endDate) {
  const startMonthIndex = months.indexOf(startDate.month);
  const endMonthIndex = months.indexOf(endDate.month);

  const monthIndex = months.indexOf(item.month);
  const itemYear = item.year;
  if (
    (itemYear === Number(startDate.year) && monthIndex >= startMonthIndex) ||
    (itemYear === Number(endDate.year) && monthIndex <= endMonthIndex) ||
    (itemYear > Number(startDate.year) && itemYear < Number(endDate.year))
  ) {
    return true;
  } else {
    return false;
  }
}

export const checkIfMonthIsInSubscription = (
  month: string,
  subscription: {
    endIt: { month: string, year: number },
    startOn: { year: number, month: string },
  }
) => {
  const monthToNumber = helperFormatMonthStringToNumber(month);

  const subscriptionStartMonthToNumber = helperFormatMonthStringToNumber(
    subscription?.startOn?.month
  );

  const subscriptionEndMonthToNumber = helperFormatMonthStringToNumber(
    subscription?.endIt?.month
  );

  const date = new Date();
  const nowMonthNumber = helperFormatNumber(date.getMonth() + 1);
  const year = date.getFullYear();

  const currentMonth = `${monthToNumber}/${year}`;
  const nowMonth = `${nowMonthNumber}/${year}`;
  const subscriptionStartMonth = `${subscriptionStartMonthToNumber}/${subscription?.startOn?.year}`;
  const subscriptionEndMonth = `${subscriptionEndMonthToNumber}/${subscription?.endIt?.year}`;

  return (
    (currentMonth <= subscriptionEndMonth ||
      year < subscription?.endIt?.year) &&
    ((currentMonth >= subscriptionStartMonth &&
      nowMonth <= currentMonth &&
      year <= subscription?.startOn?.year) ||
      year < subscription?.startOn?.year)
  );
};

export const checkMonthIsInSubscription = (
  month: string,
  itemYear: string,
  subscription: {
    endIt: { month: string, year: number },
    startOn: { year: number, month: string },
  }
) => {
  const monthToNumber = helperFormatMonthStringToNumber(month);

  const subscriptionStartMonthToNumber = helperFormatMonthStringToNumber(
    subscription?.startOn?.month
  );

  const subscriptionEndMonthToNumber = helperFormatMonthStringToNumber(
    subscription?.endIt?.month
  );

  const date = new Date();
  const nowMonthNumber = helperFormatNumber(date.getMonth() + 1);
  const nowYear = date.getFullYear();

  const currentMonth = `${monthToNumber}/${nowYear}`;
  const nowMonth = `${nowMonthNumber}/${nowYear}`;
  const subscriptionStartMonth = `${subscriptionStartMonthToNumber}/${subscription?.startOn?.year}`;
  const subscriptionEndMonth = `${subscriptionEndMonthToNumber}/${subscription?.endIt?.year}`;

  const result =
    (currentMonth <= subscriptionEndMonth ||
      itemYear < subscription?.endIt?.year) &&
    ((currentMonth >= subscriptionStartMonth &&
      nowMonth <= currentMonth &&
      itemYear <= subscription?.startOn?.year) ||
      itemYear < subscription?.startOn?.year ||
      itemYear > nowYear);

  return result;
};

export const getFilterItemsLength = (items) => {
  return items.reduce(
    (total, item) =>
      total +
      ((item.ingredients || item.filterValues || item.subSpecialFilters)
        ?.length || 0),
    0
  );
};

export const helperFilterParentIsSelected = ({
  dataActive,
  itemsKey,
  items,
}) => {
  return (
    dataActive &&
    dataActive[itemsKey] &&
    dataActive[itemsKey].length > 0 &&
    getFilterItemsLength(dataActive[itemsKey]) ===
      getFilterItemsLength(items) &&
    dataActive[itemsKey].length === items.length
  );
};

export const helperDownloadAllImages = (images, watermark = false) => {
  [
    helperImageRoute(images['portrait'], 'picture', false, watermark),
    helperImageRoute(images['landscape'], 'picture', false, watermark),
    helperImageRoute(images['square'], 'picture', false, watermark),
  ]
    .filter((el) => el)
    .forEach((imgRoute) => {
      const a = document.createElement('a');
      a.href = imgRoute;
      a.target = '_self';
      a.download = imgRoute?.split('/')?.pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
};

export const compareArrays = (array1, array2) => {
  return array1?.every((item) =>
    array2?.find((x) => x?._id === item?._id || item?.familyId || item)
  );
};

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
