import {toast} from 'react-toastify';
import {deleteOneMood} from '../../../api/moods.api';
import {toast_loader} from '../../../assets/utils/toast';
import {DeleteModal} from '../../../components/modals/DeleteModal';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {useResetFilters} from '../../../hooks/useResetFilters';
import {resetFilteredSelectedItemAction} from '../../../redux/filtersSelected';
import {deleteModalAction} from '../../../redux/modals';

export const DeleteMood = () => {
  const dispatch = useAppDispatch();
  const moodSelected = useAppSelector((state) => state.filtersSelected.moods);

  const {resetFilters} = useResetFilters();

  const handleDeleteMood = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      await deleteOneMood(moodSelected?._id);
      resetFilters('moods');
      dispatch(resetFilteredSelectedItemAction('moods'));
      toast_loader(loader, 'Mood supprimé', 'success');
      dispatch(deleteModalAction(false));
    } catch (error) {
      toast_loader(loader, 'Erreur lors de la suppression du mood', 'error');
    }
  };

  return (
    <DeleteModal
      onDelete={handleDeleteMood}
      text={`le mood "${moodSelected?.label}"`}
    />
  );
};
