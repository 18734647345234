import { useDispatch } from 'react-redux';
import { colors } from '../../../assets/constants';
import { ArrowLeftIcon } from '../../../assets/svgs/ArrowLeftIcon';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { IComposition } from '../../../redux/compositions';
import {
  imgCompositionsSelectedAction,
  imgProductIdAction,
} from '../../../redux/imageAttributions';
import { Button } from '../../atoms/Buttons';
import { CompositionCard } from '../CompositionsGestion/CompositionCard';
import { useEffect, useState } from 'react';
import { SearchIcon } from '../../../assets/svgs/SearchIcon';

export const CompositionsListRight: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const imgCompositionsSelected = useAppSelector(
    (s) => s.imageAttributions?.imgCompositionsSelected
  );
  const { filteredCompositions, maybeCompositions, onSelectComposition } =
    useAttributionsImgComposition();

  const compositionsFiltered = filteredCompositions?.filter((comp) =>
    comp?.short_name?.toLowerCase().includes(search.toLowerCase())
  );

  const imageAttributions = useAppSelector((s) => s.imageAttributions);
  const imageStore = useAppSelector((s) => s.images);

  const compsSelected =
    imageAttributions?.imageAttributionsTab === 'product-main'
      ? imageStore?.attrMainCompositions
      : imageStore?.attrSecondaryCompositions;

  useEffect(() => {
    if (compsSelected.length > 0) {
      dispatch(imgCompositionsSelectedAction(compsSelected as any));
    }
  }, [compsSelected, dispatch]);

  return (
    <>
      <div className='w-100 flex-col-start-start'>
        <div className='imageAttributions--rightBlock-text'>
          <p> Liste des compositions</p>
          <div
            id='input'
            className='header--input'
            style={{
              width: '95%',
              margin: '0 auto',
            }}
          >
            <SearchIcon />
            <input
              type='text'
              placeholder='Search'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className='w-100 flex-col-start-start'>
          {compositionsFiltered.map(
            (composition: IComposition, index: number) => {
              const compositionsFiltered = imgCompositionsSelected?.find(
                (_c: IComposition) => _c?._id === composition?._id
              );
              return (
                <CompositionCard
                  onSelectComposition={() => onSelectComposition(composition)}
                  composition={composition}
                  key={index}
                  selected={!!compositionsFiltered}
                  width='95%'
                  backgroundColor={colors.white}
                  margin='.5rem auto'
                />
              );
            }
          )}
        </div>
      </div>
      {maybeCompositions.length > 0 && (
        <div className='w-100 flex-col-start-start '>
          <p className='imageAttributions--rightBlock-text'>
            Ca pourrait aussi être :
          </p>
          <div className='w-100 flex-col-start-start imageAttributions--rightBlock-maybeComps'>
            {maybeCompositions.map(
              (composition: IComposition, index: number) => {
                const findCompositionSelected = imgCompositionsSelected?.find(
                  (_c: IComposition) => _c?._id === composition?._id
                );
                return (
                  <CompositionCard
                    onSelectComposition={() => onSelectComposition(composition)}
                    composition={composition}
                    key={index}
                    selected={!!findCompositionSelected}
                    width='95%'
                    backgroundColor={colors.white}
                    margin='.5rem auto'
                  />
                );
              }
            )}
          </div>
        </div>
      )}
    </>
  );
};

export const CompositionsListCenter: React.FC = () => {
  //
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const { filteredCompositions, onSelectComposition } =
    useAttributionsImgComposition();
  const { imgCompositionsSelected } = useAppSelector(
    (s) => s.imageAttributions
  );

  const compositionsFiltered = filteredCompositions?.filter((comp) =>
    comp?.short_name?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Button
        margin='0 1rem 0 0'
        variant='grey'
        Icon={ArrowLeftIcon}
        onClick={() => dispatch(imgProductIdAction(null))}
      />
      <div
        id='input'
        className='header--input'
        style={{
          width: '95%',
          margin: '1rem 0',
        }}
      >
        <SearchIcon />
        <input
          type='text'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div
        className='w-100 flex-wrap'
        style={{
          gap: '1rem',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        {compositionsFiltered?.length > 0 &&
          compositionsFiltered.map(
            (composition: IComposition, index: number) => {
              const findCompositionSelected = imgCompositionsSelected?.find(
                (_c: IComposition) => _c?._id === composition?._id
              );
              return (
                <CompositionCard
                  onSelectComposition={() => onSelectComposition(composition)}
                  composition={composition}
                  key={index}
                  width='48%'
                  selected={!!findCompositionSelected}
                  backgroundColor={colors.whiteBlue}
                />
              );
            }
          )}
      </div>
    </>
  );
};
