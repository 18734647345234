import { Sidebar } from '../components/organisms/Sidebar';
import { useAppSelector } from '../hooks/useReduxHook';

export const withNavigation = (WrappedComponent) => (props) => {
  const userData = useAppSelector((s) => s.app.userData);

  return (
    <div className='withNavigation'>
      <Sidebar />
      <div className='withNavigation--container'>
        <WrappedComponent {...props} user={userData} />
      </div>
    </div>
  );
};
