export const ArrowRightIcon = () => {
  return (
    <svg
      width='24'
      height='14'
      viewBox='0 0 24 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M-0.00135871 6.00003L-0.00135853 8.00003L21.4436 7.96903L17.1216 12.293L18.5356 13.707L23.1216 9.12103C23.6841 8.55845 24 7.79552 24 7.00003C24 6.20454 23.6841 5.44161 23.1216 4.87903L18.5356 0.293031L17.1216 1.70703L21.3836 5.96903L-0.00135871 6.00003Z'
        fill='black'
      />
    </svg>
  );
};
