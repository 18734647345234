import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setOnlineAction } from '../../redux/app';
import Logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/atoms/Buttons';
import { Input } from '../../components/atoms/Fields';
import { Toast } from '../../assets/utils/toast';
import { Version } from '../../components/atoms/Version';
import { userAuth } from '../../api/auth.api';

export const LoginView = () => {
  //
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form, setForm] = useState({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((currentState) => ({ ...currentState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await userAuth(form)
      .then((res) => {
        localStorage.setItem('slm-token', res.data?.token);
        localStorage.setItem(
          'slm-user-data',
          JSON.stringify({ ...res.data?.user, password: null })
        );
        dispatch(setOnlineAction(true));
        if (
          ['COMMERCIAL', 'PROGRAMMEUR', 'PUNCHLINER'].includes(
            res.data?.user?.role
          )
        ) {
          navigate('/images-list');
        } else if (['SUPER-ADMIN', 'ADMIN'].includes(res.data?.user?.role)) {
          navigate('/images');
        }
        setLoading(false);
        Toast('success', `🥳 Bienvenue ${res.data?.user?.lastname} !`);
      })
      .catch((err) => {
        setLoading(false);
        Toast(
          'error',
          err?.response?.data?.message || "Une erreur s'est produit !"
        );
      });
    setLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem('slm-token')) {
      const user = JSON.parse(localStorage.getItem('slm-user-data'));
      if (['COMMERCIAL', 'PROGRAMMEUR', 'PUNCHLINER'].includes(user?.role)) {
        window.location.href = '/#/images-list';
      } else {
        window.location.href = '/#/images';
      }
    }
  });

  return (
    <div className='offline'>
      <form className='offline--container' onSubmit={handleSubmit}>
        <img src={Logo} alt='logo' />
        <p className='offline--title'>Connectez-vous</p>
        <p className='offline--text' style={{ marginBottom: '2rem' }}>
          à votre tableau de bord
        </p>
        <Input
          label='Adresse Email'
          name='email'
          onChange={(e) => handleChange(e)}
          value={form.email}
        />
        <Input
          label='Mot de passe'
          name='password'
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => handleChange(e)}
          value={form.password}
          icon
          onShowPassword={() => setShowPassword((s) => !s)}
        />
        <button
          className='offline--text btn--neutral'
          style={{ margin: ' 0 0 2rem auto' }}
          onClick={() => navigate('/forget-password')}
          type='button'
        >
          Mot de passe oublié?
        </button>
        <Button
          label={loading ? 'Connexion...' : 'Connexion'}
          margin='auto 0 0'
          width='100%'
          disabled={loading}
        />
      </form>
      <Version />
    </div>
  );
};
