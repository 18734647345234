import { compose } from 'recompose';
import { Button } from '../../../components/atoms/Buttons';
import { InformationsPanel } from '../../../components/organisms/Customer/InformationsPanel';
import { HeaderWithTabs } from '../../../components/organisms/Header';
import { withNavigation } from '../../../hoc/withNavigation';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { createCutomer } from '../../../api/customers.api';
import { Toast, toast_loader } from '../../../assets/utils/toast';
import { toast } from 'react-toastify';
import { customerDetailsAction } from '../../../redux/customer';
import { useResetFilters } from '../../../hooks/useResetFilters';

const AddCustomerView = () => {
  //
  const dispatch = useAppDispatch();
  const customer = useAppSelector((state) => state.customer.customer);

  const { resetFilters } = useResetFilters();

  const handleSubmit = async () => {
    const requiredFields = ['restaurant_name', 'restaurant_city'];

    if (customer?.informations) {
      const isRequiredFieldsFilled = requiredFields.every((field) => {
        return customer?.informations[field];
      });

      if (!isRequiredFieldsFilled) {
        Toast('error', 'Veuillez remplir les informations du client');
        return;
      }
      const newCustomer = {
        ...customer,
        informations: {
          ...customer?.informations,
          restaurant_name: customer?.informations?.restaurant_name?.trim(),
          restaurant_city: customer?.informations?.restaurant_city?.trim(),
          restaurant_country: customer?.informations?.restaurant_country?.trim(),
        },
      };

      const loader = toast.loading('Création du client en cours...');
      try {
        await createCutomer(newCustomer);

        toast_loader(loader, 'Client créé avec succès', 'success');
        resetFilters('customers');
        dispatch(customerDetailsAction(null));
      } catch (err) {
        toast_loader(
          loader,
          "Une erreur s'est produite lors de la création du client",
          'error'
        );
      }
    } else {
      //

      Toast('error', 'Veuillez remplir les informations du client');
    }
  };

  return (
    <div className='pages-layout'>
      <HeaderWithTabs hidden navigateTo={-1} isCustomer />
      <div className='pages-layout--container customer'>
        <InformationsPanel add />
      </div>
      <div className='customer--footer'>
        <Button label='Enregistrer' variant='yellow' onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(AddCustomerView);
