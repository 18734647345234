export const PlusIcon = ({ color = '#A88E1B', size = '12' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 5.5H6.5V0H5.5V5.5H0V6.5H5.5V12H6.5V6.5H12V5.5Z'
        fill={color}
      />
    </svg>
  );
};
