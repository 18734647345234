//COLORS
export const colors = {
  red: '#BC0505',
  yellow: '#A88E1B',
  dark: '#282828',
  greyDark: '#DBDBDB',
  grey: '#F5F5F5',
  white: '#FFFFFF',
  whiteBlue: '#F9FAFB',
  green: '#24A856',
};

const formulas = {
  formulas_6: [
    {
      formula_number: 1,
      date: null,
    },
    {
      formula_number: 2,
      date: null,
    },
    {
      formula_number: 3,
      date: null,
    },
    {
      formula_number: 4,
      date: null,
    },
    {
      formula_number: 5,
      date: null,
    },
    {
      formula_number: 6,
      date: null,
    },
  ],
  formulas_12: [
    {
      formula_number: 1,
      date: null,
    },
    {
      formula_number: 2,
      date: null,
    },
    {
      formula_number: 3,
      date: null,
    },
    {
      formula_number: 4,
      date: null,
    },
    {
      formula_number: 5,
      date: null,
    },
    {
      formula_number: 6,
      date: null,
    },
    {
      formula_number: 7,
      date: null,
    },
    {
      formula_number: 8,
      date: null,
    },
    {
      formula_number: 9,
      date: null,
    },
    {
      formula_number: 10,
      date: null,
    },
    {
      formula_number: 11,
      date: null,
    },
    {
      formula_number: 12,
      date: null,
    },
  ],
};

export const initPackages = {
  year: new Date().getFullYear(),
  formula: 6,
  months: [
    {
      index: 1,
      name: 'Janvier',
      ...formulas,
    },
    {
      index: 2,
      name: 'Février',
      ...formulas,
    },
    {
      index: 3,
      name: 'Mars',
      ...formulas,
    },
    {
      index: 4,
      name: 'Avril',
      ...formulas,
    },
    {
      index: 5,
      name: 'Mai',
      ...formulas,
    },
    {
      index: 6,
      name: 'Juin',
      ...formulas,
    },
    {
      index: 7,
      name: 'Juillet',
      ...formulas,
    },
    {
      index: 8,
      name: 'Aout',
      ...formulas,
    },
    {
      index: 9,
      name: 'Septembre',
      ...formulas,
    },
    {
      index: 10,
      name: 'Octobre',
      ...formulas,
    },
    {
      index: 11,
      name: 'Novembre',
      ...formulas,
    },
    {
      index: 12,
      name: 'Décembre',
      ...formulas,
    },
  ],
};
export const years = [2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
export const months = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
];

export const initUserForm = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  role: 'SUPER-ADMIN',
  avatar: '',
  password: '',
};

export const citiesLetters = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const initCustomer = {
  informations: {
    restaurant_name: '',
    restaurant_phone: '',
    restaurant_address: '',
    restaurant_delivery: '',
    restaurant_country: '',
    restaurant_city: '',
    url_facebook: '',
    url_instagram: '',
    slogan: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    phone_whatsapp: '',
  },
  logo_card: {
    avatar: '',
    logo: [],
    card: [],
  },
  hashtags: [],
  univers: {
    styles: [],
    families: [],
    filters: [],
    specialsFilters: [],
    moods: [],
    compositions: [],
  },
  signature: {
    address: { value: '', enabled: false },
    slogan: { value: '', enabled: false },
    phone: { value: '', enabled: false },
    delivery: { value: '', enabled: false },
    text: { value: '', enabled: false },
  },
  attributions: [],
  subscription: {
    startOn: {
      month: new Date()
        .toLocaleString('default', { month: 'long' })
        .toLowerCase(),
      year: new Date().getFullYear().toString(),
    },
    endIt: {
      month: null,
      year: null,
    },
    formula: 6,
  },
};

export const initPicture = {
  name: '',
  image: null,
  styles: [],
  families: [],
  filters: [],
  specialsFilters: [],
  moods: [],
  groupId: null,
  punchlines: {
    punchline_1: '',
    punchline_2: '',
    punchline_3: '',
  },
  hashtags: [],
  infosMainProducts: [],
  infosSecondaryProducts: [],
  attrMainCompositions: [],
  attrSecondaryCompositions: [],
  longName: '',
  shortName: '',
  relatedCustomers: [],
};

export const countries = [
  'United States',
  'Canada',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and/or Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Cape Verde',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Croatia (Hrvatska)',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Malvinas)',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'France, Metropolitan',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard and Mc Donald Islands',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran (Islamic Republic of)',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  "Korea, Democratic People's Republic of",
  'Korea, Republic of',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia, Federated States of',
  'Moldova, Republic of',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'St. Helena',
  'St. Pierre and Miquelon',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen Islands',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Tanzania, United Republic of',
  'Thailand',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States minor outlying islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City State',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (British)',
  'Virgin Islands (U.S.)',
  'Wallis and Futuna Islands',
  'Western Sahara',
  'Yemen',
  'Yugoslavia',
  'Zaire',
  'Zambia',
  'Zimbabwe',
];

export const roles = [
  { name: 'Super admin', value: 'SUPER-ADMIN' },
  { name: 'Admin', value: 'ADMIN' },
  { name: 'Commercial', value: 'COMMERCIAL' },
  { name: 'Punchliner', value: 'PUNCHLINER' },
  { name: 'Programmeur', value: 'PROGRAMMEUR' },
];

export const regexRemoveHtmlTag = /(<([^>]+)>)/gi;

//fake
export const initQuiz = {
  familyId: { _id: '', label: '' },
  questionsList: [],
};

export const initQuestion = {
  rayonId: '',
  question: 'Tapez le nom de la question',
  answers: [],
  hasMultipleAnswer: false,
  answerType: 'manual',
  logicals: [],
  _id: '',
};

export const initComposition = {
  short_name: '',
  image: '',
  ingredients: [],
  isNew: true,
};

export const formatPictureData = (data) => {
  const body = {
    image: data?.image,
    groupId: data?.groupId,
    families: data?.families,
    filters: data?.filters,
    specialsFilters: data?.specialsFilters,
    moods: data?.moods,
    infosMainProducts: data?.infosMainProducts,
    infosSecondaryProducts: data?.infosSecondaryProducts,
    attrMainCompositions: data?.attrMainCompositions,
    attrSecondaryCompositions: data?.attrSecondaryCompositions,
    longName: data?.longName,
    shortName: data?.shortName,
    punchlines: data?.punchlines,
    hashtags: data?.hashtags,
    relatedCustomers: data?.relatedCustomers,
    samePictures: data?.samePictures,
  };
  return body;
};
