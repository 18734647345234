import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { imagePunchlinesAction } from '../../../redux/images';
import { Input } from '../../atoms/Fields';

export const Punchlines = () => {
  const dispatch = useDispatch();
  const role = useSelector((s) => s.app.role);
  const punchlines = useSelector((s) => s.images.punchlines);

  const isCommercial = role === 'COMMERCIAL';

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(
      imagePunchlinesAction({
        key: name,
        value,
      })
    );
  };

  return (
    <>
      <p className='image--sectionTitle'>PUNCHLINE</p>
      <Input
        label='Punchline 1'
        name='punchline_1'
        disabled={isCommercial}
        onChange={handleChange}
        value={punchlines?.punchline_1}
      />
      <Input
        label='Punchline 2'
        name='punchline_2'
        disabled={isCommercial}
        onChange={handleChange}
        value={punchlines?.punchline_2}
      />
      <Input
        label='Punchline 3'
        name='punchline_3'
        disabled={isCommercial}
        onChange={handleChange}
        value={punchlines?.punchline_3}
      />
    </>
  );
};
