import { useState } from 'react';
import { SidebarImgLayout } from '../components/organisms/Sidebar';
import { HeaderImgList } from '../components/organisms/Header';
import { useDebounce } from '../hooks/useDebounce';

export const withImagesLayout = (WrappedComponent) => (props) => {
  //
  // const dispatch = useAppDispatch();
  // const filteredPictures = useAppSelector((s) => s.picture.filteredPictures);
  const [showSidebar, setShowSidebar] = useState(true);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const searchDebounce = useDebounce(search, 500);

  // const getPictures = useCallback(async () => {
  //   setLoading(true);
  //   const _mapElement = (el) => el.name || el.label || el;
  //   fetchPicturesByFilters(filteredPictures, _mapElement, setLoading).then(
  //     (res) => {
  //       if (res) {
  //         dispatch(setPicturesAction(res?.data?.pictures || []));
  //         setLoading(false);
  //       }
  //     }
  //   );
  // }, [filteredPictures, dispatch]);

  // useEffect(() => {
  //   getPictures();
  // }, [getPictures]);

  //onContextMenu={(e) => e.preventDefault()}
  return (
    <div className='imagesLayout'>
      <HeaderImgList search={search} setSearch={setSearch} />
      <div className='imagesLayout--contain'>
        <SidebarImgLayout showSidebar={showSidebar} />
        <div
          className='imagesLayout--container'
          style={{ width: showSidebar ? 'calc(100% - 300px)' : '100%' }}
        >
          <WrappedComponent
            {...props}
            // getPictures={getPictures}
            setShowSidebar={setShowSidebar}
            pending={loading}
            search={searchDebounce}
          />
        </div>
      </div>
    </div>
  );
};
