import { axiosInstance } from '../assets/utils/axiosInstance';
import { IComposition } from '../redux/compositions';

//Families api
export const fetchFamilies = async (search = '') => {
  const res: any = await axiosInstance
    .get(`/family/get-all?label=${search}`)
    .catch((err) => {
      console.log('fetchFamilies', err);
    });
  return res.data;
};

export const fetchFamiliesList = async (search = '') => {
  const res: any = await axiosInstance
    .get(`/families?label=${search}`)
    .catch((err) => {
      console.log('fetchFamilies', err);
    });
  return res.data;
};

export const createOneFamily = async (body) => {
  const res = await axiosInstance.post('/family/create', body).catch((err) => {
    console.log('createOneFamily', err);
  });
  return res;
};

export const duplicateOneFamily = async (body) => {
  const res = await axiosInstance
    .post('/family/duplicate', body)
    .catch((err) => {
      console.log('duplicateOneFamily', err);
    });
  return res;
};

export const updateOneFamily = async ({ familyId, label, defaultList }) => {
  const res = await axiosInstance
    .put(`/family/${familyId}`, {
      label,
      defaultList,
    })
    .catch((err) => {
      console.log('updateOneFamily', err);
    });
  return res;
};

export const deleteOneFamily = async (id) => {
  const res = await axiosInstance.put(`/family/delete/${id}`).catch((err) => {
    console.log('deleteOneFamily', err);
  });
  return res;
};

export const getOneFamilyById = async (familyId) => {
  const res: any = await axiosInstance
    .get(`/family/by-id/${familyId}`)
    .catch((err) => {
      console.log('getOneFamilyById', err);
    });
  return res;
};

export const getProductListByFamilyId = async (familyId) => {
  const res: any = await axiosInstance
    .get(`/family/products/${familyId}`)
    .catch((err) => {
      console.log('getOneFamilyById', err);
    });
  return res;
};

export const getAllCompositionsByProductId = async (productId: string) => {
  try {
    const res: any = await axiosInstance.get(
      `/family/get-compositions-by-product/${productId}`
    );
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: null };
  }
};

export const getAllCompositionsByFamilyId = async (familyId: string) => {
  try {
    const res: any = await axiosInstance.get(
      `/family/get-compositions-by-family/${familyId}`
    );
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: null };
  }
};

export const getAllCompositionsByCustomerId = async (customerId: string) => {
  try {
    const res: any = await axiosInstance.get(
      `/family/get-compositions-by-customer/${customerId}`
    );
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: null };
  }
};

export const createOneProduct = async (familyId, name) => {
  const res = await axiosInstance
    .post(`/family/add-product/${familyId}`, {
      name,
      familyId,
    })
    .catch((err) => {
      console.log('createOneProduct', err);
    });
  return res;
};

export const createComposition = async (body: IComposition) => {
  try {
    const res = await axiosInstance.post<{
      data: { success: boolean; message: string; data: IComposition };
    }>(`/family/add-composition`, body);
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: null };
  }
};

export const updateComposition = async (id: string, body: IComposition) => {
  try {
    const res = await axiosInstance.put(
      `/family/update-product-composition/${id}`,
      body
    );
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: null };
  }
};

export const updateOneProduct = async (productId, familyId, data) => {
  const res = await axiosInstance
    .put(`/family/update-product/${productId}/${familyId}`, data)
    .catch((err) => {
      console.log('updateOneProduct', err);
    });
  return res;
};

export const deleteOneProduct = async (id) => {
  const res = await axiosInstance
    .put(`/family/delete-product/${id}`)
    .catch((err) => {
      console.log('deleteOneProduct', err);
    });
  return res;
};

export const deleteOneComposition = async (id: string) => {
  try {
    const res = await axiosInstance.delete(
      `/family/delete-product-composition/${id}`
    );
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: null };
  }
};

export const getOneProduct = async (productId: string) => {
  try {
    const res = await axiosInstance.get(
      `/family/get-product-by-id/${productId}`
    );
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: null };
  }
};
