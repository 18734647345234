import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initComposition } from '../../assets/constants';
import { type } from 'os';

export interface IComposition {
  image: string;
  short_name: string;
  ingredients: {
    name: string;
    quantity: number;
    ingredientId: string;
    questionId: string;
    specialityId: string | any;
  }[];
  productId?: string;
  familyId?: string;
  createdAt?: string;
  _id?: string;
  isNew?: boolean;
  pictureCounter?: number;
  type?: string;
}

interface IInitState {
  compositions: IComposition[] | [];
  compositionSelected: IComposition;
  productId?: string | null;
  compositionsSelected?: IComposition[] | [];
}

const initialState: IInitState = {
  compositions: [],
  compositionSelected: initComposition,
  productId: null,
  compositionsSelected: [],
};

export const slice = createSlice({
  name: 'compositions',
  initialState,
  reducers: {
    compositionsAction: (state, action: PayloadAction<IComposition[] | []>) => {
      state.compositions = action.payload;
    },
    compositionSelectedAction: (
      state,
      action: PayloadAction<IComposition | null>
    ) => {
      state.compositionSelected = action.payload;
    },
    compositionsSelectedAction: (
      state,
      action: PayloadAction<IComposition[] | null>
    ) => {
      state.compositionsSelected = action.payload;
    },
    productIdAction: (state, action: PayloadAction<null | string>) => {
      state.productId = action.payload;
    },
    resetStatesCompositionAction: (state) => {
      return initialState;
    },
  },
});

export const {
  compositionSelectedAction,
  compositionsAction,
  productIdAction,
  compositionsSelectedAction,
  resetStatesCompositionAction,
} = slice.actions;
export default slice.reducer;
