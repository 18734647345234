import React from 'react';
import { Button } from '../../../components/atoms/Buttons';
import { SelectWithSearch } from '../../../components/atoms/Fields';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { questionSelectedAction } from '../../../redux/familyQuiz';

export const LogicBlock: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const onAddLogic = () => {
    const newLogicals = [
      ...questionSelected?.logicals,
      { condition: '', action: '' },
    ];
    dispatch(
      questionSelectedAction({ ...questionSelected, logicals: newLogicals })
    );
  };

  return (
    <div className='familyQuiz--logicBlock'>
      <div className='familyQuiz--logicBlock-header'>
        <span>
          {questionSelected?.question || 'Veuillez sélectionner une question'}
        </span>
      </div>
      <div className='familyQuiz--logicBlock-container'>
        <Button
          label='Ajouter une logique'
          margin='0 0 1rem'
          onClick={onAddLogic}
        />
        <div className='familyQuiz--logicBlock-cards'>
          {questionSelected?.logicals?.map((logic, index) => {
            return <Card logic={logic} index={index} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

const Card: React.FC<{
  logic: { condition: string; action: string };
  index: number;
}> = ({ logic, index }) => {
  //
  const dispatch = useAppDispatch();
  const quiz = useAppSelector((s) => s.familyQuiz.quiz);
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const answersList = questionSelected?.answers?.flatMap((answer) => {
    return answer?.values?.map((value) => ({
      name: value?.label,
      value: value?.label,
    }));
  });
  const questionsList = quiz?.questionsList?.map((question) => ({
    name: question?.question,
    value: question?.question,
  }));

  const onChangeCondition = (value) => {
    const newLogicals = questionSelected?.logicals?.map((_logic, _index) => {
      if (_index === index) {
        return { ..._logic, condition: value };
      }
      return _logic;
    });
    dispatch(
      questionSelectedAction({ ...questionSelected, logicals: newLogicals })
    );
  };

  const onChangeAction = (value) => {
    const newLogicals = questionSelected?.logicals?.map((_logic, _index) => {
      if (_index === index) {
        return { ..._logic, action: value };
      }
      return _logic;
    });
    dispatch(
      questionSelectedAction({ ...questionSelected, logicals: newLogicals })
    );
  };

  const onRemoveLogic = () => {
    const newLogicals = questionSelected?.logicals?.filter(
      (_logic, _index) => _index !== index
    );
    dispatch(
      questionSelectedAction({ ...questionSelected, logicals: newLogicals })
    );
  };

  return (
    <div className='familyQuiz--logicBlock-card'>
      <SelectWithSearch
        label='Si reponse'
        options={answersList}
        onSelect={(value) => onChangeCondition(value)}
        value={logic?.condition || 'Choisissez la condition'}
        width={null}
        margin='0 0 1rem 0'
      />
      <SelectWithSearch
        label='Aller a la question'
        options={questionsList}
        onSelect={(value) => onChangeAction(value)}
        value={logic?.action || 'Choisissez la question'}
        width={null}
        margin='0 0 1rem 0'
      />
      <Button
        label='Supprimer la condition'
        variant='red'
        onClick={onRemoveLogic}
      />
    </div>
  );
};
