import { months } from '../constants';

export const helperSubscriptionMonths = (currentSubscription) => {

  if (!currentSubscription) {
    return [];
  }

  const startMonthIndex = months.indexOf(currentSubscription.startOn.month);
  const startYear = parseInt(currentSubscription.startOn.year);
  const endMonthIndex = months.indexOf(currentSubscription.endIt.month);
  const endYear = parseInt(currentSubscription.endIt.year);

  const result = [];

  for (let year = startYear; year <= endYear; year++) {
    const startMonth = year === startYear ? startMonthIndex : 0;
    const endMonth = year === endYear ? endMonthIndex : months.length - 1;

    for (let month = startMonth; month <= endMonth; month++) {
      result.push({ month: months[month], year });
    }
  }

  return result;
};
