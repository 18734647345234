import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toast } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  compositionSelectedAction,
  resetStatesCompositionAction,
} from '../../../redux/compositions';
import {
  IQuestion,
  questionSelectedAction,
  quizAction,
  resetQuizStoreAction,
} from '../../../redux/familyQuiz';
import {
  familySelectedImgAttrAction,
  imgCompositionIsSelectedAction,
  imgCompositionsSelectedAction,
  resetImgAttrAction,
  resetImgCompositionsSelectedAction,
} from '../../../redux/imageAttributions';
import {
  attrMainCompositionsAction,
  attrSecondaryCompositionsAction,
  imageFormAction,
  infosMainProductsAction,
  infosSecondaryProductsAction,
} from '../../../redux/images';
import {
  confirmReplayQuizModalAction,
  editImgModalAction,
} from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { CompositionsListRight } from './CompositionsList';
import { CompositionsFilters } from './CompsitionsFilters';
import { initComposition } from '../../../assets/constants';
import { imageAttributionsTabAction } from '../../../redux/imageAttributions';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';

export const RightBlock: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const cloneParam = urlParams.get('clone');
  const isClone = cloneParam === 'true';
  const imageAttributions = useAppSelector((s) => s.imageAttributions);
  const imageStore = useAppSelector((s) => s.images);
  const compositionSelected = useAppSelector(
    (s) => s.compositions?.compositionSelected
  );
  const { count, mainCompCount, secondaryCompCount, compositionsAttr } =
    useAttributionsImgComposition();
  const familiesSelected =
    imageAttributions?.imageAttributionsTab === 'product-main'
      ? imageStore?.infosMainProducts
      : imageStore?.infosSecondaryProducts;

  const attributionAction =
    imageAttributions?.imageAttributionsTab === 'product-main'
      ? attrMainCompositionsAction
      : attrSecondaryCompositionsAction;

  const action =
    imageAttributions?.imageAttributionsTab === 'product-main'
      ? infosMainProductsAction
      : infosSecondaryProductsAction;

  const onCancelled = () => {
    const formatedFamilies = familiesSelected?.map((f) => {
      return { ...f, checkedNum: 0 };
    });
    dispatch(imgCompositionsSelectedAction([]));
    dispatch(resetImgAttrAction());
    dispatch(resetStatesCompositionAction());
    dispatch(resetQuizStoreAction());
    dispatch(action(formatedFamilies));
    dispatch(imageFormAction({ key: 'tab', value: 'attribution' }));

    if (imageAttributions?.updateImage) {
      dispatch(editImgModalAction(true));
      return;
    } else {
      navigate(-1);
    }
  };

  const onAttributeCompositions = () => {
    const totalFamiliesComposition = familiesSelected?.reduce(
      (acc, f) => acc + (f?.quantity || 0),
      0
    );

    const checkedFamilySelected =
      totalFamiliesComposition ===
      imageAttributions?.imgCompositionsSelected?.length;

    if (!checkedFamilySelected) {
      Toast(
        'warning',
        'Le nombre de compositions à attribuer ne correspond pas au nombre de produits de famille sélectionnés.',
        4000
      );
      return;
    }

    dispatch(attributionAction(imageAttributions?.imgCompositionsSelected));
    dispatch(resetImgCompositionsSelectedAction());

    const formatedFamilies = familiesSelected?.map((f) => {
      return { ...f, checkedNum: 0 };
    });
    dispatch(resetStatesCompositionAction());
    dispatch(resetQuizStoreAction());
    dispatch(action(formatedFamilies));
    dispatch(imageFormAction({ key: 'tab', value: 'attribution' }));

    if (
      imageAttributions?.imageAttributionsTab === 'product-main' &&
      checkedFamilySelected
    ) {
      dispatch(imageAttributionsTabAction('product-secondary'));
      dispatch(resetImgCompositionsSelectedAction());
      dispatch(familySelectedImgAttrAction(null));

      if (isClone && secondaryCompCount === 0) {
        navigate('/clone-image');
      } else if (isClone && secondaryCompCount > 0) {
        return;
      }
    } else if (
      imageAttributions?.imageAttributionsTab === 'product-secondary' &&
      checkedFamilySelected
    ) {
      dispatch(resetImgAttrAction());
      dispatch(resetImgCompositionsSelectedAction());
      navigate(isClone ? '/clone-image' : '/add-image');
    }
  };

  const onCheckedFamily = () => {
    if (!imageAttributions?.imgCompositionIsSelected) {
      dispatch(confirmReplayQuizModalAction(true));
      return;
    }

    //formated quiz
    const payload = {
      ...imageAttributions.familySelectedImgAttr?.quiz,
      questionsList:
        imageAttributions.familySelectedImgAttr?.quiz.questionsList.map(
          (q: IQuestion) => {
            return {
              ...q,
              answered: false,
              noAnswer: false,
            };
          }
        ),
    };

    dispatch(quizAction(payload));
    dispatch(questionSelectedAction(payload.questionsList[0]));
    dispatch(imgCompositionIsSelectedAction(false));
    dispatch(compositionSelectedAction(initComposition));
  };

  return (
    <div className='imageAttributions--rightBlock'>
      <div className='w-100 flex-row-center-between'>
        <Button onClick={onCancelled} label='Annuler' width='35%' />
        {imageAttributions?.imgCompositionsSelected?.length > 0 && (
          <Button
            label={`Ajouter ${
              imageAttributions?.imgCompositionsSelected?.length
            } composition${
              imageAttributions?.imgCompositionsSelected?.length > 1 ? 's' : ''
            }`}
            variant='yellow'
            width='60%'
            onClick={onAttributeCompositions}
          />
        )}
      </div>
      {imageAttributions?.familySelectedImgAttr?.checkedNum > 0 &&
        compositionSelected?.ingredients?.length > 0 && (
          <Button
            margin='1rem auto 1rem 0'
            variant='yellow'
            label='Rejouer le quiz'
            onClick={onCheckedFamily}
            width='100%'
          />
        )}
      <div className='imageAttributions--rightBlock-list'>
        {imageAttributions?.attributionMode === 'quiz' && (
          <CompositionsListRight />
        )}
        {imageAttributions?.attributionMode === 'product' &&
          !!imageAttributions?.imgProductId && <CompositionsFilters />}
      </div>
    </div>
  );
};
