import {useCallback, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {DotsCircleIcon} from '../../../assets/svgs/DotsCircleIcon';
import {ActionModal} from '../../../components/atoms/ActionModal';
import {TableHeader} from '../../../components/atoms/TableHeader';
import {withNavigation} from '../../../hoc/withNavigation';
import {Toast, toast_loader} from '../../../assets/utils/toast';
import {Loader} from '../../../components/atoms/Loader';
import {HeaderWithSearch} from '../../../components/organisms/Header';
import {SwitchButton} from '../../../components/atoms/Switch';
import {deleteModalAction} from '../../../redux/modals';
import {DeleteModal} from '../../../components/modals/DeleteModal';
import {compose} from 'recompose';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {sortedList} from '../../../assets/utils/sortedList';
import {
  deleteOneUser,
  fetchAllUsers,
  toggleActiveOneUser,
} from '../../../api/users.api';
import {copyObj} from '../../../assets/utils/copyObj';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';

const UsersView = ({user}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const deleteModal = useAppSelector((s) => s.modals.deleteModal);
  const [users, setUsers] = useState([]);
  const [userform, setUserform] = useState({});
  const [search, setSearch] = useState('');

  const [loading, setLoading] = useState(false);

  const getUsers = useCallback(async () => {
    setLoading(true);
    await fetchAllUsers()
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        Toast('error', "Une erreur s'est produite ! ❌");
        setLoading(false);
      });
    setLoading(false);
  }, []);

  const handleDeleteUser = async (id) => {
    setLoading(true);
    await deleteOneUser(id)
      .then((res) => {
        setLoading(false);
        Toast('success', 'Utilisateur supprimé !');
        dispatch(deleteModalAction(false));
        getUsers();
      })
      .catch((err) => {
        setLoading(false);
        Toast('error', "Une erreur s'est produite ! ❌");
      });
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (loading) return <Loader />;
  return (
    <>
      {deleteModal && (
        <DeleteModal
          onClose={() => dispatch(deleteModalAction(false))}
          onDelete={() => handleDeleteUser(userform?._id)}
          text={`l'utilisateur "${userform?.lastname}"`}
        />
      )}
      <div className='pages-layout'>
        <HeaderWithSearch search={search} setSearch={setSearch} user={user} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`UTILISATEURS (${users.length})`}
            onAdd={() => navigate('/user/add')}
          />
          <div className='table'>
            {copyObj(users)
              ?.sort((a, b) => sortedList(a, b, 'lastname'))
              .map((user, index) => {
                const reg = new RegExp(`${search}`, 'ig');
                if (
                  search?.length >= 3 &&
                  !user?.lastname?.match(reg) &&
                  !user?.firstname?.match(reg) &&
                  !user?.email?.match(reg)
                ) {
                  return null;
                }
                return (
                  <Trow
                    key={index}
                    user={user}
                    getUsers={getUsers}
                    userform={userform}
                    setUserform={setUserform}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

const Trow = ({user, getUsers, setUserform}) => {
  //
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showActionModal, setShowActionModal] = useState(false);

  const onShowModalDelete = () => {
    setUserform(user);
    dispatch(deleteModalAction(true));
  };

  const handleActive = async (activeVal) => {
    const loader = toast.loading('Activation en cours...');
    await toggleActiveOneUser(user?._id, activeVal)
      .then((_) => {
        toast_loader(
          loader,
          `Utilisateur ${activeVal === true ? 'activé' : 'désactivé'} !`,
          'success'
        );
        getUsers();
        setShowActionModal(false);
      })
      .catch((_) => {
        toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
      });
  };

  return (
    <div className='table-trow'>
      <SwitchButton
        checked={user?.active}
        onChange={(val) => handleActive(val)}
      />
      <div className='table-trow-texts' style={{flex: 4.5, marginLeft: '1rem'}}>
        <p
          className='table-trow-text'
          style={{
            fontFamily: 'Inter Bold',
            textTransform: 'capitalize',
            margin: '0 0 .5rem 0',
          }}
        >
          {user.lastname + ' ' + user.firstname}
        </p>
        <p
          className='table-trow-text'
          style={{textTransform: 'inherit', margin: 0}}
        >
          {user.email}
        </p>
      </div>
      <p
        className='table-trow-text'
        style={{flex: 1, textTransform: 'inherit', textAlign: 'right'}}
      >
        {user.role}
      </p>

      <div className='table-trow-action' style={{flex: 0.5}}>
        {showActionModal && (
          <ActionModal
            onEdit={() => navigate(`/user/${user?._id}`)}
            onDelete={onShowModalDelete}
          />
        )}
        <DotsCircleIcon onClick={() => setShowActionModal(!showActionModal)} />
      </div>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(UsersView);
