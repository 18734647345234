export const helperTotalCompositionByType = (
  family: any,
  type: 'principal' | 'secondary'
) => {
  return family?.products?.reduce((acc, product) => {
    const totalComposition = product?.compositions?.reduce(
      (acc, composition) => (composition?.type === type ? acc + 1 : acc),
      0
    );
    return acc + totalComposition;
  }, 0);
};
