import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createQuiz, updateOneQuiz } from '../../../api/quiz.api';
import { Toast } from '../../../assets/utils/toast';
import { Button } from '../../../components/atoms/Buttons';
import { Loader } from '../../../components/atoms/Loader';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { questionSelectedAction, quizAction } from '../../../redux/familyQuiz';

export const RightBlockHeader: React.FC = () => {
  //
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { familyId } = useParams();
  const quiz = useAppSelector((s) => s.familyQuiz.quiz);
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const [loading, setLoading] = useState<boolean>(false);

  const onCancel = () => {
    navigate(-1);
    dispatch(questionSelectedAction(null));
    dispatch(quizAction(null));
  };

  const onSaveQuiz = async () => {
    setLoading(true);
    const questions = quiz.questionsList.map((_question) => {
      if (questionSelected?._id === _question._id) {
        return questionSelected;
      }
      return _question;
    });

    const payload = {
      familyId,
      questionsList: questions.map((_question) => ({
        rayonId: _question.rayonId,
        question: _question.question,
        answers: _question.answers?.map((_answer) => {
          let values = _answer.values?.map((_value) => _value?._id);
          return { ..._answer, values };
        }),
        hasMultipleAnswer: _question.hasMultipleAnswer,
        answerType: _question.answerType,
        logicals: _question.logicals,
      })),
    };

    const { error } = await createQuiz(payload);
    if (!error) {
      setLoading(false);
      Toast('success', 'Quiz créé avec succès !');
      onCancel();
    } else {
      setLoading(false);
      Toast('error', 'Une erreur est survenue !');
    }
  };

  const onUpdateQuiz = async () => {
    setLoading(true);

    const questions = quiz.questionsList.map((_question) => {
      if (questionSelected && questionSelected._id === _question._id) {
        return questionSelected;
      }
      return _question;
    });

    const payload = {
      familyId,
      questionsList: questions
        ?.map((_question) => ({
          rayonId: _question.rayonId,
          question: _question.question,
          answers: _question.answers?.map((_answer) => {
            let values = _answer.values?.map((_value) => _value?._id);
            return { ..._answer, values };
          }),
          hasMultipleAnswer: _question.hasMultipleAnswer,
          answerType: _question.answerType,
          logicals: _question.logicals,
          _id: _question.isNew ? null : _question._id,
        }))
        .filter((_question) => _question.rayonId),
    };

    const { error } = await updateOneQuiz(quiz._id, payload);
    if (!error) {
      setLoading(false);
      Toast('success', 'Quiz modifié avec succès !');
      onCancel();
    } else {
      setLoading(false);
      Toast('error', 'Une erreur est survenue !');
    }
  };

  if (loading) return <Loader />;

  return (
    <div className='familyQuiz--rightBlock-header'>
      <p className='familyQuiz--rightBlock-headerTitle'>
        Questionnaire Famille : {quiz?.familyId?.label}
      </p>
      <div className='familyQuiz--rightBlock-headerBtns'>
        <Button label='Quitter et annuler' onClick={onCancel} />
        <Button
          label='Enregistrer et terminer'
          variant='yellow'
          margin='0 0 0 1rem'
          onClick={quiz?._id ? onUpdateQuiz : onSaveQuiz}
        />
      </div>
    </div>
  );
};
