import { HeaderAddImgView } from '../../../components/organisms/Header';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../../../assets/utils/toast';
import { useState } from 'react';
import {
  infosMainProductsAction,
  infosSecondaryProductsAction,
} from '../../../redux/images';

import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { ImageForm } from './ImageForm';
import { Tabs } from '../../../components/atoms/Tabs';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';
import { useQuery } from 'react-query';
import { fetchFamilies } from '../../../api/families.api';
import { editImgModalAction } from '../../../redux/modals';
import { ImageContainerFooter } from './ImageContainerFooter';
import {
  imageAttributionsTabAction,
  resetImgAttrAction,
} from '../../../redux/imageAttributions';

const tabData = [
  { label: 'Informations', id: 'informations' },
  { label: 'Attribution', id: 'attribution' },
];

const CloneImageView = () => {
  //
  const {
    infosMainProducts,
    infosSecondaryProducts,
    image,
    tab: imageFormTab,
  } = useAppSelector((s) => s.images);

  const [tab, setTab] = useState(imageFormTab);

  const products = [...infosMainProducts, ...infosSecondaryProducts];

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: familiesData } = useQuery('families', () => fetchFamilies());

  const { imageAttributionsTab } = useAppSelector(
    (state) => state.imageAttributions
  );
  const imageFormData = useAppSelector((state) => state.images);
  const { count, mainCompCount, secondaryCompCount, compositionsAttr } =
    useAttributionsImgComposition();

  const handleRedirect = (type, isClone) => {
    navigate(
      !isClone ? '/image-attributions' : '/image-attributions?clone=true'
    );
    dispatch(resetImgAttrAction());
    dispatch(imageAttributionsTabAction(type));
  };

  const onAddAttribution = (edit, isClone) => {
    if (count > 0) {
      const familiesSelected =
        imageAttributionsTab === 'product-main'
          ? imageFormData?.infosMainProducts
          : imageFormData?.infosSecondaryProducts;

      const action =
        imageAttributionsTab === 'product-main'
          ? infosMainProductsAction
          : infosSecondaryProductsAction;

      for (let i = 0; i < familiesData.length; i++) {
        const family = familiesData[i];
        const formatedFamilies = familiesSelected?.map((f) =>
          f.familyId === family?.familyId ? family : f
        );
        dispatch(action(formatedFamilies));
      }

      const hasSecondaryComp = secondaryCompCount > 0;
      const hasMainComp = mainCompCount > 0;

      if (
        (hasSecondaryComp && hasMainComp) ||
        (!hasSecondaryComp && hasMainComp)
      ) {
        handleRedirect('product-main', isClone);
      } else if (hasSecondaryComp && !hasMainComp) {
        handleRedirect('product-secondary', isClone);
      }

      if (edit) {
        dispatch(editImgModalAction(false));
      }
    } else if (count === 0 && compositionsAttr.length > 0) {
      setTab('attribution');
    } else {
      Toast('warning', 'Veuillez sélectionner au moins une famille !');
    }
  };

  return (
    <div className='image'>
      <Tabs
        tabs={tab}
        setTabs={setTab}
        buttons={tabData}
        disabled={{
          attribution: products.length === 0 || !!!image,
        }}
      />
      <div className='image--container'>
        <ImageForm tab={tab} onAddAttribution={onAddAttribution} isClone />
        <ImageContainerFooter
          onAddAttribution={onAddAttribution}
          tab={tab}
          isClone
        />
      </div>
    </div>
  );
};

export default withCheckAuth(CloneImageView);
