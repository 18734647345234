import {useState} from 'react';
import {compose} from 'recompose';
import {TableHeader} from '../../../components/atoms/TableHeader';
import {withNavigation} from '../../../hoc/withNavigation';
import {DotsCircleIcon} from '../../../assets/svgs/DotsCircleIcon';
import {ActionModal} from '../../../components/atoms/ActionModal';
import {deleteModalAction, stylesModalAction} from '../../../redux/modals';
import {Loader} from '../../../components/atoms/Loader';
import {HeaderWithSearch} from '../../../components/organisms/Header';
import {DeleteModal} from '../../../components/modals/DeleteModal';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {sortedList} from '../../../assets/utils/sortedList';
import {Toast} from '../../../assets/utils/toast';
import {deleteOneStyle, fetchStyles} from '../../../api/styles.api';
import {useQuery} from 'react-query';
import {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
} from '../../../redux/filtersSelected';
import {useResetFilters} from '../../../hooks/useResetFilters';
import {copyObj} from '../../../assets/utils/copyObj';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';

const StylesView = ({user}) => {
  //
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const {data: stylesData, isLoading} = useQuery('styles', () =>
    fetchStyles('')
  );

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const styles = copyObj(stylesData)
    ?.sort((a, b) => sortedList(a, b, 'label'))
    ?.filter((style) =>
      style.label.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <>
      <DeleteStyleModal />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`styles (${styles?.length || 0})`}
            onAdd={() => dispatch(stylesModalAction(true))}
          />
          <div className='table'>
            {isLoading ? (
              <Loader />
            ) : (
              styles
                ?.filter((style) =>
                  style.label.toLowerCase().includes(search.toLowerCase())
                )
                .map((style, index) => (
                  <Trow
                    key={index}
                    style={style}
                    onOpen={onOpen}
                    showActionModal={showActionModal}
                  />
                ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteStyleModal = () => {
  const dispatch = useAppDispatch();
  const style = useAppSelector((state) => state.filtersSelected.style);

  const {resetFilters} = useResetFilters();

  const handleDeleteStyle = async () => {
    try {
      await deleteOneStyle(style._id);
      Toast('success', `Style "${style.label}" supprimé avec succès`);
      resetFilters('styles');
      dispatch(resetFilteredSelectedItemAction('style'));
      dispatch(deleteModalAction(false));
    } catch (error) {
      Toast('error', 'Une erreur est survenue');
    }
  };

  return (
    <DeleteModal
      onDelete={handleDeleteStyle}
      text={`le style "${style?.label}"`}
    />
  );
};

const Trow = ({style, showActionModal, onOpen}) => {
  //
  const dispatch = useAppDispatch();

  const handleSelectedStyle = () => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'style',
        item: style,
      })
    );
  };

  const onShowModal = () => {
    handleSelectedStyle();
    dispatch(stylesModalAction(true));
  };
  const onShowModalDelete = () => {
    handleSelectedStyle();
    dispatch(deleteModalAction(true));
  };

  return (
    <div className='table-trow'>
      <span className='table-trow-text'>{style?.label}</span>
      <div className='table-trow-action'>
        {showActionModal === style?._id && (
          <ActionModal onEdit={onShowModal} onDelete={onShowModalDelete} />
        )}
        <DotsCircleIcon onClick={() => onOpen(style?._id)} />
      </div>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(StylesView);
