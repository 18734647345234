import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchRayons, getOneRayonById } from '../../../api/rayons.api';
import { copyObj } from '../../../assets/utils/copyObj';
import { helperCheckIngredients } from '../../../assets/utils/helperCheckIngredients';
import { sortedList } from '../../../assets/utils/sortedList';
import { Toast } from '../../../assets/utils/toast';
import { Button } from '../../../components/atoms/Buttons';
import { Checkbox, SelectWithSearch } from '../../../components/atoms/Fields';
import { Loader } from '../../../components/atoms/Loader';
import { SwitchButton } from '../../../components/atoms/Switch';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { IComposition } from '../../../redux/compositions';
import { IIngredient, questionSelectedAction } from '../../../redux/familyQuiz';
import { rayonIngredientModalAction } from '../../../redux/modals';
import { Ingredient } from './Ingredient';

export const QuestionBlock: React.FC = () => {
  const dispatch = useAppDispatch();

  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const rayonIngredientModal = useAppSelector(
    (s) => s.modals.rayonIngredientModal
  );

  const allProdsComps = useAppSelector((s) => s.familyQuiz.allProdsComps);

  const { data: rayons } = useQuery('rayons', () => {
    return fetchRayons('');
  });
  const { data, refetch: refetchRayonSelected } = useQuery(
    ['rayonSelected', questionSelected?.rayonId],
    () =>
      questionSelected?.rayonId
        ? getOneRayonById(questionSelected?.rayonId)
        : null,
    {
      enabled: !!questionSelected?.rayonId,
    }
  );
  const [open, setOpen] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const rayon = data?.data?.rayon;
  const ingredients = rayon?.ingredients;

  const allCompsIngredients = Array.from(
    new Set(
      allProdsComps?.flatMap((prodComp: IComposition) =>
        prodComp?.ingredients?.map((_ingredient) => _ingredient?.name)
      )
    )
  );

  const formattedRayons: { name: string; value: string }[] | undefined =
    rayons?.map((rayon) => ({
      name: rayon?.label,
      value: rayon?._id,
    }));

  const onSelectAllAnswer = () => {
    const answersAllValues = ingredients?.map((_ingredient) => ({
      ingredient_id: _ingredient?._id,
      values: _ingredient?.specialties,
    }));
    if (questionSelected?.answers?.length === ingredients?.length) {
      dispatch(questionSelectedAction({ ...questionSelected, answers: [] }));
    } else {
      dispatch(
        questionSelectedAction({
          ...questionSelected,
          answers: answersAllValues,
        })
      );
    }
  };

  const onOpen = (id: string) => {
    if (open === id) return setOpen(null);
    return setOpen(id);
  };

  useEffect(() => {
    (async () => {
      if (!rayonIngredientModal) {
        setLoading(true);
        const { isFetched } = await refetchRayonSelected();
        if (isFetched) {
          setLoading(false);
        } else {
          setLoading(false);
          Toast(
            'error',
            "Une erreur s'est produit lors de la récupération des ingrédients !"
          );
        }
      }
    })();
  }, [refetchRayonSelected, rayonIngredientModal]);

  if (loading) return <Loader />;

  return (
    <div className='familyQuiz--questionBlock'>
      <input
        type='text'
        value={questionSelected?.question}
        onChange={(e) =>
          dispatch(
            questionSelectedAction({
              ...questionSelected,
              question: e.target.value,
            })
          )
        }
        className='familyQuiz--questionBlock-input'
      />
      <SelectWithSearch
        label=''
        options={rayons?.length > 0 ? formattedRayons : []}
        onSelect={(value) =>
          dispatch(
            questionSelectedAction({
              ...questionSelected,
              rayonId: value,
            })
          )
        }
        value={
          rayons?.find((_rayon) => _rayon?._id === questionSelected?.rayonId)
            ?.label
        }
        width={null}
        margin='0 0 1rem 0'
      />
      <div className='familyQuiz--questionBlock-options'>
        <div style={{ display: 'flex' }}>
          <Button
            label='Ajouter un ingrédient'
            onClick={() => dispatch(rayonIngredientModalAction(true))}
            margin='0 1rem 0 0'
          />
          <Checkbox
            label='Reponse multiple'
            checked={questionSelected?.hasMultipleAnswer}
            onCheck={() =>
              dispatch(
                questionSelectedAction({
                  ...questionSelected,
                  hasMultipleAnswer: !questionSelected?.hasMultipleAnswer,
                })
              )
            }
            margin='0 1rem 0 0'
          />
          <Checkbox
            label={
              !!questionSelected &&
              questionSelected?.answers?.length === ingredients?.length
                ? 'Tout décoché'
                : 'Tout coché'
            }
            checked={
              !!questionSelected &&
              questionSelected?.answers?.length === ingredients?.length
            }
            onCheck={onSelectAllAnswer}
            margin='0'
          />
        </div>
        <div>
          <span className='familyQuiz--questionBlock-labelSwitch'>
            Réponse manuelle
          </span>
          <SwitchButton
            onChange={(val: boolean) => {
              dispatch(
                questionSelectedAction({
                  ...questionSelected,
                  answerType: val ? 'auto' : 'manual',
                })
              );
            }}
            checked={questionSelected?.answerType === 'auto'}
          />
          <span className='familyQuiz--questionBlock-labelSwitch'>
            Réponse auto
          </span>
        </div>
      </div>

      {questionSelected?.answerType === 'manual' ? (
        <div className='familyQuiz--questionBlock-ingredients'>
          {copyObj(ingredients)
            ?.sort((a, b) => sortedList(a, b, 'label'))
            ?.map((ingredient: IIngredient, index: number) => {
              return (
                <Ingredient
                  key={index}
                  open={open}
                  onOpen={onOpen}
                  ingredient={ingredient}
                />
              );
            })}
        </div>
      ) : (
        <div className='familyQuiz--questionBlock-ingredients'>
          {copyObj(ingredients)
            ?.sort((a, b) => sortedList(a, b, 'label'))
            ?.map((ingredient: IIngredient, index: number) => {
              if (
                helperCheckIngredients(
                  allCompsIngredients,
                  ingredient?.specialties
                )
              ) {
                return (
                  <Ingredient
                    key={index}
                    open={open}
                    onOpen={onOpen}
                    ingredient={ingredient}
                  />
                );
              }
              return null;
            })}
        </div>
      )}
    </div>
  );
};
