import { colors } from '../../../../assets/constants';

export const MeasureIngredients = ({ ingredientsByQuestion }) => {
  return (
    <div>
      {ingredientsByQuestion.map((question) => {
        const [, title] = question?.questionId?.name?.split(' ');
        return (
          <div
            style={{
              marginBottom: '1rem',
            }}
            key={question?.questionId?._id}
          >
            <span
              style={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '1rem',
                color: colors.yellow,
                marginRight: '.5rem',
              }}
            >
              {title}
            </span>{' '}
            - {question?.ingredients?.map((ing) => ing?.name).join(' - ')}
          </div>
        );
      })}
    </div>
  );
};
