import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { compose } from 'recompose';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { withNavigation } from '../../../hoc/withNavigation';
import { filterValuesModalAction } from '../../../redux/modals';
import { Loader } from '../../../components/atoms/Loader';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { sortedList } from '../../../assets/utils/sortedList';
import { getOneFilterById } from '../../../api/filters.api';
import { useQuery } from 'react-query';
import { filteredSelectedItemAction } from '../../../redux/filtersSelected';
import { DeleteFilterValue } from './DeleteFilterValue';
import { TableSimpleRow } from '../../../components/atoms/TableSimpleRow';
import { copyObj } from '../../../assets/utils/copyObj';
import { useAppDispatch } from '../../../hooks/useReduxHook';

const FilterValueView = ({ user }) => {
  //
  const dispatch = useAppDispatch();
  const { filterId } = useParams();
  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const { data: filterData, isLoading }: any = useQuery(
    ['filter', filterId],
    () => (filterId ? getOneFilterById(filterId) : null)
  );

  const filter = filterData?.data?.filter;

  const filterValues = filterData?.data?.filter?.filterValues || [];

  useEffect(() => {
    if (filterData) {
      dispatch(
        filteredSelectedItemAction({
          filter: 'filter',
          item: filterData?.data?.filter,
        })
      );
    }
  }, [filterData, dispatch]);

  if (isLoading) return <Loader />;

  return (
    <>
      <DeleteFilterValue />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            goBack
            title={`valeurs '${filter?.label}'`}
            onAdd={() => dispatch(filterValuesModalAction(true))}
          />
          <div className='table'>
            {copyObj(filterValues)
              ?.sort((a, b) => sortedList(a, b, 'name'))
              ?.map((filterValue, index) => {
                const reg = new RegExp(`${search}`, 'ig');
                if (search?.length >= 3 && !filterValue?.name?.match(reg)) {
                  return null;
                }
                return (
                  <TableSimpleRow
                    key={index}
                    item={filterValue}
                    filter='filterValue'
                    onOpen={onOpen}
                    showActionModal={showActionModal}
                    onOpenModal={() => dispatch(filterValuesModalAction(true))}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withNavigation, withCheckAuth)(FilterValueView);
