import { axiosInstance } from '../assets/utils/axiosInstance';

// /family/add-ingredient/
export const createIngredient = async (familyId, body) => {
  const res = await axiosInstance
    .post(`/family/add-ingredient/${familyId}`, body)
    .catch((err) => {
      console.log('createOneProduct', err);
    });
  return res;
};

export const updateIngedient = async (
  ingredientId,
  productId,
  familyId,
  data
) => {
  const res: any = await axiosInstance
    .put(
      `/family/update-product-ingredient/${ingredientId}/${productId}/${familyId}`,
      data
    )
    .catch((err) => {
      console.log('updateIngredient', err);
    });
  return res?.data;
};

export const deleteIngredient = async (ingredientId) => {
  const res: any = await axiosInstance
    .delete(`/family/delete-product-ingredient/${ingredientId}`)
    .catch((err) => {
      console.log('deleteIngredient', err);
    });
  return res?.data;
};
