import { createSlice } from '@reduxjs/toolkit';
import { initPicture } from '../../assets/constants';

const initialState: any = {
  filteredPictures: {
    customerId: null,
    customer: null,
    families: [],
    familiesItem: [],
    styles: [],
    filters: [],
    filtersValues: [],
    specialsFilters: [],
    moods: [],
    attributed: false,
  },
  filteredByCustomerUnivers: false,
  pictures: [],
  pictureSelected: null,
  pictureDisplay: { picture: '', folder: '' },
  pictureForm: initPicture,
  pictureLoading: false,
};

export const slice = createSlice({
  name: 'picture',
  initialState: initialState,

  reducers: {
    setAttributedAction: (state, action) => {
      state.filteredPictures = {
        ...state.filteredPictures,
        attributed: action.payload,
      };
    },

    setFilteredByCustomerUnivers: (state, action) => {
      state.filteredByCustomerUnivers = action.payload;
    },

    setPicturesAction: (state, action) => {
      state.pictures = action.payload;
    },
    pictureSelectedAction: (state, action) => {
      state.pictureSelected = action.payload;
    },
    getPictureDisplayAction: (state, action) => {
      state.pictureDisplay = action.payload;
    },
    pictureDisplayWaterMarkedAction: (state, action) => {
      state.pictureWaterMarked = action.payload;
    },
    setPictureFormAction: (state, action) => {
      state.pictureForm = action.payload;
    },
    pictureLoadingAction: (state, action) => {
      state.pictureLoading = action.payload;
    },
  },
});

export const {
  setPicturesAction,
  pictureSelectedAction,
  setAttributedAction,
  setFilteredByCustomerUnivers,
  setPictureFormAction,
  pictureLoadingAction,
  getPictureDisplayAction,
  pictureDisplayWaterMarkedAction,
} = slice.actions;
export default slice.reducer;
