import { useQuery } from 'react-query';
import {
  fetchFamilies,
  getAllCompositionsByCustomerId,
  getAllCompositionsByFamilyId,
} from '../api/families.api';
import { useAppSelector } from './useReduxHook';
import { helperFilterIngForCompose } from '../assets/utils/helperFilterIngForCompose';

export const useGenerateStrategy = () => {
  const customer = useAppSelector((state) => state.customer.customer);

  const specialFilters = customer?.univers?.specialsFilters || [];
  const { data: familiesData } = useQuery(['families'], () => fetchFamilies());
  const { data: allCustomerCompsData } = useQuery(
    ['all-compositions-customer', customer?._id, customer?.univers?.families],
    () => getAllCompositionsByCustomerId(customer?._id),
    {
      enabled: !!customer?._id,
    }
  );

  const familyIds = [
    ...new Set(
      allCustomerCompsData?.data?.map((composition) => {
        return composition.familyId?._id || composition.familyId;
      })
    ),
  ];

  const families = (customer?.univers?.families || []).filter((f) =>
    familyIds.includes(f._id)
  );

  const types = ['principal', 'forCompose'];

  // Récupérer l'index du prochain élément à sélectionner
  const getNextIndex = (id, currentIndexes) => {
    const index = currentIndexes[id];
    currentIndexes[id] = index + 1;
    return index;
  };

  const handleGenerateRandomStrategy = async ({ index, currentIndexes }) => {
    const strategyModels = customer?.strategyModel || [];

    // Initialiser la stratégie aléatoire à vide
    const randomStrategy: {
      family?: any,
      product?: any,
      composition?: any,
      specialFilter?: any,
      subSpecialFilter?: any,
      strategyModel?: any,
      model?: any,
    } = {};

    let strategyModelIndex = 0;
    let model = {};

    if (strategyModels[index]?.length > 1) {
      if (currentIndexes.model[strategyModels[index][0]._id] !== undefined) {
        // on recupere l'index du model
        strategyModelIndex =
          currentIndexes.model[strategyModels[index][0]._id] + 1;

        currentIndexes.model[strategyModels[index][0]._id] = strategyModelIndex;
        // on verifie si l'index du model est inferieur au nombre de model du strategyModel
        if (strategyModelIndex < strategyModels[index].length) {
          // on recupere le model
          model = strategyModels[index][strategyModelIndex];
        } else {
          // sinon on reinitialise l'index du model à 0
          currentIndexes.model[strategyModels[index][0]._id] = 0;
          // on recupere le model
          model = strategyModels[index][0];
        }
      } else {
        // sinon on initialise l'index du model à 0
        currentIndexes.model[strategyModels[index][0]._id] = 0;
        // on recupere le model
        model = strategyModels[index][0];
      }
    } else {
      model = strategyModels[index][0];
    }

    // Extraire les identifiants de famille, de produit, d'ingrédient, de filtre spécial et de valeur de filtre spécial
    const {
      familyId,
      productId,
      compositionId,
      specialFilterId,
      subSpecialFilterId,
      type,
    } = model || {};

    let typeSelected = type;

    if (model) {
      randomStrategy.strategyModel = model;
    }

    // Sélectionner une famille, un produit, un ingrédient, un filtre spécial et une valeur de filtre spécial en utilisant les clés extraites du modèle si elles sont disponibles, sinon de manière aléatoire en vérifiant qu'ils ne sont pas déjà présents dans les identifiants respectifs des stratégies du mois

    // verifier si la famille existe

    const findfamily = families.find((family) => family._id === familyId);

    if (findfamily) {
      randomStrategy.family = findfamily;
    } else {
      // recuperer l'index current de la famille
      const familyIndex = getNextIndex('family', currentIndexes);

      if (familyIndex < families.length) {
        randomStrategy.family = families[familyIndex];
      } else {
        currentIndexes.family = 1;
        randomStrategy.family = families[0];
      }
    }

    if (!typeSelected && randomStrategy.family?.forCompose) {
      if (currentIndexes.type[randomStrategy.family._id] !== undefined) {
        // on recupere l'index du produit
        const typeIndex = currentIndexes.type[randomStrategy.family._id] + 1;

        currentIndexes.type[randomStrategy.family._id] = typeIndex;
        // on verifie si l'index du produit est inferieur au nombre de produit de la famille
        if (typeIndex < types.length) {
          // on recupere le produit
          typeSelected = types[typeIndex];
        } else {
          // sinon on reinitialise l'index du produit à 0
          currentIndexes.type[randomStrategy.family._id] = 0;
          // on recupere le produit
          typeSelected = types[0];
        }
      } else {
        // sinon on initialise l'index du produit à 0
        currentIndexes.type[randomStrategy.family._id] = 0;
        // on recupere le produit
        typeSelected = types[0];
      }
    } else if (!typeSelected) {
      typeSelected = 'principal';
    }

    if (typeSelected === 'forCompose') {
      const allProdsCompsData = await getAllCompositionsByFamilyId(
        randomStrategy.family?._id
      );

      const _familySelected = familiesData
        ?.filter((f) => familyIds.includes(f._id))
        ?.find((_f) => _f?._id === randomStrategy.family?._id);

      const compositionsPrincipal = randomStrategy.family?.products?.reduce(
        (acc, product) => {
          const compositions = product?.compositions?.filter(
            (composition) => composition.type === 'principal'
          );

          return [...acc, ...compositions];
        },
        []
      );

      const compositionsForCompose = (allProdsCompsData?.data || [])?.filter(
        (comp) => {
          // verifier si les ingredients selectionnés sont inclus dans les ingredients de la composition
          return helperFilterIngForCompose(comp, randomStrategy.family);
        }
      );

      const productsIdsForCompose = compositionsForCompose?.map(
        (composition) => {
          return composition.productId?._id || composition.productId;
        }
      );

      const products =
        _familySelected.products?.filter((product) =>
          productsIdsForCompose?.includes(product._id)
        ) || [];

      // verifier si le produit existe

      const compositionsForComposeWithoutPrincipal =
        compositionsForCompose?.filter(
          (comp) => !compositionsPrincipal?.find((_c) => _c?._id === comp?._id)
        );

      randomStrategy.family = {
        ...randomStrategy.family,
        products: products?.map((product) => ({
          ...product,
          compositions: compositionsForComposeWithoutPrincipal?.filter(
            (composition) =>
              (composition.productId?._id || composition.productId) ===
              product._id
          ),
        })),
      };
    }

    // verifier si le produit existe

    const findProduct = randomStrategy?.family?.products?.find(
      (product) => product._id === productId
    );

    const findComposition = findProduct?.compositions
      ?.filter((composition) => composition.type === 'principal')
      ?.find((_composition) => _composition._id === compositionId);

    const findSpecialFilter = specialFilters.find(
      (specialFilter) => specialFilter._id === specialFilterId
    );

    const findSubSpecialFilter = findSpecialFilter?.subSpecialFilters?.find(
      (subSpecialFilter) => subSpecialFilter._id === subSpecialFilterId
    );

    if (findProduct) {
      randomStrategy.product = findProduct;
    } else {
      // verifie si la famille a des produits
      if (randomStrategy.family?.products?.length > 0) {
        // on enregistre l'index du produit sur l'id de la famille
        // on verifie d'abord si l'id de la famille existe dans l'objet currentIndexes.product
        if (
          currentIndexes.product[randomStrategy.family._id] &&
          currentIndexes.product[randomStrategy.family._id][typeSelected] !==
            undefined
        ) {
          // on recupere l'index du produit
          const productIndex =
            currentIndexes.product[randomStrategy.family._id][typeSelected] + 1;

          currentIndexes.product[randomStrategy.family._id][typeSelected] =
            productIndex;
          // on verifie si l'index du produit est inferieur au nombre de produit de la famille
          if (productIndex < randomStrategy.family.products.length) {
            // on recupere le produit
            randomStrategy.product =
              randomStrategy.family.products[productIndex];
          } else {
            // sinon on reinitialise l'index du produit à 0
            currentIndexes.product[randomStrategy.family._id] = {
              ...currentIndexes.product[randomStrategy.family._id],
              [typeSelected]: 0,
            };
            // on recupere le produit
            randomStrategy.product = randomStrategy.family.products[0];
          }
        } else {
          // sinon on initialise l'index du produit à 0

          if (!currentIndexes.product[randomStrategy.family._id]) {
            currentIndexes.product[randomStrategy.family._id] = {
              ...currentIndexes.product[randomStrategy.family._id],
              [typeSelected]: 0,
            };
          } else {
            currentIndexes.product[randomStrategy.family._id][typeSelected] = 0;
          }
          // on recupere le produit
          randomStrategy.product = randomStrategy.family.products[0];
        }
      }
    }

    if (findComposition) {
      randomStrategy.composition = findComposition;
    } else {
      // verifie si le produit a des compositions
      let prodCompositions = randomStrategy.product?.compositions.filter(
        (composition) =>
          typeSelected === 'forCompose'
            ? true
            : composition.type === 'principal'
      );

      if (prodCompositions?.length > 0) {
        // on enregistre l'index de la composition sur l'id du produit

        // on verifie d'abord si l'id du produit existe dans l'objet currentIndexes.composition

        if (
          currentIndexes.composition[randomStrategy.product._id] &&
          currentIndexes.composition[randomStrategy.product._id][
            typeSelected
          ] !== undefined
        ) {
          // on recupere l'index de la composition
          const compositionIndex =
            currentIndexes.composition[randomStrategy.product._id][
              typeSelected
            ] + 1;

          currentIndexes.composition[randomStrategy.product._id][typeSelected] =
            compositionIndex;
          // on verifie si l'index de la composition est inferieur au nombre de composition du produit
          if (compositionIndex < prodCompositions.length) {
            // on recupere la composition
            randomStrategy.composition = prodCompositions[compositionIndex];
          } else {
            // sinon on reinitialise l'index de la composition à 0
            currentIndexes.composition[randomStrategy.product._id] = {
              ...currentIndexes.composition[randomStrategy.product._id],
              [typeSelected]: 0,
            };
            // on recupere la composition
            randomStrategy.composition = prodCompositions[0];
          }
        } else {
          // sinon on initialise l'index de la composition à 0
          if (!currentIndexes.composition[randomStrategy.product._id]) {
            currentIndexes.composition[randomStrategy.product._id] = {
              ...currentIndexes.composition[randomStrategy.product._id],
              [typeSelected]: 0,
            };
          } else {
            currentIndexes.composition[randomStrategy.product._id][
              typeSelected
            ] = 0;
          }
          // on recupere la composition
          randomStrategy.composition = prodCompositions[0];
        }
      }
    }

    if (findSpecialFilter) {
      randomStrategy.specialFilter = findSpecialFilter;
    } else {
      if (specialFilters?.length > 0) {
        if (currentIndexes.specialFilter !== undefined) {
          const specialFilterIndex = currentIndexes.specialFilter + 1;

          currentIndexes.specialFilter = specialFilterIndex;

          if (specialFilterIndex < specialFilters.length) {
            randomStrategy.specialFilter = specialFilters[specialFilterIndex];
          } else {
            currentIndexes.specialFilter = 0;
            randomStrategy.specialFilter = specialFilters[0];
          }
        } else {
          currentIndexes.specialFilter = 0;
          randomStrategy.specialFilter = specialFilters[0];
        }
      }
    }

    if (findSubSpecialFilter) {
      randomStrategy.subSpecialFilter = findSubSpecialFilter;
    } else {
      // verifie si le specialFilter a des subSpecialFilters
      if (randomStrategy.specialFilter?.subSpecialFilters?.length > 0) {
        // on enregistre l'index du subSpecialFilter sur l'id du specialFilter
        // on verifie d'abord si l'id du specialFilter existe dans l'objet currentIndexes.subSpecialFilter
        if (
          currentIndexes.subSpecialFilter[randomStrategy.specialFilter._id] !==
          undefined
        ) {
          // on recupere l'index du subSpecialFilter
          const subSpecialFilterIndex =
            currentIndexes.subSpecialFilter[randomStrategy.specialFilter._id] +
            1;

          currentIndexes.subSpecialFilter[randomStrategy.specialFilter._id] =
            subSpecialFilterIndex;
          // on verifie si l'index du subSpecialFilter est inferieur au nombre de subSpecialFilter du specialFilter
          if (
            subSpecialFilterIndex <
            randomStrategy.specialFilter.subSpecialFilters.length
          ) {
            // on recupere le subSpecialFilter
            randomStrategy.subSpecialFilter =
              randomStrategy.specialFilter.subSpecialFilters[
                subSpecialFilterIndex
              ];
          } else {
            // sinon on reinitialise l'index du subSpecialFilter à 0
            currentIndexes.subSpecialFilter[
              randomStrategy.specialFilter._id
            ] = 0;
            // on recupere le subSpecialFilter
            randomStrategy.subSpecialFilter =
              randomStrategy.specialFilter.subSpecialFilters[0];
          }
        } else {
          // sinon on initialise l'index du subSpecialFilter à 0
          currentIndexes.subSpecialFilter[randomStrategy.specialFilter._id] = 0;
          // on recupere le subSpecialFilter
          randomStrategy.subSpecialFilter =
            randomStrategy.specialFilter.subSpecialFilters[0];
        }
      }
    }

    return {
      familyId: randomStrategy.family?._id,
      productId: randomStrategy.product?._id,
      compositionId: randomStrategy.composition?._id,
      specialFilterId: randomStrategy.specialFilter?._id,
      subSpecialFilterId: randomStrategy.subSpecialFilter?._id,
      strategyModelId: randomStrategy.strategyModel?._id,
      type: typeSelected,
    };
  };

  return {
    handleGenerateRandomStrategy,
  };
};
