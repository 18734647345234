import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createSamePicture, updateOnePicture } from '../../../api/picture.api';
import { uploadFile } from '../../../api/uploadFile.api';
import { Toast, toast_loader } from '../../../assets/utils/toast';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { initImagesAction } from '../../../redux/images';
import { identicalImageModalAction } from '../../../redux/modals';
import { Modal } from '../../atoms/Modal';
import { MoodsList } from '../../atoms/MoodsList';
import {
  resetIdenticalImageAction,
  setIdenticalImageAction,
} from '../../../redux/images/imageIdentical';
import { DownloadIdenticalImg } from '../../organisms/Images/DownloadIdenticalImg';
import { formatPictureData } from '../../../assets/constants';

export const IdenticalImageModal = () => {
  const imageSelected = useAppSelector((s) => s.images);
  const imageIdentical = useAppSelector((s) => s.imageIdentical);

  const moodsFilter = useAppSelector((s) => s.filters.moods);

  const [loading, setLoading] = useState(false);

  const moodAlreadySelected = imageSelected?.samePictures
    ?.map((picture) => picture.moods)
    .flat()
    .concat(moodsFilter || [])
    .concat(imageSelected.moods);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    if (imageSelected && imageSelected?._id) {
      setLoading(true);

      const data = {
        ...formatPictureData(imageSelected),
        image: imageIdentical?.image,
        moods: imageIdentical?.moods,
      };
      if (!!!imageIdentical?.image) {
        setLoading(false);
        return Toast('warning', 'Veuillez ajouter au moins une image !');
      }

      if (imageIdentical.moods.length === 0) {
        setLoading(false);
        return Toast('warning', 'Veuillez sélectionner un mood !');
      }
      const loader = toast.loading('Veuillez patienter...');
      if (!!imageIdentical?.image?.name) {
        const fileName = await uploadFile(imageIdentical?.image, 'picture');
        if (fileName) {
          data.image = fileName;
        }
      }

      const res = await createSamePicture(imageSelected._id, data);

      if (res && res.data) {
        const samePictures = imageSelected.samePictures || [];
        dispatch(
          initImagesAction({
            ...imageSelected,
            samePictures: [...samePictures, res.data],
          })
        );
        onClose();
      }

      toast_loader(loader, 'Image identique ajoutée avec succès !', 'success');
      setLoading(false);
    }
  };
  const handleUpdate = async () => {
    if (imageSelected && imageSelected?._id) {
      setLoading(true);
      const data = {
        image: imageIdentical?.image,
        moods: imageIdentical?.moods,
      };
      if (!!!imageIdentical?.image) {
        setLoading(false);
        return Toast('warning', 'Veuillez ajouter au moins une image !');
      }

      if (imageIdentical.moods.length === 0) {
        setLoading(false);
        return Toast('warning', 'Veuillez sélectionner un mood !');
      }
      const loader = toast.loading('Veuillez patienter...');
      if (!!imageIdentical?.image?.name) {
        const fileName = await uploadFile(imageIdentical?.image, 'picture');
        if (fileName) {
          data.image = fileName;
        }
      }

      const res = await updateOnePicture(imageIdentical._id, data);

      if (res && res.data) {
        dispatch(
          initImagesAction({
            ...imageSelected,
            samePictures: imageSelected.samePictures.map((_samePicture) => {
              if (_samePicture._id === imageIdentical._id) {
                return { ..._samePicture, ...data };
              }
              return _samePicture;
            }),
          })
        );
        onClose();
      }

      toast_loader(loader, 'Image identique modifiée avec succès !', 'success');
      setLoading(false);
    }
  };

  const handleSelectedMood = (mood) => {
    if (imageIdentical?.moods?.includes(mood)) {
      dispatch(
        setIdenticalImageAction({
          key: 'moods',
          value: [],
        })
      );
    } else {
      dispatch(
        setIdenticalImageAction({
          key: 'moods',
          value: [mood],
        })
      );
    }
  };

  const onClose = () => {
    dispatch(identicalImageModalAction(false));
    dispatch(resetIdenticalImageAction());
  };

  return (
    <Modal
      onClose={onClose}
      title="Selectionner l'image identique"
      onValidate={!!imageIdentical._id ? handleUpdate : handleSubmit}
      width='60%'
      left='20%'
      bgWidth='100%'
      loading={loading}
      btnWidth={180}
      zIndex={1000}
    >
      <DownloadIdenticalImg />
      <MoodsList
        justifyContent='center'
        localMoodsSelected={imageIdentical?.moods}
        onSelectMood={handleSelectedMood}
        moodAlreadySelected={moodAlreadySelected}
      />
    </Modal>
  );
};
