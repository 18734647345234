import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';
import { ArrowRightIcon } from '../../../assets/svgs/ArrowRightIcon';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { withNavigation } from '../../../hoc/withNavigation';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import { customerDetailsAction } from '../../../redux/customer';
import { setFilteredByCustomerUnivers } from '../../../redux/picture';

const ImagesView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleGoToAddImage = () => {
    navigate('/add-image');
    window.location.reload();
  };

  const handleGoToImagesList = () => {
    dispatch(setFilteredByCustomerUnivers(false));
    dispatch(customerDetailsAction(null));
    navigate('/images-list');
  };

  return (
    <div className='images--navigate'>
      <button onClick={handleGoToAddImage} className='images--navigate-btn'>
        <p>AJOUTER UNE IMAGE</p> <ArrowRightIcon />
      </button>
      <button onClick={handleGoToImagesList} className='images--navigate-btn'>
        <p>VOIR LES IMAGES</p> <ArrowRightIcon />
      </button>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(ImagesView);
