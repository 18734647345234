export const ChevronLeftIcon = () => {
  return (
    <svg
      width='11'
      height='31'
      viewBox='0 0 11 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.4209 6.00023L4.10511 16.0002L10.4209 26.0002L10.4209 30.5458L0.315638 16.0002L10.4209 0.545773L10.4209 6.00023Z'
        fill='#4C747D'
        fillOpacity='0.4'
      />
    </svg>
  );
};
