//import { Collapse } from "../../../components/atoms/Collapse";
import { TableHeader } from '../../../components/atoms/TableHeader';
import { withNavigation } from '../../../hoc/withNavigation';
import { citiesModalAction } from '../../../redux/modals';
import { Button } from '../../../components/atoms/Buttons';
import { useEffect, useState } from 'react';
import { Loader } from '../../../components/atoms/Loader';
import { HeaderCities } from '../../../components/organisms/Header';
import { Select } from '../../../components/atoms/Fields';
import { citiesLetters } from '../../../assets/constants';
import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { sortedList } from '../../../assets/utils/sortedList';
import { fetchCitiesByFilters } from '../../../api/cities.api';
import { copyObj } from '../../../assets/utils/copyObj';
import { useInfiniteQuery } from 'react-query';
import { useAppDispatch } from '../../../hooks/useReduxHook';

const CitiesView = ({ user }) => {
  //
  const dispatch = useAppDispatch();
  const [cities, setCities] = useState([]);
  const [filters, setFilters] = useState({
    letter: 'a',
    name: '',
    country: 'france',
    page: 1,
    limit: 20,
    totalPages: 0,
    currentPage: 1,
    totalCities: 0,
  });

  const [lastPage, setLastPage] = useState(null);

  const { data, isLoading } = useInfiniteQuery(
    ['cities', filters],
    () => fetchCitiesByFilters(filters),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.currentPage > lastPage.totalPages) return;
        return lastPage?.currentPage + 1;
      },
    }
  );

  useEffect(() => {
    if (data) {
      setCities(data?.pages.flatMap((page) => page.cities));
      setLastPage(data?.pages[data?.pages.length - 1]);
    }
  }, [data]);

  if (isLoading) return <Loader />;
  return (
    <>
      <div className='pages-layout'>
        <HeaderCities user={user} setFilters={setFilters} filters={filters} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`Villes (${cities.length} sur ${filters.totalCities})`}
            onAdd={() => dispatch(citiesModalAction(true))}
          />
          <div className='cities--letters'>
            <span>Filtrer par lettre :</span>
            {citiesLetters.map((letter) => (
              <button
                key={letter}
                className={`cities--letter cities--letter-${
                  filters.letter === letter ? 'active' : ''
                }`}
                onClick={() =>
                  setFilters((s) => ({
                    ...s,
                    name: '',
                    letter: letter,
                    page: 1,
                    limit: 20,
                  }))
                }
              >
                {letter}
              </button>
            ))}
          </div>
          <div className='cities'>
            {copyObj(cities)
              .sort((a, b) => sortedList(a, b, 'name'))
              .map((el, index) => {
                return <Trow city={el} key={index} />;
              })}
          </div>
          <div className='cities--footer'>
            <div className='cities--footer-page'>
              <p>
                Page{' '}
                {`${lastPage?.currentPage ?? ''} sur ${
                  lastPage?.totalPages ?? ''
                }`}
              </p>
              <Select
                width={120}
                options={Array(lastPage?.totalPages ?? 0)
                  .fill('a')
                  .map((_, index) => ({ name: index + 1, value: index + 1 }))}
                onChange={(e) =>
                  setFilters((s) => ({ ...s, page: e.target.value }))
                }
                value={filters.page}
              />
            </div>
            <Button
              variant='black'
              label='Voir plus'
              onClick={() =>
                setFilters((s) => ({ ...s, limit: filters.limit + 20 }))
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

const Trow = ({ city }) => {
  const navigate = useNavigate();
  return (
    <div
      className='table-trow'
      onClick={() => navigate(`/cities/${city._id}`)}
      style={{ cursor: 'pointer' }}
    >
      <span className='table-trow-text'>{city?.name}</span>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(CitiesView);
