import React, { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { colors, initComposition } from '../../../assets/constants';
import { PlusIcon } from '../../../assets/svgs/PlusIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { sortedList } from '../../../assets/utils/sortedList';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { compositionSelectedAction } from '../../../redux/compositions';
import { Button } from '../../atoms/Buttons';
import { useDropFile } from '../../../hooks/useDropFile';

export const CreateCompositionCard: React.FC<{
  isAccordion?: boolean;
  onSaveComposition?: (
    e: React.MouseEvent<HTMLParagraphElement>
  ) => Promise<void>;
}> = ({ isAccordion = false, onSaveComposition }) => {
  //
  const dispatch = useAppDispatch();
  const compositionSelected = useAppSelector(
    (s) => s.compositions.compositionSelected
  );
  const [open, setOpen] = useState(!isAccordion);

  const onRemoveComposition = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(compositionSelectedAction(initComposition));
  };

  return (
    <div className='createCompositionCard'>
      {isAccordion && (
        <span
          className='createCompositionCard--badge'
          style={{
            backgroundColor: open ? colors.yellow : colors.grey,
            cursor: 'pointer',
          }}
          onClick={
            isAccordion
              ? (e) =>
                  setOpen((prevState) => {
                    e.stopPropagation();
                    return !prevState;
                  })
              : () => {}
          }
        />
      )}
      {(!!!compositionSelected?.isNew || isAccordion) && (
        <button
          onClick={onRemoveComposition}
          className='createCompositionCard--removeBtn'
        >
          X
        </button>
      )}
      {isAccordion ? (
        <Button
          label='Créer une composition'
          margin='0 auto'
          variant='yellow'
          onClick={onSaveComposition}
        />
      ) : (
        <p className='createCompositionCard--title'>
          {compositionSelected.isNew
            ? 'Créer une composition'
            : 'Modifier la composition'}
        </p>
      )}

      {open && (
        <div className='createCompositionCard--card'>
          <UploadImage
            image={compositionSelected?.image}
            onChange={(image) =>
              dispatch(
                compositionSelectedAction({ ...compositionSelected, image })
              )
            }
          />
          <div
            style={{ width: 'calc(100% - 120px)' }}
            className='flex-col-start-start'
          >
            <input
              type='text'
              placeholder='Nom Court'
              className='createCompositionCard--input'
              value={compositionSelected?.short_name}
              onChange={(e) =>
                dispatch(
                  compositionSelectedAction({
                    ...compositionSelected,
                    short_name: e.target.value,
                  })
                )
              }
            />
            <div style={{ width: '100%' }} className='flex-wrap'>
              {copyObj(compositionSelected.ingredients)
                ?.sort((a, b) => sortedList(a, b, 'name'))
                ?.map((ingredient, index) => {
                  return (
                    <p
                      className='createCompositionCard--itemsText'
                      style={{ padding: '0' }}
                      key={index}
                    >
                      {` ${ingredient?.specialityId?.label || ingredient.name}`}
                      {ingredient.quantity > 1
                        ? ` x${ingredient.quantity}`
                        : ''}
                      {index + 1 < compositionSelected.ingredients?.length
                        ? ` - `
                        : ''}
                    </p>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const UploadImage: React.FC<{ image: any; onChange: (e) => void }> = ({
  image,
  onChange,
}) => {
  const [loaded, setLoaded] = useState(false);
  const { handleDrop, handleDragOver } = useDropFile(onChange, {
    type: 'file',
  });

  const onLoadedImage = () => {
    setLoaded(true);
  };

  return (
    <>
      <label
        className='createCompositionCard--upload'
        htmlFor='compositionCardImg'
        style={{ position: 'relative' }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          type='file'
          name='compositionCardImg'
          id='compositionCardImg'
          hidden
          accept='image/png, image/jpeg, image/gif'
          onChange={(e) => onChange(e.target.files[0])}
          onClick={(e) => e.stopPropagation()}
        />
        {image ? (
          <>
            <img
              src={
                image?.name
                  ? URL.createObjectURL(image)
                  : helperImageRoute(image, 'composition')
              }
              alt='pic upload'
              width='90%'
              onLoad={onLoadedImage}
            />
            {!loaded && (
              <div className='blurhash'>
                <Blurhash
                  hash='LKN]Rv%2Tw=w]~RBVZRi};RPxuwH'
                  width={110}
                  height={110}
                  resolutionX={32}
                  resolutionY={32}
                  punch={1}
                />
              </div>
            )}
          </>
        ) : (
          <PlusIcon color={colors.dark} />
        )}
      </label>
    </>
  );
};
