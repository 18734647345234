import {useNavigate} from 'react-router-dom';
import {ArrowLeftIcon} from '../../../assets/svgs/ArrowLeftIcon';
import {Button} from '../Buttons';

export const TableHeader = ({onAdd, title, goBack = false}) => {
  //
  const navigate = useNavigate();

  return (
    <div className='tableHeader'>
      {goBack && (
        <button className='tableHeader--backBtn' onClick={() => navigate(-1)}>
          <ArrowLeftIcon />
        </button>
      )}

      <span className='tableHeader--title'>{title}</span>
      <Button
        label='Ajouter'
        width={133}
        margin='auto 0 auto auto'
        onClick={onAdd}
      />
    </div>
  );
};
