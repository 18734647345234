import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteOneComposition } from '../../../api/families.api';
import { colors } from '../../../assets/constants';
import { DotsCircleIcon } from '../../../assets/svgs/DotsCircleIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { sortedList } from '../../../assets/utils/sortedList';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import {
  compositionSelectedAction,
  IComposition,
} from '../../../redux/compositions';
import { ActionModal } from '../../atoms/ActionModal';
import { TrashIcon } from '../../../assets/svgs/TrashIcon';
import { getPictureDisplayAction } from '../../../redux/picture';
import { viewImageModalAction } from '../../../redux/modals';
import { ButtonZoom } from '../../atoms/Buttons';
import { CounterItem } from '../../atoms/Counter';

export const CompositionTrow: React.FC<{
  composition: IComposition;
  onOpen?: (index: string | null) => void;
  showActionModal?: string | null;
  refetch?: () => void;
  withActionModal?: boolean;
  withDelete?: boolean;
  selected?: IComposition;
  onSelect?: (compositionId: string) => void;
  onDelete?: () => void;
}> = ({
  composition,
  onOpen,
  showActionModal,
  refetch,
  withActionModal,
  withDelete,
  onSelect,
  onDelete,
  selected,
}) => {
  //
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { productId, familyId } = useParams();
  const [hoverImg, setHoverImg] = useState<boolean>(false);

  const onDisplayImg = (e) => {
    e.stopPropagation();
    dispatch(
      getPictureDisplayAction({
        picture: composition?.image,
        folder: 'composition',
      })
    );
    dispatch(viewImageModalAction(true));
  };

  const onSelectComposotion = () => {
    dispatch(compositionSelectedAction(composition));
    navigate(`/family-product-compositions-gestion/${familyId}/${productId}`);
  };

  const onDeleteComposition = async () => {
    const loader = toast.loading('Suppression en cours...');
    const { error, data } = await deleteOneComposition(composition._id);
    if (!error) {
      if (data.success) {
        toast_loader(loader, 'Suppression effectuée avec succès', 'success');
        refetch();
      } else {
        toast_loader(loader, 'Une erreur est survenue', 'error');
        refetch();
      }
    } else {
      toast_loader(loader, 'Une erreur est survenue', 'error');
      refetch();
    }
    toast.dismiss(loader);
  };

  return (
    <div
      className='productCompositions--trow'
      style={{ cursor: withActionModal || withDelete ? 'auto' : 'pointer' }}
    >
      <div className='productCompositions--trowInfos'>
        <div
          style={{
            width: 70,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            marginRight: '1rem',
          }}
          onMouseEnter={() => setHoverImg(true)}
          onMouseLeave={() => setHoverImg(false)}
        >
          {composition?.image?.length > 0 && hoverImg && (
            <ButtonZoom onDisplayImg={onDisplayImg} />
          )}
          {composition?.image?.length > 0 && (
            <img
              src={helperImageRoute(composition?.image, 'composition')}
              alt='composition'
            />
          )}
        </div>
        <div style={{ width: 'calc(100% - 86px)', marginLeft: 'auto' }}>
          <p className='productCompositions--shortname'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {composition?.short_name}
              {/* <Counters isComposition compositionId={composition._id} /> */}
              <CounterItem number={composition?.pictureCounter} />
            </div>
          </p>

          <div className='flex-wrap w-100'>
            {copyObj(composition.ingredients)
              ?.sort((a, b) => sortedList(a, b, 'name'))
              ?.map((ingredient, index) => {
                return (
                  <span
                    key={index}
                    style={{ marginLeft: index > 0 ? '.3rem' : '' }}
                  >
                    {` ${ingredient?.specialityId?.label || ingredient.name}`}
                    {ingredient.quantity > 1 ? ` x${ingredient.quantity}` : ''}
                    {index + 1 < composition.ingredients?.length ? ` - ` : ''}
                  </span>
                );
              })}
          </div>
        </div>
      </div>
      {withActionModal && !withDelete && (
        <div style={{ position: 'relative' }}>
          {showActionModal === composition?._id && (
            <ActionModal
              onEdit={onSelectComposotion}
              onDelete={onDeleteComposition}
            />
          )}
          <DotsCircleIcon onClick={() => onOpen(composition?._id)} />
        </div>
      )}
      {!withActionModal && withDelete && (
        <div style={{ position: 'relative' }}>
          <TrashIcon
            margin='0 1rem 0 0'
            color={colors.red}
            onClick={onDelete}
          />
        </div>
      )}
      {!withActionModal && !withDelete && (
        <div className='productCompositions--trowBadgeContainer'>
          <div className='productCompositions--trowBadgeContainer--badge'>
            <p>Principal</p>
            <span
              className='productCompositions--trowBadge'
              style={{
                backgroundColor:
                  selected?.type === 'principal' ? colors.yellow : colors.white,
              }}
              onClick={
                withActionModal || withDelete
                  ? () => {}
                  : () => onSelect('principal')
              }
            />
          </div>

          <div className='productCompositions--trowBadgeContainer--badge'>
            <p>Secondaire</p>
            <span
              className='productCompositions--trowBadge'
              style={{
                backgroundColor:
                  selected?.type === 'secondary' ? colors.yellow : colors.white,
              }}
              onClick={
                withActionModal || withDelete
                  ? () => {}
                  : () => onSelect('secondary')
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};
