import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { imageUploadAction } from '../../../redux/images';
import { ImgDownload } from '../../atoms/Gallery';
import { useAppSelector } from '../../../hooks/useReduxHook';

const DownloadImgs = ({ isClone, edit, miniModal }) => {
  //

  const role = useSelector((s) => s.app.role);
  const imageRedux = useSelector((s) => s.images.image);
  const imageIdentical = useAppSelector((s) => s.imageIdentical);

  const image = imageRedux;

  const dispatch = useDispatch();

  const [watermark, setWatermark] = useState(false);

  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    dispatch(
      imageUploadAction({
        key: 'image',
        value: file,
      })
    );

    e.target.value = null;
  };

  const handleRemoveImage = (key) => {
    dispatch(
      imageUploadAction({
        key: 'image',
        value: null,
      })
    );
  };

  return (
    <>
      <ImgDownload
        image={image}
        onChange={handleChangeImage}
        onRemoveImg={handleRemoveImage}
        watermarked={watermark || ['COMMERCIAL', 'PUNCHLINER'].includes(role)}
        labelId={!!imageIdentical.image ? 'image--portrait' : ''}
        watermark={watermark}
        setWatermark={setWatermark}
        edit={edit}
        isClone={isClone}
        miniModal={miniModal}
      />
    </>
  );
};

export default DownloadImgs;
