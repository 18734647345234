import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

export const PackageModal = ({ onClose, setPackages }) => {
  const { formulaInfos } = useSelector((s) => s.packages);
  const [date, setDate] = useState(formulaInfos?.formula?.date ?? '');

  const onValidate = () => {
    const newMonthData = formulaInfos?.month[
      `formulas_${formulaInfos?.formulaLength}`
    ].map((f) => {
      if (f.formula_number === formulaInfos?.formula?.formula_number) {
        return {
          formula_number: formulaInfos?.formula?.formula_number,
          date: date,
        };
      }
      return f;
    });
    setPackages((s) => ({
      ...s,
      months: s.months.map((m) => {
        if (m.name === formulaInfos?.month?.name) {
          return {
            ...m,
            [`formulas_${formulaInfos?.formulaLength}`]: newMonthData,
          };
        }
        return m;
      }),
    }));
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      title='Sélectionner une date'
      onValidate={onValidate}
    >
      <Input
        label='Date'
        type='date'
        value={date}
        onChange={(e) => setDate(e.target.value)}
      />
    </Modal>
  );
};
