import React, { Fragment, useState } from 'react';
import { ArrowLeftIcon } from '../../../assets/svgs/ArrowLeftIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { Button } from '../../../components/atoms/Buttons';
import {
  IngredientCard,
  NoAnswerCard,
} from '../../../components/organisms/CompositionsGestion/IngredientCard';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { productIdAction } from '../../../redux/compositions';
import {
  answerSelectedAction,
  IAnswer,
  questionSelectedAction,
} from '../../../redux/familyQuiz';
import { filteredSelectedItemAction } from '../../../redux/filtersSelected';
import { specialitiesModalAction } from '../../../redux/modals';

export const AnswersList: React.FC = () => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');

  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);
  const compositionSelected = useAppSelector(
    (s) => s.compositions.compositionSelected
  );

  const onSelectAnswer = (answer: IAnswer) => {
    dispatch(answerSelectedAction(answer));
    dispatch(specialitiesModalAction(true));
  };

  const onCancelled = () => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'product',
        item: null,
      })
    );
    dispatch(productIdAction(null));
    dispatch(questionSelectedAction(null));
  };

  return (
    <div className='customerCompositionsView--ingredientsBlock'>
      <div className='w-100 flex-row-center-start'>
        <Button
          margin='0 1rem 0 0'
          variant='grey'
          Icon={ArrowLeftIcon}
          onClick={onCancelled}
        />
        <p className='customerCompositionsView--ingredientsBlock--title'>
          {questionSelected?.question}
        </p>
      </div>
      <div className='customerCompositionsView--ingredientsBlock--inputSearch'>
        <label>Rechercher votre ingredient</label>
        <input
          type='text'
          placeholder='Recherche'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className='customerCompositionsView--ingredientsBlock--ingredients'>
        {!!questionSelected &&
          copyObj(questionSelected?.answers)
            ?.sort((a, b) => sortedList(a, b, 'ingredient_id', 'label'))
            ?.map((answer, index: number) => {
              const filteredIngredients =
                compositionSelected?.ingredients?.filter(
                  (_ingredient) =>
                    _ingredient.ingredientId === answer.ingredient_id?._id &&
                    _ingredient?.questionId === questionSelected?._id
                );
              const reg = new RegExp(`${search}`, 'ig');
              if (
                search.length > 0 &&
                !answer?.ingredient_id?.label?.match(reg)
              ) {
                return null;
              }
              return (
                <Fragment key={index}>
                  {index === 0 && <NoAnswerCard answer={answer} />}
                  <IngredientCard
                    key={index}
                    ingredient={answer?.ingredient_id}
                    onSelect={() => onSelectAnswer(answer)}
                    specialitiesInComp={filteredIngredients?.length}
                  />
                </Fragment>
              );
            })}
      </div>
    </div>
  );
};
