import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchStyles } from '../../../api/styles.api';
import { colors } from '../../../assets/constants';
import { SettingIcon } from '../../../assets/svgs/SettingIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { helperGenerateRandomPicture } from '../../../assets/utils/helperGenerateRandomPicture';
import { Toast, toast_loader } from '../../../assets/utils/toast';
import { useFamilies } from '../../../hooks/useFamilies';
import { useFilters } from '../../../hooks/useFilters';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { useSpecialFilter } from '../../../hooks/useSpecialFilter';
import {
  addPictureIdToAllAttributionAction,
  formulaAction,
  strategyModelCompOptAction,
  updateCustomerDiariesAction,
} from '../../../redux/customer';
import {
  customerCompositionsModalAction,
  imagesListModalAction,
  replayImgAttrModalAction,
} from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';
import { helperFindSamePicturesIdByPicture } from '../../../assets/utils/helperFindSamePictures';
import { helperResetStrategy } from '../../../assets/utils/helperResetStrategy';

export const ReplayImgAttrModal = ({ bgWidth = '80%' }) => {
  const dispatch = useDispatch();
  const customer = useAppSelector((state) => state.customer.customer);

  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );

  const formula = useAppSelector((state) => state.customer.formula);

  const strategy = formula?.strategy;
  const { compositions } = useFamilies(
    strategy?.familyId,
    strategy?.productId,
    false,
    strategy?.type === 'forCompose'
  );

  const composition = strategy?.compositionId
    ? compositions?.find(
        (_c: IComposition) =>
          (_c?.productId?._id || _c.productId) === strategy?.productId &&
          _c._id === strategy?.compositionId
      )
    : null;

  const currentSubscription = customer?.subscriptions[currentSubscriptionIndex];

  const [loading, setLoading] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const { data: styles } = useQuery(['styles'], () => fetchStyles(''));
  const { filters, filterValues, handleSelectFilter } = useFilters(
    strategy?.filterId
  );

  const { families, products, handleSelectFamily, handleSelectProduct } =
    useFamilies(
      strategy?.familyId,
      strategy?.productId,
      false,
      strategy?.type === 'forCompose'
    );

  const { specialFilters, specialFilterValues, handleSelectSpecialFilter } =
    useSpecialFilter(strategy?.specialFilterId);

  const familySelected = customer?.univers?.families?.find(
    (family) => family._id === strategy?.familyId
  );

  const onManualAttribution = () => {
    dispatch(imagesListModalAction(true));
    dispatch(replayImgAttrModalAction(false));
  };

  const handleChange = (e, type) => {
    const newFomula = copyObj(formula);
    if (!!!newFomula.strategy) {
      newFomula.strategy = {
        familyId: null,
        productId: null,
        type: null,
        compositionId: null,
        strategyModelId: null,
      };
    }
    newFomula.strategy[type] = e.target.value;

    switch (type) {
      case 'familyId':
        handleSelectFamily(e.target.value);
        helperResetStrategy('familyId', newFomula.strategy);
      break;
        
      case 'productId':
        handleSelectProduct(e.target.value);
        helperResetStrategy('productId', newFomula.strategy);
      break;

      case 'specialFilterId':
        handleSelectSpecialFilter(e.target.value);
        helperResetStrategy('specialFilterId', newFomula.strategy);
      break;

      case 'filterId':
        handleSelectFilter(e.target.value);
        helperResetStrategy('filterId', newFomula.strategy);
      break;

      default:
        break;
    }

    dispatch(formulaAction(newFomula));
  };

  const handleSelectComposition = () => {
    if (!!!strategy?.productId)
      return Toast('warning', 'Veuillez selectionner un produit avant !');
    dispatch(
      strategyModelCompOptAction({
        from: 'imgAttrModal',
        label: 'compositionId',
      })
    );
    dispatch(customerCompositionsModalAction(true));
  };

  const onValidate = async () => {
    if (loading) return;

    setLoading(true);

    const loader = toast.loading('Veuillez patienter...');
    // copier les agendas du forfait courant
    const diaries = copyObj(currentSubscription?.diaries);
    let allAttributions = copyObj(customer?.allAttributions || []);
    // recuperer les agendas du mois courant
    const currentDiaries = diaries.filter(
      (diary) => diary.month === formula.month && diary.year === formula.year
    );

    // retrouver les images en fonction de la stratégie sur le serveur
    const picture = await helperGenerateRandomPicture({
      formula,
      diaries,
      subscriptionId: currentSubscription._id,
      customerUnivers: customer.univers,
      customerId: customer?._id,
      allAttributions,
    });

    if (picture) {
      // une fois l'image trouvée, on l'attribue en fonction du forfait de l'agenda;
      const currentFormulaInDiaries = currentDiaries.find(
        (diary) => diary.formula_number === formula.formula_number
      );

      const oldPicture = currentFormulaInDiaries?.picture;

      if (currentFormulaInDiaries) {
        currentFormulaInDiaries.picture = picture;
      } else {
        diaries.push({
          formula_number: formula.formula_number,
          month: formula.month,
          year: formula.year,
          picture,
        });
      }

      const { oldsPictureIds, pictureIds } =
        await helperFindSamePicturesIdByPicture({
          oldPicture,
          picture,
        });

      // mettre à jour le forfait courant
      dispatch(
        addPictureIdToAllAttributionAction({
          oldsPictureIds,
          pictureIds,
        })
      );
      dispatch(updateCustomerDiariesAction(diaries));

      toast_loader(loader, 'Attribution réussie', 'success');

      dispatch(replayImgAttrModalAction(false));
    } else {
      toast_loader(
        loader,
        'Aucune image ne correspond à la stratégie',
        'error'
      );
    }

    setLoading(false);
  };

  return (
    <Modal
      onClose={
        loading
          ? () => {}
          : () => {
              dispatch(replayImgAttrModalAction(false));
            }
      }
      title='Rejouer l’attribution d’image'
      onValidate={onValidate}
      btnValidateLabel={loading ? '...' : 'REJOUER L’ATTRIBUTION'}
      width={553}
      bodyPadding='1rem 3rem'
      bgWidth={bgWidth}
    >
      <p>Etes vous sur de vouloir rejouer l’attribution ?</p>
      <Button
        label='ATTRIBUER MANUELLEMENT'
        variant='yellow'
        width='100%'
        margin='0 0 1rem'
        onClick={loading ? () => {} : onManualAttribution}
      />
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST1
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='family'
          disabled={loading}
          options={
            families?.map((family) => ({ ...family, value: family._id })) || []
          }
          defaultValue={{
            name: 'Toutes les familles',
          }}
          value={strategy?.familyId}
          onChange={(e) => handleChange(e, 'familyId')}
        />
      </div>
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST2
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='produit'
          disabled={loading}
          options={[
            { name: 'Principal', value: 'principal' },
            familySelected?.forCompose && {
              name: 'fait à composer',
              value: 'forCompose',
            },
          ].filter((opt) => !!opt)}
          onChange={(e) => handleChange(e, 'type')}
          value={strategy?.type}
        />
      </div>
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST3
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='produit'
          disabled={loading}
          options={products}
          defaultValue={{
            name: 'Tout les produits',
          }}
          value={strategy?.productId}
          onChange={(e) => handleChange(e, 'productId')}
        />
      </div>
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST4
        </span>

        <button
          onClick={handleSelectComposition}
          className='customer--textData customer--textData-btn'
          style={{
            borderColor: !!composition ? colors.yellow : colors.grey,
            width: 'calc(100% - 60px)',
            margin: '0',
          }}
        >
          {!!composition ? composition?.short_name : 'Toutes les compositions'}
        </button>
      </div>
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST5
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='specialFilter'
          disabled={loading}
          options={specialFilters}
          defaultValue={{
            name: 'Tout les filtres',
          }}
          value={strategy?.specialFilterId}
          onChange={(e) => handleChange(e, 'specialFilterId')}
        />
      </div>
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST6
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='subSpecialFilter'
          disabled={loading}
          options={specialFilterValues}
          defaultValue={{
            name: 'Tout les sous filtres spéciaux',
          }}
          value={strategy?.subSpecialFilterId}
          onChange={(e) => handleChange(e, 'subSpecialFilterId')}
        />
      </div>
      <button
        style={{
          width: 45,
          height: 45,
          borderRadius: 50,
          backgroundColor: 'black',
          border: 'none',
        }}
        onClick={() => setDropDownOpen(!dropDownOpen)}
      >
        <SettingIcon />
      </button>
      {dropDownOpen && (
        <>
          <div className='flex-row-center-between w-100'>
            <span
              style={{
                fontFamily: 'Inter Bold',
                fontSize: 14,
                color: colors.dark,
              }}
            >
              ST7
            </span>
            <Select
              label=''
              width='calc(100% - 60px)'
              name='filters'
              disabled={loading}
              defaultValue={{
                name: 'Tout les filtres',
              }}
              options={filters}
              value={strategy?.filterId}
              onChange={(e) => handleChange(e, 'filterId')}
            />
          </div>

          <div className='flex-row-center-between w-100'>
            <span
              style={{
                fontFamily: 'Inter Bold',
                fontSize: 14,
                color: colors.dark,
              }}
            >
              ST8
            </span>
            <Select
              label=''
              width='calc(100% - 60px)'
              name='filterValues'
              disabled={loading}
              defaultValue={{
                name: 'Tout les sous filtres',
              }}
              options={filterValues}
              value={strategy?.filterValueId}
              onChange={(e) => handleChange(e, 'filterValueId')}
            />
          </div>

          <div className='flex-row-center-between w-100'>
            <span
              style={{
                fontFamily: 'Inter Bold',
                fontSize: 14,
                color: colors.dark,
              }}
            >
              ST9
            </span>
            <Select
              label=''
              width='calc(100% - 60px)'
              name='styles'
              disabled={loading}
              defaultValue={{
                name: 'Tout les styles',
              }}
              options={
                styles?.map((style) => ({ ...style, value: style._id })) || []
              }
              value={strategy?.stylesId}
              onChange={(e) => handleChange(e, 'stylesId')}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
