import React, { useState } from 'react';
import { colors } from '../../../assets/constants';
import { ArrowLeftIcon } from '../../../assets/svgs/ArrowLeftIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  attributionModeAction,
  familySelectedImgAttrAction,
  imgProductIdAction,
} from '../../../redux/imageAttributions';
import { Button } from '../../atoms/Buttons';
import { SearchIcon } from '../../../assets/svgs/SearchIcon';

export const ProductsList: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const imageAttributions = useAppSelector((state) => state.imageAttributions);
  const [search, setSearch] = useState('');
  const products = imageAttributions?.familySelectedImgAttr?.products || [];

  const onSelectProduct = (productId: string) => {
    dispatch(imgProductIdAction(productId));
    dispatch(attributionModeAction('product'));
  };

  const productsFiltered = products?.filter((prod: any) =>
    prod?.name?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <div className='w-100 flex-row-center-start'>
        <Button
          margin='0 1rem 0 0'
          variant='grey'
          Icon={ArrowLeftIcon}
          onClick={() => dispatch(familySelectedImgAttrAction(null))}
        />
        <p className='customerCompositionsView--productsAnswersBlock--title'>
          Pour quel produit ?
        </p>
      </div>

      <div className='customerCompositionsView--productsAnswersBlock--list'>
        <div
          id='input'
          className='header--input'
          style={{
            width: '95%',
            margin: '0 auto',
          }}
        >
          <SearchIcon />
          <input
            type='text'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {productsFiltered?.length > 0 &&
          productsFiltered?.map((_product: any, index: number) => {
            return (
              <div
                style={{
                  borderColor:
                    _product?._id === imageAttributions?.imgProductId
                      ? colors.yellow
                      : colors.grey,
                }}
                className='customerCompositionsView--productsAnswersBlock--card'
                key={index}
                onClick={() => onSelectProduct(_product?._id)}
              >
                {_product?.name}
              </div>
            );
          })}
      </div>
    </>
  );
};
