import { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useDispatch, useSelector } from 'react-redux';
import { SearchCircleIcon } from '../../../assets/svgs/SearchBlackIcon';
import { RefreshIcon } from '../../../assets/svgs/RefreshIcon';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { getPictureDisplayAction } from '../../../redux/picture';
import {
  customersListModalAction,
  editImgMiniModalAction,
  editImgModalAction,
  viewImageModalAction,
} from '../../../redux/modals';
import { pictureToAttributeAction } from '../../../redux/customer';
import { initImagesAction } from '../../../redux/images';
import { updateImageAction } from '../../../redux/imageAttributions';
import { useLocation } from 'react-router-dom';

const breakpoints = [
  { size: 724, columns: 4 },
  { size: 980, columns: 4 },
  { size: 1020, columns: 4 },
  { size: 1130, columns: 4 },
  { size: 1220, columns: 4 },
  { size: 1320, columns: 4 },
  { size: 1420, columns: 5 },
  { size: 1520, columns: 5 },
  { size: 1610, columns: 5 },
];

const findBreakpoint = (containerWidth) => {
  return breakpoints.reduce((closestBreakpoint, currentBreakpoint) => {
    const currentDiff = Math.abs(containerWidth - currentBreakpoint.size);
    const closestDiff = Math.abs(containerWidth - closestBreakpoint.size);

    if (currentDiff < closestDiff) {
      return currentBreakpoint;
    }

    return closestBreakpoint;
  });
};

export const ImageRender = ({
  pic,
  size,
  small,
  isThumb = true,
  onSelectPicture,
  isIdenticalImg,
  onSelectPictureIdentical,
  className,
}) => {
  const dispatch = useDispatch();
  const role = useSelector((s) => s.app.role);
  const [loaded, setLoaded] = useState(true);
  const [hoverImg, setHoverImg] = useState(false);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const cloneParam = urlParams.get('clone');
  const isClone = cloneParam === 'true';

  const imgContainerWidth = window.innerWidth - 300;
  let imgWidth =
    imgContainerWidth / findBreakpoint(imgContainerWidth).columns - 32;

  const onLoadedImg = () => {
    setLoaded(true);
  };

  const handleClick = (e) => {
    e.stopPropagation();

    if (e.target.id === pic?.image) {
      if (isIdenticalImg) {
        onSelectPictureIdentical();
      } else if (onSelectPicture) {
        onSelectPicture(pic);
      } else {
        if (isClone) {
          const newPic = { ...pic };
          newPic.image = null;
          dispatch(initImagesAction(newPic));
          dispatch(editImgModalAction(true));
          dispatch(updateImageAction(true));
        } else {
          dispatch(initImagesAction(pic));
          dispatch(editImgMiniModalAction(true));
          dispatch(updateImageAction(true));
        }
      }
    }
  };

  const onDisplayImg = (e) => {
    e.stopPropagation();
    dispatch(
      getPictureDisplayAction({
        picture: pic?.image,
        folder: 'picture',
      })
    );
    dispatch(viewImageModalAction(true));
  };

  const hanldeAttributeImage = () => {
    dispatch(pictureToAttributeAction(pic));
    dispatch(customersListModalAction(true));
  };

  return (
    <div
      className={className ? `${className}` : 'images--container-img'}
      onClick={handleClick}
      disabled={loaded}
      style={{
        width: size?.width || imgWidth,
        height: size?.height,
        minHeight: size?.height ? 'inherit' : null,
      }}
      onMouseEnter={() => setHoverImg(true)}
      onMouseLeave={() => setHoverImg(false)}
    >
      <img
        src={helperImageRoute(pic?.image, 'picture', true)}
        style={{
          width: size?.width || imgWidth,
          minHeight: size?.height ? 'inherit' : null,
        }}
        loading='lazy'
        alt={pic?.name}
        onLoad={onLoadedImg}
        id={pic?.image}
      />
      {hoverImg && (
        <>
          <button
            onClick={onDisplayImg}
            className={`images--container-img-zoom ${small ? 'small' : ''}`}
          >
            <SearchCircleIcon />
          </button>
          {['ADMIN', 'SUPER-ADMIN', 'PROGRAMMEUR'].includes(role) && (
            <button
              onClick={hanldeAttributeImage}
              className={`images--container-img-attr ${small ? 'small' : ''}`}
            >
              <RefreshIcon />
            </button>
          )}
        </>
      )}

      {!loaded && (
        <div className='blurhash' style={{ width: '100%', height: '200px' }}>
          <Blurhash
            hash='LEHV6nWB2yk8pyo0adR*.7kCMdnj'
            width='100%'
            height='100%'
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        </div>
      )}
    </div>
  );
};
