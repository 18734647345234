import { axiosInstance } from '../assets/utils/axiosInstance';
import { Toast } from '../assets/utils/toast';

export const uploadFile = async (file: any, folder: string, type?: string) => {
  const formData = new FormData();
  formData.append('file', file);
  if (type) {
    formData.append('type', type);
  }

  const res: any = await axiosInstance
    .post(`/upload/${folder}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .catch((err) => {
      console.log('uploadFile', err);
    });
  if (res.status === 200) {
    return res.data.filename;
  } else {
    return Toast(
      'error',
      `Nous n'avons pas pu enregistrer l'image ${file?.name} !`
    );
  }
};
