export const ReloadIcon = () => {
  return (
    <svg
      width='27'
      height='27'
      viewBox='0 0 27 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6779 18.6641C16.1333 18.6641 18.0862 16.8894 18.0862 14.6641C18.0862 12.4387 16.1333 10.6641 13.6779 10.6641C11.2224 10.6641 9.26953 12.4387 9.26953 14.6641C9.26953 16.8894 11.2224 18.6641 13.6779 18.6641Z'
        fill='#A88E1B'
      />
      <path
        d='M26.6303 12.248C26.284 10.7125 25.6074 9.25397 24.6392 7.95599C23.6878 6.67904 22.4758 5.57922 21.0684 4.716C19.6378 3.83777 18.0305 3.22386 16.3383 2.90933C15.4494 2.74577 14.5444 2.66535 13.6375 2.66933V0L7.79644 4L13.6375 7.99999V5.336C14.3487 5.33333 15.0599 5.39466 15.7461 5.52266C17.0613 5.76721 18.3106 6.24427 19.4227 6.92666C20.5194 7.59765 21.4634 8.45416 22.2029 9.44933C23.351 10.9896 23.9632 12.8071 23.9603 14.6667C23.9601 15.9145 23.6853 17.1497 23.1521 18.3C22.8928 18.854 22.5759 19.3844 22.2058 19.884C21.8342 20.3805 21.4121 20.8443 20.945 21.2693C19.5226 22.5576 17.7168 23.4412 15.7491 23.812C14.3806 24.0627 12.9707 24.0627 11.6023 23.812C10.2865 23.5672 9.0367 23.0897 7.92428 22.4066C6.82878 21.7363 5.88586 20.8807 5.14703 19.8867C4.00021 18.3447 3.38767 16.5269 3.38811 14.6667H0.449219C0.450782 17.0582 1.23814 19.3951 2.71069 21.3787C3.66267 22.6535 4.87351 23.7522 6.2785 24.616C8.46154 25.9566 11.0382 26.6711 13.6742 26.6666C14.5695 26.6666 15.4625 26.5848 16.3398 26.4226C18.0307 26.1058 19.6371 25.4921 21.0684 24.616C21.7713 24.1863 22.4263 23.6955 23.0243 23.1506C23.6231 22.6058 24.1643 22.0112 24.6407 21.3747C26.1169 19.3944 26.9037 17.0574 26.8992 14.6667C26.8991 13.8543 26.809 13.044 26.6303 12.248V12.248Z'
        fill='#A88E1B'
      />
    </svg>
  );
};
