import { fetchPicturesByStrategy } from '../../api/picture.api';
import { compareArrays } from './funcsUtils';
import { helperSelectRandomElement } from './randomHelper';

export const helperGenerateRandomPicture = async ({
  diaries,
  formula,
  subscriptionId,
  customerUnivers,
  customerId,
  allAttributions,
}) => {
  // const diaries = copyObj(currentSubscription.diaries);
  // retrouver les images en fonction de la stratégie sur le serveur

  const res = await fetchPicturesByStrategy({
    ...formula?.strategy,
    subscriptionId: subscriptionId,
    customerId,
  }, {
    tabs: 'not-attributed',
    attributed: allAttributions,
    ...customerUnivers
  });


   if (res.pictures.length > 0) {
   
    const picture = helperSelectRandomElement(
      res.pictures,
      diaries?.map((diary) => diary?.picture?._id || diary.picture)
    );

    return picture;
  } else {
    return null;
  }
};

export const checkPictureCompatibility = (picture, customerUnivers) => {
  const hasFamilies = compareArrays(
    picture.attrMainCompositions,
    customerUnivers.families
  );

  const pictureProducts = picture.attrMainCompositions.flatMap(
    (family) => family.productId
  );
  const customerProducts = customerUnivers.families.flatMap(
    (family) => family.products
  );

  const hasProducts = compareArrays(pictureProducts, customerProducts);

  const pictureIngredients = pictureProducts.flatMap(
    (family) => family?.ingredients
  );

  const customerIngredients = customerProducts.flatMap(
    (family) => family?.ingredients
  );

  const hasIngredients = compareArrays(pictureIngredients, customerIngredients);

  const hasSpecialFilters = compareArrays(
    picture.specialsFilters,
    customerUnivers.specialsFilters
  );

  const hasSpecialFilterValues = compareArrays(
    picture.specialsFilters?.flatMap((filter) => filter.subSpecialFilters),
    customerUnivers.specialsFilters?.flatMap(
      (filter) => filter.subSpecialFilters
    )
  );

  return (
    hasFamilies &&
    hasProducts &&
    hasIngredients &&
    hasSpecialFilters &&
    hasSpecialFilterValues
  );
};

export const helperSelectPictureWithCustomerUnivers = (
  picture,
  customerUnivers,
  assignedPictures
) => {
  const filteredSamePictures = picture?.samePictures?.filter(
    (samePicture) =>
      customerUnivers.moods.includes(samePicture.moods[0]) &&
      !assignedPictures.includes(samePicture._id)
  );

  if (filteredSamePictures?.length > 0) {
    return filteredSamePictures[0];
  } else if (
    picture?.parentId &&
    customerUnivers.moods.includes(picture.moods[0]) &&
    !assignedPictures.includes(picture._id)
  ) {
    return picture;
  }

  return null;
};
