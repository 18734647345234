import React, { useState } from 'react';
import { colors } from '../../../assets/constants';
import { MinusGrayIcon } from '../../../assets/svgs/MinusGrayIcon';
import { PlusGrayIcon } from '../../../assets/svgs/PlusGrayIcon';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { Toast } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { compositionSelectedAction } from '../../../redux/compositions';
import {
  ISpeciality,
  questionSelectedAction,
  quizAction,
} from '../../../redux/familyQuiz';
import { ButtonZoom } from '../../atoms/Buttons';
import { getPictureDisplayAction } from '../../../redux/picture';
import { viewImageModalAction } from '../../../redux/modals';

export const SpecialityCard: React.FC<{
  speciality: ISpeciality;
  forCompose?: boolean;
}> = ({ speciality }) => {
  //
  const dispatch = useAppDispatch();
  const compositionSelected = useAppSelector(
    (s) => s.compositions.compositionSelected
  );
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);
  const answerSelected = useAppSelector((s) => s.familyQuiz.answerSelected);

  const url = window.location.href.split('/');
  const isForCompose = url[url.length - 3] === 'customer-univers-compose';

  const quiz = useAppSelector((s) => s.familyQuiz?.quiz);

  const [hoverImg, setHoverImg] = useState<boolean>(false);

  const findIngredient = compositionSelected?.ingredients?.find(
    (_ingredient) => {
      return (
        (_ingredient.specialityId?._id || _ingredient.specialityId) ===
          speciality._id && _ingredient?.questionId === questionSelected?._id
      );
    }
  );

  const findQuestionsIngr = compositionSelected.ingredients?.filter(
    (el) => el.questionId === questionSelected?._id
  );
  const onIncremente = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (!!findIngredient) {
      const newIngredients = compositionSelected.ingredients.map(
        (_ingredient) => {
          if (
            (_ingredient.specialityId?._id || _ingredient.specialityId) ===
            speciality._id
          ) {
            return { ..._ingredient, quantity: _ingredient.quantity + 1 };
          }
          return _ingredient;
        }
      );
      dispatch(
        compositionSelectedAction({
          ...compositionSelected,
          ingredients: newIngredients,
        })
      );
    } else {
      onAddIngredient();
    }
  };

  const onDecremente = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    if (!!findIngredient && findIngredient.quantity === 1) {
      const newIngredients = compositionSelected.ingredients.filter(
        (_ingredient) =>
          (_ingredient.specialityId?._id || _ingredient.specialityId) !==
          speciality._id
      );
      dispatch(
        compositionSelectedAction({
          ...compositionSelected,
          ingredients: newIngredients,
        })
      );
      if (newIngredients.length === 0) {
        dispatch(
          questionSelectedAction({
            ...questionSelected,
            answered: false,
          })
        );
      }
    } else if (!!findIngredient && findIngredient.quantity > 1) {
      const newIngredients = compositionSelected.ingredients.map(
        (_ingredient) => {
          if (
            (_ingredient.specialityId?._id || _ingredient.specialityId) ===
            speciality._id
          ) {
            return {
              ..._ingredient,
              quantity: _ingredient.quantity > 1 ? _ingredient.quantity - 1 : 1,
            };
          }
          return _ingredient;
        }
      );
      dispatch(
        compositionSelectedAction({
          ...compositionSelected,
          ingredients: newIngredients,
        })
      );
    } else {
      return Toast(
        'warning',
        "L'ingredient n'existe pas dans la composition !"
      );
    }
  };

  const onAddIngredient = () => {
    if (!!findIngredient) return null;

    if (
      !isForCompose &&
      !!!questionSelected?.hasMultipleAnswer &&
      findQuestionsIngr?.length > 0
    )
      return Toast(
        'warning',
        "Vous ne pouvez pas ajouter plus d'un ingrédient pour cette question !"
      );
    const newIngredients = [
      ...compositionSelected.ingredients,
      {
        specialityId: speciality?._id,
        name: speciality.label,
        quantity: 1,
        ingredientId: answerSelected?.ingredient_id?._id || answerSelected?._id,
        questionId: questionSelected?._id,
      },
    ];

    dispatch(
      compositionSelectedAction({
        ...compositionSelected,
        ingredients: newIngredients,
      })
    );
    dispatch(
      questionSelectedAction({
        ...questionSelected,
        answered: true,
        noAnswer: false,
      })
    );
    const newQuestionsList = quiz?.questionsList?.map((question) => {
      if (question._id === questionSelected._id) {
        return { ...question, answered: true, noAnswer: false };
      } else {
        return question;
      }
    });
    dispatch(quizAction({ ...quiz, questionsList: newQuestionsList }));
  };

  const onDisplayImg = (e) => {
    e.stopPropagation();
    dispatch(
      getPictureDisplayAction({
        picture: speciality?.image,
        folder: 'picture',
      })
    );
    dispatch(viewImageModalAction(true));
  };

  return (
    <div
      className='ingredientCard'
      style={{
        borderColor: !!findIngredient ? colors.yellow : colors.grey,
        cursor: 'pointer',
      }}
      onClick={onAddIngredient}
    >
      <p className='ingredientCard--ingredient'>{speciality.label}</p>
      <div
        className='ingredientCard--imgBlock'
        onMouseEnter={() => setHoverImg(true)}
        onMouseLeave={() => setHoverImg(false)}
      >
        {speciality?.image?.length > 0 && hoverImg && (
          <ButtonZoom onDisplayImg={onDisplayImg} />
        )}
        {speciality?.image && (
          <img
            src={helperImageRoute(speciality.image, 'picture')}
            alt={speciality.image}
            width='90%'
            height={80}
            style={{ objectFit: 'cover' }}
          />
        )}
      </div>
      {findIngredient && (
        <div className='ingredientCard--btns'>
          <MinusGrayIcon onClick={onDecremente} />
          <span>{findIngredient?.quantity || 1}</span>
          <PlusGrayIcon onClick={onIncremente} />
        </div>
      )}
    </div>
  );
};
