import { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  createOneFilterValue,
  fetchFilters,
  updateOneFilterValue,
} from '../../../api/filters.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { filterValuesModalAction } from '../../../redux/modals';
import { Input, Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

//TODO Rendre autonome et vider les props
export const FilterValueModal = ({ bgWidth = '80%' }) => {
  const filterSelected = useAppSelector(
    (state) => state.filtersSelected.filter
  );

  const filterValueSelected = useAppSelector(
    (state) => state.filtersSelected.filterValue
  );

  const { data: filtersData } = useQuery('filters', () => fetchFilters(''));

  const [filterId, setFilterId] = useState(filterSelected?._id || '');
  const { resetFilters } = useResetFilters();
  const filterValueRef = useRef();
  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      if (filterValueRef.current.value === '') {
        toast_loader(loader, 'Veuillez remplir le champ', 'error');
        return;
      }

      const filterValue = { name: filterValueRef.current.value, filterId };

      if (filterValueSelected) {
        await updateOneFilterValue(
          { _id: filterValueSelected?._id, ...filterValue },
          filterValueSelected?.filterId
        );
        toast_loader(loader, 'Valeur Modifiée', 'success');
      } else {
        await createOneFilterValue(filterValue);
        toast_loader(loader, 'Valeur ajoutée', 'success');
      }

      resetFilters(['filter', 'filters']);

      handleClose();
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
    }
  };

  const handleClose = () => {
    dispatch(resetFilteredSelectedItemAction('filterValue'));
    dispatch(filterValuesModalAction(false));
  };

  return (
    <Modal
      zIndex={700}
      bgWidth={bgWidth}
      onClose={handleClose}
      title='Valeur'
      onValidate={handleSubmit}
    >
      <Input
        label='Nom'
        type='text'
        ref={filterValueRef}
        defaultValue={filterValueSelected?.name}
      />
      <Select
        label='Filtre'
        options={filtersData?.map((f) => ({
          name: f.label,
          value: f._id,
        }))}
        defaultValue={
          filterSelected
            ? {
                name: filterSelected?.label,
                value: filterSelected?._id,
              }
            : null
        }
        onChange={(e) => setFilterId(e.target.value)}
      />
    </Modal>
  );
};
