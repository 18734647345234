export const CustomerFilters = ({ isArchived, setIsArchived }) => {
  return (
    <div className='customer--filters'>
      <div
        className={`customer--filters--item ${
          !isArchived ? 'customer--filters--item-active' : ''
        }`}
        onClick={() => setIsArchived(false)}
      >
        <p>Actifs</p>
      </div>
      <div
        className={`customer--filters--item ${
          isArchived ? 'customer--filters--item-active' : ''
        }`}
        onClick={() => setIsArchived(true)}
      >
        <p>Archivés</p>
      </div>
    </div>
  );
};
