import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { RefreshIcon } from '../../../assets/svgs/RefreshIcon';
import { SettingIcon } from '../../../assets/svgs/SettingIcon';
import { checkMonthIsInSubscription } from '../../../assets/utils/funcsUtils';
import { useFamilies } from '../../../hooks/useFamilies';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useSpecialFilter } from '../../../hooks/useSpecialFilter';
import {
  editCustomerInfoAction,
  strategyModelCompOptAction,
  updateCustomerStrategiesAction,
} from '../../../redux/customer';
import {
  customerCompositionsModalAction,
  replayStrategyModalAction,
  strategyModelModalAction,
} from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Collapse } from '../../atoms/Collapse';
import { Select } from '../../atoms/Fields';

import { useGenerateStrategy } from '../../../hooks/useGenerateStrategy';
import { copyObj } from '../../../assets/utils/copyObj';
import { useQuery } from 'react-query';
import { fetchStyles } from '../../../api/styles.api';
import { useFilters } from '../../../hooks/useFilters';
import { HeaderSubscription } from './HeaderSubscription';
import { helperSubscriptionMonths } from '../../../assets/utils/helperSubscriptionMonths';
import { colors } from '../../../assets/constants';
import { Toast } from '../../../assets/utils/toast';
import { helperResetStrategy } from '../../../assets/utils/helperResetStrategy';

export const StrategyPanel = () => {
  return (
    <>
      <div
        className='customer--diarypanel-header'
        style={{
          backgroundColor: 'white',
          marginBottom: '1rem',
        }}
      >
        <div style={{ flex: 1 }} className='flex-col-start-start'>
          <HeaderSubscription />
        </div>
        <StrategyHeaderButtons />
      </div>
      <StrategyListMonths />
    </>
  );
};

const StrategyHeaderButtons = () => {
  const dispatch = useDispatch();
  const customer = useAppSelector((state) => state.customer.customer);
  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );
  const currentSubscription = customer?.subscriptions?.[currentSubscriptionIndex];


  return (
    <div className='flex-row-center'>
      <Button
        label='Rejouer la strategie'
        variant='yellow'
        Icon={RefreshIcon}
        iconPosition='right'
        onClick={() => {
          if(!currentSubscription){
            Toast('error', 'Veuillez selectionner un abonnement !');
          }else{
            dispatch(replayStrategyModalAction(true))
          }
        }}
      />
      <Button
        label='Modèle'
        variant='black'
        margin='0 0 0 1rem'
        onClick={() => {
          if(!currentSubscription){
            Toast('error', 'Veuillez selectionner un abonnement !');
          }else{
            dispatch(strategyModelModalAction(true))
          }
        }}
      />
    </div>
  );
};

const StrategyListMonths = () => {
  const customer = useAppSelector((state) => state.customer.customer);

  const subscriptions = customer?.subscriptions || [];

  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );

  const currentSubscription = subscriptions[currentSubscriptionIndex];

  const monthsSorted = helperSubscriptionMonths(currentSubscription);

  return (
    <div className='customer--diarypanel-listMonths'>
      {monthsSorted.map((monthData, monthIndex) => {
        const isMonthValidInSubscription = checkMonthIsInSubscription(
          monthData.month,
          monthData.year,
          currentSubscription
        );

        return (
          <Collapse
            title={`${monthData.month?.toUpperCase()} ${monthData.year}`}
            bg='white'
            opened={isMonthValidInSubscription}
            key={monthIndex + `-${currentSubscription?.endIt?.month}`}
            className='customer--diarypanel-listMonths-collapse'
          >
            {new Array(currentSubscription?.formula || 6)
              .fill('')
              .map((_formula, index) => {
                return (
                  <StrategyItem
                    key={index}
                    index={index}
                    monthData={monthData}
                    monthIndex={monthIndex}
                    isMonthValidInSubscription={isMonthValidInSubscription}
                    currentSubscription={currentSubscription}
                  />
                );
              })}
          </Collapse>
        );
      })}
    </div>
  );
};

const StrategyItem = ({
  index,
  isMonthValidInSubscription,
  monthData,
  currentSubscription,
}) => {
  const dispatch = useAppDispatch();
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const strategy = currentSubscription?.strategies?.find(
    (strategy) =>
      strategy.month === monthData.month &&
      strategy.year === monthData.year &&
      strategy.formula_number === index + 1
  )?.strategy;

  const { data: styles } = useQuery(['styles'], () => fetchStyles(''));
  const { filters, filterValues, handleSelectFilter } = useFilters(
    strategy?.filterId
  );

  const {
    families,
    products,
    compositions,
    handleSelectFamily,
    handleSelectProduct,
  } = useFamilies(
    strategy?.familyId,
    strategy?.productId,
    false,
    strategy?.type === 'forCompose'
  );

  const { specialFilters, specialFilterValues, handleSelectSpecialFilter } =
    useSpecialFilter(strategy?.specialFilterId, false);

  const { handleGenerateRandomStrategy } = useGenerateStrategy();

  const familySelected = families?.find((_f) => _f?._id === strategy?.familyId);

  const strategyModel = useAppSelector(
    (state) => state.customer?.customer?.strategyModel
  );
  // const customerCompositions = useAppSelector(
  //   (state) => state.customer?.customer?.univers?.compositions
  // );

  const composition = strategy?.compositionId
    ? compositions?.find(
        (_c: IComposition) =>
          (_c?.productId?._id || _c.productId) === strategy?.productId &&
          _c._id === strategy?.compositionId
      )
    : null;

  const handleGenerateStrategy = async () => {
    const currentIndexes = {
      family: 0,
      type: {},
      product: {},
      composition: {},
      specialFilter: {},
      subSpecialFilter: {},
    };
    const strategies = copyObj(currentSubscription?.strategies);
    const strategy = await handleGenerateRandomStrategy({
      index,
      month: monthData.month,
      year: monthData.year,
      currentIndexes,
    });

    const strategyFinded = strategies?.find(
      (strategy) =>
        strategy.month.month === monthData.month &&
        strategy.year === monthData.year &&
        strategy.formula_number === index + 1
    );

    if (strategyFinded) {
      strategyFinded.strategy = strategy;
    } else {
      strategies.push({
        month: monthData.month,
        year: monthData.year,
        formula_number: index + 1,
        strategy,
      });
    }

    dispatch(updateCustomerStrategiesAction(strategies));
  };

  const handleSelectComposition = () => {
    if (!strategy?.productId) {
      Toast('warning', 'Veuillez selectionner un produit avant !');
      return;
    }

    dispatch(
      editCustomerInfoAction({
        tab: 'strategyModel',
        value: strategyModel,
      })
    );

    const compositionActionPayload = {
      strategyIndex: index,
      from: 'strategyPanel',
      month: monthData.month,
      index,
      label: 'compositionId',
    };
    dispatch(strategyModelCompOptAction(compositionActionPayload));

    dispatch(customerCompositionsModalAction(true));
  };

  const handleChange = (e, type) => {
    const strategies = copyObj(currentSubscription?.strategies);

    const formula_number = index + 1;

    const strategyFinded = strategies?.find(
      (strategy) =>
        strategy.month === monthData.month &&
        strategy.formula_number === formula_number
    );

    if (strategyFinded) {
      strategyFinded.strategy[type] = e.target.value;
    } else {
      strategies.push({
        month: monthData.month,
        year: monthData.year,
        formula_number,
        strategy: {
          [type]: e.target.value,
        },
      });
    }

    switch (type) {
      case 'familyId':
        handleSelectFamily(e.target.value);
        helperResetStrategy('familyId', strategyFinded.strategy);
        break;

      case 'productId':
        handleSelectProduct(e.target.value);
        helperResetStrategy('productId', strategyFinded.strategy);
        break;

      case 'specialFilterId':
        handleSelectSpecialFilter(e.target.value);
        helperResetStrategy('specialFilterId', strategyFinded.strategy);
        break;

      case 'filterId':
        handleSelectFilter(e.target.value);
        helperResetStrategy('filterId', strategyFinded.strategy);
        break;

      default:
        break;
    }

    dispatch(updateCustomerStrategiesAction(strategies));
  };

  return (
    <div className='customer--diarypanel-strategy' style={{ width: '100%' }}>
      <div>
        <span className='customer--diarypanel-strategy-number'>
          {index + 1}
        </span>
        <Select
          width='22%'
          margin='0 1rem 0 0'
          label='Famille'
          name='families'
          disabled={!isMonthValidInSubscription}
          options={
            families?.map((family) => ({ ...family, value: family._id })) || []
          }
          defaultValue={{
            name: 'Toutes les familles',
          }}
          value={strategy?.familyId}
          onChange={(e) => handleChange(e, 'familyId')}
        />
        <Select
          width='22%'
          margin='0 .5rem 0 0'
          label={'Type'}
          disabled={!isMonthValidInSubscription}
          options={[
            {
              name: 'Principal',
              value: 'principal',
            },
            familySelected?.forCompose && {
              name: 'fait à composer',
              value: 'forCompose',
            },
          ].filter((t) => !!t)}
          onChange={(e) => handleChange(e, 'type')}
          value={strategy?.type}
          defaultValue={{
            name: 'Tout les types',
          }}
        />

        <Select
          width='22%'
          margin='0 1rem 0 0'
          label='Produit'
          name='produit'
          disabled={!isMonthValidInSubscription}
          options={products}
          defaultValue={{
            name: 'Tout les produits',
          }}
          value={strategy?.productId}
          onChange={(e) => handleChange(e, 'productId')}
        />

        <div style={{ width: '22%', display: 'flex', flexDirection: 'column' }}>
          <label className='fields--label' style={{ marginBottom: '.8rem' }}>
            Compositions
          </label>

          <button
            onClick={handleSelectComposition}
            className='customer--textData customer--textData-btn'
            style={{ borderColor: !!composition ? colors.yellow : colors.grey }}
          >
            {!!composition
              ? composition?.short_name
              : 'Toutes les compositions'}
          </button>
        </div>
        <Select
          width='22%'
          margin='0 1rem 0 0'
          label='Filre special'
          name='products'
          disabled={!isMonthValidInSubscription}
          options={specialFilters}
          defaultValue={{
            name: 'Tout les filtres',
          }}
          value={strategy?.specialFilterId}
          onChange={(e) => handleChange(e, 'specialFilterId')}
        />
        <Select
          width='22%'
          margin='0 1rem 0 0'
          label='Sous filtre special'
          name='products'
          disabled={!isMonthValidInSubscription}
          options={specialFilterValues}
          defaultValue={{
            name: 'Tout les sous filtres',
          }}
          value={strategy?.subSpecialFilterId}
          onChange={(e) => handleChange(e, 'subSpecialFilterId')}
        />
        <div
          className='customer--diarypanel-strategy-btns'
          style={{ width: 'auto' }}
        >
          <button onClick={() => setDropDownOpen(!dropDownOpen)}>
            <SettingIcon />
          </button>
          <button
            disabled={!isMonthValidInSubscription}
            onClick={handleGenerateStrategy}
          >
            <RefreshIcon />
          </button>
        </div>
      </div>
      {dropDownOpen && (
        <div>
          <span className='customer--diarypanel-strategy-number'>
            &nbsp;&nbsp;
          </span>
          <Select
            width='27.5%'
            margin='0 1rem 0 0'
            label='Filtre'
            name='filtre'
            disabled={!isMonthValidInSubscription}
            defaultValue={{
              name: 'Tout les filtres',
            }}
            options={filters}
            value={strategy?.filterId}
            onChange={(e) => handleChange(e, 'filterId')}
          />
          <Select
            width='27.5%'
            margin='0 1rem 0 0'
            label='Sous filtres'
            name='fitlerValues'
            disabled={!isMonthValidInSubscription}
            defaultValue={{
              name: 'Tout les sous filtres',
            }}
            options={filterValues}
            value={strategy?.filterValueId}
            onChange={(e) => handleChange(e, 'filterValueId')}
          />
          <Select
            width='27.5%'
            margin='0 1rem 0 0'
            label='Styles'
            name='styles'
            disabled={!isMonthValidInSubscription}
            defaultValue={{
              name: 'Tout les styles',
            }}
            options={
              styles?.map((style) => ({ ...style, value: style._id })) || []
            }
            value={strategy?.stylesId}
            onChange={(e) => handleChange(e, 'stylesId')}
          />

          <div
            className='customer--diarypanel-strategy-btns'
            style={{ width: 'auto' }}
          />
        </div>
      )}
    </div>
  );
};
