import { axiosInstance } from '../assets/utils/axiosInstance';

type SpecialFilter = {
  label: string;
  filterId: string;
};

export const createOneSubSpecialFilter = async (data: SpecialFilter) => {
  const res = await axiosInstance
    .post('/specialfilter/add-sub-special-filter', data)
    .catch((err) => {
      console.log('createOneSubSpecialFilter', err);
    });
  return res;
};

export const updateOneSubSpecialFilter = async (
  id: string,
  specialFilterId: string,
  data: SpecialFilter
) => {
  const res = await axiosInstance
    .put(
      `/specialfilter/update-sub-special-filter/${id}/${specialFilterId}`,
      data
    )
    .catch((err) => {
      console.log('updateOneSpecialFilter', err);
    });
  return res;
};

export const deleteOneSubSpecialFilter = async (id: string) => {
  const res = await axiosInstance
    .delete(`/specialfilter/sub-special-filter/${id}`)
    .catch((err) => {
      console.log('deleteOneSpecialFilter', err);
    });
  return res;
};
