import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchCustomerById } from '../../../api/customers.api';
import {
  getAllCompositionsByProductId,
  getOneFamilyById,
} from '../../../api/families.api';
import { initQuiz } from '../../../assets/constants';
import { Loader } from '../../../components/atoms/Loader';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { customerDetailsAction } from '../../../redux/customer';
import { quizAction } from '../../../redux/familyQuiz';
import { ProductsAnswersBlock } from './ProductsAnswersBlock';
import { CompositionsBlock } from './CompositionsBlock';
import { PicturesBlock } from './PicturesBlock';
import { filteredSelectedItemAction } from '../../../redux/filtersSelected';
import { compositionsAction } from '../../../redux/compositions';
import { getOneQuiz } from '../../../api/quiz.api';
import { Toast } from '../../../assets/utils/toast';

export const CustomerCompositionsView: React.FC = () => {
  const dispatch = useAppDispatch();
  const { customerId, familyId } = useParams<{
    customerId: string;
    familyId: string;
  }>();
  const [loading, setLoading] = useState<boolean>(false);

  const productId = useAppSelector((s) => s.compositions.productId);

  const { data: customerData, isLoading: customerIsLoading } = useQuery(
    ['customer', customerId],
    () => fetchCustomerById(customerId)
  );

  const {
    data: compositionsData,
    isLoading: isLoadingCompositions,
    isFetched: isFetchedCompositions,
  } = useQuery(['compositions', productId], () =>
    getAllCompositionsByProductId(productId)
  );

  const getCustomerData = useCallback(() => {
    if (customerData) {
      dispatch(customerDetailsAction(customerData.customer));
    }
    return () => {
      dispatch(customerDetailsAction(null));
    };
  }, [customerData, dispatch]);

  const getQuiz = useCallback(() => {
    (async () => {
      setLoading(true);
      const familyData = await getOneFamilyById(familyId);
      if (!!familyData?.data) {
        const family = familyData?.data?.family;
        dispatch(
          filteredSelectedItemAction({ filter: 'family', item: family })
        );
        const quizId = familyData?.data?.family?.quiz?._id;
        const quizData = await getOneQuiz(quizId);
        if (!!quizData?.data) {
          const payload = {
            _id: quizData?.data?._id,
            familyId: {
              _id: family?._id,
              label: family?.label,
            },
            questionsList: quizData?.data?.questionsList,
            propositionsList: quizData?.data?.propositionsList,
            responsesSaved: quizData?.data?.responsesSaved,
            update: true,
          };
          dispatch(quizAction(payload));
          setLoading(false);
        } else {
          dispatch(
            quizAction({
              ...initQuiz,
              familyId: { _id: family?._id, label: family?.label },
            })
          );
          setLoading(false);
        }
      } else {
        Toast('error', 'Une erreur est survenue');
        setLoading(false);
      }
    })();
  }, [familyId, dispatch]);

  const getCompositions = useCallback(() => {
    if (productId && !!compositionsData?.data && isFetchedCompositions) {
      dispatch(compositionsAction(compositionsData?.data));
    }
  }, [dispatch, compositionsData, isFetchedCompositions, productId]);

  useEffect(() => {
    getCompositions();
  }, [getCompositions]);

  useEffect(() => {
    getCustomerData();
    getQuiz();
  }, [getQuiz, getCustomerData]);

  if (customerIsLoading || loading || isLoadingCompositions) return <Loader />;

  return (
    <div className='customerCompositionsView'>
      <PicturesBlock />
      <ProductsAnswersBlock />
      <CompositionsBlock />
    </div>
  );
};
