import {useState} from 'react';
import {compose} from 'recompose';
import {TableHeader} from '../../../components/atoms/TableHeader';
import {withNavigation} from '../../../hoc/withNavigation';
import {groupsModalAction} from '../../../redux/modals';
import {Loader} from '../../../components/atoms/Loader';
import {HeaderWithSearch} from '../../../components/organisms/Header';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {sortedList} from '../../../assets/utils/sortedList';
import {fetchGroups} from '../../../api/groups.api';
import {useQuery} from 'react-query';
import {TableSimpleRow} from '../../../components/atoms/TableSimpleRow';
import {DeleteGroup} from './DeleteGroup';
import {copyObj} from '../../../assets/utils/copyObj';
import {useAppDispatch} from '../../../hooks/useReduxHook';

const GroupsView = ({user}) => {
  const dispatch = useAppDispatch();
  const [showActionModal, setShowActionModal] = useState(null);

  const [search, setSearch] = useState('');

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };
  const params = new URLSearchParams([
    ['label', ''],
    ['page', '1'],
    ['limit', '100'],
  ]);
  const {data, isLoading} = useQuery('groups', () => {
    return fetchGroups(params);
  });

  const groups = copyObj(data?.data?.groups)
    ?.sort((a, b) => sortedList(a, b, 'label'))
    ?.filter((group) =>
      group.label.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <>
      <DeleteGroup />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`groupes (${groups?.length || 0})`}
            onAdd={() => dispatch(groupsModalAction(true))}
          />
          <div className='table'>
            {isLoading ? (
              <Loader />
            ) : (
              groups?.length > 0 &&
              copyObj(groups)
                .sort((a, b) => sortedList(a, b, 'label'))
                ?.map((group, index) => (
                  <TableSimpleRow
                    key={index}
                    item={group}
                    filter='groups'
                    onOpen={onOpen}
                    onOpenModal={() => dispatch(groupsModalAction(true))}
                    showActionModal={showActionModal}
                  />
                ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withNavigation, withCheckAuth)(GroupsView);
