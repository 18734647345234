import {useCallback, useEffect, useState} from 'react';
import {initPackages} from '../../../assets/constants';
import {Toast, toast_loader} from '../../../assets/utils/toast';
import {Button} from '../../../components/atoms/Buttons';
import {Collapse} from '../../../components/atoms/Collapse';
import {Loader} from '../../../components/atoms/Loader';
import {PackageModal} from '../../../components/modals/PackagesModal';
import {HeaderPackages} from '../../../components/organisms/Header';
import {withNavigation} from '../../../hoc/withNavigation';
import {packageModalAction} from '../../../redux/modals';
import {getFormulaSelected} from '../../../redux/packages';
import {toast} from 'react-toastify';
import {compose} from 'recompose';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {
  fetchPackagesByYear,
  updateOnePackages,
} from '../../../api/packages.api';
import {copyObj} from '../../../assets/utils/copyObj';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';

export const PackagesView = ({user}) => {
  //
  const dispatch = useAppDispatch();
  const [packages, setPackages] = useState(initPackages);
  const packageModal = useAppSelector((s) => s.modals.packageModal);
  const [loading, setLoading] = useState(false);

  const getPackagesByYear = useCallback(async () => {
    setLoading(true);
    await fetchPackagesByYear(packages.year)
      .then((res) => {
        setPackages(res.data?.package);
        setLoading(false);
      })
      .catch((_) => {
        Toast('error', "Une erreur s'est produite !");
        setLoading(false);
      });
    setLoading(false);
  }, [packages.year]);

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');
    await updateOnePackages(packages)
      .then((res) => {
        toast_loader(loader, 'Forfait modifié !', 'success');
        getPackagesByYear();
      })
      .catch((err) => {
        toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
      });
  };

  useEffect(() => {
    getPackagesByYear();
  }, [getPackagesByYear]);

  if (loading) return <Loader />;

  return (
    <>
      {packageModal && (
        <PackageModal
          onClose={() => dispatch(packageModalAction(false))}
          setPackages={setPackages}
        />
      )}
      <div className='pages-layout'>
        <HeaderPackages
          packages={packages}
          setPackages={setPackages}
          user={user}
        />
        <div className='pages-layout--container packages'>
          <div className='packages--container'>
            {copyObj(packages.months)
              .sort((a, b) => a?.index - b?.index)
              .map((month, index) => (
                <Collapse key={index} title={month?.name}>
                  {month[`formulas_${packages?.formula}`]?.map(
                    (formula, index) => (
                      <Formula
                        key={index}
                        formula={formula}
                        month={month}
                        formulaLength={packages?.formula}
                      />
                    )
                  )}
                </Collapse>
              ))}
          </div>
        </div>
        <div className='packages--footer'>
          <Button
            onClick={handleSubmit}
            variant='yellow'
            label='Enregistrer'
            margin='0 1rem 0 auto'
          />
        </div>
      </div>
    </>
  );
};

const Formula = ({formula, month, formulaLength}) => {
  const dispatch = useAppDispatch();

  const onSelect = () => {
    dispatch(getFormulaSelected({month, formula, formulaLength}));
    dispatch(packageModalAction(true));
  };
  return (
    <div className='packages--date'>
      <button onClick={onSelect}>{formula.formula_number}</button>
      <p>{formula.date}</p>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(PackagesView);
