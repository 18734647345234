import { colors } from '../../../assets/constants';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import { warningAttrImgModalAction } from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Modal } from '../../atoms/Modal';

export const WarningAttrImgModal = ({ bgWidth = '80%' }) => {
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(warningAttrImgModalAction(false));
  };
  return (
    <Modal
      onClose={onClose}
      title="Alerte d'attribution d'image"
      hiddenBtns
      width={540}
      minHeight={150}
      bgWidth={bgWidth}
    >
      <p
        style={{
          fontFamily: 'Inter Semi Bold',
          fontSize: 14,
          textTransform: 'uppercase',
          color: colors.dark,
        }}
      >
        ❌ Attention ! Vous avez des alertes d'attribution d'image
      </p>
      <Button
        label='OK'
        variant='yellow'
        width={'100%'}
        margin='0 .5rem'
        onClick={onClose}
      />
    </Modal>
  );
};
