import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DotsCircleIcon } from '../../../assets/svgs/DotsCircleIcon';
import { ActionModal } from '../../../components/atoms/ActionModal';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { withNavigation } from '../../../hoc/withNavigation';
import { Loader } from '../../../components/atoms/Loader';
import { deleteModalAction } from '../../../redux/modals';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import {
  deleteCustomer,
  fetchCustomers,
  updateCustomerInfo,
} from '../../../api/customers.api';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { compose } from 'recompose';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { useQuery, useQueryClient } from 'react-query';
import { toast_loader } from '../../../assets/utils/toast';
import { toast } from 'react-toastify';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { sortedList } from '../../../assets/utils/sortedList';
import { copyObj } from '../../../assets/utils/copyObj';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { CustomerFilters } from './Filters';
import { customerDetailsAction } from '../../../redux/customer';

const CustomersView = ({ user }) => {
  //
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const deleteModal = useAppSelector((s) => s.modals.deleteModal);
  const [search, setSearch] = useState('');
  const [id, setId] = useState(null);
  const [showActionModal, setShowActionModal] = useState(null);
  const [isArchived, setIsArchived] = useState(false);

  const { data: customers, isLoading } = useQuery('customers', () =>
    fetchCustomers('')
  );

  const { resetFilters } = useResetFilters();

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const customersFiltered = customers
    ?.filter(
      (customer) =>
        customer?.isArchived === isArchived ||
        (!isArchived && customer?.isArchived === undefined)
    )
    ?.filter((customer) =>
      customer?.informations?.restaurant_name
        ?.toLowerCase()
        .includes(search.toLowerCase())
    );

  const handleDeleteCustomer = async () => {
    try {
      if (id) {
        const loader = toast.loading('Suppression en cours...');
        const res = await deleteCustomer(id);

        if (res) {
          toast_loader(loader, 'Suppression réussie', 'success');
          dispatch(deleteModalAction(false));
          resetFilters('customers');
        } else {
          toast_loader(loader, 'Une erreur est survenue', 'error');
        }
      }
    } catch (error) {}
  };

  return (
    <>
      {deleteModal && (
        <DeleteModal
          onClose={() => dispatch(deleteModalAction(false))}
          onDelete={handleDeleteCustomer}
          text={`le client`}
        />
      )}
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <CustomerFilters
            isArchived={isArchived}
            setIsArchived={setIsArchived}
          />
          <TableHeader
            title={`clients (${customersFiltered?.length ?? ''})`}
            onAdd={() => {
              dispatch(customerDetailsAction(null));
              navigate('/customer/add')}}
          />

          <div className='table'>
            {isLoading ? (
              <Loader />
            ) : (
              copyObj(customersFiltered)
                ?.sort((a, b) =>
                  sortedList(
                    a?.informations,
                    b?.informations,
                    'restaurant_name'
                  )
                )
                .map((customer, index) => {
                  return (
                    <Trow
                      key={index}
                      customer={customer}
                      setId={setId}
                      onOpen={onOpen}
                      showActionModal={showActionModal}
                    />
                  );
                })
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Trow = ({ customer, setId, showActionModal, onOpen }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clientQuery = useQueryClient();

  const onShowModalDelete = () => {
    setId(customer?._id);
    dispatch(deleteModalAction(true));
  };

  const handleToggleArchive = async () => {
    try {
      const loader = toast.loading(
        customer?.isArchived ? 'Désarchivage...' : 'Archivage...'
      );

      const res = await updateCustomerInfo(customer?._id, {
        isArchived: !customer?.isArchived,
      });

      if (res) {
        await clientQuery.invalidateQueries('customers');
        toast_loader(
          loader,
          customer?.isArchived ? 'Désarchivage réussi' : 'Archivage réussi',
          'success'
        );
        onOpen(null);
      } else {
        toast_loader(loader, 'Une erreur est survenue', 'error');
      }
    } catch (error) {}
  };

  return (
    <div
      className='table-trow'
      onClick={(e) => {
        if (
          typeof e.target.className === 'string' &&
          e.target.className?.includes('table-trow')
        ) {
          navigate(`/customer/${customer?._id}`);
          e.stopPropagation();
        }
      }}
    >
      {customer?.logo_card?.avatar && (
        <img
          src={helperImageRoute(customer?.logo_card?.avatar, 'customer-avatar')}
          alt='customer logo'
          loading='lazy'
          width={45}
        />
      )}
      <div
        className='table-trow-texts'
        style={{ flex: 4.5, marginLeft: '1rem' }}
      >
        <p
          className='table-trow-text'
          style={{
            fontFamily: 'Inter Bold',
            textTransform: 'uppercase',
            margin: '0 0 .5rem 0',
          }}
        >
          {customer?.informations?.restaurant_name +
            (customer?.informations?.restaurant_city
              ? `-  ${customer?.informations?.restaurant_city}`
              : '')}
        </p>
        <p
          className='table-trow-text'
          style={{ textTransform: 'inherit', margin: 0 }}
        >
          {customer?.informations?.email}
        </p>
      </div>
      <div className='table-trow-action'>
        {showActionModal === customer?._id && (
          <ActionModal
            onEdit={() => navigate(`/customer/${customer?._id}`)}
            onDelete={onShowModalDelete}
            onToggleArchive={handleToggleArchive}
            isArchived={customer?.isArchived}
            showArchiveBtn
          />
        )}
        <DotsCircleIcon onClick={() => onOpen(customer?._id)} />
      </div>
    </div>
  );
};

export default compose(withCheckAuth, withNavigation)(CustomersView);
