export const ArrowSmallTop = ({ color = '#282828', margin = '0 0 0 auto' }) => {
  return (
    <svg
      width='10'
      height='4'
      viewBox='0 0 10 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ margin }}
    >
      <path d='M10 4L5 1.30032e-08L4.76995e-08 4L10 4Z' fill={color} />
    </svg>
  );
};
