import { useState } from 'react';
import { toast } from 'react-toastify';
import { createOneGroup, updateOneGroup } from '../../../api/groups.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';

import { groupsModalAction } from '../../../redux/modals';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

export const GroupsModal = ({ bgWidth = '80%' }) => {
  const groupSelected = useAppSelector((state) => state.filtersSelected.groups);
  const dispatch = useAppDispatch();
  const [label, setLabel] = useState(groupSelected?.label || '');

  const { resetFilters } = useResetFilters();

  const handleClose = () => {
    dispatch(resetFilteredSelectedItemAction('groups'));
    dispatch(groupsModalAction(false));
  };

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      if (!label)
        return toast_loader(loader, 'Veuillez renseigner un nom', 'error');

      if (groupSelected) {
        await updateOneGroup(groupSelected?._id, label);
        toast_loader(loader, 'Group modifié', 'success');
      } else {
        await createOneGroup(label);
        toast_loader(loader, 'Group ajouté', 'success');
      }

      resetFilters('groups');
      handleClose();
    } catch (err) {
      toast_loader(loader, 'Une erreur est survenue', 'error');
    }
  };

  return (
    <Modal
      onClose={handleClose}
      title='Groupe'
      onValidate={handleSubmit}
      zIndex={700}
      bgWidth={bgWidth}
    >
      <Input
        label='Nom'
        type='text'
        value={label}
        onChange={(e) => setLabel(e.target.value)}
      />
    </Modal>
  );
};
