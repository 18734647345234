import { toast } from 'react-toastify';
import { deleteOneGroup } from '../../../api/groups.api';
import { toast_loader } from '../../../assets/utils/toast';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { deleteModalAction } from '../../../redux/modals';

export const DeleteGroup = () => {
  //
  const dispatch = useAppDispatch();
  const groupSelected = useAppSelector((state) => state.filtersSelected.groups);

  const { resetFilters } = useResetFilters();

  const handleDeleteGroup = () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      deleteOneGroup(groupSelected?._id);
      dispatch(deleteModalAction(false));
      resetFilters('groups');
      dispatch(resetFilteredSelectedItemAction('groups'));
      toast_loader(loader, 'Groupe supprimé', 'success');
    } catch (error) {
      toast_loader(loader, 'Erreur lors de la suppression du groupe', 'error');
    }
  };

  return (
    <DeleteModal
      onDelete={handleDeleteGroup}
      text={`le group "${groupSelected?.label}"`}
    />
  );
};
