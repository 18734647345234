import { useState } from 'react';
import { compose } from 'recompose';
import { useNavigate } from 'react-router-dom';
import { DotsCircleIcon } from '../../../assets/svgs/DotsCircleIcon';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { withNavigation } from '../../../hoc/withNavigation';
import { deleteModalAction, familiesModalAction } from '../../../redux/modals';
import { Loader } from '../../../components/atoms/Loader';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { sortedList } from '../../../assets/utils/sortedList';
import { toast_loader } from '../../../assets/utils/toast';
import { deleteOneFamily, fetchFamiliesList } from '../../../api/families.api';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
} from '../../../redux/filtersSelected';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { copyObj } from '../../../assets/utils/copyObj';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { EditIcon } from '../../../assets/svgs/EditIcon';
import { TrashIcon } from '../../../assets/svgs/TrashIcon';
import { colors, initQuiz } from '../../../assets/constants';
import { quizAction } from '../../../redux/familyQuiz';
import { CounterItem } from '../../../components/atoms/Counter';

const FamilliesView = ({ user }) => {
  //
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const [showActionModal, setShowActionModal] = useState(null);

  const { data: families, isLoading: loadingFamilies } = useQuery(
    'families',
    () => fetchFamiliesList('')
  );

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const handleAddFamily = () => {
    dispatch(resetFilteredSelectedItemAction('family'));
    dispatch(familiesModalAction(true));
  };

  return (
    <>
      <DeleteFamilyModal />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`Familles (${families?.length ?? ''})`}
            onAdd={handleAddFamily}
          />
          <div className='table'>
            {loadingFamilies ? (
              <Loader />
            ) : (
              copyObj(families)
                ?.sort((a, b) => sortedList(a, b, 'label'))
                .filter((family) => family.label.toLowerCase().includes(search))
                .map((family, index) => (
                  <Trow
                    key={index}
                    family={family}
                    onOpen={onOpen}
                    showActionModal={showActionModal}
                    parentKey={'families'}
                    itemsKey={'products'}
                  />
                ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteFamilyModal = () => {
  const dispatch = useAppDispatch();
  const familySelected = useAppSelector((s) => s.filtersSelected.family);

  const { resetFilters } = useResetFilters();

  const handleDeleteFamily = async () => {
    const loader = toast.loading('Veuillez patienter...');
    const { data } = await deleteOneFamily(familySelected._id);
    if (data?.status) {
      toast_loader(loader, data?.message, 'success');
      resetFilters('families');
      dispatch(resetFilteredSelectedItemAction('family'));
      dispatch(deleteModalAction(false));
    } else {
      toast_loader(loader, data?.message, 'error');
      dispatch(deleteModalAction(false));
    }
  };

  return (
    <DeleteModal
      onDelete={handleDeleteFamily}
      text={`la famille "${familySelected?.label}"`}
    />
  );
};

const Trow = ({ family, showActionModal, onOpen, itemsKey, parentKey }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSelectFamily = (toDuplicate) => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'family',
        item: { ...family, toDuplicate: toDuplicate },
      })
    );
  };

  const onShowModal = (toDuplicate) => {
    handleSelectFamily(toDuplicate);
    dispatch(familiesModalAction(true));
  };
  const onShowModalDelete = () => {
    handleSelectFamily();
    dispatch(deleteModalAction(true));
  };

  const onQuiz = () => {
    dispatch(quizAction(initQuiz));
    navigate(`/family-quiz/${family?._id}`);
  };

  return (
    <div className='table-trow'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span
          style={{ cursor: 'pointer', flex: 1 }}
          className='table-trow-text'
          onClick={() => navigate(`/family-products/${family?._id}`)}
        >
          {family?.label}
        </span>
        <CounterItem number={family?.pictureCounter} />
      </div>
      <div className='table-trow-action'>
        {showActionModal === family?._id && (
          <FamilyActionModal
            onSelectFamily={onShowModal}
            onDelete={onShowModalDelete}
            onQuiz={onQuiz}
          />
        )}
        <DotsCircleIcon onClick={() => onOpen(family?._id)} />
      </div>
    </div>
  );
};

const FamilyActionModal = ({
  onSelectFamily,
  onDelete,
  onQuiz,
  disabledDelete = false,
}) => {
  return (
    <div className='actionModal scale-up-center' style={{ height: 'auto' }}>
      <button onClick={() => onSelectFamily(false)}>
        <EditIcon margin='0 .5rem 0 0' /> Modifier
      </button>
      <button
        style={{ borderBottom: `1px solid ${colors.grey}` }}
        onClick={onDelete}
        disabled={disabledDelete}
      >
        <TrashIcon margin='0 .5rem 0 0' /> Supprimer
      </button>
      <button onClick={onQuiz}>
        <TrashIcon margin='0 .5rem 0 0' /> Questionnaire
      </button>
      <button onClick={() => onSelectFamily(true)}>
        <EditIcon margin='0 .5rem 0 0' /> Dupliquer
      </button>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(FamilliesView);
