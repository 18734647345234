import {toast} from 'react-toastify';
import {deleteOneFilter} from '../../../api/filters.api';
import {toast_loader} from '../../../assets/utils/toast';
import {DeleteModal} from '../../../components/modals/DeleteModal';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {useResetFilters} from '../../../hooks/useResetFilters';
import {resetFilteredSelectedItemAction} from '../../../redux/filtersSelected';
import {deleteModalAction} from '../../../redux/modals';

export const DeleteFilter = () => {
  //
  const filter = useAppSelector((state) => state.filtersSelected.filter);
  const dispatch = useAppDispatch();

  const {resetFilters} = useResetFilters();

  const handleDeleteFilter = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      await deleteOneFilter(filter._id);
      toast_loader(loader, 'Filtre supprimé !', 'success');
      resetFilters('filters');
      dispatch(resetFilteredSelectedItemAction('filter'));
      dispatch(deleteModalAction(false));
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
    }
  };

  return (
    <DeleteModal
      onDelete={handleDeleteFilter}
      text={`le filtre "${filter?.label}"`}
    />
  );
};
