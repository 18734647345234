import { useState } from 'react';
import { updateCustomerInfo } from '../api/customers.api';
import { createOnePicture } from '../api/picture.api';
import { uploadFile } from '../api/uploadFile.api';
import { copyObj } from '../assets/utils/copyObj';
import { Toast } from '../assets/utils/toast';
import { resetFiltersAction } from '../redux/filters';
import { initImagesAction, resetImageFormAction } from '../redux/images';
import store from '../redux/storeConfig';
import { useAppDispatch, useAppSelector } from './useReduxHook';
import { useAttributionsImgComposition } from './useCompositions';
import { useNavigate } from 'react-router-dom';

export const useCloneImage = () => {
  //
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { infosMainProducts, infosSecondaryProducts } = useAppSelector(
    (s) => s.images
  );
  const { count } = useAttributionsImgComposition();
  const products = [...infosMainProducts, ...infosSecondaryProducts];

  const handleCloneImage = async () => {
    try {
      if (loading) return;

      setLoading(true);
      const images = store.getState().images;
      const data = copyObj(images);

      if (count > 0) {
        Toast('warning', 'Veuillez attribuer tous les produits sélectionnés !');
        setLoading(false);
        return;
      }
      if (!images.image) {
        setLoading(false);
        return Toast('warning', 'Veuillez ajouter au moins une image !');
      }

      if (products.length === 0) {
        setLoading(false);
        return Toast(
          'warning',
          'Veuillez sélectionner au moins 1 produit primaire ou sécondaire !'
        );
      }

      if (!!images.image?.name) {
        const fileName = await uploadFile(images.image, 'picture');
        if (fileName) {
          data.image = fileName;
        }
      }

      const payload = {
        ...data,
        attrMainCompositions: data?.attrMainCompositions?.map((_el: any) => ({
          familyId: _el.familyId,
          productId: _el.productId?._id || _el?.productId,
          compositionId:
            _el.compositionId?._id || _el?.compositionId || _el._id,
        })),
        attrSecondaryCompositions: data?.attrSecondaryCompositions?.map(
          (_el: any) => ({
            familyId: _el.familyId,
            productId: _el.productId?._id || _el?.productId,
            compositionId:
              _el.compositionId?._id || _el?.compositionId || _el._id,
          })
        ),
      };
      delete payload._id;
      const pictureRes = await createOnePicture(payload);

      if (pictureRes && pictureRes?.data?.data) {
        dispatch(initImagesAction(pictureRes?.data?.data));
        Toast('success', 'Image clonée avec succès !');
        dispatch(resetFiltersAction());
        dispatch(resetImageFormAction());
        navigate('/images');
        window.location.reload();
      } else {
        Toast(
          'error',
          pictureRes ? pictureRes?.data?.message : 'Erreur serveur !'
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    handleCloneImage,
    loading,
  };
};
