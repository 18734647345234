import { axiosInstance } from '../assets/utils/axiosInstance';
import { uploadFile } from './uploadFile.api';

export const fetchAllUsers = async () => {
  const res = await axiosInstance.get('/users').catch((err) => {
    console.log('fetchAllUsers', err);
  });
  return res;
};

export const createOneUser = async (userForm) => {
  let imageData = '';
  if (userForm.avatar) {
    imageData = await uploadFile(userForm.avatar, 'avatar');
  }
  const res = await axiosInstance
    .post('/user/create', {
      ...userForm,
      avatar: imageData,
    })
    .catch((err) => {
      console.log('createOneUser', err);
    });
  return res;
};

export const deleteOneUser = async (id) => {
  const res = await axiosInstance.put(`/user/delete/${id}`).catch((err) => {
    console.log('deleteOneUser', err);
  });
  return res;
};

export const toggleActiveOneUser = async (id, activeVal) => {
  const res = await axiosInstance
    .put(`/user/${id}`, { active: activeVal })
    .catch((err) => {
      console.log('toggleActiveOneUser', err);
    });
  return res;
};

export const getUserById = async (userId) => {
  const res = await axiosInstance.get(`/user/by-id/${userId}`).catch((err) => {
    console.log('getUserById', err);
  });
  return res;
};

export const updateUserInfos = async (userForm, userId) => {
  let imageData = userForm?.avatar;
  if (userForm?.avatar?.name) {
    imageData = await uploadFile(userForm?.avatar, 'avatar');
  }
  const data = {
    avatar: imageData,
    firstname: userForm.firstname,
    lastname: userForm.lastname,
    email: userForm.email,
    phone: userForm.phone,
    role: userForm.role,
  };
  const res = await axiosInstance.put(`/user/${userId}`, data).catch((err) => {
    console.log('updateUserInfos', err);
  });
  return res;
};

export const updateUserPassword = async (userId, password) => {
  const res = await axiosInstance
    .put(`/user/password/${userId}`, {
      password,
    })
    .catch((err) => {
      console.log('updateUserPassword', err);
    });
  return res;
};

export const logOutUser = async (userId) => {
  const res = await axiosInstance
    .post(`/user/logout/${userId}`)
    .catch((err) => {
      console.log('logOutUser', err);
    });
  return res;
};
