import React from 'react';

export const LogOutIcon = ({ color = '#F90716' }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.9999 4.009C12.9999 3.74379 12.8946 3.48943 12.7071 3.3019C12.5195 3.11436 12.2652 3.009 11.9999 3.009C11.7347 3.009 11.4804 3.11436 11.2928 3.3019C11.1053 3.48943 10.9999 3.74379 10.9999 4.009L10.9969 12.012C10.9969 12.2772 11.1023 12.5316 11.2898 12.7191C11.4774 12.9066 11.7317 13.012 11.9969 13.012C12.2622 13.012 12.5165 12.9066 12.7041 12.7191C12.8916 12.5316 12.9969 12.2772 12.9969 12.012L12.9999 4.01V4.009Z'
        fill={color}
      />
      <path
        d='M4 12.992C4 10.782 4.895 8.78201 6.343 7.33501L7.757 8.74901C6.91785 9.58811 6.34636 10.6572 6.11482 11.8211C5.88327 12.985 6.00207 14.1914 6.45618 15.2878C6.9103 16.3842 7.67933 17.3213 8.66604 17.9806C9.65274 18.6399 10.8128 18.9918 11.9995 18.9918C13.1862 18.9918 14.3463 18.6399 15.333 17.9806C16.3197 17.3213 17.0887 16.3842 17.5428 15.2878C17.9969 14.1914 18.1157 12.985 17.8842 11.8211C17.6526 10.6572 17.0812 9.58811 16.242 8.74901L17.657 7.33501C18.5872 8.26529 19.2737 9.41063 19.6556 10.6696C20.0374 11.9285 20.1029 13.2622 19.8463 14.5525C19.5896 15.8428 19.0187 17.0499 18.1841 18.0669C17.3495 19.0838 16.2769 19.8793 15.0615 20.3827C13.8461 20.8862 12.5252 21.0821 11.216 20.9532C9.90671 20.8243 8.64946 20.3744 7.55558 19.6436C6.46169 18.9127 5.56493 17.9233 4.94474 16.7631C4.32454 15.6029 4.00005 14.3076 4 12.992V12.992Z'
        fill={color}
      />
    </svg>
  );
};
