import _ from 'lodash';

export const randomHelper = (array) => {
  return Math.floor(Math.random() * array?.length);
};

export const helperSelectRandomElement = (array, all, prevAll = []) => {
  // Récupérer tous les éléments du tableau qui ne sont pas encore présents dans la liste "all"
  let element = null;
  const lastElement = all[all.length - 1];
  const availableElements = array.filter(
    (element) => !all.includes(element?._id || element?.value)
  );

  do {
    if (availableElements.length > 0) {
      element = _.sample(availableElements);
    } else {
      element = _.sample(array);
    }
  } while (
    array.length > 1 &&
    element &&
    ((lastElement &&
      (lastElement === element?._id || lastElement === element?.value)) ||
      (prevAll.length > 0 &&
        (prevAll[element.strategyIndex] === element?.value ||
          prevAll[element.strategyIndex] === element?._id)))
  );

  // console.log('👉 👉 👉  ~ file: randomHelper.js:8 ~ all', all, element);

  // S'il y a des éléments disponibles, en sélectionner un aléatoirement

  return element;
};
