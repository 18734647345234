import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { familyQuizTabAction, quizAction } from '../../../redux/familyQuiz';

export const LeftBlockHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const familyQuizTab = useAppSelector((s) => s.familyQuiz.familyQuizTab);
  const quiz = useAppSelector((s) => s.familyQuiz.quiz);
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const saveNewQuestionInStore = () => {
    const newQuestionsList = quiz.questionsList.map((_question) => {
      if (questionSelected._id === _question._id) {
        return questionSelected;
      }
      return _question;
    });
    dispatch(
      quizAction({
        ...quiz,
        questionsList: newQuestionsList,
      })
    );
  };

  return (
    <div className='familyQuiz--leftBlock-header'>
      <button
        className={
          familyQuizTab === 'question'
            ? 'familyQuiz--leftBlock-headerBtnActive'
            : ''
        }
        onClick={() => {
          dispatch(familyQuizTabAction('question'));
          saveNewQuestionInStore();
        }}
      >
        Question
      </button>
      <button
        className={
          familyQuizTab === 'logic'
            ? 'familyQuiz--leftBlock-headerBtnActive'
            : ''
        }
        onClick={() => {
          dispatch(familyQuizTabAction('logic'));
          saveNewQuestionInStore();
        }}
      >
        Logique
      </button>
    </div>
  );
};
