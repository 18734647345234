import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createOneFilter, updateOneFilter } from '../../../api/filters.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { filtersModalAction } from '../../../redux/modals';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

//TODO Rendre autonome et vider les props
export const FiltersModal = ({ bgWidth = '80%' }) => {
  const filterSelected = useSelector((state) => state.filtersSelected.filter);
  const [filter, setFilter] = useState(filterSelected?.label || '');

  const dispatch = useDispatch();

  const { resetFilters } = useResetFilters();

  const handleClose = () => {
    dispatch(resetFilteredSelectedItemAction('filter'));
    dispatch(filtersModalAction(false));
  };

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      if (filterSelected) {
        await updateOneFilter(filterSelected?._id, filter);
        toast_loader(loader, 'Filtre modifié !', 'success');
      } else {
        await createOneFilter(filter);
        toast_loader(loader, 'Filtre ajouté !', 'success');
      }

      resetFilters('filters');
      handleClose();
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
    }
  };
  return (
    <Modal
      bgWidth={bgWidth}
      zIndex={700}
      onClose={handleClose}
      title='Filtre'
      onValidate={handleSubmit}
    >
      <Input
        label='Nom'
        type='text'
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
    </Modal>
  );
};
