export const SwitchButton = ({
  checked = false,
  onChange,
  uncheckedBg = '#ccc',
  checkedBg = '#48C36F',
  disabled = false,
}) => {
  //

  return (
    <label className='switch'>
      <input
        type='checkbox'
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
        disabled={disabled}
      />
      <span
        className='switch-slider'
        style={{
          backgroundColor: checked ? checkedBg : uncheckedBg,
          cursor: disabled ? 'default' : 'pointer',
        }}
      />
    </label>
  );
};
