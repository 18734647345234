import { colors } from '../../../assets/constants';
import { ArrowSmallBottom } from '../../../assets/svgs/ArrowSmallBottom';
import { ArrowSmallTop } from '../../../assets/svgs/ArrowSmallTop';
import { helperFilterParentIsSelected } from '../../../assets/utils/funcsUtils';
import { sortByProperty } from '../../../assets/utils/sortedList';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { filtersParentSelectedAction } from '../../../redux/filters';
import { Counters } from '../../atoms/Counter';
import { ItemButton } from './ItemButton';

export const FilterAccordion = ({
  setOpen,
  open,
  data,
  parentKey,
  itemsKey,
}: {
  setOpen: (arg0: any) => void,
  open: boolean,
  data: any,
  parentKey?: string,
  itemsKey?: string,
}) => {
  const dispatch = useAppDispatch();

  const selectedData = useAppSelector((s) => s.filters[parentKey]);

  const items = data[itemsKey] || [];

  const itemActive = selectedData.find((item) => data._id === item._id);

  const parentActive =
    helperFilterParentIsSelected({
      dataActive: itemActive,
      itemsKey,
      items,
    }) ||
    (itemActive && items.length === 0);

  const handleSelectParent = () => {
    dispatch(
      filtersParentSelectedAction({
        key: parentKey,
        value: {
          ...data,
          [itemsKey]: data[itemsKey]?.map((item) => ({
            ...item,
            selected: true,
          })),
        },
        addSelected: true,
        itemsLength: data[itemsKey]?.length || 0,
        selected: parentActive,
      })
    );
  };

  const onOpenAccordion = () => {
    if (open === data?.label) return setOpen(null);
    setOpen(data?.label);
  };

  return (
    <div
      className='accordionFilters'
      style={{ width: '100%', margin: '0 0 .3rem', padding: '.5rem 1.3rem ' }}
    >
      <div className='accordionFilters--btn'>
        {/* <span
          className='accordionFilters--check'
          style={{
            backgroundColor: parentActive ? colors.yellow : 'transparent',
          }}
          onClick={handleSelectParent}
        /> */}
        <button className='accordionFilters--title' onClick={onOpenAccordion}>
          {data?.label + ' '}
          <Counters
            parentId={parentKey}
            childrenId={itemsKey}
            item={data}
            isChild
          />
          {itemActive &&
            itemActive[itemsKey] &&
            itemActive[itemsKey].length > 0 && (
              <span className='accordion--badge' />
            )}
          {items.length > 0 && open === data?.label ? (
            <ArrowSmallTop />
          ) : (
            items.length > 0 && open !== data?.label && <ArrowSmallBottom />
          )}
        </button>
      </div>

      {!!items?.length && data?.label === open && (
        <div
          className={`accordionFilters--content`}
          style={{
            maxHeight: !!items?.length && data?.label === open ? 9999 : 0,
            transition:
              !!items?.length && data?.label === open
                ? 'all 0.5s cubic-bezier(1, 0, 1, 0) '
                : 'all 0.5s cubic-bezier(0, 1, 0, 1)',
          }}
        >
          {items
            ?.sort((a, b) => sortByProperty(a, b, 'name'))
            ?.map((itemChild, index) => {
              return (
                <ItemButton
                  key={index}
                  item={itemChild}
                  itemsSelected={itemActive && itemActive[itemsKey]}
                  parent={data}
                  parentKey={parentKey}
                  itemsKey={itemsKey}
                />
              );
            })}
          <button
            className='accordionFilters--close'
            onClick={() => setOpen(null)}
          >
            <ArrowSmallTop margin='0' color={colors.yellow} />
            <span>Fermer {data?.label}</span>
            <ArrowSmallTop margin='0' color={colors.yellow} />
          </button>
        </div>
      )}
    </div>
  );
};
