import React from 'react';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { QuestionCard } from './QuestionCard';

export const QuestionsBlock: React.FC = () => {
  //
  const quiz = useAppSelector((s) => s.familyQuiz.quiz);
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  return (
    <div className='productCompositions--questionsBlock'>
      <p className='productCompositions--questionsBlock--title'>
        Questions “{quiz?.familyId?.label}”
      </p>
      <div className='productCompositions--questionsBlock--questionsList'>
        {quiz?.questionsList?.map((question, index) => {
          const isAnswered =
            question?.answered && question?._id !== questionSelected?._id;
          return (
            <QuestionCard
              key={index}
              question={question}
              isAnswered={isAnswered}
            />
          );
        })}
      </div>
    </div>
  );
};
