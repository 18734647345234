import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createOneMood, updateOneMood } from '../../../api/moods.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { moodsModalAction } from '../../../redux/modals';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';
import { UploadImg } from '../../atoms/UploadImg';

export const MoodsModal = () => {
  const dispatch = useDispatch();
  const moodSelected = useAppSelector((state) => state.filtersSelected.moods);

  const [mood, setMood] = useState({
    label: moodSelected?.label || '',
    image: moodSelected?.image || null,
  });
  const [loading, setLoading] = useState(false);

  const { resetFilters } = useResetFilters();

  const onClose = () => {
    dispatch(moodsModalAction(false));
    dispatch(resetFilteredSelectedItemAction('moods'));
  };

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      if (!mood.label)
        return toast_loader(loader, 'Veuillez renseigner un nom', 'error');

      if (!mood.image)
        return toast_loader(loader, 'Veuillez renseigner une image', 'error');

      setLoading(true);
      if (moodSelected) {
        await updateOneMood(moodSelected?._id, mood);
        toast_loader(loader, 'Mood modifié', 'success');
      } else {
        await createOneMood(mood);
        toast_loader(loader, 'Mood créé', 'success');
      }
      resetFilters('moods');
      onClose();
      setLoading(false);
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite !", 'error');
    }
  };

  return (
    <Modal
      onClose={onClose}
      title='Moods'
      onValidate={handleSubmit}
      loading={loading}
    >
      <UploadImg
        image={mood.image}
        onChange={(file) => setMood((s) => ({ ...s, image: file }))}
        margin='0 auto 0 0'
      />
      <Input
        label='Nom'
        type='text'
        onChange={(e) => setMood((s) => ({ ...s, label: e.target.value }))}
        value={mood.label}
      />
    </Modal>
  );
};
