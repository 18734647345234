export const helperFilterIngForCompose = (
  comp: any,
  compositionSelected: any
) => {
  let compIngredients = comp?.ingredients?.reduce((acc, ingr: any) => {
    const questionId = ingr?.questionId?._id || ingr?.questionId;
    const findQuestion = acc.find(
      (_q) =>
        _q?.questionId?._id === questionId || _q?.questionId === questionId
    );

    if (!!findQuestion) {
      findQuestion.ingredients.push(ingr);
    } else {
      acc.push({
        questionId,
        ingredients: [ingr],
      });
    }

    return acc;
  }, []);

  let selectedIngredients = compositionSelected?.ingredients?.reduce(
    (acc, ingr: any) => {
      const questionId = ingr?.questionId?._id || ingr?.questionId;
      const findQuestion = acc.find(
        (_q) =>
          _q?.questionId?._id === questionId || _q?.questionId === questionId
      );

      if (!!findQuestion) {
        findQuestion.ingredients.push(ingr);
      } else {
        acc.push({
          questionId,
          ingredients: [ingr],
        });
      }

      return acc;
    },
    []
  );

  return (
    selectedIngredients?.length > 0 &&
    compIngredients?.every((_compIngr) => {
      const cmpIngr = selectedIngredients.find(
        (selIngr) =>
          (selIngr?.questionId?._id || selIngr?.questionId) ===
          (_compIngr?.questionId?._id || _compIngr?.questionId)
      );

      return _compIngr?.ingredients?.every((ingr) => {
        return !!cmpIngr?.ingredients.find((compIngr) => {
          return (
            (compIngr.specialityId?._id || compIngr.specialityId) ===
            (ingr.specialityId?._id || ingr.specialityId)
          );
        });
      });
    })
  );
};
