import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { createOneLog } from '../../../api/logs.api';
import { deleteOnePicture } from '../../../api/picture.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useResetFilters } from '../../../hooks/useResetFilters';
import {
  deleteImageModalAction,
  editImgMiniModalAction,
  editImgModalAction,
} from '../../../redux/modals';
import { Modal } from '../../atoms/Modal';
import { useQueryClient } from 'react-query';

export const ConfirmDeleteImageModal = () => {
  const dispatch = useDispatch();
  const pictureSelected = useSelector((s) => s.images);
  const { userData } = useSelector((s) => s.app);
  const queryClient = useQueryClient();
  const { resetFilters } = useResetFilters();

  const onDeleteImage = async () => {
    const loader = toast.loading('Suppression en cours...');
    const logBody = {
      userId: userData?._id,
      pictureId: pictureSelected?._id,
      action: `Suppression de l'image ${pictureSelected?.name}`,
      from: 'image',
      createdAt: new Date(),
    };
    const { success } = await deleteOnePicture(pictureSelected?._id);

    if (success) {
      resetFilters('pictures');
      toast_loader(loader, 'Image supprimé !', 'success');
      queryClient.invalidateQueries('pictures-infinite');
      dispatch(editImgModalAction(false));
      dispatch(deleteImageModalAction(false));
      dispatch(editImgMiniModalAction(false));
      createOneLog(logBody);
    } else {
      toast_loader(loader, "Une erreur s'est produite !", 'error');
    }
  };
  return (
    <Modal
      onClose={() => dispatch(deleteImageModalAction(false))}
      title='Attention'
      onValidate={onDeleteImage}
      bgWidth='100%'
      left='30%'
      zIndex={600}
    >
      <p>Etes-vous sûr de supprimer l'image !</p>
    </Modal>
  );
};
