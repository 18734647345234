import React from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPicturesByGroupId } from '../../../api/picture.api';
import { PlusIcon } from '../../../assets/svgs/PlusIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  deleteImageModalAction,
  editImgMiniModalAction,
  editImgModalAction,
  identicalImageModalAction,
  similarsImagesModalAction,
} from '../../../redux/modals';
import { CollapseItemImgFilter } from '../../../views/online/CustomersView/CollapseItemImgFilter';
import { CollapseItemImgSpecialsFilters } from '../../../views/online/CustomersView/CollapseItemImgSpecialsFilters';
import { Button } from '../../atoms/Buttons';
import { MoodsList } from '../../atoms/MoodsList';
import DownloadImgs from './DownloadImgs';
import { ImageInfosProducts } from './ImageProducts';
import { ImageRender } from './ImageRender';
import { CustomersAttribution } from './CustomersAttribution';
import { Punchlines } from './Punchlines';
import { setIdenticalImageAction } from '../../../redux/images/imageIdentical';
import { CollapseItemImgGroups } from '../../../views/online/CustomersView/CollapseItemImgGroups';
import { useNavigate } from 'react-router-dom';
import { initImagesAction } from '../../../redux/images';
import { updateImageAction } from '../../../redux/imageAttributions';

export const ImageInformations = ({
  miniModal = false,
  edit = false,
  isClone = false,
}) => {
  //

  const dispatch = useDispatch();
  const imagesSelected = useAppSelector((s) => s.images);
  const role = useSelector((s) => s.app.role);
  const navigate = useNavigate();

  const handleOpenSimilarImagesModal = () => {
    dispatch(similarsImagesModalAction(true));
    dispatch(editImgModalAction(false));
    dispatch(editImgMiniModalAction(false));
  };

  const handleCloneImage = () => {
    navigate('/images-list?clone=true');
    dispatch(editImgModalAction(false));
  };

  const handleRedirectToClone = () => {
    const newImagesSelected = { ...imagesSelected };
    newImagesSelected.image = null;
    navigate('/images-list?clone=true');
    dispatch(initImagesAction(newImagesSelected));
    dispatch(editImgModalAction(true));
    dispatch(updateImageAction(true));
    dispatch(editImgMiniModalAction(false));
  };

  return (
    <>
      {window.location.hash === '#/add-image' && (
        <Button
          margin='0 auto'
          label={`Cloner l'image`}
          onClick={handleCloneImage}
          variant='black'
        />
      )}
      <DownloadImgs edit={edit} isClone={isClone} miniModal={miniModal} />
      {!miniModal ? (
        <>
          {!['COMMERCIAL', 'PUNCHLINER'].includes(role) && (
            <>
              <MoodsList />
              <CollapseItemImgFilter />
              <CollapseItemImgSpecialsFilters />
              <CustomersAttribution />
              <CollapseItemImgGroups />
            </>
          )}
        </>
      ) : null}

      {role !== 'COMMERCIAL' && <Punchlines />}

      {!miniModal ? (
        <>
          {!['COMMERCIAL', 'PUNCHLINER'].includes(role) && (
            <ImageInfosProducts />
          )}
        </>
      ) : null}

      <>
        {edit && !['COMMERCIAL', 'PUNCHLINER'].includes(role) && (
          <>
            <PictureListLayout
              label='IMAGES IDENTIQUE'
              type='identique'
              onClick={() => {
                dispatch(identicalImageModalAction(true));
                dispatch(
                  setIdenticalImageAction({
                    key: 'isImageLocal',
                    value: true,
                  })
                );
              }}
            />
            <PictureListLayout
              label='IMAGES SIMILAIRES'
              type='similars'
              onClick={handleOpenSimilarImagesModal}
            />
          </>
        )}
      </>

      <div className='image--actionBtn'>
        <div></div>
        <div className='image--actionBtn-item'>
          {miniModal && (role === 'ADMIN' || role === 'SUPER-ADMIN') && (
            <Button
              label="Clone l'image"
              margin='1rem 0 1rem auto'
              onClick={handleRedirectToClone}
            />
          )}
          {edit && (role === 'ADMIN' || role === 'SUPER-ADMIN') && (
            <Button
              label="Supprimer l'image"
              variant='red'
              margin='1rem 0 1rem auto'
              onClick={() => dispatch(deleteImageModalAction(true))}
            />
          )}
        </div>
      </div>
    </>
  );
};

const PictureListLayout = ({ label, type, onClick }) => {
  const dispatch = useAppDispatch();
  const role = useSelector((s) => s.app.role);
  const images = useAppSelector((s) => s.images);

  const { data: imagesSimilars } = useQuery(
    ['imagesSimilars', images?.groupId],
    () => fetchPicturesByGroupId(images?.groupId),
    {
      enabled: !!images?.groupId && type === 'similars',
    }
  );

  const onSelectImgIdentical = (pic) => {
    dispatch(identicalImageModalAction(true));
    dispatch(setIdenticalImageAction({ key: 'isImageLocal', value: true }));
    dispatch(setIdenticalImageAction({ key: 'image', value: pic?.image }));
    dispatch(setIdenticalImageAction({ key: '_id', value: pic?._id }));
    dispatch(setIdenticalImageAction({ key: 'moods', value: pic?.moods }));
  };

  const samesPictures = (
    images?.parentId?.samePictures ?? images?.samePictures
  )?.filter((img) => img._id !== images?._id);
  return (
    <>
      <p className='image--sectionTitle'>{label}</p>
      <div
        className='flex-wrap'
        style={{
          flexDirection: type === 'similars' ? 'row' : 'row-reverse',
          justifyContent: 'flex-start',
          marginBottom: '1rem',
        }}
      >
        {/* Mapper les images avec ce component */}
        <div
          style={{
            flexDirection: type === 'similars' ? 'row' : 'row-reverse',
            justifyContent: 'flex-start',
            marginBottom: '1rem',
            overflowX:
              (type !== 'similars' && samesPictures?.length > 0) ||
              (type === 'similars' && imagesSimilars?.data?.length > 0)
                ? 'scroll'
                : 'hidden',
            width:
              (type !== 'similars' && samesPictures?.length > 0) ||
              (type === 'similars' && imagesSimilars?.data?.length > 0)
                ? '730px'
                : 'auto',
            display: 'flex',
          }}
        >
          {type !== 'similars' &&
            samesPictures?.length > 0 &&
            samesPictures.map((img) => (
              <ImageRender
                size={{ width: 93, height: 83 }}
                pic={img}
                key={img._id}
                small
                onSelectPictureIdentical={() => onSelectImgIdentical(img)}
                isIdenticalImg
              />
            ))}
        </div>

        {type === 'similars' &&
          imagesSimilars?.data?.length > 0 &&
          imagesSimilars?.data
            .filter((img) => img._id !== images._id)
            .slice(0, 10)
            .map((img) => (
              <ImageRender
                size={{ width: 90, height: 83 }}
                pic={img}
                key={img._id}
                small
              />
            ))}

        <button
          className='image--btnPlus'
          onClick={onClick}
          disabled={
            type !== 'similars' && !['ADMIN', 'SUPER-ADMIN'].includes(role)
          }
        >
          {type === 'similars' ? 'Voir plus' : <PlusIcon />}
        </button>
      </div>
    </>
  );
};
