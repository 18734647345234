import React from 'react';
import { colors } from '../../../assets/constants';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  IIngredient,
  ISpeciality,
  questionSelectedAction,
} from '../../../redux/familyQuiz';
import { ChevronBottomIcon } from '../../../assets/svgs/ChevronBottomIcon';

export const Ingredient: React.FC<{
  ingredient: IIngredient;
  open: string | null;
  onOpen: (id: string) => void;
}> = ({ ingredient, open, onOpen }) => {
  //
  const dispatch = useAppDispatch();
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const findAnswer = questionSelected?.answers?.find(
    (_answer) =>
      _answer.ingredient_id === ingredient?._id ||
      _answer.ingredient_id?._id === ingredient?._id
  );

  const onSelectSpeciality = (
    e: React.MouseEvent<HTMLButtonElement>,
    speciality: ISpeciality
  ) => {
    e.stopPropagation();
    if (!!findAnswer) {
      if (
        findAnswer?.values?.find(
          (_value) => _value?.label === speciality?.label
        )
      ) {
        const newValues = findAnswer?.values.filter(
          (_value) => _value?.label !== speciality?.label
        );
        const newAnswer = { ...findAnswer, values: newValues };
        const newAnswers =
          newValues.length === 0
            ? questionSelected?.answers?.filter(
                (_answer) =>
                  _answer?.ingredient_id !== findAnswer?.ingredient_id
              )
            : questionSelected?.answers?.map((_answer) => {
                if (_answer?.ingredient_id === findAnswer?.ingredient_id)
                  return newAnswer;
                return _answer;
              });
        dispatch(
          questionSelectedAction({ ...questionSelected, answers: newAnswers })
        );
      } else {
        const newAnswer = {
          ...findAnswer,
          values: [...findAnswer?.values, speciality],
        };
        const newAnswers = questionSelected?.answers?.map((_answer) => {
          if (_answer?.ingredient_id === findAnswer?.ingredient_id)
            return newAnswer;
          return _answer;
        });
        dispatch(
          questionSelectedAction({ ...questionSelected, answers: newAnswers })
        );
      }
    } else {
      dispatch(
        questionSelectedAction({
          ...questionSelected,
          answers: [
            ...questionSelected?.answers,
            {
              ingredient_id: ingredient?._id,
              values: [speciality],
            },
          ],
        })
      );
    }
  };

  const onSelectAnswer = (
    ingredientId: string,
    ingredientSpecialities: any
  ) => {
    const selectedAnswer = {
      ingredient_id: ingredientId,
      values: ingredientSpecialities,
    };

    const updatedAnswers = [...questionSelected.answers];

    const existingAnswerIndex = updatedAnswers.findIndex(
      (answer) =>
        answer.ingredient_id === ingredientId ||
        answer?.ingredient_id?._id === ingredientId
    );

    if (existingAnswerIndex !== -1) {
      updatedAnswers.splice(existingAnswerIndex, 1);
    } else {
      updatedAnswers.push(selectedAnswer);
    }

    const updatedQuestionSelected = {
      ...questionSelected,
      answers: updatedAnswers,
    };

    dispatch(questionSelectedAction(updatedQuestionSelected));
  };

  return (
    <div
      className='familyQuiz--questionBlock-ingredient'
      style={{
        borderColor: open === ingredient?._id ? colors.yellow : colors.grey,
      }}
    >
      <span
        onClick={() => onSelectAnswer(ingredient?._id, ingredient?.specialties)}
        className={`familyQuiz--questionBlock-ingredient-badge ${
          !!findAnswer && findAnswer?.values?.length > 0
            ? 'familyQuiz--questionBlock-ingredient-selected'
            : ''
        }`}
      />
      <button
        onClick={() => onOpen(ingredient?._id)}
        className='familyQuiz--questionBlock-ingredient-openButton'
      >
        <ChevronBottomIcon width={'12'} />
      </button>
      <div className='familyQuiz--questionBlock-ingredient-infos'>
        <p className='familyQuiz--questionBlock-ingredient-label'>
          {`${ingredient.label} (${findAnswer?.values?.length || 0}/${
            ingredient?.specialties?.length || 0
          })`}
        </p>
        <div className='familyQuiz--questionBlock-ingredient-image'>
          {ingredient?.image?.length > 0 && (
            <img
              src={helperImageRoute(ingredient?.image, 'picture')}
              loading='lazy'
              alt={ingredient.label}
            />
          )}
        </div>
      </div>
      {open === ingredient?._id && (
        <div className='familyQuiz--questionBlock-specialities'>
          {ingredient?.specialties?.map(
            (_speciality: ISpeciality, index: number) => {
              const selected = findAnswer?.values?.find(
                (_s: ISpeciality) => _s?.label === _speciality?.label
              );

              console.log(
                '👉 👉 👉  ~ file: Ingredient.tsx:119 ~ findAnswer:',
                findAnswer
              );

              return (
                <Speciality
                  key={index}
                  selected={!!selected}
                  speciality={_speciality}
                  onSelectSpeciality={onSelectSpeciality}
                />
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

const Speciality: React.FC<{
  selected: boolean;
  speciality: ISpeciality;
  onSelectSpeciality: (
    e: React.MouseEvent<HTMLButtonElement>,
    speciality: ISpeciality
  ) => void;
}> = ({ selected, speciality, onSelectSpeciality }) => {
  return (
    <button
      className='familyQuiz--questionBlock-speciality'
      onClick={(e) => onSelectSpeciality(e, speciality)}
    >
      {speciality?.image?.length > 0 && (
        <img
          src={helperImageRoute(speciality?.image, 'picture')}
          loading='lazy'
          alt={speciality.label}
        />
      )}
      <span className='familyQuiz--questionBlock-speciality-label'>
        {speciality?.label}
      </span>
      <span
        className={`familyQuiz--questionBlock-speciality-badge ${
          selected ? 'familyQuiz--questionBlock-speciality-selected' : ''
        }`}
      />
    </button>
  );
};
