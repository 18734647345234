import { AddImgIcon } from '../../../assets/svgs/AddImgIcon';
import { ChevronLeftIcon } from '../../../assets/svgs/ChevronLeftIcon';
import { ChevronRightIcon } from '../../../assets/svgs/ChevronRightIcon';
import { colors } from '../../../assets/constants';
import { truncateText } from '../../../assets/utils/truncateText';
import { CheckIcon } from '../../../assets/svgs/CheckIcon';
import { DownloadIcon } from '../../../assets/svgs/DownloadIcon';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { SearchCircleIcon } from '../../../assets/svgs/SearchBlackIcon';
import { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { viewImageModalAction } from '../../../redux/modals';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  getPictureDisplayAction,
  pictureDisplayWaterMarkedAction,
} from '../../../redux/picture';
import { useDropFile } from '../../../hooks/useDropFile';
import { ButtonRadio } from '../Buttons';

const ViewImgUploaded = ({ img, handleDeleteFile, name, folder }) => {
  //
  const [loaded, setLoaded] = useState(false);

  const onLoadedImage = () => {
    setLoaded(true);
  };

  return (
    <div className='gallery--imgBlock'>
      <span
        className='gallery--imgBlock-close'
        onClick={() => handleDeleteFile(img, name)}
      >
        X
      </span>
      <img
        src={
          img?.name
            ? URL.createObjectURL(img)
            : helperImageRoute(img, folder, false)
        }
        alt='imgview'
        className='gallery--imgBlock-img'
        onLoad={onLoadedImage}
      />
      {!loaded && (
        <div className='blurhash'>
          <Blurhash
            hash='LKN]Rv%2Tw=w]~RBVZRi};RPxuwH'
            width={190}
            height={165}
            resolutionX={32}
            resolutionY={32}
            punch={1}
          />
        </div>
      )}
      <p>{img?.name ? truncateText(img?.name, 15) : truncateText(img, 15)}</p>
    </div>
  );
};

const Gallery = ({ onChange, imgs = [], handleDeleteFile, name }) => {
  const { handleDragOver, handleDrop } = useDropFile(onChange, {
    type: 'target',
    name,
  });
  return (
    <div className='gallery'>
      <label
        htmlFor={name}
        className='gallery--uploader'
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <input
          type='file'
          id={name}
          name={name}
          accept='image/png, image/jpeg, image/gif'
          multiple
          onChange={onChange}
          hidden
        />
        <p className='gallery--uploader-texts'>Déposer la photo</p>
        <AddImgIcon />
        <p className='gallery--uploader-texts'>
          <span style={{ color: colors.yellow }}>Ou téléchargez</span> en
          cliquant ici <br />
          <span style={{ textTransform: 'uppercase' }}>PNG, JPG, GIF </span>
        </p>
      </label>
      <div className='gallery--carousel'>
        <button
          className='gallery--carousel-btn'
          id='slider'
          //   onClick={onScrollLeft}
        >
          <ChevronLeftIcon />
        </button>
        <div className='gallery--carousel-items'>
          {imgs?.map((img, index) => (
            <ViewImgUploaded
              handleDeleteFile={handleDeleteFile}
              index={index}
              img={img}
              name={name}
              folder={`customer-${name}`}
            />
          ))}
        </div>
        <button
          className='gallery--carousel-btn'
          id='slider'
          //   onClick={onScrollRight}
        >
          <ChevronRightIcon />
        </button>
      </div>
    </div>
  );
};

const ImgDownload = ({
  onChange,
  image,
  onRemoveImg,
  watermarked,
  onLogAction,
  labelId,
  watermark,
  setWatermark,
  edit,
  isClone,
  miniModal,
}) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((s) => s.app?.role);
  const { handleDragOver, handleDrop } = useDropFile(onChange);

  const onDisplayImg = (e) => {
    e.stopPropagation();
    dispatch(getPictureDisplayAction({ picture: image, folder: 'picture' }));
    dispatch(pictureDisplayWaterMarkedAction(watermarked));
    dispatch(viewImageModalAction(true));
  };

  return (
    <div className={`imgDownload ${miniModal ? 'imgDownloadMini' : ''}`}>
      {/* <p>{label}</p> */}
      <div
        className='imgDownload--imgBlock'
        onDrop={(e) => handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
      >
        {role?.includes('ADMIN') && (
          <input
            type='file'
            name='image'
            id='image'
            accept='image/png, image/jpeg, image/gif'
            multiple
            onChange={onChange}
            hidden
          />
        )}
        {image && !['COMMERCIAL', 'PUNCHLINER'].includes(role) && (
          <button
            className='imgDownload--imgBlock-close'
            onClick={() => onRemoveImg()}
          >
            X
          </button>
        )}

        <label
          htmlFor='image'
          className='imgDownload--imgBlock-label'
          style={{ cursor: 'pointer' }}
        >
          {image && (
            <>
              <img
                src={
                  image?.name
                    ? URL.createObjectURL(image)
                    : helperImageRoute(image, 'picture', true, watermarked)
                }
                className='imgDownload--imgBlock-img'
                alt={image?.name || image}
              />
              {typeof image === 'string' && image.length > 0 && (
                <button
                  onClick={onDisplayImg}
                  className='imgDownload--imgBlock-icon'
                >
                  <SearchCircleIcon />
                </button>
              )}
            </>
          )}
          {!image && (
            <div className='imgDownload--imgBlock-btn'>
              <DownloadIcon />
            </div>
          )}
        </label>
      </div>

      <div className='imgDownload--imgBlock-btnAction'>
        {image?.name && <CheckIcon />}
        {typeof image === 'string' &&
          image.length > 0 &&
          role !== 'PUNCHLINER' && (
            <a
              className='imgDownload--imgBlock-btnDownload'
              href={helperImageRoute(image, 'picture', false, watermarked)}
              download
              onClick={() => onLogAction(watermarked)}
            >
              <DownloadIcon />
            </a>
          )}

        {['ADMIN', 'SUPER-ADMIN'].includes(role) && edit && !isClone && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '1rem 0',
            }}
          >
            <ButtonRadio
              label='Avec filigrane'
              onClick={() => setWatermark(true)}
              active={watermark === true}
            />
            <ButtonRadio
              label='Sans filigrane'
              onClick={() => setWatermark(false)}
              active={watermark === false}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const IdenticalImgDownload = ({
  onChange,
  image,
  onRemoveImg,
  watermarked,
  onLogAction,
  labelId,
}) => {
  const dispatch = useAppDispatch();
  const role = useAppSelector((s) => s.app?.role);
  const { handleDragOver, handleDrop } = useDropFile(onChange);

  const onDisplayImg = () => {
    dispatch(getPictureDisplayAction({ picture: image, folder: 'picture' }));
    dispatch(pictureDisplayWaterMarkedAction(watermarked));
    dispatch(viewImageModalAction(true));
  };

  return (
    <div className='imgDownload'>
      <div
        className='imgDownload--imgBlock'
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {role?.includes('ADMIN') && (
          <input
            type='file'
            name='identical_image'
            id='identical_image'
            accept='image/png, image/jpeg, image/gif'
            multiple
            onChange={onChange}
            hidden
          />
        )}
        {image && !['COMMERCIAL', 'PUNCHLINER'].includes(role) && (
          <button
            className='imgDownload--imgBlock-close'
            onClick={() => onRemoveImg()}
          >
            X
          </button>
        )}

        <label
          htmlFor='identical_image'
          className='imgDownload--imgBlock-label'
          style={{ cursor: 'pointer' }}
        >
          {image && (
            <>
              <img
                src={
                  image?.name
                    ? URL.createObjectURL(image)
                    : helperImageRoute(image, 'picture', false, watermarked)
                }
                className='imgDownload--imgBlock-img'
                alt={image?.name || image}
              />
              {typeof image === 'string' && image.length > 0 && (
                <button
                  onClick={onDisplayImg}
                  className='imgDownload--imgBlock-icon'
                >
                  <SearchCircleIcon />
                </button>
              )}
            </>
          )}
          {!image && (
            <div className='imgDownload--imgBlock-btn'>
              <DownloadIcon />
            </div>
          )}
        </label>
      </div>
      {image?.name && <CheckIcon />}
      {typeof image === 'string' &&
        image.length > 0 &&
        role !== 'PUNCHLINER' && (
          <a
            className='imgDownload--imgBlock-btnDownload'
            href={helperImageRoute(image, 'picture', false, watermarked)}
            download
            onClick={() => onLogAction(watermarked)}
          >
            <DownloadIcon />
          </a>
        )}
    </div>
  );
};

export { Gallery, ImgDownload, IdenticalImgDownload };
