import { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchFilters } from '../../../api/filters.api';
import { colors } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { ButtonAdd } from '../../../components/atoms/Buttons';
import { Collapse } from '../../../components/atoms/Collapse';
import { AccordionImg } from '../../../components/organisms/Images/AccordionImg';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
} from '../../../redux/filtersSelected';
import {
  filtersModalAction,
  filterValuesModalAction,
} from '../../../redux/modals';

export const CollapseItemImgFilter = () => {
  //
  const role = useAppSelector((s) => s.app.role);
  const { data } = useQuery('filters', () => fetchFilters(''));

  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(null);

  const onClickAddNew = () => {
    dispatch(resetFilteredSelectedItemAction('filter'));
    dispatch(filtersModalAction(true));
  };

  const toggleOpen = (index) => {
    if (index === open) return setOpen(null);
    setOpen(index);
  };

  const onClickAddSubItem = (item) => {
    dispatch(resetFilteredSelectedItemAction('filterValue'));
    dispatch(filteredSelectedItemAction({ filter: 'filter', item: item }));
    dispatch(filterValuesModalAction(true));
  };

  const disabled =
    role === 'COMMERCIAL' || role === 'PUNCHLINER' || role === 'PROGRAMMEUR';

  return (
    <Collapse title={'FILTRES'} border margin='0 0 1rem' bg='white'>
      {copyObj(data)
        ?.sort((a, b) => sortedList(a, b, a.label ? 'label' : 'name'))
        ?.map((item, index) => {
          return (
            <AccordionImg
              key={index}
              index={index}
              open={open}
              onOpen={() => toggleOpen(index)}
              disabled={disabled}
              data={item}
              parentKey={'filters'}
              itemsKey={'filterValues'}
              addSubItemBtnLabel={'Ajouter une valeur'}
              onClickAddSubItem={() => onClickAddSubItem(item)}
            />
          );
        })}
      <ButtonAdd
        bg={colors.dark}
        label={'Ajouter un filtre'}
        height={55}
        width='48%'
        disabled={disabled}
        onClick={onClickAddNew}
      />
    </Collapse>
  );
};
