export const ChevronBottomIcon = ({
  onClick = () => {},
  margin,
  color = '#282828',
  width = '15',
}: {
  onClick?: () => void;
  margin?: string;
  color?: string;
  width?: string;
}) => {
  return (
    <svg
      width={width}
      height='9'
      viewBox='0 0 16 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={() => onClick()}
      style={{ cursor: 'pointer', margin }}
    >
      <path
        d='M15 1L8 8L1 1'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
