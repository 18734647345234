import { useQuery } from 'react-query';
import { fetchPicturesByFiltersCounter } from '../api/picture.api';
import { useAppSelector } from './useReduxHook';
import { useMemo } from 'react';
import { useDebounce } from './useDebounce';

export const usePictureCounters = () => {
  const filters = useAppSelector((state) => state.filters);
  const filteredPictures = useAppSelector(
    (state) => state.picture?.filteredPictures
  );
  const customer = useAppSelector((s) => s.customer?.customer);
  const filteredByCustomerUnivers = useAppSelector(
    (s) => s.picture.filteredByCustomerUnivers
  );

  const attributed = useMemo(() => {
    return (
      customer?.subscriptions
        ?.flatMap((subscription) => subscription?.diaries)
        .map((diary) => diary?.picture?._id || diary?.picture)
        .filter((x) => !!x) || []
    );
  }, [customer?.subscriptions]);

  const tabs = filteredPictures?.attributed ? 'attributed' : 'not-attributed';

  const filtersDebounce = useDebounce(filters, 500);

  // const pictures = [];

  const { data: pictures } = useQuery(
    [
      'fetchPicturesByFiltersCounter',
      filtersDebounce,
      filteredByCustomerUnivers,
      customer?.univers,
      filteredPictures?.attributed,
      attributed,
    ],
    ({ signal }) =>
      fetchPicturesByFiltersCounter(
        {
          body: {
            ...filtersDebounce,
            tabs: customer && !filteredByCustomerUnivers ? tabs : '',
            attributed:
              customer && !filteredByCustomerUnivers
                ? tabs === 'not-attributed'
                  ? customer?.allAttributions
                  : attributed
                : false,
            customerId: customer?._id,
          },
          universResto: filteredByCustomerUnivers
            ? {
                ...customer?.univers,
                tabs,
                attributed:
                  tabs === 'not-attributed'
                    ? customer?.allAttributions
                    : attributed,
              }
            : false,
        },
        signal
      )
  );

  return {
    pictures,
  };
};
