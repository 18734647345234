import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  fetchFamilies,
  getAllCompositionsByCustomerId,
  getAllCompositionsByFamilyId,
} from '../api/families.api';
import { useAppSelector } from './useReduxHook';
import { helperFilterIngForCompose } from '../assets/utils/helperFilterIngForCompose';

export const useFamilies = (
  defaultId,
  defaultProductId,
  enabledFetch = true,
  forCompose = false
) => {
  const customer = useAppSelector((state) => state.customer.customer);

  const [familyId, setFamilyId] = useState(defaultId || '');

  const [productId, setProductId] = useState(defaultProductId || '');

  const { data: familiesData, isLoading } = useQuery(
    ['families'],
    () => fetchFamilies(),
    {
      enabled: enabledFetch,
    }
  );

  const { data: allProdsCompsData } = useQuery(
    ['all-compositions-products', familyId],
    () => getAllCompositionsByFamilyId(familyId),
    {
      enabled: !!familyId && forCompose,
      refetchOnWindowFocus: false,
    }
  );

  const { data: allCustomerCompsData } = useQuery(
    ['all-compositions-customer', customer?._id, customer?.univers?.families],
    () => getAllCompositionsByCustomerId(customer?._id),
    {
      enabled: !!customer?._id,
    }
  );

  // find family by id in customer univers
  const familySelected = customer?.univers?.families?.find(
    (_f) => _f?._id === familyId
  );
  // find family by id in all families
  const _familySelected = familiesData?.find((_f) => _f?._id === familyId);

  const productSelected =
    familySelected?.products?.find((product) => product._id === productId) ||
    null;

  const compositionsPrincipal = productSelected?.compositions?.filter(
    (composition) => composition.type === 'principal'
  );

  const compositions =
    (forCompose ? allProdsCompsData?.data : compositionsPrincipal) || [];

  const compositionsForCompose = (allProdsCompsData?.data || [])
    ?.filter((comp) => {
      return helperFilterIngForCompose(comp, familySelected);
    })
    ?.filter(
      (comp) => !compositionsPrincipal?.find((_c) => _c?._id === comp?._id)
    );

  const productsIdsForCompose = compositionsForCompose?.map((composition) => {
    return composition.productId?._id || composition.productId;
  });

  const familyIds = [
    ...new Set(
      allCustomerCompsData?.data?.map((composition) => {
        return composition.familyId?._id || composition.familyId;
      })
    ),
  ];
  const productsIds = [
    ...new Set(
      allCustomerCompsData?.data?.map((composition) => {
        return composition.productId?._id || composition.productId;
      })
    ),
  ];

  const products =
    (forCompose
      ? _familySelected?.products?.filter((product) =>
          productsIdsForCompose?.includes(product._id)
        )
      : familySelected?.products
    )
      ?.map((product) => {
        return {
          name: product.name,
          value: product._id,
        };
      })
      ?.filter((product) => productsIds?.includes(product.value)) || [];

  const ingredients =
    productSelected?.ingredients?.map((ingredient) => {
      return {
        name: ingredient.name,
        value: ingredient._id,
      };
    }) || [];

  const handleSelectFamily = (arg) => {
    if (typeof arg === 'string') {
      setFamilyId(!arg ? null : arg);
    } else {
      setFamilyId(!arg.target.value ? null : arg.target.value);
    }
  };

  const handleSelectProduct = (arg) => {
    if (typeof arg === 'string') {
      setProductId(!arg ? null : arg);
    } else {
      setProductId(!arg.target.value ? null : arg.target.value);
    }
  };

  useEffect(() => {
    if (defaultId) {
      setFamilyId(defaultId);
    } else if (
      (enabledFetch && familiesData) ||
      customer?.univers?.families?.length > 0
    ) {
      setFamilyId((_familyId) =>
        !_familyId
          ? enabledFetch
            ? familiesData?.[0]?._id
            : customer?.univers?.families?.[0]._id
          : _familyId
      );
    } else {
      setFamilyId('');
    }
  }, [familiesData, defaultId, enabledFetch, customer?.univers]);

  useEffect(() => {
    setProductId(defaultProductId);
  }, [defaultProductId]);

  return {
    families: (enabledFetch
      ? familiesData
      : customer?.univers?.families || []
    ).filter((f) => familyIds?.includes(f._id)),
    isLoading,
    products,
    ingredients,
    compositions,
    familyId,
    productId,
    handleSelectProduct,
    handleSelectFamily,
    compositionsForCompose,
  };
};
