import { CloseIcon } from '../../../assets/svgs/CloseIcon';
import { Button } from '../Buttons';

interface ModalProps {
  children: any;
  onClose?: () => void;
  width?: number | string;
  minHeight?: number;
  title?: string;
  onValidate?: () => void;
  hiddenBtns?: boolean;
  loading?: boolean;
  btnValidateLabel?: string;
  bodyPadding?: string;
  left?: string;
  top?: string;
  bgWidth?: string;
  zIndex?: number;
  fixedFooter?: boolean;
  btnWidth?: string;
  justifyContent?: string;
  Icon?: any;
}

export const Modal = ({
  children,
  onClose = () => {},
  width = 500,
  minHeight = 250,
  title,
  onValidate,
  hiddenBtns = false,
  loading = false,
  btnValidateLabel = 'VALIDER',
  bodyPadding = '1rem 0',
  left = '40%',
  top,
  bgWidth,
  zIndex = 500,
  fixedFooter = false,
  btnWidth = '47%',
  justifyContent = 'space-between',
  Icon,
}: ModalProps) => {
  //
  return (
    <>
      <div
        onClick={onClose}
        className='modal--bg'
        style={{ width: bgWidth, zIndex: zIndex - 50 }}
      />
      <div
        className='modal'
        style={{ width, minHeight, left, top, zIndex, justifyContent }}
      >
        <div className='modal--header'>
          <p className='modal--title'>
            {Icon && (
              <span style={{ marginRight: '4px' }}>
                <Icon />
              </span>
            )}
            {title}
          </p>
          <CloseIcon onClick={onClose} />
        </div>
        <div className='modal--content' style={{ padding: bodyPadding }}>
          {children}
        </div>
        {!hiddenBtns && (
          <div
            className='modal--footer'
            style={
              fixedFooter
                ? {
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                  }
                : null
            }
          >
            <Button
              label='ANNULER'
              width={btnWidth}
              margin='0 .5rem'
              onClick={onClose}
            />
            <Button
              label={loading ? `${btnValidateLabel}...` : btnValidateLabel}
              variant='yellow'
              width={btnWidth}
              margin='0 .5rem'
              onClick={onValidate}
              disabled={loading}
            />
          </div>
        )}
      </div>
    </>
  );
};
