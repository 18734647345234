import { useEffect, useState } from 'react';
import { colors } from '../../../assets/constants';
import { ChevronBottomIcon } from '../../../assets/svgs/ChevronBottomIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { helperFilterParentIsSelected } from '../../../assets/utils/funcsUtils';
import { sortedList } from '../../../assets/utils/sortedList';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { filteredSelectedItemAction } from '../../../redux/filtersSelected';
import {
  imageFiltersIngredientSelectedAction,
  imageFiltersParentItemSelectedAction,
  imageFiltersParentSelectedAction,
} from '../../../redux/images';

import { productIngredientsModalAction } from '../../../redux/modals';
import { ButtonAdd } from '../../atoms/Buttons';

export const AccordionImg = ({
  onOpen,
  data,
  index,
  disabled = false,
  open,
  parentKey,
  itemsKey,
  addSubItemBtnLabel,
  onClickAddSubItem,
}: any) => {
  const dispatch = useAppDispatch();
  const selectedData = useAppSelector((s) => s.images[parentKey]);

  const items = data[itemsKey];

  const dataActive = selectedData.find((item) => data._id === item._id);

  const allItemsSelected =
    dataActive &&
    dataActive[itemsKey] &&
    dataActive[itemsKey].length > 0 &&
    dataActive[itemsKey].length === items?.length;

  const hasNoItemSelected =
    !dataActive || !dataActive[itemsKey] || dataActive[itemsKey].length === 0;

  const activeParent =
    helperFilterParentIsSelected({
      dataActive,
      itemsKey,
      items,
    }) ||
    (dataActive && !items?.length && (allItemsSelected || hasNoItemSelected));

  const handleSelectParent = () => {
    if (disabled) return;

    dispatch(
      imageFiltersParentSelectedAction({
        key: parentKey,
        value: data,
        selected: activeParent,
      })
    );
  };

  return (
    <div className='accordion'>
      <div className='accordion--btn' style={{ cursor: 'inherit' }}>
        <span
          onClick={handleSelectParent}
          className='accordion--check'
          style={{
            backgroundColor: activeParent ? colors.yellow : 'transparent',
            cursor: 'pointer',
          }}
        />
        <button
          className='accordion--title'
          onClick={onOpen}
          style={{ cursor: 'pointer' }}
        >
          {data.label}
          {dataActive && dataActive[itemsKey]?.length > 0 && (
            <span className='accordion--badge' />
          )}
          {items && <ChevronBottomIcon margin='0 0 0 auto' />}
        </button>
      </div>

      {items && (
        <div
          className={`accordion--content`}
          style={{
            maxHeight: index === open ? 9999 : 0,
            transition:
              index === open
                ? 'all 0.5s cubic-bezier(1, 0, 1, 0) '
                : 'all 0.5s cubic-bezier(0, 1, 0, 1)',
          }}
        >
          {copyObj(items)
            ?.sort((a, b) => sortedList(a, b, a.label ? 'label' : 'name'))
            ?.map((product) => {
              return (
                <Item
                  key={product._id}
                  item={product}
                  disabled={disabled}
                  itemsSelected={dataActive && dataActive[itemsKey]}
                  parent={data}
                  parentKey={parentKey}
                  itemsKey={itemsKey}
                />
              );
            })}

          <ButtonAdd
            label={addSubItemBtnLabel}
            height={40}
            width='100%'
            margin='.3rem 0'
            bg={colors.dark}
            disabled={disabled}
            onClick={onClickAddSubItem}
          />
        </div>
      )}
    </div>
  );
};

const Item = ({
  item,
  itemsSelected,
  disabled,
  parentKey,
  parent,
  product,
  ingredient,
  itemsKey,
}: any) => {
  const dispatch = useAppDispatch();
  const itemSelected = itemsSelected?.find((el) => el._id === item._id);

  const [open, setOpen] = useState(false);

  const handleSelect = () => {
    if (disabled) return;
    let selected =
      item.ingredients?.length > 0
        ? itemSelected?.ingredients?.length === item.ingredients.length
        : null;
    if (item.ingredients?.length > 0 && !selected) {
      selected = true;
    }
    dispatch(
      imageFiltersParentItemSelectedAction({
        key: parentKey,
        itemsKey,
        value: item,
        parent,
        selected,
      })
    );
  };

  const handleSelectIngredient = () => {
    if (disabled) return;
    dispatch(
      imageFiltersIngredientSelectedAction({
        ingredient: item,
        parent,
        product,
      })
    );
  };

  if (item.ingredients) {
    const onClickAddNewIngredient = () => {
      dispatch(
        filteredSelectedItemAction({
          filter: 'family',
          item: parent,
        })
      );

      dispatch(
        filteredSelectedItemAction({
          filter: 'product',
          item,
        })
      );
      dispatch(productIngredientsModalAction(true));
    };

    return (
      <div
        className='accordion'
        style={{
          width: '100%',
          background: '#eee',
        }}
      >
        <div className='accordion--btn' style={{ cursor: 'inherit' }}>
          <span
            onClick={handleSelect}
            className='accordion--check'
            style={{
              backgroundColor:
                itemSelected?.ingredients?.length === item.ingredients.length
                  ? colors.yellow
                  : 'transparent',
              cursor: 'pointer',
            }}
          />
          <button
            className='accordion--title'
            onClick={() => setOpen(!open)}
            style={{ cursor: 'pointer' }}
          >
            {item.name}
            {itemSelected?.ingredients?.length > 0 && (
              <span className='accordion--badge' />
            )}
            <ChevronBottomIcon margin='0 0 0 auto' />
          </button>
        </div>

        <div
          className={`accordion--content`}
          style={{
            maxHeight: open ? 9999 : 0,
            transition: open
              ? 'all 0.5s cubic-bezier(1, 0, 1, 0) '
              : 'all 0.5s cubic-bezier(0, 1, 0, 1)',
          }}
        >
          {copyObj(item.ingredients)
            ?.sort((a, b) => sortedList(a, b, a.label ? 'label' : 'name'))
            .map((ingredient) => {
              return (
                <Item
                  key={ingredient._id}
                  item={ingredient}
                  disabled={disabled}
                  itemsSelected={itemSelected?.ingredients}
                  parent={parent}
                  product={item}
                  parentKey={parentKey}
                  ingredient
                />
              );
            })}

          <ButtonAdd
            label='Ajouter un ingredient'
            height={40}
            width='100%'
            margin='.3rem 0'
            bg={colors.dark}
            disabled={disabled}
            onClick={onClickAddNewIngredient}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <button
        style={{
          borderColor: itemSelected ? colors.yellow : colors.white,
        }}
        className='customer--universpanel-btnRadio'
        onClick={ingredient ? handleSelectIngredient : handleSelect}
        disabled={disabled}
      >
        <span>{item.name || item?.label}</span>
        <span
          style={{
            backgroundColor: itemSelected ? colors.yellow : 'transparent',
          }}
        />
      </button>
      <div
        style={{
          backgroundColor: itemSelected ? colors.yellow : 'transparent',
        }}
      >
        {item.ingredients?.map((ingredient) => {
          return <span>{ingredient.name}</span>;
        })}
      </div>
    </>
  );
};
