import {toast} from 'react-toastify';
import {deleteOneFilterValue} from '../../../api/filters.api';
import {toast_loader} from '../../../assets/utils/toast';
import {DeleteModal} from '../../../components/modals/DeleteModal';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {useResetFilters} from '../../../hooks/useResetFilters';
import {resetFilteredSelectedItemAction} from '../../../redux/filtersSelected';
import {deleteModalAction} from '../../../redux/modals';

export const DeleteFilterValue = () => {
  const dispatch = useAppDispatch();
  const filterValueSelected = useAppSelector(
    (s) => s.filtersSelected.filterValue
  );

  const {resetFilters} = useResetFilters();
  const handleDeleteFilterValue = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      await deleteOneFilterValue(filterValueSelected._id);
      resetFilters('filter');
      dispatch(resetFilteredSelectedItemAction('filterValue'));
      dispatch(deleteModalAction(false));
      toast_loader(loader, 'Valeur supprimée', 'success');
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
    }
  };
  return (
    <DeleteModal
      onDelete={handleDeleteFilterValue}
      text={`la valeur "${filterValueSelected?.name ?? ''}"`}
    />
  );
};
