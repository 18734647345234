import { useAppSelector } from './useReduxHook';

export const useCompositionMatchPercentage = (composition: any) => {
  const compositionSelected = useAppSelector(
    (s) => s.compositions.compositionSelected
  );
  const answers = compositionSelected.ingredients;
  const ingredientCount = composition?.ingredients.length;
  const percentageIncrease = 100 / ingredientCount;
  let matchedPercent = 0;

  answers?.forEach((answer: any) => {
    if (
      composition.ingredients.some(
        (ingredient: any) =>
          ingredient?.specialityId?._id === answer?.specialityId
      )
    ) {
      matchedPercent += percentageIncrease;
    }
  });

  return { matchedPercent };
};
