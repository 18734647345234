import { useState } from 'react';
import { toast } from 'react-toastify';
import { createOneCity } from '../../../api/cities.api';
import { countries } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { citiesModalAction } from '../../../redux/modals';
import { Input, Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

const initCity = { name: '', country: 'france', hashtags: [] };

export const CitiesModal = () => {
  const [city, setCity] = useState(initCity);
  const dispatch = useAppDispatch();

  const citiesListModal = useAppSelector((s) => s.modals.citiesListModal);

  const { resetFilters } = useResetFilters();

  const handleCloseModal = () => {
    dispatch(citiesModalAction(false));
  };

  const handleAddCity = async () => {
    const loader = toast.loading('Veuillez patienter...');
    const res = await createOneCity(city);

    if (res) {
      toast_loader(loader, 'Ville ajoutée avec succès', 'success');
      setCity(initCity);
      handleCloseModal();
    } else {
      toast_loader(loader, 'Une erreur est survenue', 'error');
    }
    resetFilters('cities');
  };

  return (
    <Modal
      onClose={handleCloseModal}
      title='Ville'
      onValidate={handleAddCity}
      zIndex={9999999}
      bgWidth={citiesListModal ? '100%' : '80%'}
    >
      <Input
        label='Nom'
        type='text'
        value={city?.name}
        onChange={(e) => setCity((s) => ({ ...s, name: e.target.value }))}
      />
      <Select
        label='Pays'
        options={copyObj(countries)
          ?.sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
              return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((country) => country.toLowerCase())}
        defaultValue='france'
        onChange={(e) => setCity((s) => ({ ...s, country: e.target.value }))}
      />
    </Modal>
  );
};
