import { colors } from '../constants';

export const EditIcon = ({ color = colors.yellow, onClick = () => {} }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ minWidth: 25, cursor: 'pointer' }}
      onClick={onClick}
    >
      <path
        d='M16 2H8C4.691 2 2 4.691 2 8V21C2 21.2652 2.10536 21.5196 2.29289 21.7071C2.48043 21.8946 2.73478 22 3 22H16C19.309 22 22 19.309 22 16V8C22 4.691 19.309 2 16 2ZM20 16C20 18.206 18.206 20 16 20H4V8C4 5.794 5.794 4 8 4H16C18.206 4 20 5.794 20 8V16Z'
        fill={color}
      />
      <path
        d='M7 14.987V16.986H8.999L14.528 11.464L12.53 9.466L7 14.987ZM15.47 10.522L13.472 8.522L14.995 7L16.995 8.999L15.47 10.522Z'
        fill={color}
      />
    </svg>
  );
};
