import { Magnifier, MOUSE_ACTIVATION } from 'react-image-magnifiers';

export const ZoomPicture = ({ picture }) => {
  return (
    <>
      <Magnifier
        imageSrc={picture}
        imageAlt={picture}
        mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
        style={{ height: '100%' }}
      />
    </>
  );
};
