import { DisplayImageModal } from '../components/modals/ImagesModals/DisplayImageModal';
import { CustomerModal } from '../components/modals/ImagesModals/CustomerModal';
import { ReplayImgAttrModal } from '../components/modals/CustomerModals/ReplayImgAttrModal';
import { DownloadImgModal } from '../components/modals/CustomerModals/DownloadImgModal';
import { DisplayImgModal } from '../components/modals/CustomerModals/DisplayImgModal';
import { ReplayStrategyModal } from '../components/modals/CustomerModals/ReplayStrategyModal';
import { AddImageModal } from '../components/modals/CustomerModals/AddImage';
import { ConfirmDeleteAttrModal } from '../components/modals/CustomerModals/ConfirmDeleteAttrModal';
// import { PackageCustomerImgListModal } from '../components/modals/ImagesModals/PackageCustomerImgListModal';
import { ConfirmDeleteImageModal } from '../components/modals/CustomerModals/ConfirmDeleteImageModal';
import { ImagesSimilarsModal } from '../components/modals/ImagesModals/ImagesSimilarsModal';
import { GroupsModal } from '../components/modals/GroupsModal';
import { ImageInfosModal } from '../components/modals/ImagesModals/ImageInfosModal';
import { useLocation } from 'react-router-dom';
import { CitiesModal } from '../components/modals/CitiesModal';
import { CitiesListModal } from '../components/modals/CustomerModals/CitiesListModal';
import { StylesModal } from '../components/modals/StylesModal';
import { FamiliesModal } from '../components/modals/FamiliesModal';
import { FamilyProductModal } from '../components/modals/FamiliesModal/FamilyProductModal';
import { FiltersModal } from '../components/modals/FiltersModal';
import { FilterValueModal } from '../components/modals/FiltersModal/FilterValueModal';
import { SpecialsFiltresModal } from '../components/modals/SpecialsFiltresModal';
import { PackageCustomerModal } from '../components/modals/CustomerModals/PackageCustomerModal';
import ImagesListModal from '../components/modals/CustomerModals/ImagesListModal';
import { ProductIngredientModal } from '../components/modals/FamiliesModal/ProductIngredientModal';
import { useAppSelector } from '../hooks/useReduxHook';
import { SubSpecialFiltersModal } from '../components/modals/SpecialsFiltresModal/subSpecialFiltersModal';
import { MoodsModal } from '../components/modals/MoodsModal';
import { CustomersListModal } from '../components/modals/CustomerModals/CustomersListModal';
import { IdenticalImageModal } from '../components/modals/ImagesModals/IdenticalImageModal';
import { SuggestionsSamePictureModal } from '../components/modals/SuggestionSamePictureModal';
import { StrategyModelModal } from '../components/modals/CustomerModals/StrategyModelModal/StrategyModelModal';
import { ReplayDiaryModal } from '../components/modals/CustomerModals/ReplayDiaryModal';
import { WarningAttrImgModal } from '../components/modals/CustomerModals/WarningAttrImgModal';
import { RayonsModal } from '../components/modals/RayonsModal';
import { RayonIngredientModal } from '../components/modals/RayonIngredientModal';
import { RayonIngredientSpecialtyModal } from '../components/modals/RayonIngredientSpecialtyModal';
import { SpecialitiesModal } from '../components/modals/CompositionsModals/SpecialitiesModal';
import { ConfirmeReplayQuizModal } from '../components/modals/ImagesModals/ConfirmeReplayQuizModal';
import { CompositionsModal } from '../components/modals/CustomerModals/CompositionsModal';
import { ConfirmeSwitcheFamilyModal } from '../components/modals/ImagesModals/ConfirmeSwitcheFamilyModal';
import { ImageInfosMiniModal } from '../components/modals/ImagesModals/ImageInfosMiniModal';
import { ViewFeedModal } from '../components/modals/CustomerModals/ViewFeedModal';

export const ModalsRender = () => {
  const modalsStore = useAppSelector((s) => s.modals);
  const location = useLocation();

  return (
    <>
      {modalsStore?.addImageModal && (
        <AddImageModal
          bgWidth={location.pathname === '/images-list' ? '100%' : null}
        />
      )}
      {modalsStore?.confirmDeleteAttrModal && (
        <ConfirmDeleteAttrModal
          bgWidth={location.pathname === '/images-list' ? '100%' : null}
        />
      )}
      {modalsStore?.deleteImageModal && (
        <ConfirmDeleteImageModal
          bgWidth={location.pathname === '/images-list' ? '100%' : null}
        />
      )}
      {modalsStore?.viewImageModal && <DisplayImageModal />}
      {modalsStore?.customerModal && <CustomerModal />}

      {modalsStore?.replayImgAttrModal && (
        <ReplayImgAttrModal
          bgWidth={location.pathname === '/images-list' ? '100%' : null}
        />
      )}

      {modalsStore?.replayDiaryModal && (
        <ReplayDiaryModal
          bgWidth={location.pathname === '/images-list' ? '100%' : null}
        />
      )}
      {modalsStore?.warningAttrImgModal && <WarningAttrImgModal />}
      {modalsStore?.downloadImgModal && (
        <DownloadImgModal
          bgWidth={location.pathname === '/images-list' ? '100%' : null}
        />
      )}
      {modalsStore?.displayImgModal && (
        <DisplayImgModal
          bgWidth={location.pathname === '/images-list' ? '100%' : null}
        />
      )}
      {modalsStore?.replayStrategyModal && (
        <ReplayStrategyModal
          bgWidth={location.pathname === '/images-list' ? '100%' : null}
        />
      )}
      {modalsStore?.similarsImagesModal && <ImagesSimilarsModal />}
      {modalsStore?.groupsModal && <GroupsModal />}
      {modalsStore?.editImgModal && <ImageInfosModal />}
      {modalsStore?.editImgMiniModal && <ImageInfosMiniModal />}
      {modalsStore?.citiesModal && <CitiesModal />}
      {modalsStore?.citiesListModal && <CitiesListModal />}

      {modalsStore?.stylesModal && <StylesModal />}
      {modalsStore?.familiesModal && <FamiliesModal />}
      {modalsStore?.familyProductsModal && <FamilyProductModal />}
      {modalsStore?.filtersModal && <FiltersModal />}
      {modalsStore?.filterValuesModal && <FilterValueModal />}

      {modalsStore?.specialsFiltresModal && <SpecialsFiltresModal />}
      {modalsStore?.packageCustomerModal && <PackageCustomerModal />}
      {modalsStore?.imagesListModal && <ImagesListModal />}
      {modalsStore?.groupsModal && <GroupsModal bgWidth='100%' />}
      {modalsStore?.productIngredientsModal && <ProductIngredientModal />}
      {modalsStore?.subSpecialsFiltersModal && <SubSpecialFiltersModal />}
      {modalsStore?.moodsModal && <MoodsModal />}
      {modalsStore?.customersListModal && <CustomersListModal />}
      {modalsStore?.identicalImageModal && <IdenticalImageModal />}
      {modalsStore?.suggestionSamePictureModal && (
        <SuggestionsSamePictureModal />
      )}
      {modalsStore?.strategyModelModal && <StrategyModelModal />}

      {modalsStore?.rayonsModal && <RayonsModal />}
      {modalsStore.rayonIngredientModal && <RayonIngredientModal />}
      {modalsStore.rayonIngredientSpecialtyModal && (
        <RayonIngredientSpecialtyModal />
      )}
      {modalsStore.specialitiesModal && <SpecialitiesModal />}
      {modalsStore?.confirmReplayQuizModal && <ConfirmeReplayQuizModal />}
      {modalsStore?.confirmSwitcheFamilyModal && <ConfirmeSwitcheFamilyModal />}
      {modalsStore.customerCompositionsModal && <CompositionsModal />}
      {modalsStore.viewFeedModal && <ViewFeedModal />}
    </>
  );
};
