import React, { useEffect, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import {
  getAllCompositionsByFamilyId,
  getAllCompositionsByProductId,
  getOneFamilyById,
} from '../../../api/families.api';
import { getOneQuiz } from '../../../api/quiz.api';
import { Toast } from '../../../assets/utils/toast';
import { Loader } from '../../../components/atoms/Loader';
import { CompositionsBlock } from '../../../components/organisms/CompositionsGestion/CompositionsBlock';
import { IngredientsBlock } from '../../../components/organisms/CompositionsGestion/IngredientsBlock';
import { QuestionsBlock } from '../../../components/organisms/CompositionsGestion/QuestionsBlock';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  compositionSelectedAction,
  compositionsAction,
} from '../../../redux/compositions';
import { questionSelectedAction, quizAction } from '../../../redux/familyQuiz';
import { initComposition } from '../../../assets/constants';
import { useCustomerDetails } from '../../../hooks/useCustomerDetails';

export const CompositionsGestionView: React.FC = withCheckAuth(
  ({ forCompose }) => {
    const dispatch = useAppDispatch();
    const univers = useAppSelector((s) => s.customer.customer?.univers);

    let { familyId, productId, customerId } = useParams();

    const urlSplit = window.location.href.split('/');

    if (!familyId) {
      familyId = urlSplit[urlSplit.length - 2];
    }

    if (!productId && !forCompose) {
      productId = urlSplit[urlSplit.length - 1];
    }

    if (!customerId && forCompose) {
      customerId = urlSplit[urlSplit.length - 1];
    }

    const [loading, setLoading] = useState(false);

    const {
      data: compositionsData,
      isLoading: isLoadingCompositions,
      isFetched: isFetchedCompositions,
    } = useQuery(
      ['compositions', productId],
      () => getAllCompositionsByProductId(productId),
      {
        enabled: !!productId,
      }
    );

    const { data: allProdsCompsData } = useQuery(
      ['all-compositions-products', familyId],
      () => getAllCompositionsByFamilyId(familyId),
      {
        enabled: !!familyId && forCompose,
        refetchOnWindowFocus: false,
      }
    );

    const getQuiz = useCallback(() => {
      (async () => {
        setLoading(true);
        const familyData = await getOneFamilyById(familyId);
        if (!!familyData?.data) {
          const quizId = familyData?.data?.family?.quiz?._id;

          if (!quizId) {
            setLoading(false);
            Toast('error', 'Une erreur est survenue');
            return;
          }

          const quizData = await getOneQuiz(quizId);
          if (!!quizData?.data) {
            const family = familyData?.data?.family;
            const Ufamily = univers?.families?.find(
              (_f) => _f?._id === familyId
            );
            const payload = {
              _id: quizData?.data?._id,
              familyId: {
                _id: family?._id,
                label: family?.label,
              },
              questionsList: quizData?.data?.questionsList?.map((question) => ({
                ...question,
                answered:
                  forCompose &&
                  !!Ufamily?.ingredients?.find(
                    (ing) =>
                      ing.questionId?._id === question._id ||
                      ing.questionId === question._id
                  ),
              })),
              propositionsList: quizData?.data?.propositionsList,
              responsesSaved: quizData?.data?.responsesSaved,
              update: true,
            };
            dispatch(quizAction(payload));
            dispatch(questionSelectedAction(payload.questionsList[0]));
            setLoading(false);
          }
        } else {
          setLoading(false);
          Toast('error', 'Une erreur est survenue');
        }
      })();
    }, [dispatch, familyId]);

    const getCompositions = useCallback(() => {
      if (
        (!!compositionsData?.data && isFetchedCompositions) ||
        (allProdsCompsData?.data && forCompose)
      ) {
        dispatch(
          compositionsAction(
            forCompose ? allProdsCompsData?.data : compositionsData?.data
          )
        );
      }
    }, [
      dispatch,
      compositionsData,
      allProdsCompsData,
      forCompose,
      isFetchedCompositions,
    ]);

    useCustomerDetails(customerId, !univers);

    useEffect(() => {
      getQuiz();
      getCompositions();
    }, [getQuiz, getCompositions]);

    useEffect(() => {
      if (!forCompose) return;
      const family = univers?.families?.find((_f) => _f?._id === familyId);
      if (!family) return;

      dispatch(
        compositionSelectedAction({
          ...initComposition,
          ingredients:
            family.ingredients?.map((ing) => ({
              ...ing,
              questionId: ing.questionId?._id || ing.questionId,
            })) || [],
        })
      );
    }, [univers, familyId, forCompose, dispatch]);

    if (loading && isLoadingCompositions) return <Loader />;

    return (
      <div className='productCompositions'>
        <QuestionsBlock />
        <IngredientsBlock />
        <CompositionsBlock forCompose={forCompose} />
      </div>
    );
  }
);
