export const Settings2Icon = ({ onClick = () => {} }) => {
  return (
    <svg
      width='27'
      height='24'
      viewBox='0 0 27 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.21596 1.28516C7.63273 1.28516 8.03243 1.45072 8.32713 1.74542C8.62183 2.04012 8.78739 2.43982 8.78739 2.85658V5.99944C8.78739 6.41621 8.62183 6.81591 8.32713 7.11061C8.03243 7.40531 7.63273 7.57087 7.21596 7.57087C6.79919 7.57087 6.39949 7.40531 6.10479 7.11061C5.81009 6.81591 5.64453 6.41621 5.64453 5.99944V2.85658C5.64453 2.43982 5.81009 2.04012 6.10479 1.74542C6.39949 1.45072 6.79919 1.28516 7.21596 1.28516V1.28516Z'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.0709 4.42969H8.78516'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.64397 4.42969H0.929688'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.21596 17C7.63273 17 8.03243 17.1656 8.32713 17.4603C8.62183 17.755 8.78739 18.1547 8.78739 18.5714V21.7143C8.78739 22.1311 8.62183 22.5308 8.32713 22.8255C8.03243 23.1202 7.63273 23.2857 7.21596 23.2857C6.79919 23.2857 6.39949 23.1202 6.10479 22.8255C5.81009 22.5308 5.64453 22.1311 5.64453 21.7143V18.5714C5.64453 18.1547 5.81009 17.755 6.10479 17.4603C6.39949 17.1656 6.79919 17 7.21596 17Z'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.0709 20.1406H8.78516'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.64397 20.1406H0.929688'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.7863 9.14062C20.203 9.14062 20.6027 9.30619 20.8974 9.60089C21.1921 9.89559 21.3577 10.2953 21.3577 10.7121V13.8549C21.3577 14.2717 21.1921 14.6714 20.8974 14.9661C20.6027 15.2608 20.203 15.4263 19.7863 15.4263C19.3695 15.4263 18.9698 15.2608 18.6751 14.9661C18.3804 14.6714 18.2148 14.2717 18.2148 13.8549V10.7121C18.2148 10.2953 18.3804 9.89559 18.6751 9.60089C18.9698 9.30619 19.3695 9.14062 19.7863 9.14062V9.14062Z'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.2154 12.2852H0.929688'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M26.0737 12.2852H21.3594'
        stroke='black'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
