export const DotsCircleIcon = ({ onClick = () => {} }) => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <circle cx='14' cy='14' r='13.5' fill='white' stroke='#A88E1B' />
      <path
        d='M14 10C12.8954 10 12 9.10457 12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8C16 9.10457 15.1046 10 14 10Z'
        fill='#A88E1B'
      />
      <path
        d='M14 16C12.8954 16 12 15.1046 12 14C12 12.8954 12.8954 12 14 12C15.1046 12 16 12.8954 16 14C16 15.1046 15.1046 16 14 16Z'
        fill='#A88E1B'
      />
      <path
        d='M14 22C12.8954 22 12 21.1046 12 20C12 18.8954 12.8954 18 14 18C15.1046 18 16 18.8954 16 20C16 21.1046 15.1046 22 14 22Z'
        fill='#A88E1B'
      />
    </svg>
  );
};
