import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initQuiz } from '../../assets/constants';
import { IComposition } from '../compositions';

export interface IQuiz {
  _id?: string; // from db
  familyId: IFamily | string | any; // family _id
  questionsList: IQuestion[]; // from db
  propositionsList?: []; // use this from ask db what is this product
  responsesSaved?: IAnswer[]; // from user
  update?: boolean;
}

export interface IQuestion {
  _id?: string;
  rayonId: string; // rayon _id
  question: string;
  answers: IAnswer[];
  hasMultipleAnswer: boolean;
  answerType: 'auto' | 'manual' | string;
  logicals: ILogical[];
  isNew?: boolean;
  answered?: boolean;
  noAnswer?: boolean;
  index?: number;
  position?: number;
}

interface ILogical {
  condition: string; // if answer value === ingredient _id => question _id
  action: string; // question _id
}
export interface IAnswer {
  _id?: string;
  ingredient_id: string | any; //ingredient _id
  values: ISpeciality[]; // speciality _id
}

interface IFamily {
  _id: string;
  label: string;
}

export interface ISpeciality {
  _id?: string;
  label: string;
  ingredientId: string;
  rayonId: string;
  image?: string;
}

export interface IIngredient {
  image: string;
  label: string;
  rayonId: string;
  specialties: [];
  _id: string;
}

interface IInitState {
  familyQuizTab: 'question' | 'logic';
  quiz: IQuiz | null;
  questionSelected: IQuestion | null;
  allProdsComps?: IComposition[] | [];
  answerSelected?: any | null;
}

const initialState: IInitState = {
  familyQuizTab: 'question',
  quiz: initQuiz,
  questionSelected: null,
  allProdsComps: [],
  answerSelected: null,
};

export const slice = createSlice({
  name: 'familyQuiz',
  initialState,
  reducers: {
    familyQuizTabAction: (
      state,
      action: PayloadAction<'question' | 'logic'>
    ) => {
      state.familyQuizTab = action.payload;
    },
    questionSelectedAction: (
      state,
      action: PayloadAction<IQuestion | null>
    ) => {
      state.questionSelected = action.payload;
    },
    quizAction: (state, action: PayloadAction<IQuiz | null>) => {
      state.quiz = action.payload;
    },
    allProdsCompsAction: (
      state,
      action: PayloadAction<IComposition[] | []>
    ) => {
      state.allProdsComps = action.payload;
    },
    answerSelectedAction: (state, action: PayloadAction<IAnswer | null>) => {
      state.answerSelected = action.payload;
    },
    resetQuizStoreAction: (state) => {
      return initialState;
    },
  },
});

export const {
  familyQuizTabAction,
  questionSelectedAction,
  quizAction,
  allProdsCompsAction,
  answerSelectedAction,
  resetQuizStoreAction,
} = slice.actions;
export default slice.reducer;
