import React from 'react';
import { colors } from '../../../assets/constants';

export const Tabs: React.FC<{
  tabs: string;
  setTabs: (btnId: string) => void;
  buttons: { label: string; id: string }[];
  disabled?: any;
}> = ({ tabs, setTabs, buttons, disabled }) => {
  return (
    <div className='tabs'>
      {buttons?.map((btn, index) => (
        <button
          key={index}
          onClick={() => setTabs(btn?.id)}
          className={`tabs--btn tabs--btn-${tabs === btn?.id ? 'active' : ''}`}
          disabled={!!disabled && disabled?.[btn?.id]}
          style={{
            color: !!disabled && disabled?.[btn?.id] ? colors.grey : '',
          }}
        >
          {btn?.label}
        </button>
      ))}
    </div>
  );
};
