import {withImagesLayout} from '../../../hoc/withImagesLayout';
import {compose} from 'recompose';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {ImagesList} from '../../../components/organisms/Images/ImagesList';

const ImagesListView = ({search}: {search: unknown}) => {
  return <ImagesList search={search} />;
};

export default compose(withImagesLayout, withCheckAuth)(ImagesListView);
