import { axiosInstance } from '../assets/utils/axiosInstance';

export const fetchSpecialFilters = async (search = '') => {
  const res: any = await axiosInstance
    .get(`/specialfilter/get-all?label=${search}`)
    .catch((err) => {
      console.log('fetchSpecialFilters', err);
    });
  return res.data;
};

export const fetchOneSpecialFilter = async (id) => {
  const res: any = await axiosInstance
    .get(`/specialfilter/${id}`)
    .catch((err) => {
      console.log('fetchOneSpecialFilter', err);
    });
  return res.data;
};

export const createOneSpecialFilter = async (specialFilter) => {
  const res = await axiosInstance
    .post('/specialfilter/create', {
      label: specialFilter.label,
      linkGoodBoard: specialFilter.linkGoodBoard,
      linkWorkList: specialFilter.linkWorkList,
      linkDrive: specialFilter.linkDrive,
    })
    .catch((err) => {
      console.log('createOneSpecialFilter', err);
    });
  return res;
};

export const updateOneSpecialFilter = async (id, specialFilter) => {
  const res = await axiosInstance
    .put(`/specialfilter/${id}`, {
      label: specialFilter.label,
      linkGoodBoard: specialFilter.linkGoodBoard,
      linkWorkList: specialFilter.linkWorkList,
      linkDrive: specialFilter.linkDrive,
    })
    .catch((err) => {
      console.log('updateOneSpecialFilter', err);
    });
  return res;
};

export const deleteOneSpecialFilter = async (id) => {
  const res = await axiosInstance
    .put(`/specialfilter/delete/${id}`)
    .catch((err) => {
      console.log('deleteOneSpecialFilter', err);
    });
  return res;
};
