import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Toast } from '../../../assets/utils/toast';
import { updateCustomerInfo } from '../../../api/customers.api';
import { createOneLog, getAllByUserAndPictureId } from '../../../api/logs.api';
import { uploadFile } from '../../../api/uploadFile.api';
import { copyObj } from '../../../assets/utils/copyObj';
import helperDate from '../../../assets/utils/helperDate';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFiltersAction } from '../../../redux/filters';
import { updateImageAction } from '../../../redux/imageAttributions';
import {
  resetImageFormAction,
  infosMainProductsAction,
  infosSecondaryProductsAction,
  imageFormAction,
} from '../../../redux/images';
import {
  editImgMiniModalAction,
  editImgModalAction,
} from '../../../redux/modals';
import { ImageForm } from '../../../views/online/ImagesView/ImageForm';

import { Button } from '../../atoms/Buttons';
import { updateOnePicture } from '../../../api/picture.api';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';
import { fetchFamilies } from '../../../api/families.api';
import { Input } from '../../atoms/Fields';
import { sortedList } from '../../../assets/utils/sortedList';

export const ImageInfosMiniModal = () => {
  //

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector((s) => s.app.userData);
  const imagesSelected = useAppSelector((s) => s.images);
  const similarsImagesModal = useAppSelector(
    (s) => s.modals.similarsImagesModal
  );
  const role = useAppSelector((s) => s.app.role);
  const imageAttributions = useAppSelector((state) => state.imageAttributions);
  const imageFormData = useAppSelector((state) => state.images);

  const longName = imageFormData?.longName;
  const shortName = imageFormData?.shortName;

  const { count, compositionsAttr } = useAttributionsImgComposition();
  const { data: familiesData } = useQuery('families', () => fetchFamilies());

  const [formTabs] = useState(imagesSelected.tab || 'informations');

  const { resetFilters } = useResetFilters();

  const handleCloseModal = () => {
    dispatch(editImgMiniModalAction(false));
    dispatch(updateImageAction(false));
    if (!similarsImagesModal) {
      // dispatch(resetFiltersAction());
      dispatch(resetImageFormAction());
    }

    navigate('/images-list');
  };

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');

    const picture = imagesSelected.image;

    const data = copyObj({ ...imagesSelected });

    if (!!picture?.name) {
      const fileName = await uploadFile(picture, 'picture');
      if (fileName) {
        data.image = fileName;
      }
    }

    const payload = {
      ...data,
      attrMainCompositions: data?.attrMainCompositions?.map((_el) => ({
        familyId: _el.familyId,
        productId: _el.productId?._id || _el?.productId,
        compositionId: _el.compositionId?._id || _el?.compositionId || _el._id,
      })),
      attrSecondaryCompositions: data?.attrSecondaryCompositions?.map(
        (_el) => ({
          familyId: _el.familyId,
          productId: _el.productId?._id || _el?.productId,
          compositionId:
            _el.compositionId?._id || _el?.compositionId || _el._id,
        })
      ),
    };
    const res = await updateOnePicture(imagesSelected?._id, payload);

    resetFilters('pictures');

    if (res.status === 200) {
      const logBody = {
        userId: userData?._id,
        pictureId: imagesSelected?._id,
        action: `Modification de l'image ${imagesSelected.name}`,
        from: 'image',
        createdAt: new Date(),
      };
      toast_loader(loader, 'Image modifiée !', 'success');
      handleCloseModal();
      await createOneLog(logBody);
    } else {
      toast_loader(loader, "Une erreur s'est produite !", 'error');
    }
  };

  const handleUpdateImage = async () => {
    dispatch(editImgMiniModalAction(false));
    dispatch(editImgModalAction(true));
    dispatch(updateImageAction(true));
  };

  const onAddAttribution = (edit) => {
    if (count > 0) {
      const familiesSelected =
        imageAttributions?.imageAttributionsTab === 'product-main'
          ? imageFormData?.infosMainProducts
          : imageFormData?.infosSecondaryProducts;

      const action =
        imageAttributions?.imageAttributionsTab === 'product-main'
          ? infosMainProductsAction
          : infosSecondaryProductsAction;

      for (let i = 0; i < familiesData.length; i++) {
        const family = familiesData[i];
        const formatedFamilies = familiesSelected?.map((f) => {
          if (f.familyId === family?.familyId) {
            return family;
          }
          return f;
        });
        dispatch(action(formatedFamilies));
      }
      navigate('/image-attributions');

      if (edit) {
        dispatch(editImgMiniModalAction(false));
      }
    } else if (count === 0 && compositionsAttr.length > 0) {
      Toast(
        'warning',
        'Vous avez déjà attribué le nombre de compositions requis !'
      );
      return;
    } else {
      Toast('warning', 'Veuillez sélectionner au moins une famille !');
      return;
    }
  };

  useEffect(() => {
    if (window.location.href.includes('add-image')) {
      const selectImageTitle = document.querySelector('.image--sectionTitle');
      const modalImage = document.querySelector('.modalImage');
      let count = selectImageTitle.offsetTop - modalImage.scrollTop - 85;

      modalImage.scrollTo({
        top: count,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(editImgMiniModalAction(false));
    };
  }, [dispatch]);

  useEffect(() => {
    const compositionsAttrCombine = [
      ...imageFormData.attrMainCompositions.map((x) => x.compositionId),
      ...imageFormData.attrSecondaryCompositions.map((x) => x.compositionId),
    ];

    const longNameValueFormated = compositionsAttrCombine
      ?.flatMap((comp) =>
        copyObj(comp.ingredients)
          ?.sort((a, b) => sortedList(a, b, 'name'))
          ?.map((ingredient, index) => ingredient?.specialityId?.label)
      )
      ?.join(' - ');

    const shortNameValueFormated = copyObj(compositionsAttrCombine)
      ?.map((comp) => comp.short_name)
      ?.join(' - ');

    dispatch(
      imageFormAction({
        key: 'longName',
        value: longNameValueFormated,
      })
    );
    dispatch(
      imageFormAction({
        key: 'shortName',
        value: shortNameValueFormated,
      })
    );
  }, [
    imageFormData.attrMainCompositions,
    imageFormData.attrSecondaryCompositions,
    dispatch,
  ]);

  return (
    <>
      <div onClick={handleCloseModal} className='modalImage--bg' />
      <div className='modalImage modalImageMini'>
        {role !== 'COMMERCIAL' && role !== 'PUNCHLINER' && (
          <Button
            label="Modifier l'image"
            variant='black'
            onClick={handleUpdateImage}
          />
        )}

        <div className='modalImageMini--attributionNames'>
          <Input
            label='Nom Long'
            value={longName}
            onChange={(e) =>
              dispatch(
                imageFormAction({
                  key: 'longName',
                  value: e.target.value,
                })
              )
            }
            disabled
          />
          <Input
            label='Nom Court'
            value={shortName}
            onChange={(e) =>
              dispatch(
                imageFormAction({
                  key: 'shortName',
                  value: e.target.value,
                })
              )
            }
            disabled
          />
        </div>

        <div className='modalImage--container modalImageMini--container'>
          <ImageForm
            miniModal
            tab={formTabs}
            edit
            onAddAttribution={onAddAttribution}
          />
        </div>
        <div className='image--footer'>
          <Button
            label='Fermer'
            margin='0 15px'
            variant='black'
            onClick={handleCloseModal}
          />
          <Button label='Terminer' variant='yellow' onClick={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export const HistoricalPanel = () => {
  const userData = useAppSelector((s) => s.app.userData);
  const imagesSelected = useAppSelector((s) => s.images);

  const { data: data } = useQuery(
    'logs',
    () =>
      getAllByUserAndPictureId({
        userId: userData?._id,
        pictureId: imagesSelected?._id,
      }),
    {
      enabled: !!imagesSelected?._id && !!userData?._id,
    }
  );

  return (
    <div className='modalImage--historicals'>
      <div className='modalImage--historicals-thead'>
        <span>Date</span>
        <span>Action</span>
        <span style={{ paddingLeft: '1rem' }}>Par</span>
      </div>
      {data?.data?.map((log, index) => (
        <div key={index} className='modalImage--historicals-trow'>
          <span>{helperDate(log?.createdAt, 'DD-MM-YYYY HH:mm')}</span>
          <span>{log.action}</span>
          <span style={{ paddingLeft: '1rem' }}>
            {log.userId?.lastname + ' ' + log?.userId.firstname}
          </span>
        </div>
      ))}
    </div>
  );
};
