import React from 'react';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { LogicBlock } from './LogicBlock';
import { QuestionBlock } from './QuestionBlock';
import { RightBlockHeader } from './RightBlockHeader';

export const RightBlock: React.FC = () => {
  //
  const familyQuizTab = useAppSelector((s) => s.familyQuiz.familyQuizTab);
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  return (
    <div className='familyQuiz--rightBlock'>
      <RightBlockHeader />
      {familyQuizTab === 'question' && !!questionSelected ? (
        <QuestionBlock />
      ) : (
        familyQuizTab === 'question' &&
        !!!questionSelected && <NoQuestionWarning />
      )}
      {familyQuizTab === 'logic' && !!questionSelected ? (
        <LogicBlock />
      ) : (
        familyQuizTab === 'logic' &&
        !!!questionSelected && <NoQuestionWarning />
      )}
    </div>
  );
};

const NoQuestionWarning: React.FC = () => {
  return (
    <div className='familyQuiz--noQuestionWarning'>
      <p>Veuillez sélectionner une question ou en créé une !</p>
    </div>
  );
};
