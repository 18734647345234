import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  createComposition,
  updateComposition,
} from '../../../api/families.api';
import { uploadFile } from '../../../api/uploadFile.api';
import { colors, initComposition } from '../../../assets/constants';
import { helperCompositionsEqual } from '../../../assets/utils/helperCompositionEqual';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  compositionsAction,
  compositionSelectedAction,
  IComposition,
} from '../../../redux/compositions';
import {
  IQuestion,
  questionSelectedAction,
  quizAction,
} from '../../../redux/familyQuiz';
import { Button } from '../../atoms/Buttons';
import { CompositionCard } from './CompositionCard';
import { CreateCompositionCard } from './CreateCompositionCard';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';
import {
  customerTabsAction,
  universFamilySelectedIngredientsAction,
  universTabsAction,
} from '../../../redux/customer';

export const CompositionsBlock: React.FC<{ forCompose?: boolean }> = ({
  forCompose,
}) => {
  //
  const navigate = useNavigate();
  const { familyId, productId } = useParams<{
    familyId: string;
    productId: string;
  }>();

  const dispatch = useAppDispatch();
  const compositionSelected = useAppSelector(
    (s) => s.compositions?.compositionSelected
  );
  const compositions = useAppSelector((s) => s.compositions?.compositions);
  const quiz = useAppSelector((s) => s.familyQuiz?.quiz);
  const imgCompositionsSelected = useAppSelector(
    (s) => s.imageAttributions?.imgCompositionsSelected
  );

  const { filteredCompositions, maybeCompositions, onSelectComposition } =
    useAttributionsImgComposition(forCompose, familyId);

  const [loading, setLoading] = useState<boolean>(false);

  const onCancelled = () => {
    dispatch(compositionSelectedAction(initComposition));
    dispatch(compositionsAction([]));
    dispatch(quizAction(null));
    dispatch(questionSelectedAction(null));
    navigate(-1);
  };

  const handleSuccessComposition = (loader, v = false) => {
    setLoading(false);
    toast_loader(
      loader,
      v
        ? 'composition modifiée avec succès !'
        : forCompose
        ? 'Ingredients selectionnés avec succès !'
        : `composition créée avec succès !`,
      'success'
    );
    dispatch(compositionSelectedAction(initComposition));
    navigate(-1);
  };

  const handleCreateComposition = async () => {
    const allAnswered = quiz?.questionsList?.every(
      (q: IQuestion) => q.answered
    );
    const isEqual = compositions.some((comp) =>
      helperCompositionsEqual(comp, compositionSelected)
    );

    const loader = toast.loading('Veuillez patienter !');
    setLoading(true);
    if (!allAnswered) {
      setLoading(false);
      toast_loader(
        loader,
        'Veuillez répondre à toutes les questions !',
        'warning'
      );
      return;
    }

    // return;
    if (compositionSelected.ingredients?.length === 0) {
      toast_loader(loader, 'Veuillez sélectionner au moins un ingrédient !');
      return;
    }
    if (!allAnswered) {
      setLoading(false);
      toast_loader(
        loader,
        'Veuillez répondre à toutes les questions !',
        'warning'
      );
      return;
    }
    if (compositionSelected.short_name.length === 0 && !forCompose) {
      setLoading(false);
      toast_loader(loader, 'Veuillez saisir un nom court !', 'warning');
      return;
    }

    if (isEqual && !forCompose) {
      setLoading(false);
      toast_loader(loader, 'Cette composition existe déjà !', 'warning');
      return;
    }

    let image = '';
    if (compositionSelected?.image) {
      image = await uploadFile(compositionSelected?.image, 'composition');
    }
    const payload = {
      short_name: compositionSelected.short_name.trim(),
      image,
      ingredients: compositionSelected.ingredients,
      familyId: familyId,
      productId: productId,
    };

    if (forCompose) {
      let ingredients = compositionSelected.ingredients?.map((ing) => {
        const questionId = quiz?.questionsList?.find(
          (q: IQuestion) => q._id === ing.questionId
        );

        return {
          ...ing,
          questionId: {
            name: questionId?.question,
            _id: ing.questionId,
          },
        };
      });

      dispatch(
        universFamilySelectedIngredientsAction({
          familyId,
          ingredients,
        })
      );

      dispatch(customerTabsAction('univers'));
      dispatch(universTabsAction('measure'));

      handleSuccessComposition(loader);

      return;
    }

    const { error } = await createComposition(payload);
    if (!error) {
      handleSuccessComposition(loader);
    } else {
      setLoading(false);
      toast_loader(loader, "Une erreur s'est produite !", 'error');
    }
  };

  const handleUpdateComposition = async () => {
    const loader = toast.loading('Veuillez patienter !');
    const filteredComps =
      compositions?.filter(
        (_comp: IComposition) => _comp?._id !== compositionSelected?._id
      ) || [];
    setLoading(true);
    const isEqual = filteredComps?.some((comp) =>
      helperCompositionsEqual(comp, compositionSelected)
    );
    if (compositionSelected.short_name.length === 0) {
      setLoading(false);
      toast_loader(loader, 'Veuillez saisir un nom court !', 'warning');
      return;
    }
    if (isEqual) {
      setLoading(false);
      toast_loader(loader, 'Cette composition existe déjà !', 'warning');
      return;
    }
    let image: any = compositionSelected?.image;
    if (image?.name) {
      image = await uploadFile(image, 'composition');
    }
    const payload = {
      short_name: compositionSelected.short_name.trim(),
      image,
      ingredients: compositionSelected.ingredients,
      familyId: familyId,
      productId: productId,
    };

    if (forCompose) {
      let ingredients = compositionSelected.ingredients?.map((ing) => {
        const questionId = quiz?.questionsList?.find(
          (q: IQuestion) => q._id === ing.questionId
        );

        return {
          ...ing,
          questionId: {
            name: questionId?.question,
            _id: ing.questionId,
          },
        };
      });

      dispatch(
        universFamilySelectedIngredientsAction({
          familyId,
          ingredients,
        })
      );

      handleSuccessComposition(loader, true);

      return;
    }

    const { error } = await updateComposition(compositionSelected._id, payload);
    if (!error) {
      setLoading(false);
      toast_loader(loader, 'composition modifiée avec succès !', 'success');
      navigate(-1);
    } else {
      setLoading(false);
      toast_loader(loader, "Une erreur s'est produite !", 'error');
    }
  };

  return (
    <div className='productCompositions--compositionsBlock'>
      <div className='productCompositions--compositionsBlock--header'>
        <Button
          onClick={onCancelled}
          label='Annuler'
          width='45%'
          disabled={loading}
        />
        <Button
          label={
            compositionSelected?.isNew
              ? forCompose
                ? 'Selectionner les ingredients'
                : 'Creer la composition'
              : 'Modifier la composition'
          }
          variant='yellow'
          width='45%'
          onClick={
            compositionSelected?.isNew
              ? handleCreateComposition
              : handleUpdateComposition
          }
          disabled={loading}
        />
      </div>
      <div className='productCompositions--compositionsBlock--cards'>
        {!forCompose && <CreateCompositionCard />}
        <div className='productCompositions--compositionsBlock--compositions'>
          <p className='productCompositions--compositionsBlock--compositionsTitle'>
            Composition existante
          </p>
          {filteredCompositions.map(
            (composition: IComposition, index: number) => {
              return (
                <CompositionCard
                  onSelectComposition={() =>
                    dispatch(
                      compositionSelectedAction({
                        ...composition,
                        isNew: false,
                      })
                    )
                  }
                  composition={composition}
                  key={index}
                  backgroundColor={colors.white}
                />
              );
            }
          )}

          {!forCompose && maybeCompositions.length > 0 && (
            <div className='w-100 flex-col-start-start productCompositions--compositionsBlock-maybeComps'>
              <p className='productCompositions--compositionsBlock--compositionsTitle'>
                Ca pourrait aussi être :
              </p>
              <div className='w-100 flex-col-start-start productCompositions--compositionsBlock-maybeComps-Item'>
                {maybeCompositions.map(
                  (composition: IComposition, index: number) => {
                    const findCompositionSelected =
                      imgCompositionsSelected?.find(
                        (_c: IComposition) => _c?._id === composition?._id
                      );
                    return (
                      <CompositionCard
                        onSelectComposition={() =>
                          onSelectComposition(composition)
                        }
                        composition={composition}
                        key={index}
                        selected={!!findCompositionSelected}
                        width='95%'
                        backgroundColor={colors.white}
                        margin='.5rem auto'
                      />
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
