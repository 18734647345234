import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { initUserForm } from '../../../assets/constants';
import { Button } from '../../../components/atoms/Buttons';
import { withNavigation } from '../../../hoc/withNavigation';
import { Toast } from '../../../assets/utils/toast';
import { Loader } from '../../../components/atoms/Loader';
import { HeaderWithTabs } from '../../../components/organisms/Header';
import { InformationsUpdatePanel } from '../../../components/organisms/User/InformationsUpdatePanel';
import { HistoricalPanel } from '../../../components/organisms/User/HistoricalPanel';
import { UserIcon } from '../../../assets/svgs/UserIcon';
// import { CardPayIcon } from '../../../assets/svgs/CardPayIcon';
import { compose } from 'redux';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import {
  getUserById,
  updateUserInfos,
  updateUserPassword,
} from '../../../api/users.api';
import { useQuery } from 'react-query';

const tabsData = [
  { label: 'Informations', link: 'informations', icon: UserIcon },
  // { label: 'Historique', link: 'historical', icon: CardPayIcon },
];
const UpdateUserView = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [tabs, setTabs] = useState('informations');
  const [userForm, setUserForm] = useState(initUserForm);
  const [logs] = useState([]);

  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { data, isLoading } = useQuery('moods', () => getUserById(userId));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserForm({ ...userForm, [name]: value });
  };

  const handleUpdateInfos = async (e) => {
    setLoading(true);
    await updateUserInfos(userForm, userId)
      .then((res) => {
        const userData = JSON.parse(localStorage.getItem('slm-user-data'));
        setLoading(false);
        navigate(-1);
        if (userData?._id === res.data.user?._id) {
          setLoading(false);
          localStorage.setItem(
            'slm-user-data',
            JSON.stringify({ ...res.data.user, password: null })
          );
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        Toast('success', 'Utilisateur modifié !');
      })
      .catch((err) => {
        setLoading(false);
        Toast('error', "Erreur lors de l'ajout !");
      });
    setLoading(false);
  };

  const handleUpdatePassword = async () => {
    try {
      if (userForm.password && userForm.password === confirmPassword) {
        setLoading(true);
        await updateUserPassword(userId, userForm.password);
        Toast('success', 'Mot de passe mis à jour');
        setLoading(false);
      } else if (userForm.password) {
        Toast('error', 'Les mots de passe ne correspondent pas !');
      } else {
        navigate(-1);
      }
    } catch (err) {
      setLoading(false);
      Toast('error', 'Erreur lors de la mise à jour du mot de passe !');
    }
  };

  useEffect(() => {
    if (data?.data?.user?._id) {
      setUserForm({ ...data.data.user, password: '' });
    }
  }, [data]);

  if (loading || isLoading) return <Loader />;

  return (
    <div className='pages-layout'>
      <HeaderWithTabs
        tabs={tabs}
        setTabs={setTabs}
        user={userForm}
        tabsData={tabsData}
      />
      <div className='pages-layout--container user'>
        {tabs === 'informations' && (
          <InformationsUpdatePanel
            userForm={userForm}
            setUserForm={setUserForm}
            handleChange={handleChange}
            setConfirmPassword={setConfirmPassword}
            confirmPassword={confirmPassword}
            handleUpdateInfos={handleUpdateInfos}
            loading={loading}
          />
        )}
        {tabs === 'historical' && <HistoricalPanel logs={logs} />}

        <div className='user--inputBlock' style={{ marginTop: '2rem' }}>
          <Button
            onClick={() => navigate(-1)}
            variant='black'
            label='Annuler'
            width='45%'
          />
          {tabs === 'informations' && (
            <Button
              onClick={handleUpdatePassword}
              variant='yellow'
              label={loading ? 'Valider...' : 'Valider'}
              width='45%'
              disabled={confirmPassword !== userForm.password || loading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(UpdateUserView);
