import { forwardRef, useState } from 'react';
import { colors } from '../../../assets/constants';
import { ChevronBottomIcon } from '../../../assets/svgs/ChevronBottomIcon';
import { CloseIcon } from '../../../assets/svgs/CloseIcon';
import { CopyIcon } from '../../../assets/svgs/CopyIcon';
import { EyeCloseIcon } from '../../../assets/svgs/EyeCloseIcon';
import { EyeOpenIcon } from '../../../assets/svgs/EyeOpenIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { Button } from '../Buttons';

export const Input = forwardRef(
  (
    {
      type = 'text',
      name,
      placeholder,
      value = '',
      onChange,
      disabled = false,
      label,
      width,
      margin = '.7rem 0',
      variant = 'full',
      required = false,
      icon = false,
      onShowPassword,
      defaultValue,
    },
    ref
  ) => {
    const variants = {
      quarter: '24%',
      half: '48%',
      third: '72%',
      full: '100%',
    };
    return (
      <div
        className='fields'
        style={{ width: width ? width : variants[variant], margin }}
      >
        <label className='fields--label'>{label}</label>
        {!!ref ? (
          <input
            className='fields--input'
            ref={ref}
            placeholder={placeholder}
            name={name}
            required={required}
            type={type}
            disabled={disabled}
            defaultValue={defaultValue}
          />
        ) : (
          <input
            className='fields--input'
            type={type}
            name={name}
            value={value}
            onChange={(e) => onChange(e)}
            placeholder={placeholder}
            disabled={disabled}
            required={required}
          />
        )}

        {icon && (
          <button type='button' onClick={onShowPassword}>
            {type === 'password' ? <EyeCloseIcon /> : <EyeOpenIcon />}
          </button>
        )}
      </div>
    );
  }
);

export const InputLink = ({
  name,
  placeholder,
  value = '',
  onChange,
  disabled = false,
  label,
  Icon,
  width,
  margin = '.7rem 0',
  variant = 'full',
  required = false,
}) => {
  const variants = {
    quarter: '24%',
    half: '48%',
    third: '72%',
    full: '100%',
  };
  return (
    <div
      className='fields'
      style={{ width: width ? width : variants[variant], margin }}
    >
      <label className='fields--label'>{label}</label>
      <div className='fields--link'>
        <div>
          <Icon />
        </div>
        <input
          className='fields--link-input'
          type='text'
          name={name}
          value={value}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
        />
      </div>
    </div>
  );
};

export const Select = ({
  label,
  name,
  onChange,
  value,
  variant = 'full',
  width,
  disabled = false,
  margin = '.7rem 0',
  options = [],
  defaultValue,
  hiddenDefaultValue,
}) => {
  const variants = {
    quarter: '24%',
    half: '48%',
    third: '72%',
    full: '100%',
  };
  return (
    <div
      className='fields'
      style={{ width: width ? width : variants[variant], margin }}
    >
      <label className='fields--label'>{label}</label>
      <select
        className='fields--selectBlock'
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {(defaultValue || !value) && (
          <option disabled={disabled} hidden={hiddenDefaultValue} value={''}>
            {defaultValue?.name ? defaultValue?.name : defaultValue}
          </option>
        )}
        {options?.map((opt, index) => {
          const { name, label } = opt;
          const optLabel = label ? label : name;
          return (
            <option value={opt?.value ? opt?.value : opt} key={index}>
              {optLabel ? optLabel : opt}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const Pill = ({ pill, onRemove }) => {
  return (
    <div className='fields--pills'>
      {pill?.label ?? pill}
      <CloseIcon
        onClick={() => onRemove(pill)}
        margin='0 0 0 .5rem'
        color='transparent'
      />
    </div>
  );
};

export const SelectPills = ({
  width = '100%',
  list = [],
  itemsSelected = [],
  label,
  onRemove = () => {},
  onSelect = () => {},
  onChange,
  name,
  value,
  placeholder,
}) => {
  return (
    <div className='fields' style={{ width }}>
      <label className='fields--label'>{label}</label>
      <div className='fields--selectPills'>
        <div className='fields--selectPills-field'>
          <div className='fields--selectPills-pillsBlock'>
            {itemsSelected?.map((item, index) => (
              <Pill key={index} pill={item} onRemove={onRemove} />
            ))}
            <input
              type='text'
              name={name}
              onChange={(e) => onChange(e.target.value)}
              value={value}
              placeholder={placeholder}
            />
          </div>
          {/* <ChevronBottomIcon
            margin='1.5rem 0 0'
            onClick={() => setShowList(!showList)}
          /> */}
        </div>
        {value?.length >= 3 && (
          <div className='fields--selectPills-list'>
            {list?.map((el) => {
              const reg = new RegExp(`${value}`, 'ig');
              if (!el?.label?.match(reg)) {
                return null;
              }
              return (
                <button
                  key={el?._id}
                  onClick={() => onSelect(el)}
                  className='fields--selectPills-item'
                >
                  {el?.label}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export const PillsField = ({
  onAdd,
  disabled = false,
  margin = '0 0 1rem',
  btnWidth = 160,
}) => {
  const [value, setValue] = useState('');

  const onSubmit = () => {
    if (value.length === 0) return;
    onAdd(value);
    setValue('');
  };
  return (
    <div className='fields--pillsField' style={{ margin }}>
      <input
        type='text'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        onKeyPress={(e) => {
          if (e.code === 'Enter') {
            onSubmit();
          }
        }}
      />
      <Button
        label='Ajouter'
        variant='yellow'
        width={btnWidth}
        disabled={disabled}
        onClick={onSubmit}
      />
    </div>
  );
};

export const InputCopy = ({
  label,
  name,
  placeholder,
  onChange,
  value,
  type = 'text',
  disabled = false,
  width,
  margin = '.7rem 0',
  variant = 'full',
  required = false,
  copyValue = '',
  onClick,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const variants = {
    quarter: '24%',
    half: '48%',
    third: '72%',
    full: '100%',
  };
  const onCopy = () => {
    if (!navigator.clipboard) {
      const tempInput = document.createElement('input');
      tempInput.value = copyValue;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
    } else {
      navigator.clipboard.writeText(copyValue);
    }
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 3000);
  };
  return (
    <div
      className='fields'
      style={{ width: width ? width : variants[variant], margin }}
    >
      <label className='fields--label'>{label}</label>
      <div className='fields--inputCopy'>
        <input
          type={type}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          onChange={onChange}
        />
        {copySuccess ? (
          <span className='fields--inputCopy-successText'>
            Punchline copié !
          </span>
        ) : (
          <CopyIcon
            onClick={() => {
              onCopy(value);
              onClick();
            }}
          />
        )}
      </div>
    </div>
  );
};

export const Checkbox = ({ checked, onCheck, label, margin }) => {
  return (
    <div className='fields--checkbox' style={{ margin }}>
      <span
        style={{ backgroundColor: checked ? colors.yellow : 'transparent' }}
        className='fields--checkbox-check'
        onClick={onCheck}
      />
      <span className='fields--label'>{label}</span>
    </div>
  );
};

export const SelectWithSearch = ({
  label,
  onSelect,
  value,
  variant = 'full',
  width,
  disabled = false,
  margin = '.7rem 0',
  options = [],
}) => {
  //
  const [search, setSearch] = useState('');
  const [showList, setShowList] = useState(false);

  const variants = {
    quarter: '24%',
    half: '48%',
    third: '72%',
    full: '100%',
  };

  return (
    <div
      className='fields'
      style={{ width: width ? width : variants[variant], margin }}
    >
      <label className='fields--label'>{label}</label>
      <div className='fields--selectWithSearch-block'>
        <div
          className='fields--selectWithSearch-btn'
          onClick={() => setShowList(!showList)}
        >
          <span>{value || 'Selectionnez une valeur'}</span>
          <ChevronBottomIcon />
        </div>
        {showList && (
          <div className='fields--selectWithSearch-select'>
            <input
              type='text'
              placeholder='Rechercher'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {copyObj(options)
              ?.sort((a, b) => sortedList(a, b, 'name'))
              ?.map((el) => {
                const reg = new RegExp(`${search}`, 'ig');
                if (search.length > 2 && !el?.name?.match(reg)) {
                  return null;
                }
                return (
                  <button
                    onClick={() => {
                      onSelect(el?.value);
                      setShowList(false);
                    }}
                    style={{
                      backgroundColor:
                        el?.value === value ? colors.yellow : 'transparent',
                      color: el?.value === value ? colors.white : colors.dark,
                    }}
                  >
                    {el?.name}
                  </button>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
