import { useEffect, useState } from 'react';
import { DotsCircleIcon } from '../../../assets/svgs/DotsCircleIcon';
import { ActionModal } from '../../../components/atoms/ActionModal';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { withNavigation } from '../../../hoc/withNavigation';
import {
  deleteModalAction,
  rayonIngredientModalAction,
} from '../../../redux/modals';
import { Loader } from '../../../components/atoms/Loader';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'recompose';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { sortedList } from '../../../assets/utils/sortedList';
import { useQuery } from 'react-query';
import {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
} from '../../../redux/filtersSelected';
import { copyObj } from '../../../assets/utils/copyObj';
import { getOneRayonById } from '../../../api/rayons.api';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { DeleteRayonIngredient } from './DeleteIngredient';
import { useAppDispatch } from '../../../hooks/useReduxHook';

const RayonIngredient = ({ user }) => {
  //
  const dispatch = useAppDispatch();
  const { rayonId } = useParams();
  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const { data, isLoading } = useQuery(
    ['rayon', rayonId],
    () => (rayonId ? getOneRayonById(rayonId) : null),
    {
      enabled: !!rayonId,
    }
  );

  const rayon = data?.data?.rayon;

  const ingredients = rayon?.ingredients;

  const handleOpenAddProductModal = () => {
    dispatch(resetFilteredSelectedItemAction('rayonIngredient'));
    dispatch(rayonIngredientModalAction(true));
  };

  useEffect(() => {
    if (data?.data) {
      dispatch(
        filteredSelectedItemAction({
          filter: 'rayon',
          item: data?.data?.rayon,
        })
      );
    }
  }, [data, dispatch]);

  if (isLoading) return <Loader />;

  return (
    <>
      <DeleteRayonIngredient />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            goBack
            title={`Ingredients '${rayon?.label}'`}
            onAdd={handleOpenAddProductModal}
          />
          <div className='table'>
            {ingredients &&
              copyObj(ingredients)
                ?.sort((a, b) => sortedList(a, b, 'label'))
                ?.map((ingredient, index) => {
                  const reg = new RegExp(`${search}`, 'ig');
                  if (search?.length >= 3 && !ingredient?.label?.match(reg)) {
                    return null;
                  }
                  return (
                    <Trow
                      key={index}
                      ingredient={ingredient}
                      onOpen={onOpen}
                      showActionModal={showActionModal}
                      setShowActionModal={setShowActionModal}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

const Trow = ({ ingredient, showActionModal, onOpen, setShowActionModal }) => {
  const dispatch = useAppDispatch();

  const handleSelectIngredient = () => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'rayonIngredient',
        item: ingredient,
      })
    );
  };

  const navigate = useNavigate();
  const onShowModal = () => {
    handleSelectIngredient();
    dispatch(rayonIngredientModalAction(true));
    setShowActionModal(null);
  };
  const onShowModalDelete = () => {
    handleSelectIngredient();
    dispatch(deleteModalAction(true));
    setShowActionModal(null);
  };
  return (
    <div className='table-trow'>
      {ingredient?.image && (
        <div className='table-trow-textIgmBlock'>
          {ingredient?.image?.length > 0 && (
            <img
              style={{ marginRight: '1rem' }}
              src={helperImageRoute(ingredient?.image, 'picture')}
              loading='lazy'
              alt={ingredient.label}
              width={40}
            />
          )}

          <span
            className='table-trow-text'
            style={{ cursor: 'pointer', flex: 1 }}
            onClick={() =>
              navigate(
                `/rayon-specialties/${ingredient.rayonId}/${ingredient?._id}`
              )
            }
          >
            {ingredient?.label}
          </span>
        </div>
      )}
      {!ingredient.image && (
        <span
          className='table-trow-text'
          style={{ cursor: 'pointer', flex: 1 }}
          onClick={() =>
            navigate(
              `/rayon-specialties/${ingredient.rayonId}/${ingredient?._id}`
            )
          }
        >
          {ingredient?.label}
        </span>
      )}
      <div className='table-trow-action'>
        {showActionModal === ingredient?.label && (
          <ActionModal onEdit={onShowModal} onDelete={onShowModalDelete} />
        )}
        <DotsCircleIcon onClick={() => onOpen(ingredient?.label)} />
      </div>
    </div>
  );
};

export const RayonIngredientView = compose(
  withNavigation,
  withCheckAuth
)(RayonIngredient);
