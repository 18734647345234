//: 'development' | 'production'
export const workSpaceEnvironnement = 'production';

const BASE_URL = {
  production: 'https://goodstock2.fr/',
  development: 'http://localhost:4000/',
};

export const BASE_URL_IMAGE = 'https://goodstock2.fr/';

export const env = {
  BASE_URL: BASE_URL[workSpaceEnvironnement],
  VERSION: 'api/v1',
};

// https://goodstock2.fr/api/
// http://localhost:4000/api/
