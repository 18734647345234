import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useQuery } from 'react-query';
import { fetchCustomers } from '../../../api/customers.api';
import { NotFound } from '../../atoms/NotFound';
import { imageFormAction } from '../../../redux/images';

export const CustomersAttribution: React.FC = () => {
  const imagesSelected = useAppSelector((s) => s.images);

  const [search, setSearch] = useState<string>('');
  const { data: customers } = useQuery(['customers', search], () =>
    fetchCustomers(search)
  );

  return (
    <div className='w-100 flex-col-start-start' style={{ margin: '1rem 0' }}>
      <p className='moods--title' style={{ marginBottom: '1rem' }}>
        Liaison aux clients
      </p>
      <div className='fields' style={{ width: '100%', marginBottom: '1rem' }}>
        <label className='fields--label'>Clients</label>
        <input
          className='fields--input'
          type='text'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Recherche'
        />
      </div>
      {customers?.length > 0 ? (
        customers?.map((customer) => {
          const findCustomer = imagesSelected.relatedCustomers.find(
            (_customerId) => _customerId === customer._id
          );
          if (search.length > 2 || !!findCustomer) {
            return (
              <Trow
                key={customer?._id}
                customer={customer}
                selected={!!findCustomer}
              />
            );
          }
          return null;
        })
      ) : (
        <NotFound text='Pas de clients trouvés !' />
      )}
    </div>
  );
};

const Trow = ({ customer, selected }) => {
  //
  const dispatch = useAppDispatch();
  const imagesSelected = useAppSelector((s) => s.images);

  const onSelectedCustomer = () => {
    const findCustomer = imagesSelected.relatedCustomers.find(
      (_customerId) => _customerId === customer._id
    );

    if (!!findCustomer) {
      const newRelatedCustomers = imagesSelected.relatedCustomers.filter(
        (_customerId) => _customerId !== customer._id
      );
      dispatch(
        imageFormAction({ key: 'relatedCustomers', value: newRelatedCustomers })
      );
    } else {
      dispatch(
        imageFormAction({
          key: 'relatedCustomers',
          value: [...imagesSelected.relatedCustomers, customer._id],
        })
      );
    }
  };
  return (
    <div
      className={`table-trow table-trow-btn ${
        selected ? 'table-trow-active' : ''
      }`}
      onClick={onSelectedCustomer}
    >
      <div className='table-trow-texts' style={{ flex: 1, marginLeft: '1rem' }}>
        <p
          className='table-trow-text'
          style={{
            fontFamily: 'Inter Bold',
            textTransform: 'uppercase',
            margin: '0 0 .5rem 0',
          }}
        >
          {customer?.informations?.restaurant_name} -{' '}
          {customer?.informations?.restaurant_city}
        </p>
        <p
          className='table-trow-text'
          style={{ textTransform: 'inherit', margin: 0 }}
        >
          {customer?.diary?.month || ''}
        </p>
      </div>
    </div>
  );
};
