import { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchFamilies } from '../../api/families.api';
import { FilterImageCollapse } from '../atoms/Collapse';
import { FilterAccordion } from '../organisms/Sidebar/FilterAccordion';

export const FilterFamiliesCollapse = ({ show, setShow }) => {
  const [openAccordion, setOpenAccordion] = useState(false);
  const { data } = useQuery('families', () => fetchFamilies(''));

  return (
    <FilterImageCollapse
      label={'Familles'}
      show={show}
      setShow={setShow}
      id={'families'}
    >
      {data?.map((item, index) => {
        return (
          <FilterAccordion
            key={index}
            open={openAccordion}
            setOpen={setOpenAccordion}
            data={item}
            parentKey={'families'}
            itemsKey={'products'}
          />
        );
      })}
    </FilterImageCollapse>
  );
};
