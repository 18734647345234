import { axiosInstance } from '../assets/utils/axiosInstance';
import { uploadFile } from './uploadFile.api';

export const fetchMoods = async (search = '') => {
  const res: any = await axiosInstance
    .get(`/moods?label=${search}`)
    .catch((err) => {
      console.log('❌ fetchMoods', err);
    });
  return res.data;
};

export const createOneMood = async (mood) => {
  let image = '';
  if (mood?.image) {
    image = await uploadFile(mood?.image, 'mood');
  }

  const res = await axiosInstance
    .post('/mood/create', {
      label: mood?.label,
      image,
    })
    .catch((err) => {
      console.log('createOneMood', err);
    });
  return res;
};

export const updateOneMood = async (id, mood) => {
  let image = mood?.image;
  if (mood.image?.name) {
    image = await uploadFile(mood.image, 'mood');
  }

  const res = await axiosInstance
    .put(`/mood/${id}`, {
      label: mood.label,
      image,
    })
    .catch((err) => {
      console.log('updateOneMood', err);
    });
  return res;
};

export const deleteOneMood = async (id) => {
  const res = await axiosInstance.put(`/mood/delete/${id}`).catch((err) => {
    console.log('deleteOneMood', err);
  });
  return res;
};
