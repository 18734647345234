import { IComposition } from '../../redux/compositions';

export function helperCompositionsEqual(
  comp1: IComposition,
  comp2: IComposition
) {
  if (comp1?.ingredients?.length !== comp2?.ingredients?.length) {
    return false;
  }

  for (let i = 0; i < comp1?.ingredients.length; i++) {
    const ingredient1 = comp1?.ingredients[i];
    const ingredient2 = comp2?.ingredients[i];
    if (
      ingredient1?.quantity !== ingredient2?.quantity ||
      ingredient1?.ingredientId !== ingredient2?.ingredientId ||
      ingredient1?.name !== ingredient2?.name
    ) {
      return false;
    }
  }
  return true;
}
