import { copyObj } from '../../assets/utils/copyObj';

export const helperInitFilter = (state: any, payload: any) => {
  const newState = copyObj(state);

  const {
    styles,
    families,
    filters,
    specialsFilters,
    moods,
    page,
    limit,
    filterBy,
    restoFilter,
  } = payload;

  newState.styles = styles;

  newState.families = families;

  newState.filters = filters;

  newState.specialsFilters = specialsFilters;

  newState.moods = moods;

  newState.page = page;

  newState.limit = limit;

  newState.filterBy = filterBy;

  newState.restoFilter = restoFilter;

  return newState;
};

export const helperFiltersParentSelected = (state, payload) => {
  const newState = copyObj(state);
  const { key, value, selected, addSelected } = payload;
  const parent = newState[key].find((item) => item._id === value._id);

  const newValue = { ...value };

  if (addSelected) {
    newValue.selected = true;
  }

  if (parent) {
    newState[key] = newState[key].filter((item) => item._id !== value._id);
    if (!selected) {
      newState[key].push(newValue);
    }
  } else {
    newState[key].push(newValue);
  }

  return newState;
};

export const helperFiltersParentItemSelected = (state, payload) => {
  //
  // selected filters|families => item
  //
  const newState = copyObj(state);
  const { key, parent, itemsKey, value, selected, addSelected } = payload;

  const newValue = {
    ...value,
  };

  if (addSelected) {
    newValue.selected = true;
  }

  const parentIndex = newState[key].findIndex(
    (item) => item._id === parent._id
  );

  const parentItem = newState[key][parentIndex];

  const item =
    parentItem && parentItem[itemsKey]?.find((item) => item._id === value._id);

  if (parentItem && item) {
    parentItem[itemsKey] = parentItem[itemsKey].filter(
      (item) => item._id !== value._id
    );

    if (selected === false) {
      parentItem[itemsKey].push(newValue);
    }

    if (addSelected) {
      parentItem.selected = false;
    }

    if (parentItem[itemsKey].length === 0) {
      newState[key].splice(parentIndex, 1);
    }
  } else {
    if (parentItem) {
      parentItem[itemsKey].push(newValue);
      if (
        parentItem[itemsKey].length === parentItem.itemsLength &&
        addSelected
      ) {
        parentItem.selected = true;
      }
    } else {
      if (addSelected && parent[itemsKey]) {
        parent.itemsLength = parent[itemsKey].length;
        parent.selected = parent[itemsKey].length === 1;
      }
      newState[key].push({
        ...parent,
        [itemsKey]: [newValue],
      });
    }
  }

  return newState;
};

export const helperFiltersCompositionSelected = (state, payload) => {
  const newState = copyObj(state);

  const { product, parent, composition, addSelected } = payload;

  const familyIndex = newState.families.findIndex(
    (item: any) => item?._id === parent._id
  );

  if (familyIndex > -1) {
    const productIndex = newState.families[familyIndex].products.findIndex(
      (item) => item._id === product._id
    );

    const compositionIndex = newState.families[familyIndex].products[
      productIndex
    ]?.compositions.findIndex((item) => item._id === composition._id);

    if (compositionIndex > -1) {
      let oldComposition =
        newState.families[familyIndex].products[productIndex].compositions[
          compositionIndex
        ];
      newState.families[familyIndex].products[productIndex].compositions.splice(
        compositionIndex,
        1
      );

      if (composition?.type && oldComposition?.type !== composition?.type) {
        newState.families[familyIndex].products[productIndex].compositions = [
          ...newState.families[familyIndex].products[productIndex].compositions,
          composition,
        ]?.filter((comp) => !!comp.type);
      }

      if (addSelected) {
        newState.families[familyIndex].products[productIndex].selected = false;
        newState.families[familyIndex].selected = false;
      }

      // remove product if no compositions
      if (
        newState.families[familyIndex].products[productIndex].compositions
          .length === 0
      ) {
        newState.families[familyIndex].products.splice(productIndex, 1);
      }

      // remove family if no products

      if (newState.families[familyIndex].products.length === 0) {
        newState.families.splice(familyIndex, 1);
      }
    } else {
      // add new composition to product

      if (productIndex > -1) {
        const product = newState.families[familyIndex].products[productIndex];

        product.compositions.push(composition);
      } else {
        const newProduct = {
          ...product,
          compositions: [composition],
        };

        newState.families[familyIndex].products.push(newProduct);
      }
    }
  } else {
    // add composition product to family with product
    const newProduct = {
      ...product,
      compositions: [composition],
    };

    const newFamily = {
      ...parent,
    };

    if (addSelected) {
      newFamily.itemsLength = parent.products.length;

      newProduct.itemsLength = product.compositions.length;
      newProduct.selected = product.compositions.length === 1;

      if (newProduct.selected && newFamily.itemsLength === 1) {
        newFamily.selected = true;
      } else {
        newFamily.selected = false;
      }
    }

    newState.families.push({
      ...newFamily,
      products: [newProduct],
    });
  }

  return newState;
};

export const helperFiltersMoodSelected = (state, payload) => {
  const newState = copyObj(state);
  const moodLabel = payload;
  if (newState.moods.find((item) => item === moodLabel)) {
    newState.moods = newState.moods.filter((item) => item !== moodLabel);
  } else {
    newState.moods.push(moodLabel);
  }

  return newState;
};
