import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { colors, months } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import {
  checkMonthIsInSubscription,
  generateMonthYearArray,
} from '../../../assets/utils/funcsUtils';
import { Toast, toast_loader } from '../../../assets/utils/toast';
import { useGenerateStrategy } from '../../../hooks/useGenerateStrategy';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { updateCustomerStrategiesAction } from '../../../redux/customer';
import { replayStrategyModalAction } from '../../../redux/modals';
import store from '../../../redux/storeConfig';
import { Button } from '../../atoms/Buttons';
import { Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';
import { toast } from 'react-toastify';

export const ReplayStrategyModal = ({ bgWidth = '100%' }) => {
  //
  const currentDate = new Date();
  const mois = currentDate.getMonth() + 1;
  const annee = currentDate.getFullYear();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState({
    month: months[mois],
    year: String(annee),
  });

  const [endDate, setEndDate] = useState({
    month: months[mois],
    year: String(annee + 1),
  });

  const customer = useAppSelector((state) => state.customer.customer);
  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );
  const currentSubscription = customer?.subscriptions[currentSubscriptionIndex];
  const isUpdateSubscription = useAppSelector(
    (state) => state.customer.isUpdateSubscription
  );

  const { handleGenerateRandomStrategy } = useGenerateStrategy();

  const generateNewStrategy = async ({ month, monthIndex, currentIndexes }) => {
    const subscriptionIndex =
      store.getState().customer.currentSubscriptionIndex;

    const currentSubscription =
      store.getState().customer.customer.subscriptions[subscriptionIndex];

    const strategies = copyObj(currentSubscription.strategies);

    for (let i = 0; i < currentSubscription.formula; i++) {
      const strategyGenerate = await handleGenerateRandomStrategy({
        index: i,
        month: month.month,
        year: month.year,
        defaultStrategies: strategies,
        monthIndex,
        currentIndexes,
      });

      const strategyFind = strategies.find(
        (_strategy) =>
          _strategy.month === month.month && _strategy.formula_number === i + 1
      );

      if (strategyFind) {
        strategyFind.strategy = strategyGenerate;
      } else {
        strategies.push({
          formula_number: i + 1,
          month: month.month,
          year: month.year,
          strategy: strategyGenerate,
        });
      }
    }

    dispatch(updateCustomerStrategiesAction(strategies));
  };

  const handleGenerateGlobalStrategies = async () => {
    try {
      if (!currentSubscription) {
        Toast(
          'error',
          'Vous devez avoir un forfait pour pouvoir générer des stratégies'
        );
        return;
      }

      let strategies = copyObj(currentSubscription.strategies);

      setLoading(true);
      const loader = toast.loading('Attribution des stratégies en cours...');
      dispatch(replayStrategyModalAction(false));

      const monthYearArray = generateMonthYearArray(startDate, endDate);

      const currentIndexes = {
        family: 0,
        type: {},
        product: {},
        composition: {},
        specialFilter: {},
        subSpecialFilter: {},
        model: {},
      };

      for (let monthData of monthYearArray) {
        const isMonthValidInSubscription = checkMonthIsInSubscription(
          monthData.month,
          monthData.year,
          currentSubscription
        );

        if (isMonthValidInSubscription) {
          if (startDate.month && endDate.month) {
            strategies = strategies.filter(
              (_strategy) =>
                _strategy.month !== monthData.month &&
                _strategy.year !== monthData.year
            );
          } else {
            strategies = strategies.filter(
              (_strategy) =>
                _strategy.month !== monthData.month &&
                _strategy.year !== monthData.year
            );
          }
        }
      }

      // reinititaliser les strategies des mois valides
      dispatch(updateCustomerStrategiesAction(strategies));

      // generer les nouvelles strategies en fonction de l'intervalle de mois
      for (let [monthIndex, monthData] of monthYearArray.entries()) {
        const isMonthValidInSubscription = checkMonthIsInSubscription(
          monthData.month,
          monthData.year,
          currentSubscription
        );

        if (isMonthValidInSubscription) {
          if (startDate.month && endDate.month) {
            await generateNewStrategy({
              month: monthData,
              monthIndex,
              currentIndexes,
            });
          } else {
            await generateNewStrategy({
              month: monthData,
              monthIndex,
              currentIndexes,
            });
          }
        }
      }

      setLoading(false);
      toast_loader(loader, 'Attribution des stratégies terminée', 'success');
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isUpdateSubscription || customer) {
      const subscription = customer?.subscriptions[currentSubscriptionIndex];

      if (subscription) {
        setStartDate({
          month: subscription?.startOn?.month,
          year: subscription?.startOn?.year,
        });
        setEndDate({
          month: subscription?.endIt.month,
          year: subscription?.endIt.year,
        });
      }
    }
  }, [isUpdateSubscription, currentSubscriptionIndex, customer]);

  const generateMonthOptions = (startYear, endYear) => {
    const currentYear = new Date().getFullYear();

    const monthOptions = [];

    if (startYear === currentYear) {
      const currentMonth = new Date().getMonth() + 1;
      for (let i = currentMonth; i <= 12; i++) {
        monthOptions.push({
          name: months[i - 1],
          value: months[i - 1].toLowerCase(),
        });
      }
    } else {
      for (let i = 1; i <= 12; i++) {
        monthOptions.push({
          name: months[i - 1],
          value: months[i - 1].toLowerCase(),
        });
      }
    }

    return monthOptions;
  };

  const years = Array.from({ length: 10 }, (_, index) => annee + index);

  const startMonthOptions = generateMonthOptions(
    parseInt(startDate.year),
    parseInt(endDate.year)
  );

  const endMonthOptions = generateMonthOptions(
    parseInt(endDate.year),
    parseInt(endDate.year) + 1
  );

  return (
    <Modal
      onClose={
        loading
          ? () => {}
          : () => {
              dispatch(replayStrategyModalAction(false));
            }
      }
      title='Rejouer la strategie'
      hiddenBtns
      width={512}
      minHeight={250}
      bgWidth={bgWidth}
    >
      <p
        style={{
          fontFamily: 'Inter Semi Bold',
          fontSize: 14,
          textTransform: 'uppercase',
          color: colors.dark,
        }}
      >
        Souhaitez vous rejouer ?
      </p>
      <div className='w-100 flex-row-center' style={{ margin: '1rem 0' }}>
        <span style={{ margin: '0 1rem 0 .5rem' }}>Début :</span>
        <Select
          width={'40%'}
          margin='0 1rem 0 0'
          defaultValue={startDate.month}
          value={startDate.month}
          onChange={(e) =>
            setStartDate((prevDate) => ({ ...prevDate, month: e.target.value }))
          }
          options={startMonthOptions}
        />

        <Select
          width={'30%'}
          margin='0 1rem 0 0'
          value={startDate.year}
          onChange={(e) =>
            setStartDate((prevDate) => ({ ...prevDate, year: e.target.value }))
          }
          options={years.map((year) => ({ name: year, value: year }))}
        />
      </div>
      <div className='w-100 flex-row-center' style={{ margin: '1rem 0' }}>
        <span style={{ margin: '0 2.4rem 0 .5rem' }}>Fin :</span>
        <Select
          width={'40%'}
          margin='0 1rem 0 0'
          defaultValue={endDate.month}
          value={endDate.month}
          onChange={(e) =>
            setEndDate((prevDate) => ({ ...prevDate, month: e.target.value }))
          }
          options={endMonthOptions}
        />
        <Select
          width={'30%'}
          margin='0 1rem 0 0'
          value={endDate.year}
          onChange={(e) =>
            setEndDate((prevDate) => ({ ...prevDate, year: e.target.value }))
          }
          options={years.map((year) => ({ name: year, value: year }))}
        />
      </div>
      <div className='modal--footer' style={{ marginTop: '2rem' }}>
        <Button
          label='NON'
          width={195}
          margin='0 .5rem'
          onClick={() => dispatch(replayStrategyModalAction(false))}
        />
        <Button
          label='OUI'
          variant='yellow'
          width={195}
          margin='0 .5rem'
          disabled={loading}
          onClick={handleGenerateGlobalStrategies}
        />
      </div>
    </Modal>
  );
};
