export const CopyIcon = ({ onClick = () => {} }) => {
  return (
    <svg
      width='36'
      height='38'
      viewBox='0 0 36 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M0 2C0 0.89543 0.895431 0 2 0H34C35.1046 0 36 0.895431 36 2V36C36 37.1046 35.1046 38 34 38H2C0.895431 38 0 37.1046 0 36V2Z'
        fill='#F5F5F5'
      />
      <path
        d='M24.188 13.25H14.0625C13.9133 13.25 13.7702 13.3093 13.6648 13.4148C13.5593 13.5202 13.5 13.6633 13.5 13.8125C13.5 13.9617 13.5593 14.1048 13.6648 14.2102C13.7702 14.3157 13.9133 14.375 14.0625 14.375H23.6255V23.9376C23.6255 24.0868 23.6848 24.2298 23.7902 24.3353C23.8957 24.4408 24.0388 24.5001 24.188 24.5001C24.3372 24.5001 24.4803 24.4408 24.5857 24.3353C24.6912 24.2298 24.7505 24.0868 24.7505 23.9376V13.8126C24.7505 13.6634 24.6912 13.5203 24.5857 13.4148C24.4803 13.3093 24.3372 13.2501 24.188 13.2501V13.25Z'
        fill='#A88E1B'
      />
      <path
        d='M21.938 15.5H11.8125C11.6633 15.5 11.5202 15.5593 11.4148 15.6648C11.3093 15.7702 11.25 15.9133 11.25 16.0625V26.1875C11.25 26.3367 11.3093 26.4798 11.4148 26.5852C11.5202 26.6907 11.6633 26.75 11.8125 26.75H21.938C22.0872 26.75 22.2303 26.6907 22.3357 26.5852C22.4412 26.4798 22.5005 26.3367 22.5005 26.1875V16.0625C22.5005 15.9133 22.4412 15.7702 22.3357 15.6648C22.2303 15.5593 22.0872 15.5 21.938 15.5ZM21.3755 25.625H12.375V16.625H21.3755V25.625Z'
        fill='#A88E1B'
      />
    </svg>
  );
};
