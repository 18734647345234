import {axiosInstance} from '../assets/utils/axiosInstance';
import {Toast, toast_loader} from '../assets/utils/toast';

export const fetchCitiesByFilters = async (filters) => {
  const params = new URLSearchParams([
    ['letter', filters.letter],
    ['name', filters.name],
    ['country', filters.country],
    ['page', filters.page],
    ['limit', filters.limit],
  ]);

  const res: any = await axiosInstance
    .get(`/cities/get-all`, {params})
    .catch((err) => {
      Toast('error', "Une erreur s'est produite ! ❌");
    });
  return res.data;
};

export const createOneCity = async (city, toastLoader) => {
  const res = await axiosInstance
    .post(`/city/create`, {
      name: city.name,
      country: city.country,
    })
    .catch((err) => {
      if (!!toastLoader) {
        toast_loader(toastLoader, "Une erreur s'est produite ! ❌", 'error');
      } else {
        Toast('error', "Une erreur s'est produite ! ❌");
      }
    });
  return res;
};
