import React from 'react';
import { colors } from '../../../assets/constants';
// import { copyObj } from '../../../assets/utils/copyObj';
// import { sortedList } from '../../../assets/utils/sortedList';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { IQuestion, questionSelectedAction } from '../../../redux/familyQuiz';

export const QuestionCard: React.FC<{
  question: IQuestion;
  isAnswered: boolean;
}> = ({ question, isAnswered }) => {
  //
  const dispatch = useAppDispatch();
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const composition = useAppSelector((s) => s.compositions.compositionSelected);

  return (
    <div
      className='questionCard'
      onClick={() => dispatch(questionSelectedAction(question))}
      style={{
        borderColor:
          questionSelected?._id === question?._id
            ? colors.yellow
            : isAnswered
            ? 'rgb(5, 190, 5)'
            : colors.greyDark,
        backgroundColor: isAnswered ? 'rgba(7, 211, 7, 0.496)' : '#f5f4ef',
      }}
    >
      <p className='questionCard--title'>{question?.question}</p>
      <div className='questionCard--list'>
        {question?.answers?.map((answer) =>
          answer?.values?.map((value, index: number) => {
            const findIngredient = composition?.ingredients?.find(
              (_ingredient) => {
                return (
                  (_ingredient.specialityId?._id ||
                    _ingredient.specialityId) === value?._id &&
                  _ingredient?.questionId === question?._id
                );
              }
            );

            if (!!findIngredient && !question?.noAnswer) {
              return (
                <p
                  className='createCompositionCard--itemsText'
                  style={{ padding: '0' }}
                  key={index}
                >
                  {`${
                    index > 0 && index + 1 < composition.ingredients?.length
                      ? ` - `
                      : ''
                  } ${findIngredient?.specialityId?.label|| findIngredient.name}`}
                  {findIngredient.quantity > 1
                    ? ` x${findIngredient.quantity} `
                    : ''}
                </p>
              );
            }
            return null;
          })
        )}
      </div>
    </div>
  );
};
