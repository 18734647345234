import React, { useState } from 'react';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { specialitiesModalAction } from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Modal } from '../../atoms/Modal';
import { SpecialityCard } from '../../organisms/CompositionsGestion/SpecialityCard';
import { ISpeciality } from '../../../redux/familyQuiz';

export const SpecialitiesModal: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const answerSelected = useAppSelector((s) => s.familyQuiz.answerSelected);
  const [search, setSearch] = useState('');

  return (
    <Modal
      onClose={() => dispatch(specialitiesModalAction(false))}
      title={`Spécialités ${
        answerSelected?.ingredient_id?.label || answerSelected?.label
      }`}
      width='50%'
      left='25%'
      hiddenBtns
      bgWidth='100%'
    >
      <div className='productCompositions--ingredientsBlock--inputSearch'>
        <label>Rechercher votre spécialité</label>
        <input
          type='text'
          placeholder='Recherche'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div
        className='w-100 flex-wrap'
        style={{ gap: '1rem', padding: '1rem 0' }}
      >
        {copyObj(answerSelected?.values || answerSelected?.specialties)
          .sort((a, b) => sortedList(a, b, 'label'))
          .map((speciality: ISpeciality, index: number) => {
            const reg = new RegExp(`${search}`, 'ig');
            if (search.length > 0 && !speciality?.label?.match(reg)) {
              return null;
            }
            return <SpecialityCard key={index} speciality={speciality} />;
          })}
      </div>
      <Button
        label='Terminer'
        variant='yellow'
        onClick={() => dispatch(specialitiesModalAction(false))}
        margin='auto'
      />
    </Modal>
  );
};
