import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  createOneFamily,
  duplicateOneFamily,
  updateOneFamily,
} from '../../../api/families.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { familiesModalAction } from '../../../redux/modals';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

export const FamiliesModal = () => {
  const dispatch = useAppDispatch();

  const familySelected = useAppSelector(
    (state) => state.filtersSelected?.family
  );
  const toDuplicate = familySelected?.toDuplicate;

  const [familyLabel, setFamilyLabel] = useState('');
  const [ingredients, setIngredients] = useState([]);
  const [loading, setLoading] = useState(false);

  const { resetFilters } = useResetFilters();

  const handleResetFamily = () => {
    dispatch(resetFilteredSelectedItemAction('family'));
  };

  const handleClose = () => {
    handleResetFamily();
    dispatch(familiesModalAction(false));
  };

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');

    try {
      setLoading(true);
      if (familySelected && !toDuplicate) {
        await updateOneFamily({
          familyId: familySelected._id,
          label: familyLabel,
          defaultList: ingredients,
        });
        toast_loader(loader, 'Famille modifiée', 'success');
      } else if (familySelected && toDuplicate) {
        const payload = {
          familyId: familySelected._id,
          label: familyLabel,
          defaultList: familySelected?.ingredients,
          products: familySelected?.products?.map((product) => product._id),
          quiz: familySelected?.quiz?._id,
        };
        await duplicateOneFamily(payload);
        toast_loader(loader, 'Famille dupliquée', 'success');
      } else {
        await createOneFamily({ label: familyLabel, defaultList: ingredients });
        toast_loader(loader, 'Famille ajoutée', 'success');
      }
      resetFilters('families');

      handleResetFamily();

      setFamilyLabel('');
      setIngredients([]);
      setLoading(false);
      handleClose();
    } catch {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
      setLoading(false);
    }
  };

  // const handleAddIngredient = (ingredient) => {
  //   setIngredients([...ingredients, ingredient]);
  // };

  // const handleRemoveIngredient = (ingredient) => {
  //   setIngredients((ingredients) =>
  //     ingredients.filter((ingLabel) => ingLabel !== ingredient.label)
  //   );
  // };

  useEffect(() => {
    if (familySelected) {
      setFamilyLabel(familySelected.label);
      setIngredients(familySelected.defaultList || []);
    }
  }, [familySelected]);

  return (
    <Modal
      zIndex={700}
      onClose={handleClose}
      title={toDuplicate ? 'Dupliquer la famille' : 'Famille'}
      onValidate={handleSubmit}
      loading={loading}
    >
      <Input
        label={`Nom ${toDuplicate ? 'de la nouvelle famille' : ''}`}
        type='text'
        onChange={(e) => setFamilyLabel(e.target.value)}
        value={familyLabel}
      />
      {/* {!toDuplicate && (
        <div className='familiesModal--ingredients'>
          <p>Ingrédients automatique</p>
          <PillsField margin='0' btnWidth={120} onAdd={handleAddIngredient} />
          <div className='familiesModal--ingredients-list'>
            {ingredients.map((ing) => (
              <Pill
                pill={{
                  label: ing,
                }}
                key={ing}
                onRemove={handleRemoveIngredient}
              />
            ))}
          </div>
        </div>
      )} */}
    </Modal>
  );
};
