import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { colors } from '../../../assets/constants';
import {
  addImageModalAction,
  imagesListModalAction,
} from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

export const AddImageModal = ({ bgWidth = '80%' }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onManualAttribution = () => {
    dispatch(imagesListModalAction(true));
    dispatch(addImageModalAction(false));
  };

  return (
    <Modal
      onClose={
        loading
          ? () => {}
          : () => {
              dispatch(addImageModalAction(false));
            }
      }
      title="Attribution d'une image"
      onValidate={loading ? () => {} : () => {}}
      btnValidateLabel={loading ? '...' : 'JOUER L’ATTRIBUTION'}
      width={553}
      bodyPadding='1rem 3rem'
      bgWidth={bgWidth}
    >
      <p>Etes vous sur de vouloir rejouer l’attribution ?</p>
      <Button
        label='ATTRIBUER MANUELLEMENT'
        variant='yellow'
        width='100%'
        margin='0 0 1rem'
        onClick={loading ? () => {} : onManualAttribution}
      />
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST1
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='styles'
          disabled={loading}
        />
      </div>
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST2
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='specialsFilters'
          disabled={loading}
        />
      </div>
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST3
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='families'
          disabled={loading}
        />
      </div>
      <div className='flex-row-center-between w-100'>
        <span
          style={{ fontFamily: 'Inter Bold', fontSize: 14, color: colors.dark }}
        >
          ST4
        </span>
        <Select
          label=''
          width='calc(100% - 60px)'
          name='products'
          disabled={loading}
        />
      </div>
    </Modal>
  );
};
