import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  createRayonOneIngredient,
  fetchRayons,
  updateRayonOneIngredient,
} from '../../../api/rayons.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { rayonIngredientModalAction } from '../../../redux/modals';
import { Input, Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';
import { UploadImg } from '../../atoms/UploadImg';

export const RayonIngredientModal = () => {
  const dispatch = useDispatch();
  const rayonIngredient = useAppSelector(
    (state) => state.filtersSelected.rayonIngredient
  );

  const rayonSelected = useAppSelector((state) => state.filtersSelected.rayon);

  const [ingredient, setIngredient] = useState({
    label: rayonIngredient?.label || '',
    image: rayonIngredient?.image || null,
    rayonId: rayonIngredient?.rayonId,
  });
  const [loading, setLoading] = useState(false);
  const [rayonId, setRayonId] = useState(rayonSelected?._id);

  // const [familyId, setFamilyId] = useState(familySelected?._id || '');

  const { data: rayons } = useQuery('rayons', () => {
    return fetchRayons('');
  });

  const { resetFilters } = useResetFilters();

  const onClose = () => {
    dispatch(rayonIngredientModalAction(false));
    dispatch(resetFilteredSelectedItemAction('rayonIngredient'));
  };

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      if (!ingredient.label)
        return toast_loader(loader, 'Veuillez renseigner un nom', 'error');

      let res;
      let update = false;

      setLoading(true);
      if (rayonIngredient) {
        res = await updateRayonOneIngredient(
          rayonIngredient?._id,
          rayonId,
          ingredient
        );
        update = true;
      } else {
        res = await createRayonOneIngredient(rayonId, ingredient);
      }

      if (res.data?.success) {
        toast_loader(
          loader,
          update ? 'Ingredient modifié' : 'Ingredient crée',
          'success'
        );
        resetFilters(['rayons', 'rayon']);
        onClose();
      } else {
        toast_loader(
          loader,
          res.data?.message || "Une erreur s'est produite !",
          'error'
        );
      }

      setLoading(false);
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite !", 'error');
      setLoading(false);
    }
  };

  return (
    <Modal
      onClose={onClose}
      title='Ingredient'
      onValidate={handleSubmit}
      loading={loading}
    >
      <UploadImg
        image={ingredient.image}
        onChange={(file) => setIngredient((s) => ({ ...s, image: file }))}
        margin='0 auto 0 0'
        folder='picture'
      />

      <Input
        label='Nom'
        type='text'
        onChange={(e) =>
          setIngredient((s) => ({ ...s, label: e.target.value }))
        }
        value={ingredient.label}
      />

      <Select
        label='Rayons'
        options={rayons?.map((family) => ({ ...family, value: family._id }))}
        defaultValue={
          rayonSelected
            ? { value: rayonSelected._id, name: rayonSelected.label }
            : null
        }
        value={rayonId}
        onChange={(e) => setRayonId(e.target.value)}
      />
    </Modal>
  );
};
