import {toast} from 'react-toastify';
import {deleteIngredient} from '../../../api/ingredients.api';
import {toast_loader} from '../../../assets/utils/toast';
import {DeleteModal} from '../../../components/modals/DeleteModal';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {useResetFilters} from '../../../hooks/useResetFilters';
import {resetFilteredSelectedItemAction} from '../../../redux/filtersSelected';
import {deleteModalAction} from '../../../redux/modals';

export const DeleteIngredient = () => {
  const dispatch = useAppDispatch();
  const ingredientSelected = useAppSelector(
    (s) => s.filtersSelected.ingredient
  );
  const {resetFilters} = useResetFilters();

  const handleDeleteProduct = async () => {
    const loader = toast.loading('Veuillez patienter...');
    await deleteIngredient(ingredientSelected?._id)
      .then((_) => {
        toast_loader(loader, 'Produit Supprimé', 'success');
        resetFilters('family');
        dispatch(resetFilteredSelectedItemAction('ingredient'));
        dispatch(deleteModalAction(false));
      })
      .catch((_) => {
        toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
      });
  };

  return (
    <DeleteModal
      onDelete={handleDeleteProduct}
      text={`l'ingrédient  "${ingredientSelected?.name ?? ''}"`}
    />
  );
};
