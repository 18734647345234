export const EyeCloseIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 10C2 10 5.5 14 12 14C18.5 14 22 10 22 10M4 11.645L2 14M22 14L20.004 11.648M8.914 13.68L8 16.5M15.063 13.688L16 16.5'
        stroke='#d1d5db'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
