import { colors } from '../constants';

export const DownloadIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_101_4348)'>
        <path
          d='M22 17L22 19C22 19.7956 21.6839 20.5587 21.1213 21.1213C20.5587 21.6839 19.7956 22 19 22L5 22C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19L2 17C2 16.7348 1.89464 16.4804 1.70711 16.2929C1.51957 16.1054 1.26522 16 1 16C0.734783 16 0.480429 16.1054 0.292893 16.2929C0.105357 16.4804 -3.17573e-07 16.7348 -3.0598e-07 17L-2.18557e-07 19C0.0015877 20.3256 0.528882 21.5964 1.46622 22.5338C2.40356 23.4711 3.67441 23.9984 5 24L19 24C20.3256 23.9984 21.5964 23.4711 22.5338 22.5338C23.4711 21.5964 23.9984 20.3256 24 19L24 17C24 16.7348 23.8946 16.4804 23.7071 16.2929C23.5196 16.1054 23.2652 16 23 16C22.7348 16 22.4804 16.1054 22.2929 16.2929C22.1054 16.4804 22 16.7348 22 17Z'
          fill={colors.greyDark}
        />
        <path
          d='M18.707 5.462L14.121 0.875C13.5576 0.314294 12.795 -0.000473363 12 -0.000473328C11.2051 -0.000473293 10.4425 0.314295 9.87904 0.875L5.29304 5.462C5.11088 5.6506 5.01009 5.9032 5.01237 6.1654C5.01464 6.4276 5.11981 6.67841 5.30522 6.86382C5.49063 7.04923 5.74144 7.1544 6.00364 7.15667C6.26584 7.15895 6.51844 7.05816 6.70704 6.876L11 2.584L11 18C11 18.2652 11.1054 18.5196 11.2929 18.7071C11.4805 18.8946 11.7348 19 12 19C12.2653 19 12.5196 18.8946 12.7071 18.7071C12.8947 18.5196 13 18.2652 13 18L13 2.583L17.293 6.876C17.3853 6.97151 17.4956 7.04769 17.6176 7.1001C17.7396 7.15251 17.8709 7.1801 18.0036 7.18125C18.1364 7.1824 18.2681 7.1571 18.391 7.10682C18.5139 7.05654 18.6255 6.98229 18.7194 6.88839C18.8133 6.7945 18.8876 6.68285 18.9379 6.55995C18.9881 6.43706 19.0134 6.30538 19.0123 6.1726C19.0111 6.03982 18.9836 5.9086 18.9311 5.7866C18.8787 5.66459 18.8026 5.55425 18.707 5.462Z'
          fill={colors.greyDark}
        />
      </g>
      <defs>
        <clipPath id='clip0_101_4348'>
          <rect
            width='24'
            height='24'
            fill='white'
            transform='translate(0 24) rotate(-90)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
