import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors, countries, years } from '../../../assets/constants';
import { ArrowLeftIcon } from '../../../assets/svgs/ArrowLeftIcon';
import { EditIcon } from '../../../assets/svgs/EditIcon2';
import { LogOutIcon } from '../../../assets/svgs/LogOutIcon';
import { SearchIcon } from '../../../assets/svgs/SearchIcon';
import { axiosInstance } from '../../../assets/utils/axiosInstance';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { Toast } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  customerDetailsAction,
  customerTabsAction,
  universTabsAction,
} from '../../../redux/customer';
import { resetFiltersAction } from '../../../redux/filters';
import { resetImageFormAction } from '../../../redux/images';
import { customerModalAction } from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Select } from '../../atoms/Fields';
import { FilterCustomer } from '../../atoms/FilterCustomer';
import { setFilteredByCustomerUnivers } from '../../../redux/picture';

const handleLogOut = async (userId, navigate) => {
  const res = await axiosInstance.post(`/user/logout/${userId}`);
  if (res.status === 200) {
    localStorage.clear();
    navigate('/');
    Toast('warning', 'A bientôt !');
  } else {
    Toast('error', "Une erreur s'est produite ! ");
  }
};

const Avatar = ({ setShowActionModal, user, isCustomer = false }) => {
  return (
    <button
      className='header--avatar'
      onClick={() => setShowActionModal((s) => !s)}
    >
      {user?.avatar && !isCustomer ? (
        <img
          src={
            user?.avatar?.name
              ? URL.createObjectURL(user?.avatar)
              : user?.avatar?.length > 0 &&
                helperImageRoute(user?.avatar, 'avatar')
          }
          alt='Avatar'
        />
      ) : !user?.avatar && isCustomer && user?.logo_card?.avatar ? (
        <img
          src={
            user?.logo_card?.avatar?.name
              ? URL.createObjectURL(user?.logo_card?.avatar)
              : user?.logo_card?.avatar?.length > 0 &&
                helperImageRoute(user?.logo_card?.avatar, 'customer-avatar')
          }
          alt='Avatar'
        />
      ) : isCustomer && !user?.logo_card?.avatar ? (
        <div className='initial'>
          <span>{user?.informations?.lastname?.substring(-1, 1)}</span>
          <span>{user?.informations?.firstname?.substring(-1, 1)}</span>
        </div>
      ) : (
        <div className='initial'>
          <span>{user?.lastname?.substring(-1, 1)}</span>
          <span>{user?.firstname?.substring(-1, 1)}</span>
        </div>
      )}
    </button>
  );
};

const ModalUserAction = ({ handleLogOut, user }) => {
  const navigate = useNavigate();
  return (
    <div className='header--actionModal'>
      <button onClick={() => navigate(`/user/${user?._id}`)}>
        <EditIcon /> <span style={{ color: colors.yellow }}>Mes infos</span>
      </button>
      <button onClick={handleLogOut}>
        <LogOutIcon /> <span style={{ color: colors.red }}>Me déconnecter</span>
      </button>
    </div>
  );
};
export const HeaderWithSearch = ({
  user,
  search = '',
  setSearch = (e) => {},
}) => {
  const navigate = useNavigate();
  const [showActionModal, setShowActionModal] = useState(false);
  return (
    <div className='header'>
      <div id='input' className='header--input'>
        <SearchIcon />
        <input
          type='text'
          placeholder='Rechercher'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <Avatar setShowActionModal={setShowActionModal} user={user} />
      {showActionModal && (
        <ModalUserAction
          handleLogOut={() => handleLogOut(user?._id, navigate)}
          user={user}
        />
      )}
    </div>
  );
};
export const HeaderCities = ({
  user,
  setFilters,
  filters,
  hiddeAvatar = false,
}) => {
  const navigate = useNavigate();
  const [showActionModal, setShowActionModal] = useState(false);
  return (
    <div className='header' style={{ backgroundColor: 'white' }}>
      <div id='input' className='header--input' style={{ width: '55%' }}>
        <SearchIcon />
        <input
          type='text'
          placeholder='Search'
          onChange={(e) => setFilters((s) => ({ ...s, name: e.target.value }))}
          value={filters?.name}
        />
      </div>
      <Select
        label=''
        options={countries
          .sort((a, b) => {
            if (a.toLowerCase() < b.toLowerCase()) {
              return -1;
            }
            if (a.toLowerCase() > b.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .map((country) => country.toLowerCase())}
        defaultValue='france'
        onChange={(e) => setFilters((s) => ({ ...s, country: e.target.value }))}
        width='30%'
      />
      {!!!hiddeAvatar && (
        <Avatar setShowActionModal={setShowActionModal} user={user} />
      )}

      {showActionModal && (
        <ModalUserAction
          handleLogOut={() => handleLogOut(user?._id, navigate)}
          user={user}
        />
      )}
    </div>
  );
};
export const HeaderPackages = ({ packages, setPackages, user }) => {
  const navigate = useNavigate();
  const [showActionModal, setShowActionModal] = useState(false);
  return (
    <div className='header' style={{ height: 'auto', padding: '1rem 3rem' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Select
          label='Choisissez la formule'
          options={[
            { name: 'Formule 6', value: 6 },
            { name: 'Formule 12', value: 12 },
          ]}
          defaultValue={packages.formula === 6 ? 'Formule 6' : 'Formule 12'}
          onChange={(e) =>
            setPackages((s) => ({ ...s, formula: e.target.value }))
          }
          width={200}
          margin='0 1rem'
        />
        <Select
          label='Année'
          options={years}
          defaultValue={packages?.year}
          onChange={(e) => setPackages((s) => ({ ...s, year: e.target.value }))}
          width={200}
          margin='0 1rem'
        />
      </div>
      <Avatar setShowActionModal={setShowActionModal} user={user} />
      {showActionModal && (
        <ModalUserAction
          handleLogOut={() => handleLogOut(user?._id, navigate)}
          user={user}
        />
      )}
    </div>
  );
};
export const HeaderWithTabs = ({
  tabs,
  setTabs,
  tabsData,
  hidden = false,
  navigateTo = -1,
  isCustomer = false,
  isModal = false,
  children,
}) => {
  const customer = useAppSelector((state) => state.customer.customer);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showActionModal, setShowActionModal] = useState(false);
  const onNavigateTo = () => {
    navigate(navigateTo);
    dispatch(customerTabsAction('informations'));
    dispatch(universTabsAction('compositions'));
    dispatch(customerDetailsAction(null));
    dispatch(resetImageFormAction());
  };
  return (
    <>
      <div className='headerUser--header'>
        <div className='headerUser--headerLeft'>
          <Button
            variant='grey'
            Icon={ArrowLeftIcon}
            onClick={
              isModal
                ? () => dispatch(customerModalAction(false))
                : onNavigateTo
            }
          />
          {customer && (
            <span style={{ textTransform: 'uppercase' }}>
              {customer?.informations?.restaurant_name}
            </span>
          )}
        </div>
        <Avatar
          setShowActionModal={
            isCustomer ? () => {} : () => setShowActionModal(!showActionModal)
          }
          user={customer}
          isCustomer={isCustomer}
        />
        {showActionModal && (
          <ModalUserAction
            handleLogOut={() => handleLogOut(customer?._id, navigate)}
            user={customer}
          />
        )}
      </div>
      {!!!hidden && (
        <>
          <div className='headerUser--tabs headerUser--tabsSticky'>
            {tabsData.map((tab, index) => (
              <Button
                key={index}
                variant='neutre'
                color={tabs === tab.link ? '#A88E1B' : 'black'}
                label={tab.label}
                Icon={tab.icon}
                onClick={() => setTabs(tab.link)}
              />
            ))}
          </div>
          {children}
        </>
      )}
    </>
  );
};

export const HeaderAddImgView = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { userData } = useAppSelector((s) => s.app);
  const [showActionModal, setShowActionModal] = useState(false);

  const onCancel = () => {
    dispatch(resetFiltersAction());
    dispatch(resetImageFormAction());
    navigate('/images');
  };

  return (
    <div className='header' style={{ padding: '1rem 2rem' }}>
      <Button
        Icon={ArrowLeftIcon}
        label='Revenir au menu'
        variant='yellow'
        onClick={onCancel}
      />
      <span className='header--title'>Ajouter une image</span>
      <Avatar setShowActionModal={setShowActionModal} user={userData} />
      {showActionModal && (
        <ModalUserAction
          handleLogOut={() => handleLogOut(userData?._id, navigate)}
          user={userData}
        />
      )}
    </div>
  );
};

export const HeaderImgList = ({ search, setSearch }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((s) => s.app.userData);
  const role = useAppSelector((s) => s.app.role);
  const customer = useAppSelector((s) => s.customer.customer);

  const onReturnOnMenu = () => {
    dispatch(customerDetailsAction(null));
    dispatch(resetImageFormAction());
    dispatch(resetFiltersAction());
    dispatch(setFilteredByCustomerUnivers(false));
    navigate('/images');
  };

  return (
    <div className='imagesLayout--header'>
      {(role === 'SUPER-ADMIN' || role === 'ADMIN') && (
        <Button
          label='Retour au menu'
          Icon={ArrowLeftIcon}
          margin='0 4rem 0 0'
          onClick={onReturnOnMenu}
        />
      )}
      {role !== 'SUPER-ADMIN' && role !== 'ADMIN' && (
        <>
          <Button
            label=''
            Icon={LogOutIcon}
            margin='0 1rem 0 0'
            variant='whiteBlue'
            onClick={() => handleLogOut(userData?._id, navigate)}
          />
          <Button
            label='Mes infos'
            Icon={EditIcon}
            margin='0 1rem 0 0'
            onClick={() => navigate(`/user/${userData?._id}`)}
          />
        </>
      )}
      <FilterCustomer isImageList />
      <div
        id='input'
        className='header--input'
        style={{
          width:
            customer && (role === 'SUPER-ADMIN' || role === 'ADMIN')
              ? '37%'
              : role !== 'SUPER-ADMIN' || role !== 'ADMIN'
              ? '45%'
              : '50%',
          margin: '0 1rem 0 auto',
        }}
      >
        <SearchIcon />
        <input
          type='text'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {customer && (role === 'SUPER-ADMIN' || role === 'ADMIN') && (
        <Button
          label='Voir le client'
          margin='0 0 0 1rem'
          onClick={() => navigate(`/customer/${customer?._id}`)}
        />
      )}
    </div>
  );
};
