import React from 'react';
import loader from '../../../assets/images/loader.gif';

export const Loader = () => {
  return (
    <div
      style={{
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <img src={loader} alt='loader' className='loader' />
    </div>
  );
};

export const LoaderTwo = () => {
  return (
    <div className='lds-roller'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Loader;
