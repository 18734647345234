import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toast_loader } from '../../../assets/utils/toast';

import { Button } from '../../atoms/Buttons';
import { useAppSelector } from '../../../hooks/useReduxHook';

import { updateCustomerSubscription } from '../../../api/customers.api';
import { DiaryPanel } from '../Customer/DiaryPanel';

export const CustomerDiary = () => {
  const customer = useSelector((s) => s.customer?.customer);
  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );
  const currentSubscription = customer?.subscriptions[currentSubscriptionIndex];

  const handleEditCustomer = async () => {
    if (!currentSubscription) {
      return;
    }

    const loader = toast.loading('Veuillez patienter...');

    try {
      const diaries = currentSubscription.diaries?.map((diary) => {
        return {
          ...diary,
          picture: diary?.picture?._id || null,
        };
      });

      await updateCustomerSubscription(currentSubscription?._id, { diaries });
      toast_loader(loader, 'Attributions modifiées avec succès', 'success');
    } catch (error) {
      toast_loader(loader, 'Une erreur est survenue ❌', 'error');
    }
  };

  return (
    <>
      <div
        className='pages-layout--container customer--diarypanel--container'
        style={{ padding: '1rem 2rem' }}
      >
        <DiaryPanel />
      </div>
      <div className='customer--footer'>
        <Button
          label='Enregistrer'
          variant='yellow'
          onClick={handleEditCustomer}
        />
      </div>
    </>
  );
};
