import { useEffect, useState } from 'react';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { withNavigation } from '../../../hoc/withNavigation';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { compose } from 'recompose';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  getAllCompositionsByProductId,
  getOneProduct,
} from '../../../api/families.api';
import { Loader } from '../../../components/atoms/Loader';
import { CompositionTrow } from '../../../components/organisms/CompositionsGestion/CompositionTrow';
import { sortByProperty } from '../../../assets/utils/sortedList';
import {
  compositionSelectedAction,
  compositionsAction,
} from '../../../redux/compositions';
import { questionSelectedAction, quizAction } from '../../../redux/familyQuiz';
import { initComposition } from '../../../assets/constants';
import { useDispatch } from 'react-redux';

type TUser = {
  _id: string;
  active: boolean;
  email: string;
  avatar: string;
  createdAt: string;
  firstname: string;
  lastname: string;
  password: string;
  phone: string;
  role: string;
  token: string;
};
interface IProps {
  user: TUser;
}

const ProductCompositionsListView: React.FC<IProps> = ({ user }) => {
  //
  const dispatch = useDispatch();
  const { productId, familyId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading, isFetched, refetch } = useQuery(
    ['compositions', productId],
    () => getAllCompositionsByProductId(productId)
  );
  const { data: product, isLoading: productIsLoading } = useQuery(
    ['product', productId],
    () => getOneProduct(productId)
  );

  const [search, setSearch] = useState('');
  const [compositionsData, setCompositionsData] = useState([]);
  const [showActionModal, setShowActionModal] = useState<string | null>(null);

  const onOpen = (index: string) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };
  useEffect(() => {
    if (isFetched) {
      const sortedData = data?.data
        ?.slice()
        .sort((a: any, b: any) => sortByProperty(a, b, 'short_name'));
      setCompositionsData(sortedData);
    }
  }, [data, isFetched]);

  const handleAddComposition = () => {
    dispatch(compositionSelectedAction(initComposition));
    dispatch(compositionsAction([]));
    dispatch(quizAction(null));
    dispatch(questionSelectedAction(null));
    navigate(`/family-product-compositions-gestion/${familyId}/${productId}`);
  };

  if (isLoading || productIsLoading) return <Loader />;

  return (
    <>
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            goBack
            title={`Compositions '${product.data?.product?.name}'`}
            onAdd={handleAddComposition}
          />
          <div className='productCompositions--table'>
            {isFetched &&
              compositionsData?.map((composition, index) => {
                return (
                  <CompositionTrow
                    key={index}
                    composition={composition}
                    onOpen={onOpen}
                    showActionModal={showActionModal}
                    refetch={refetch}
                    withActionModal
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(
  withNavigation,
  withCheckAuth
)(ProductCompositionsListView);
