import { useEffect, useState } from 'react';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { withNavigation } from '../../../hoc/withNavigation';
import { rayonIngredientSpecialtyModalAction } from '../../../redux/modals';
import { Loader } from '../../../components/atoms/Loader';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { compose } from 'recompose';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { filteredSelectedItemAction } from '../../../redux/filtersSelected';
import { sortedList } from '../../../assets/utils/sortedList';
import { copyObj } from '../../../assets/utils/copyObj';
import { getOneRayonById } from '../../../api/rayons.api';
import { TableSimpleRow } from '../../../components/atoms/TableSimpleRow';
import { DeleteRayonIngredientSpecialty } from './DeleteSpecialty';
import { useAppDispatch } from '../../../hooks/useReduxHook';

const IngredientSpecialty = ({ user }) => {
  const dispatch = useAppDispatch();

  const { rayonId, ingredientId } = useParams();

  const { data, isLoading } = useQuery(['rayon', rayonId], () =>
    rayonId ? getOneRayonById(rayonId) : null
  );

  const ingredientSelected = data?.data?.rayon?.ingredients.find(
    (prod) => prod._id === ingredientId
  );

  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  useEffect(() => {
    if (data) {
      const ingredient = data?.data?.rayon?.ingredients.find(
        (prod) => prod._id === ingredientId
      );
      if (ingredient && data?.data?.rayon) {
        dispatch(
          filteredSelectedItemAction({
            filter: 'rayonIngredient',
            item: ingredient,
          })
        );

        dispatch(
          filteredSelectedItemAction({
            filter: 'rayon',
            item: data?.data?.rayon,
          })
        );
      }
    }
  }, [data, ingredientId, dispatch]);

  if (isLoading) return <Loader />;

  return (
    <>
      <DeleteRayonIngredientSpecialty />

      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            goBack
            title={`Specialités '${ingredientSelected?.label}'`}
            onAdd={() => {
              dispatch(rayonIngredientSpecialtyModalAction(true));
            }}
          />
          <div className='table'>
            {ingredientSelected?.specialties &&
              copyObj(ingredientSelected?.specialties)
                ?.sort((a, b) => sortedList(a, b, 'label'))
                ?.map((item, index) => (
                  <TableSimpleRow
                    key={item._id}
                    item={item}
                    index={index}
                    filter='rayonIngredientSpecialty'
                    folder='picture'
                    withImage={!!item.image}
                    onOpen={onOpen}
                    onOpenModal={() =>
                      dispatch(rayonIngredientSpecialtyModalAction(true))
                    }
                    showActionModal={showActionModal}
                    // setShowActionModal={setShowActionModal}
                  />
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const IngredientSpecialtyView = compose(
  withNavigation,
  withCheckAuth
)(IngredientSpecialty);
