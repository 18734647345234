export const MinusIcon = ({ onClick = () => {} }) => {
  return (
    <svg
      width='19'
      height='3'
      viewBox='0 0 19 3'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M19 0.707031H10.2917H8.70833H0V2.29036H8.70833H10.2917H19V0.707031Z'
        fill='#282828'
      />
    </svg>
  );
};
