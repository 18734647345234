import { createSlice } from '@reduxjs/toolkit';
import { IComposition } from '../compositions';
import {
  helperFiltersCompositionSelected,
  helperFiltersMoodSelected,
  helperFiltersParentItemSelected,
  helperFiltersParentSelected,
  helperInitFilter,
} from '../filters/helper';

export interface IImageState {
  _id?: string;
  name: string;
  image: any;
  groupId: string | null;
  styles: any[];
  families: any[];
  filters: any[];
  specialsFilters: any[];
  moods: string[];
  infosMainProducts: IProduct[];
  infosSecondaryProducts: IProduct[];
  attrMainCompositions: {
    familyId: string;
    productId: string;
    compositionId: IComposition;
  }[];
  attrSecondaryCompositions: {
    familyId: string;
    productId: string;
    compositionId: IComposition;
  }[];
  longName: string;
  shortName: string;
  punchlines: {
    punchline_1: string;
    punchline_2: string;
    punchline_3: string;
  };
  hashtags: string[];
  samePictures?: any[];
  relatedCustomers?: string[];
  tab?: 'informations' | 'attribution';
}

interface IProduct {
  familyId: string;
  quantity: number;
  checkedNum?: 0;
}

const initialState: IImageState = {
  name: '',
  image: null,
  groupId: null,
  styles: [],
  families: [],
  filters: [],
  specialsFilters: [],
  moods: [],
  infosMainProducts: [],
  infosSecondaryProducts: [],
  attrMainCompositions: [],
  attrSecondaryCompositions: [],
  longName: '',
  shortName: '',
  punchlines: {
    punchline_1: '',
    punchline_2: '',
    punchline_3: '',
  },
  hashtags: [],
  tab: 'informations',
  relatedCustomers: [],
};

export const slice = createSlice({
  name: 'images',
  initialState,

  reducers: {
    initImagesAction: (_state, action) => {
      return action.payload;
    },

    imageFormAction: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    imageUploadAction: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },

    imagePunchlinesAction: (state, action) => {
      state.punchlines[action.payload.key] = action.payload.value;
    },
    imageAddHashTagsAction: (state, action) => {
      state.hashtags.push(...action.payload);
    },
    imageRemoveHashTagsAction: (state, action) => {
      state.hashtags = state.hashtags.filter((item) => item !== action.payload);
    },

    initImagesFiltersAction: (state, action) => {
      return helperInitFilter(state, action.payload);
    },

    imageFiltersParentSelectedAction: (state, action) => {
      // selected filters|specialsFilters|moods|families|styles
      return helperFiltersParentSelected(state, action.payload);
    },
    imageFiltersParentItemSelectedAction: (state, action) => {
      // selected filters|families => item
      return helperFiltersParentItemSelected(state, action.payload);
    },

    imageFiltersIngredientSelectedAction: (state, action) => {
      return helperFiltersCompositionSelected(state, action.payload);
    },
    imageFiltersCompositionSelectedAction: (state, action) => {
      return helperFiltersCompositionSelected(state, action.payload);
    },
    imageFiltersMoodsAction: (state, action) => {
      return helperFiltersMoodSelected(state, action.payload);
    },
    infosMainProductsAction: (state, action) => {
      state.infosMainProducts = action.payload;
    },
    infosSecondaryProductsAction: (state, action) => {
      state.infosSecondaryProducts = action.payload;
    },
    attrMainCompositionsAction: (state, action) => {
      state.attrMainCompositions = action.payload;
    },
    attrSecondaryCompositionsAction: (state, action) => {
      state.attrSecondaryCompositions = action.payload;
    },

    resetImageFormAction: () => {
      return initialState;
    },
  },
});

export const {
  initImagesAction,
  imageFormAction,
  imageUploadAction,
  imagePunchlinesAction,
  imageAddHashTagsAction,
  imageRemoveHashTagsAction,
  resetImageFormAction,
  imageFiltersParentSelectedAction,
  imageFiltersParentItemSelectedAction,
  imageFiltersIngredientSelectedAction,
  imageFiltersMoodsAction,
  initImagesFiltersAction,
  infosMainProductsAction,
  infosSecondaryProductsAction,
  attrMainCompositionsAction,
  attrSecondaryCompositionsAction,
  imageFiltersCompositionSelectedAction,
} = slice.actions;
export default slice.reducer;
