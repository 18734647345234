import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'packages',
  initialState: {
    formulaInfos: null,
  },

  reducers: {
    getFormulaSelected: (state, action) => {
      state.formulaInfos = action.payload;
    },
  },
});

export const { getFormulaSelected } = slice.actions;
export default slice.reducer;
