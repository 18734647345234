import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImageState } from '.';
import { initPicture } from '../../assets/constants';

interface IImageIdentical extends IImageState {
  isImageLocal?: boolean;
}

const initialState: IImageIdentical = {
  ...initPicture,
  isImageLocal: false,
};

export const slice = createSlice({
  name: 'identicalImage',
  initialState,

  reducers: {
    setIdenticalImageAction: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    resetIdenticalImageAction: (state) => {
      return { ...initPicture, isImageLocal: false };
    },
  },
});

export const { setIdenticalImageAction, resetIdenticalImageAction } =
  slice.actions;
export default slice.reducer;
