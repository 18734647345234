import React from 'react';
import { Toast } from '../../../assets/utils/toast';
import { Button } from '../../../components/atoms/Buttons';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { questionSelectedAction } from '../../../redux/familyQuiz';
import { AnswersList } from './AnswersList';
import { ProductsList } from './ProductsList';

export const ProductsAnswersBlock: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const product = useAppSelector((s) => s.filtersSelected?.product);
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);
  const questionsList = useAppSelector(
    (s) => s.familyQuiz?.quiz?.questionsList
  );

  const findIndex = questionsList?.findIndex((_question) => {
    return _question._id === questionSelected?._id;
  });

  const onNextQuestion = () => {
    const nextQuestion = questionsList[findIndex + 1];
    if (questionsList.length === findIndex + 1) {
      Toast('warning', 'Vous avez atteint la dernière question !');
    } else {
      dispatch(questionSelectedAction(nextQuestion));
    }
  };

  const onPreviousQuestion = () => {
    const previousQuestion = questionsList[findIndex - 1];
    if (findIndex === 0) {
      Toast('warning', 'Vous avez atteint la première question !');
    } else {
      dispatch(questionSelectedAction(previousQuestion));
    }
  };
  return (
    <div className='customerCompositionsView--productsAnswersBlock'>
      {!!!product && <ProductsList />}
      {!!product && !!questionSelected && <AnswersList />}
      {!!product && (
        <div className='customerCompositionsView--btns'>
          <Button label='Question precedente' onClick={onPreviousQuestion} />
          <Button
            label='Question suivante'
            variant='yellow'
            onClick={onNextQuestion}
          />
        </div>
      )}
    </div>
  );
};
