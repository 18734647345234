import { Toast } from './toast';

export const protectedRoutes = (role, pathname, navigate) => {
  switch (role) {
    case 'ADMIN':
      if (pathname === '/users') {
        Toast('warning', "Vous n'avez pas accès à cette page !");
        setTimeout(() => {
          navigate(-1);
        }, 3000);
      }
      break;
    case 'COMMERCIAL' || 'PROGRAMMEUR' || 'PUNCHLINER':
      if (
        (!!!pathname?.includes('images-list') &&
          !!!pathname?.includes('user')) ||
        pathname === '/users'
      ) {
        Toast('warning', "Vous n'avez pas accès à cette page !");
        setTimeout(() => {
          navigate('/images-list');
        }, 3000);
      }
      break;
    default:
      return null;
  }
};
