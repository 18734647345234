import { useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { compose } from 'recompose';
import { fetchFilters } from '../../../api/filters.api';
import { DotsCircleIcon } from '../../../assets/svgs/DotsCircleIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { ActionModal } from '../../../components/atoms/ActionModal';
import { Loader } from '../../../components/atoms/Loader';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { withNavigation } from '../../../hoc/withNavigation';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
} from '../../../redux/filtersSelected';
import { deleteModalAction, filtersModalAction } from '../../../redux/modals';
import { DeleteFilter } from './DeleteFilter';

const FiltersView = ({ user }) => {
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const { data: filters, isLoading } = useQuery('filters', () =>
    fetchFilters('')
  );

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const handleOpenFiltersModal = () => {
    dispatch(resetFilteredSelectedItemAction('filter'));
    dispatch(filtersModalAction(true));
  };

  const filtersFiltered = copyObj(filters)
    ?.sort((a, b) => sortedList(a, b, 'label'))
    .filter((filter) =>
      filter.label.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <>
      <DeleteFilter />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`filtres (${filtersFiltered?.length ?? ''})`}
            onAdd={handleOpenFiltersModal}
          />
          <div className='table'>
            {isLoading ? (
              <Loader />
            ) : (
              filtersFiltered
                .sort((a, b) => sortedList(a, b, 'label'))
                .map((filter, index) => (
                  <Trow
                    key={index}
                    filter={filter}
                    dispatch={dispatch}
                    onOpen={onOpen}
                    showActionModal={showActionModal}
                  />
                ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const Trow = ({ filter, dispatch, showActionModal, onOpen }) => {
  const navigate = useNavigate();

  const handleSelectFilter = () => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'filter',
        item: filter,
      })
    );
  };

  const onShowModal = () => {
    handleSelectFilter();
    dispatch(filtersModalAction(true));
  };

  const onShowModalDelete = () => {
    handleSelectFilter();
    dispatch(deleteModalAction(true));
  };

  return (
    <div className='table-trow'>
      <span
        style={{ cursor: 'pointer', flex: 1 }}
        className='table-trow-text'
        onClick={() => navigate(`/filter-values/${filter?._id}`)}
      >
        {filter.label}
      </span>
      <div className='table-trow-action'>
        {showActionModal === filter?._id && (
          <ActionModal
            onEdit={onShowModal}
            onDelete={onShowModalDelete}
            disabledDelete={
              filter?.label?.toLowerCase() === 'livraison' ||
              filter?.label?.toLowerCase() === 'livraisons'
            }
          />
        )}
        <DotsCircleIcon onClick={() => onOpen(filter?._id)} />
      </div>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(FiltersView);
