import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  createIngredient,
  updateIngedient,
} from '../../../api/ingredients.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useFamilies } from '../../../hooks/useFamilies';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { productIngredientsModalAction } from '../../../redux/modals';
import { Input, Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

export const ProductIngredientModal = (props) => {
  const familySelected = useAppSelector(
    (state) => state.filtersSelected.family
  );
  const productSelected = useAppSelector(
    (state) => state.filtersSelected.product
  );
  const ingredientSelected = useAppSelector(
    (state) => state.filtersSelected.ingredient
  );
  const dispatch = useAppDispatch();
  const ingredientNameRef = useRef();
  const [productId, setProductId] = useState(productSelected?._id || '');

  const { resetFilters } = useResetFilters();

  const { families, products, familyId, handleSelectFamily } = useFamilies(
    familySelected?._id
  );

  const handleChangeFamilies = (e) => {
    handleSelectFamily(e.target.value);
  };

  const handleSelectProduct = (e) => {
    setProductId(e.target.value);
  };

  const handleSumbit = async () => {
    let loader;
    try {
      const productIdSelected =
        products.length === 1 ? products[0].value : productId;

      if (!productIdSelected || !ingredientNameRef.current.value) return;

      loader = toast.loading('Veuillez patienter...');

      if (ingredientSelected) {
        const data = {
          name: ingredientNameRef.current.value,
          productId: productIdSelected,
          familyId,
        };

        await updateIngedient(
          ingredientSelected?._id,
          ingredientSelected?.productId,
          ingredientSelected?.familyId,
          data
        );
        toast_loader(loader, 'Ingrédient modifié ! ✅', 'success');
      } else {
        const data = {
          name: ingredientNameRef.current.value,
          productId: productIdSelected,
          familyId,
        };

        const res = await createIngredient(familyId, data);
        if (res.data.success === false) {
          toast_loader(loader, res.data.message, 'error');
          return;
        } else {
          toast_loader(loader, 'Ingrédient ajouté ! ✅', 'success');
        }
      }

      resetFilters(['family', 'families']);
      handleClose();
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
    }
  };

  const handleClose = () => {
    dispatch(resetFilteredSelectedItemAction('ingredient'));
    dispatch(productIngredientsModalAction(false));
  };

  return (
    <Modal
      bgWidth={props?.bgWidth}
      zIndex={700}
      onClose={handleClose}
      title='Ingrédient'
      onValidate={handleSumbit}
    >
      <Input
        label='Nom'
        type='text'
        ref={ingredientNameRef}
        defaultValue={ingredientSelected?.name}
      />
      <Select
        label='Famille'
        options={
          families?.map((family) => ({ ...family, value: family._id })) || []
        }
        defaultValue={
          familySelected
            ? {
                value: familySelected._id,
                name: familySelected.label,
              }
            : null
        }
        onChange={handleChangeFamilies}
      />
      <Select
        label='Produit'
        options={products}
        value={productId}
        defaultValue={
          productSelected
            ? {
                value: productSelected._id,
                name: productSelected.name,
              }
            : null
        }
        onChange={handleSelectProduct}
      />
    </Modal>
  );
};
