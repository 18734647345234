import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { resetFiltersAction } from '../../../redux/filters';
import { copyObj } from '../../../assets/utils/copyObj';
import { updateCustomerInfo } from '../../../api/customers.api';
import { resetImageFormAction, initImagesAction } from '../../../redux/images';

import { uploadFile } from '../../../api/uploadFile.api';
import { createOnePicture } from '../../../api/picture.api';
import store from '../../../redux/storeConfig';
import { Button } from '../../../components/atoms/Buttons';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';
import { useNavigate } from 'react-router-dom';
import { Toast } from '../../../assets/utils/toast';
import { useCloneImage } from '../../../hooks/useCloneImage';

export const ImageContainerFooter: React.FC<{
  tab?: string;
  isClone?: boolean;
  edit?: boolean;
  onAddAttribution: (edit?: boolean, isClone?: boolean) => void;
}> = ({ isClone, edit, tab, onAddAttribution }) => {
  //

  const { infosMainProducts, infosSecondaryProducts, image, moods } =
    useAppSelector((s) => s.images);
  const { count } = useAttributionsImgComposition();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const products = [...infosMainProducts, ...infosSecondaryProducts];

  const { handleCloneImage, loading: isLoading } = useCloneImage();

  const handleSubmit = async () => {
    try {
      if (loading) return;

      setLoading(true);

      const images = store.getState().images;
      const data = copyObj(images);

      if (count > 0) {
        Toast('warning', 'Veuillez attribuer tous les produits sélectionnés !');
        setLoading(false);
        return;
      }
      if (!images.image) {
        setLoading(false);
        return Toast('warning', 'Veuillez ajouter au moins une image !');
      }

      if (products.length === 0) {
        setLoading(false);
        return Toast(
          'warning',
          'Veuillez sélectionner au moins 1 produit primaire ou sécondaire !'
        );
      }

      if (!!images.image?.name) {
        const fileName = await uploadFile(images.image, 'picture');
        if (fileName) {
          data.image = fileName;
        }
      }

      const payload = {
        ...data,
        attrMainCompositions: data?.attrMainCompositions?.map((_el: any) => ({
          familyId: _el.familyId,
          productId: _el.productId?._id || _el?.productId,
          compositionId:
            _el.compositionId?._id || _el?.compositionId || _el._id,
        })),
        attrSecondaryCompositions: data?.attrSecondaryCompositions?.map(
          (_el: any) => ({
            familyId: _el.familyId,
            productId: _el.productId?._id || _el?.productId,
            compositionId:
              _el.compositionId?._id || _el?.compositionId || _el._id,
          })
        ),
      };

      const pictureRes = await createOnePicture(payload);

      if (pictureRes && pictureRes?.data?.data) {
        for (let i = 0; i < images?.relatedCustomers.length; i++) {
          const customerId = images?.relatedCustomers[i];
          await updateCustomerInfo(customerId, {
            relatedPictures: [pictureRes?.data?.data?._id],
          });
        }
        dispatch(initImagesAction(pictureRes?.data?.data));
        Toast('success', 'Image ajoutée avec succès !');
        dispatch(resetFiltersAction());
        dispatch(resetImageFormAction());
        navigate('/images');
        window.location.reload();
      } else {
        Toast(
          'error',
          pictureRes ? pictureRes?.data?.message : 'Erreur serveur !'
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onCancel = () => {
    dispatch(resetFiltersAction());
    dispatch(resetImageFormAction());
    navigate('/images');
  };

  return (
    <div className='image--footer'>
      <Button label='Annuler' margin='0 2rem 0 ' onClick={onCancel} />
      {tab === 'informations' &&
        moods?.length > 0 &&
        products.length > 0 &&
        !!image && (
          <Button
            label={`Suivant`}
            variant='yellow'
            disabled={loading}
            onClick={() => onAddAttribution(edit, isClone)}
          />
        )}
      {tab === 'attribution' && (
        <Button
          label={`Enregistrer`}
          variant='yellow'
          loading={loading || isLoading}
          disabled={loading}
          onClick={isClone ? handleCloneImage : handleSubmit}
        />
      )}
    </div>
  );
};
