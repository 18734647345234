import { toast } from 'react-toastify';
import { deleteOneProduct } from '../../../api/families.api';
import { toast_loader } from '../../../assets/utils/toast';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { deleteModalAction } from '../../../redux/modals';

export const DeleteProduct = () => {
  const dispatch = useAppDispatch();
  const productSelected = useAppSelector((s) => s.filtersSelected.product);

  const { resetFilters } = useResetFilters();

  const handleDeleteProduct = async () => {
    const loader = toast.loading('Veuillez patienter...');
    const { data } = await deleteOneProduct(productSelected?._id);

    if (data?.status) {
      toast_loader(loader, `${data?.message}`, 'success');
      resetFilters('family');
      dispatch(resetFilteredSelectedItemAction('product'));
      dispatch(deleteModalAction(false));
    } else {
      toast_loader(loader, `${data?.message} ❌`, 'error');
    }
  };

  return (
    <DeleteModal
      onDelete={handleDeleteProduct}
      text={`le produit "${productSelected?.name ?? ''}"`}
    />
  );
};
