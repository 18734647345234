import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'modals',
  initialState: {
    filtersModal: false,
    citiesModal: false,
    familiesModal: false,
    familyProductsModal: false,
    productIngredientsModal: false,
    filterValuesModal: false,
    moodsModal: false,
    productModal: false,
    styleProductsModal: false,
    specialsFiltresModal: false,
    subSpecialsFiltersModal: false,
    stylesModal: false,
    groupsModal: false,
    packageModal: false,
    deleteModal: false,
    //customer
    replayImgAttrModal: false,
    downloadImgModal: false,
    displayImgModal: false,
    replayStrategyModal: false,
    strategyModelModal: false,
    formulaModal: false,
    citiesListModal: false,
    imagesListModal: false,
    addImageModal: false,
    confirmDeleteAttrModal: false,
    packageCustomerModal: false,
    customersListModal: false,
    customerCompositionsModal: false,
    viewFeedModal: false,
    //images
    viewImageModal: false,
    editImgModal: false,
    editImgMiniModal: false,
    customerModal: false,
    similarsImagesModal: false,
    deleteImageModal: false,
    identicalImageModal: false,
    suggestionSamePictureModal: false,
    replayDiaryModal: false,
    warningAttrImgModal: false,
    rayonsModal: false,
    rayonIngredientModal: false,
    rayonIngredientSpecialtyModal: false,
    confirmReplayQuizModal: false,
    confirmSwitcheFamilyModal: false,
    //compositions
    specialitiesModal: false,
  },

  reducers: {
    filtersModalAction: (state, action) => {
      state.filtersModal = action.payload;
    },
    filterValuesModalAction: (state, action) => {
      state.filterValuesModal = action.payload;
    },
    familiesModalAction: (state, action) => {
      state.familiesModal = action.payload;
    },
    familyProductsModalAction: (state, action) => {
      state.familyProductsModal = action.payload;
    },
    productIngredientsModalAction: (state, action) => {
      state.productIngredientsModal = action.payload;
    },
    moodsModalAction: (state, action) => {
      state.moodsModal = action.payload;
    },
    productModalAction: (state, action) => {
      state.productModal = action.payload;
    },
    styleProductsModalAction: (state, action) => {
      state.styleProductsModal = action.payload;
    },
    specialsFiltresModalAction: (state, action) => {
      state.specialsFiltresModal = action.payload;
    },
    subSpecialsFiltersModalAction: (state, action) => {
      state.subSpecialsFiltersModal = action.payload;
    },
    stylesModalAction: (state, action) => {
      state.stylesModal = action.payload;
    },
    groupsModalAction: (state, action) => {
      state.groupsModal = action.payload;
    },
    citiesModalAction: (state, action) => {
      state.citiesModal = action.payload;
    },
    packageModalAction: (state, action) => {
      state.packageModal = action.payload;
    },
    deleteModalAction: (state, action) => {
      state.deleteModal = action.payload;
    },
    //customer
    replayImgAttrModalAction: (state, action) => {
      state.replayImgAttrModal = action.payload;
    },
    downloadImgModalAction: (state, action) => {
      state.downloadImgModal = action.payload;
    },
    displayImgModalAction: (state, action) => {
      state.displayImgModal = action.payload;
    },
    viewImageModalAction: (state, action) => {
      state.viewImageModal = action.payload;
    },
    replayStrategyModalAction: (state, action) => {
      state.replayStrategyModal = action.payload;
    },
    replayDiaryModalAction: (state, action) => {
      state.replayDiaryModal = action.payload;
    },
    strategyModelModalAction: (state, action) => {
      state.strategyModelModal = action.payload;
    },
    formulaModalAction: (state, action) => {
      state.formulaModal = action.payload;
    },
    citiesListModalAction: (state, action) => {
      state.citiesListModal = action.payload;
    },
    imagesListModalAction: (state, action) => {
      state.imagesListModal = action.payload;
    },

    addImageModalAction: (state, action) => {
      state.addImageModal = action.payload;
    },
    confirmDeleteAttrModalAction: (state, action) => {
      state.confirmDeleteAttrModal = action.payload;
    },
    packageCustomerModalAction: (state, action) => {
      state.packageCustomerModal = action.payload;
    },
    customersListModalAction: (state, action) => {
      state.customersListModal = action.payload;
    },
    customerCompositionsModalAction: (state, action) => {
      state.customerCompositionsModal = action.payload;
    },
    viewFeedModalAction: (state, action) => {
      state.viewFeedModal = action.payload;
    },
    //images
    editImgModalAction: (state, action) => {
      state.editImgModal = action.payload;
    },
    editImgMiniModalAction: (state, action) => {
      state.editImgMiniModal = action.payload;
    },
    customerModalAction: (state, action) => {
      state.customerModal = action.payload;
    },
    similarsImagesModalAction: (state, action) => {
      state.similarsImagesModal = action.payload;
    },
    deleteImageModalAction: (state, action) => {
      state.deleteImageModal = action.payload;
    },
    identicalImageModalAction: (state, action) => {
      state.identicalImageModal = action.payload;
    },
    suggestionSamePictureModalAction: (state, action) => {
      state.suggestionSamePictureModal = action.payload;
    },
    warningAttrImgModalAction: (state, action) => {
      state.warningAttrImgModal = action.payload;
    },
    rayonsModalAction: (state, action) => {
      state.rayonsModal = action.payload;
    },
    rayonIngredientModalAction: (state, action) => {
      state.rayonIngredientModal = action.payload;
    },
    rayonIngredientSpecialtyModalAction: (state, action) => {
      state.rayonIngredientSpecialtyModal = action.payload;
    },
    specialitiesModalAction: (state, action) => {
      state.specialitiesModal = action.payload;
    },
    confirmReplayQuizModalAction: (state, action) => {
      state.confirmReplayQuizModal = action.payload;
    },
    confirmSwitcheFamilyModalAction: (state, action) => {
      state.confirmSwitcheFamilyModal = action.payload;
    },
  },
});

export const {
  filtersModalAction,
  filterValuesModalAction,
  familiesModalAction,
  familyProductsModalAction,
  rayonIngredientModalAction,
  moodsModalAction,
  productModalAction,
  specialsFiltresModalAction,
  stylesModalAction,
  citiesModalAction,
  packageModalAction,
  deleteModalAction,
  replayImgAttrModalAction,
  downloadImgModalAction,
  displayImgModalAction,
  viewImageModalAction,
  replayStrategyModalAction,
  strategyModelModalAction,
  styleProductsModalAction,
  formulaModalAction,
  citiesListModalAction,
  editImgModalAction,
  editImgMiniModalAction,
  imagesListModalAction,
  addImageModalAction,
  customerModalAction,
  customersListModalAction,
  customerCompositionsModalAction,
  confirmDeleteAttrModalAction,
  packageCustomerModalAction,
  groupsModalAction,
  similarsImagesModalAction,
  deleteImageModalAction,
  productIngredientsModalAction,
  subSpecialsFiltersModalAction,
  identicalImageModalAction,
  suggestionSamePictureModalAction,
  replayDiaryModalAction,
  warningAttrImgModalAction,
  rayonsModalAction,
  rayonIngredientSpecialtyModalAction,
  specialitiesModalAction,
  confirmReplayQuizModalAction,
  confirmSwitcheFamilyModalAction,
  viewFeedModalAction,
} = slice.actions;
export default slice.reducer;
