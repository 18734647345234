import { DotsCircleIcon } from '../../../assets/svgs/DotsCircleIcon';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import { filteredSelectedItemAction } from '../../../redux/filtersSelected';
import { deleteModalAction } from '../../../redux/modals';
import { ActionModal } from '../ActionModal';

export const TableSimpleRow = ({
  onOpenModal,
  showActionModal,
  item,
  filter,
  onOpen,
  withImage,
  folder,
}: any) => {
  const dispatch = useAppDispatch();
  const handleSelectFilterValue = () => {
    dispatch(
      filteredSelectedItemAction({
        filter,
        item,
      })
    );
  };

  const onShowModal = () => {
    handleSelectFilterValue();
    onOpenModal();
  };

  const onShowModalDelete = () => {
    handleSelectFilterValue();
    dispatch(deleteModalAction(true));
  };

  return (
    <div className='table-trow'>
      {withImage && (
        <div className='table-trow-textIgmBlock'>
          {item?.image?.length > 0 && (
            <img
              style={{ marginRight: '1rem' }}
              src={helperImageRoute(item?.image, folder || 'mood')}
              loading='lazy'
              alt={item.label}
              width={40}
            />
          )}

          <span className='table-trow-text'>{item.label}</span>
        </div>
      )}
      {!withImage && (
        <span className='table-trow-text'>{item?.name || item?.label}</span>
      )}
      <div className='table-trow-action'>
        {showActionModal === item?._id && (
          <ActionModal onEdit={onShowModal} onDelete={onShowModalDelete} />
        )}
        <DotsCircleIcon onClick={() => onOpen(item?._id)} />
      </div>
    </div>
  );
};
