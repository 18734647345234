import React, { useState } from 'react';
import { colors } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { sortedList } from '../../../assets/utils/sortedList';
import { IComposition } from '../../../redux/compositions';
import { Counters } from '../../atoms/Counter';
import { useCompositionMatchPercentage } from '../../../hooks/useCompositionMatchPercentage';
import { getPictureDisplayAction } from '../../../redux/picture';
import { useDispatch } from 'react-redux';
import { viewImageModalAction } from '../../../redux/modals';
import { ButtonZoom } from '../../atoms/Buttons';

export const CompositionCard: React.FC<{
  composition: IComposition;
  onSelectComposition: () => void;
  selected?: boolean | false;
  attributed?: boolean | false;
  isFilter?: false;
  width?: string | number;
  margin?: string | number;
  backgroundColor?: string;
  counter?: boolean;
}> = ({
  composition,
  onSelectComposition,
  selected,
  attributed,
  width = '100%',
  margin = '.5rem 0',
  backgroundColor = 'transparent',
  isFilter = false,
  counter,
}) => {
  //
  const dispatch = useDispatch();
  const { matchedPercent } = useCompositionMatchPercentage(composition);
  const [hoverImg, setHoverImg] = useState<boolean>(false);

  const onDisplayImg = (e) => {
    e.stopPropagation();
    dispatch(
      getPictureDisplayAction({
        picture: composition?.image,
        folder: 'composition',
      })
    );
    dispatch(viewImageModalAction(true));
  };

  return (
    <div
      className='compositionCard'
      style={{
        width,
        margin,
        backgroundColor,
        cursor: 'pointer',
        border: '1px solid ',
        borderColor: selected
          ? colors.yellow
          : attributed
          ? 'green'
          : 'transparent',
        minHeight: isFilter ? 80 : 'auto',
      }}
      onClick={attributed ? () => {} : onSelectComposition}
    >
      <div
        className='compositionCard--imgBlock'
        style={{
          width: isFilter ? 60 : 110,
          height: isFilter ? 60 : 110,
        }}
        onMouseEnter={() => setHoverImg(true)}
        onMouseLeave={() => setHoverImg(false)}
      >
        {composition?.image?.length > 0 && hoverImg && (
          <ButtonZoom onDisplayImg={onDisplayImg} />
        )}
        {matchedPercent > 0 && (
          <span>
            {(matchedPercent >= 100 ? 100 : matchedPercent).toFixed(2)}%
          </span>
        )}
        {composition?.image?.length > 0 && (
          <img
            src={helperImageRoute(composition?.image, 'composition')}
            alt='a'
            loading='lazy'
            width='35%'
            style={{ objectFit: 'contain' }}
          />
        )}
      </div>
      <div className='compositionCard--items' style={{ flex: 1 }}>
        <p
          className='compositionCard--itemsText'
          style={{ fontFamily: 'Inter Semi Bold' }}
        >
          {composition?.short_name}
        </p>
        {!!!isFilter && (
          <div className='compositionCard--list'>
            {copyObj(composition?.ingredients)
              ?.sort((a, b) => sortedList(a, b, 'name'))
              ?.map((ingredient, index) => {
                return (
                  <p className='compositionCard--itemsText' key={index}>
                    {` ${ingredient?.specialityId?.label || ingredient?.name}`}
                    {ingredient.quantity > 1 ? ` x${ingredient.quantity}` : ''}
                    {index + 1 < composition.ingredients?.length ? ` - ` : ''}
                  </p>
                );
              })}
          </div>
        )}

        {counter && <Counters isComposition compositionId={composition._id} />}
      </div>
    </div>
  );
};
