export const CardPayIcon = ({ color = '#282828' }) => {
  return (
    <svg
      width='16'
      height='12'
      viewBox='0 0 16 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2 0C0.895431 0 0 0.895431 0 2V3H16V2C16 0.895431 15.1046 0 14 0H2Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 5H0V10C0 11.1046 0.895431 12 2 12H14C15.1046 12 16 11.1046 16 10V5ZM2 9C2 8.44771 2.44772 8 3 8H4C4.55228 8 5 8.44771 5 9C5 9.55229 4.55228 10 4 10H3C2.44772 10 2 9.55229 2 9ZM7 8C6.44772 8 6 8.44771 6 9C6 9.55229 6.44772 10 7 10H8C8.55228 10 9 9.55229 9 9C9 8.44771 8.55228 8 8 8H7Z'
        fill={color}
      />
    </svg>
  );
};
