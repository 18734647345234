import { useState } from 'react';
import { colors } from '../../../assets/constants';
import { SearchIcon } from '../../../assets/svgs/SearchIcon';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { filteredByIngredientsAction } from '../../../redux/imageAttributions';

export const CompositionsFilters: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const { allCompIngredients } = useAttributionsImgComposition();
  const [search, setSearch] = useState('');
  const filteredByIngredients = useAppSelector(
    (s) => s.imageAttributions?.filteredByIngredients
  );

  const onSelectedFilter = (ing: string) => {
    const findIng = filteredByIngredients?.find((_ing) => _ing === ing);
    if (findIng) {
      dispatch(
        filteredByIngredientsAction(
          filteredByIngredients.filter((_ing) => _ing !== findIng)
        )
      );
    } else {
      dispatch(filteredByIngredientsAction([...filteredByIngredients, ing]));
    }
  };

  const compIngredientsFiltered = allCompIngredients?.filter((ing: any) =>
    ing?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className='w-100 flex-col-start-start' style={{ padding: '0 1rem' }}>
      <p className='imageAttributions--rightBlock-text'>
        Filtrer les composition
        <div
          id='input'
          className='header--input'
          style={{
            width: '100%',
            margin: '0.5rem',
          }}
        >
          <SearchIcon />
          <input
            type='text'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </p>
      {compIngredientsFiltered.length > 0 &&
        compIngredientsFiltered?.map((ing, index) => {
          const findIng = filteredByIngredients?.find((_ing) => _ing === ing);
          return (
            <button
              key={index}
              className='imageAttributions--rightBlock-filterBtn'
              onClick={() => onSelectedFilter(ing)}
            >
              <span>{ing}</span>
              <span
                style={{
                  backgroundColor:
                    findIng === ing ? colors.yellow : colors.greyDark,
                }}
              />
            </button>
          );
        })}
    </div>
  );
};
