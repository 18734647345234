export const PencilIcon = () => {
  return (
    <svg
      width='12'
      height='16'
      viewBox='0 0 12 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.6544 1.43553L8.96244 0.372035C8.75735 0.243366 8.50956 0.201423 8.27355 0.255432C8.03754 0.309441 7.83265 0.454977 7.70394 0.660035L7.03644 1.72203L10.2744 3.75453L10.9414 2.69353C11.2094 2.26603 11.0814 1.70353 10.6544 1.43553ZM1.27694 10.8925L4.51394 12.9255L9.78944 4.52653L6.55144 2.49303L1.27644 10.893L1.27694 10.8925ZM0.781938 13.4755L0.710938 15.3855L2.40094 14.4915L3.97094 13.6625L0.846938 11.6995L0.781938 13.4755Z'
        fill='#282828'
      />
    </svg>
  );
};
