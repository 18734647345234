import { useState } from 'react';
import Logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/atoms/Buttons';
import { Input } from '../../components/atoms/Fields';
import { toast_loader } from '../../assets/utils/toast';
import { toast } from 'react-toastify';
import { Version } from '../../components/atoms/Version';
import { sendEmailResetPassword } from '../../api/auth.api';

export const ForgetPasswordView = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const loader = toast.loading('Veuillez patienter...');
    await sendEmailResetPassword(email)
      .then((res) => {
        setLoading(false);
        setEmail('');
        toast_loader(loader, 'Un e-mail vous a été envoyé !', 'success');
        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        toast_loader(loader, "Une erreur s'est produite !", 'error');
      });
    setLoading(false);
  };
  return (
    <div className='offline'>
      <form className='offline--container' onSubmit={handleSubmit}>
        <img src={Logo} alt='logo' />
        <p className='offline--title'>Mot de passe oublié ? </p>
        <p className='offline--text' style={{ marginBottom: '2rem' }}>
          Saisissez votre e-mail !
        </p>
        <Input
          label='Adresse Email'
          name='email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder='Saisissez votre e-mail...'
        />
        <button
          className='offline--text btn--neutral'
          style={{ margin: ' 0 0 2rem auto' }}
          onClick={() => navigate(-1)}
          type='button'
        >
          Retour à la connexion !
        </button>
        <Button
          label={loading ? 'Envoyer...' : 'Envoyer'}
          margin='auto 0 0'
          width='100%'
          disabled={loading}
        />
      </form>
      <Version />
    </div>
  );
};
