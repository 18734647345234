import React, { useState } from 'react';
import { colors } from '../../../assets/constants';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { compositionSelectedAction } from '../../../redux/compositions';
import {
  IAnswer,
  IIngredient,
  questionSelectedAction,
  quizAction,
} from '../../../redux/familyQuiz';
import { ButtonZoom } from '../../atoms/Buttons';
import { useDispatch } from 'react-redux';
import { getPictureDisplayAction } from '../../../redux/picture';
import { viewImageModalAction } from '../../../redux/modals';

export const IngredientCard: React.FC<{
  ingredient: IIngredient;
  onSelect: () => void;
  specialitiesInComp: number;
}> = ({ ingredient, onSelect, specialitiesInComp }) => {
  //
  const dispatch = useDispatch();
  const [hoverImg, setHoverImg] = useState<boolean>(false);

  const onDisplayImg = (e) => {
    e.stopPropagation();
    dispatch(
      getPictureDisplayAction({
        picture: ingredient?.image,
        folder: 'picture',
      })
    );
    dispatch(viewImageModalAction(true));
  };

  return (
    <div
      className='ingredientCard'
      onClick={onSelect}
      style={{
        cursor: 'pointer',
      }}
    >
      {specialitiesInComp > 0 && (
        <span className='ingredientCard--badge'>{specialitiesInComp}</span>
      )}
      <p className='ingredientCard--ingredient'>{ingredient.label}</p>
      <div
        className='ingredientCard--imgBlock'
        onMouseEnter={() => setHoverImg(true)}
        onMouseLeave={() => setHoverImg(false)}
      >
        {ingredient?.image?.length > 0 && hoverImg && (
          <ButtonZoom onDisplayImg={onDisplayImg} />
        )}
        <img
          src={helperImageRoute(ingredient.image, 'picture')}
          alt={ingredient.image}
          width='90%'
          height={80}
          style={{ objectFit: 'cover' }}
        />
      </div>
    </div>
  );
};

export const NoAnswerCard: React.FC<{ answer: IAnswer }> = ({ answer }) => {
  const dispatch = useAppDispatch();
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);
  const compositionSelected = useAppSelector(
    (s) => s.compositions.compositionSelected
  );
  const quiz = useAppSelector((s) => s.familyQuiz?.quiz);

  const onNoAnswer = () => {
    let newIngredients = compositionSelected?.ingredients?.filter(
      (_ingredient) =>
        _ingredient?.ingredientId !==
          (answer?.ingredient_id?._id ||
            answer?.ingredient_id ||
            answer?._id) && _ingredient?.questionId !== questionSelected?._id
    );

    dispatch(
      questionSelectedAction({
        ...questionSelected,
        answered: true,
        noAnswer: true,
      })
    );
    dispatch(
      quizAction({
        ...quiz,
        questionsList: quiz.questionsList.map((_question) => {
          if (_question._id === questionSelected._id) {
            return {
              ..._question,
              answered: true,
              noAnswer: true,
            };
          }
          return _question;
        }),
      })
    );
    dispatch(
      compositionSelectedAction({
        ...compositionSelected,
        ingredients: newIngredients,
      })
    );
  };
  return (
    <div
      className='ingredientCard'
      onClick={onNoAnswer}
      style={{
        borderColor: questionSelected?.noAnswer
          ? colors.yellow
          : colors.greyDark,
        cursor: 'pointer',
      }}
    >
      <p className='ingredientCard--ingredient'>Aucun</p>
    </div>
  );
};
