import { axiosInstance } from '../assets/utils/axiosInstance';
import { Toast } from '../assets/utils/toast';

export const fetchGroups = async (params) => {
  const res = await axiosInstance.get(`/groups`, { params }).catch((err) => {
    return Toast('error', "Une erreur s'est produite : ");
  });
  return res;
};

export const createOneGroup = async (label) => {
  const res = await axiosInstance
    .post('/group/create', { label })
    .catch((err) => {
      return Toast('error', "Une erreur s'est produite : ");
    });
  return res.data;
};

export const updateOneGroup = async (id, label) => {
  const res = await axiosInstance
    .put(`/group/${id}`, { label })
    .catch((err) => {
      return Toast('error', "Une erreur s'est produite : ");
    });
  return res;
};

export const deleteOneGroup = async (id) => {
  const res = await axiosInstance.put(`/group/delete/${id}`).catch((err) => {
    return Toast('error', "Une erreur s'est produite : ");
  });
  return res;
};
