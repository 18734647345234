import { memo, useEffect, useState } from 'react';
import { colors } from '../../../assets/constants';
import { ChevronBottomIcon } from '../../../assets/svgs/ChevronBottomIcon';
import { MinusIcon } from '../../../assets/svgs/MinusIcon';
import { Plus2Icon } from '../../../assets/svgs/Plus2Icon';
import { Counters } from '../Counter';

const Collapse = memo(
  ({
    title,
    children,
    city = false,
    onOpenCollapse,
    width = '100%',
    bg = colors.whiteBlue,
    border = false,
    margin,
    opened = false,
    overflowY = 'auto',
    maxHeight = 'auto',
    selected,
    showCheckbox = false,
    activeForCompose,
    onSelectForCompose,
    direction = 'row',
    totalCompositionPrincipal,
    totalCompositionSecondary,
  }: any) => {
    const [open, setOpen] = useState(opened);

    const onOpen = () => {
      if (city) {
        onOpenCollapse();
        setOpen(!open);
      } else {
        setOpen(!open);
      }
    };

    useEffect(() => {
      setOpen(opened);
    }, [opened]);

    return (
      <div
        className='collapse'
        style={{
          width,
          maxHeight,
          overflowX: 'hidden',
          overflowY,
          border:
            border && `1px solid ${selected ? colors.yellow : colors.grey}`,
          borderRadius: 8,
          margin,
          position: 'relative',
        }}
      >
        <button
          className='collapse--btn'
          style={{
            borderBottom: open && 0,
            cursor: 'pointer',
            position: 'sticky',
            top: 0,
            backgroundColor: bg,
            zIndex: 20,
          }}
          onClick={onOpen}
        >
          <div>
            <span style={{ color: '#282828' }}>{title}</span>
            {totalCompositionPrincipal !== undefined && (
              <span className='collapse--counter'>
                {totalCompositionPrincipal | 0} Princip
                {totalCompositionPrincipal > 1 ? 'aux' : 'al'} .
              </span>
            )}
            {totalCompositionSecondary !== undefined && (
              <span className='collapse--counter'>
                {totalCompositionSecondary | 0} Secondaire
                {totalCompositionSecondary > 1 ? 's' : ''}
              </span>
            )}
          </div>
          <div className='collapse--right'>
            {showCheckbox && (
              <div className='collapse--checkbox'>
                <p className='collapse--checkbox-label'>Fait à composer</p>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectForCompose();
                  }}
                  className='accordion--check'
                  style={{
                    backgroundColor: activeForCompose
                      ? colors.yellow
                      : 'transparent',
                    cursor: 'pointer',
                  }}
                />
              </div>
            )}
            <ChevronBottomIcon color={colors.yellow} />
          </div>
        </button>
        {open && (
          <div
            className='collapse--content'
            style={{ backgroundColor: bg, flexDirection: direction }}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
);

const FilterImageCollapse = ({ label, children, setShow, show, id }) => {
  const toggle = () => {
    if (show === label) return setShow(null);
    setShow(label);
  };

  return (
    <div className='filterImgCollapse'>
      <button className='filterImgCollapse--btn' onClick={toggle}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {label}
          <Counters parentId={id} isParent />
        </span>
        <div className='flex-row-center' style={{ marginLeft: 'auto' }}>
          {show === label ? <MinusIcon /> : <Plus2Icon />}
        </div>
      </button>
      <div
        className='filterImgCollapse--container'
        style={{
          maxHeight: label === show ? 9999 : 0,
          overflow: 'hidden',
          transition:
            label === show
              ? 'all 0.5s cubic-bezier(1, 0, 1, 0) '
              : 'all 0.5s cubic-bezier(0, 1, 0, 1)',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export { Collapse, FilterImageCollapse };
