import { createSlice } from '@reduxjs/toolkit';
import {
  helperFiltersCompositionSelected,
  helperFiltersMoodSelected,
  helperFiltersParentItemSelected,
  helperFiltersParentSelected,
  helperInitFilter,
} from './helper';

interface IIMage {
  styles: any[];
  families: any[];
  filters: any[];
  specialsFilters: any[];
  moods: string[];
  page: number;
  limit: number;
  filterBy: string;
  restoFilter: any;
}

const initialState: IIMage = {
  styles: [],
  families: [],
  filters: [],
  specialsFilters: [],
  moods: [],
  page: 1,
  limit: 100,
  filterBy: '',
  restoFilter: {
    styles: [],
    families: [],
    filters: [],
    specialsFilters: [],
    moods: [],
    page: 1,
    limit: 100,
    filterBy: '',
  },
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    initFiltersAction: (state, action) => {
      return helperInitFilter(state, action.payload);
    },

    initRestoFiltersAction: (state, action) => {
      state.restoFilter = action.payload;
    },

    filtersParentSelectedAction: (state, action) => {
      // selected filters|specialsFilters|moods|families|styles
      return helperFiltersParentSelected(state, action.payload);
    },
    filtersParentItemSelectedAction: (state, action) => {
      // selected filters|families => item
      return helperFiltersParentItemSelected(state, action.payload);
    },

    filtersCompositionSelectedAction: (state, action) => {
      return helperFiltersCompositionSelected(state, action.payload);
    },
    filtersMoodsAction: (state, action) => {
      return helperFiltersMoodSelected(state, action.payload);
    },
    setPageAction: (state, action) => {
      state.page = action.payload;
    },

    resetFiltersAction: () => {
      return initialState;
    },
  },
});

export const {
  initFiltersAction,
  initRestoFiltersAction,
  filtersParentSelectedAction,
  filtersParentItemSelectedAction,
  filtersCompositionSelectedAction,
  filtersMoodsAction,
  resetFiltersAction,
  setPageAction,
} = slice.actions;

export default slice.reducer;
