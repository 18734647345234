import { useEffect, useState } from 'react';
import { DotsCircleIcon } from '../../../assets/svgs/DotsCircleIcon';
import { ActionModal } from '../../../components/atoms/ActionModal';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { withNavigation } from '../../../hoc/withNavigation';
import {
  deleteModalAction,
  familyProductsModalAction,
} from '../../../redux/modals';
import { Loader } from '../../../components/atoms/Loader';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { compose } from 'recompose';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { sortedList } from '../../../assets/utils/sortedList';
import { getProductListByFamilyId } from '../../../api/families.api';
import { useQuery } from 'react-query';
import {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
} from '../../../redux/filtersSelected';
import { DeleteProduct } from './DeleteProduct';
import { copyObj } from '../../../assets/utils/copyObj';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import { CounterItem } from '../../../components/atoms/Counter';

const FamilyProductView = ({ user }) => {
  //
  const dispatch = useAppDispatch();
  const { familyId } = useParams();
  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const { data, isLoading } = useQuery(['family', familyId], () =>
    getProductListByFamilyId(familyId)
  );

  const family = data?.data?.family;

  const products = family?.products;

  const handleOpenAddProductModal = () => {
    dispatch(resetFilteredSelectedItemAction('product'));
    dispatch(familyProductsModalAction(true));
  };

  useEffect(() => {
    if (data?.data) {
      dispatch(
        filteredSelectedItemAction({
          filter: 'family',
          item: data?.data?.family,
        })
      );
    }
  }, [data, dispatch]);

  if (isLoading) return <Loader />;

  return (
    <>
      <DeleteProduct />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            goBack
            title={`produits '${family?.label}'`}
            onAdd={handleOpenAddProductModal}
          />
          <div className='table'>
            {products &&
              copyObj(products)
                ?.sort((a, b) => sortedList(a, b, 'name'))
                ?.map((product, index) => {
                  const reg = new RegExp(`${search}`, 'ig');
                  if (search?.length >= 3 && !product?.name?.match(reg)) {
                    return null;
                  }
                  return (
                    <Trow
                      key={index}
                      product={{ ...product, family }}
                      onOpen={onOpen}
                      showActionModal={showActionModal}
                      setShowActionModal={setShowActionModal}
                    />
                  );
                })}
          </div>
        </div>
      </div>
    </>
  );
};

const Trow = ({ product, showActionModal, onOpen, setShowActionModal }) => {
  const dispatch = useAppDispatch();

  const handleSelectProduct = () => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'product',
        item: product,
      })
    );
  };

  const navigate = useNavigate();
  const onShowModal = () => {
    handleSelectProduct();
    dispatch(familyProductsModalAction(true));
    setShowActionModal(null);
  };
  const onShowModalDelete = () => {
    handleSelectProduct();
    dispatch(deleteModalAction(true));
    setShowActionModal(null);
  };
  return (
    <div className='table-trow'>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className='table-trow-text'
          style={{ cursor: 'pointer', flex: 1 }}
          onClick={() =>
            navigate(
              `/family-product-compositions/${product.family?._id}/${product?._id}`
            )
          }
        >
          {product?.name}
        </span>
        <CounterItem number={product?.pictureCounter} />
      </div>
      <div className='table-trow-action'>
        {showActionModal === product?.name && (
          <ActionModal onEdit={onShowModal} onDelete={onShowModalDelete} />
        )}
        <DotsCircleIcon onClick={() => onOpen(product?.name)} />
      </div>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(FamilyProductView);
