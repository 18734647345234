import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import { resetFiltersAction } from '../../../redux/filters';
import { resetImageFormAction } from '../../../redux/images';
import {
  editImgModalAction,
  suggestionSamePictureModalAction,
} from '../../../redux/modals';
import { Modal } from '../../atoms/Modal';

export const SuggestionsSamePictureModal = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    dispatch(suggestionSamePictureModalAction(false));
    dispatch(editImgModalAction(true));
  };

  const onClose = () => {
    dispatch(resetFiltersAction());
    dispatch(resetImageFormAction());
    dispatch(suggestionSamePictureModalAction(false));
    navigate('/images');
  };
  return (
    <Modal
      onClose={onClose}
      title='Images identiques'
      onValidate={handleSubmit}
      zIndex={700}
    >
      <h3>Voulez-vous ajouter des images identiques ?</h3>
    </Modal>
  );
};
