import { env, workSpaceEnvironnement } from '../app.config';

export const helperImageRoute = (
  imgName,
  folder,
  isThumb = true,
  watermarke = false
) => {
  if (workSpaceEnvironnement === 'development') {
    //FIXME: comment this for work on local
    try {
      if (watermarke) {
        return require(`../../../../slm-v2-back/assets/watermarked/${imgName}`);
      } else {
        return require(`../../../../slm-v2-back/assets/${
          isThumb ? 'thumb-' : ''
        }${folder}/${isThumb ? 'thumb-' : ''}${imgName}`);
      }
    } catch (error) {}
  } else if (
    workSpaceEnvironnement === 'production' ||
    workSpaceEnvironnement === 'preprod'
  ) {
    // return `${env.BASE_URL}${env.VERSION}/${imgName}`;
    try {
      if (watermarke) {
        return `${env.BASE_URL}assets/watermarked/${imgName}`;
      } else {
        return `${env.BASE_URL}assets/${isThumb ? 'thumb-' : ''}${folder}/${
          isThumb ? 'thumb-' : ''
        }${imgName}`;
      }
    } catch (error) {}
  }
};

export const helperGetImagePath = (pic) => {
  return pic?.images?.portrait || pic?.images?.landscape || pic?.images?.square;
};
