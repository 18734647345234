import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  style: null,
  family: null,
  specialFilter: null,
  subSpecialFilter: null,
  packages: null,
  moods: null,
  groups: null,
  filter: null,
  product: null,
  ingredients: null,
};

const slice = createSlice({
  name: 'filtersSelected',
  initialState,
  reducers: {
    filteredSelectedItemAction: (state, action) => {
      const {filter, item} = action.payload;
      state[filter] = item;
    },
    resetFilteredSelectedItemAction: (state, action) => {
      const filter = action.payload;

      if (state[filter]) {
        state[filter] = null;
      }
    },
    resetFiltersSelectedAction: () => {
      return initialState;
    },
  },
});

export const {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
  resetFiltersSelectedAction,
} = slice.actions;

export default slice.reducer;
