import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchSpecialFilters } from '../../../api/specialsFilters.api';
import {
  createOneSubSpecialFilter,
  updateOneSubSpecialFilter,
} from '../../../api/subSpecialFilter.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { subSpecialsFiltersModalAction } from '../../../redux/modals';
import { Input, Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

export const SubSpecialFiltersModal = (props) => {
  const specialFilterSelected = useAppSelector(
    (state) => state.filtersSelected.specialFilter
  );
  const subSpecialFilterSelected = useAppSelector(
    (state) => state.filtersSelected.subSpecialFilter
  );
  const [specialFilterId, setSpecialFilterId] = useState(
    specialFilterSelected?._id || ''
  );
  const dispatch = useDispatch();
  const [label, setLabel] = useState(subSpecialFilterSelected?.label || '');

  const { data: specialsFilter } = useQuery(['specialsFilters'], () => {
    return fetchSpecialFilters('');
  });

  const { resetFilters } = useResetFilters();

  const onClose = () => {
    dispatch(subSpecialsFiltersModalAction(false));
    dispatch(resetFilteredSelectedItemAction('subSpecialFilter'));
  };

  const handleSubmit = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      if (!label) {
        toast_loader(loader, 'Le label est obligatoire ! ❌', 'error');
        return;
      }

      const data = {
        label,
        specialFilterId: specialFilterId,
      };

      if (subSpecialFilterSelected) {
        await updateOneSubSpecialFilter(
          subSpecialFilterSelected?._id,
          subSpecialFilterSelected.specialFilterId,
          data
        );
        toast_loader(loader, 'Sous filtre spécial modifié !', 'success');
      } else {
        await createOneSubSpecialFilter(data);
        toast_loader(loader, 'Sous filtre spécial ajouté !', 'success');
      }

      resetFilters(['specialFilter', 'specialsFilters']);
      onClose();
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
    }
  };

  return (
    <Modal
      bgWidth={props?.bgWidth}
      zIndex={700}
      onClose={onClose}
      title='Sous filtre spécial'
      onValidate={handleSubmit}
    >
      <Input
        label='Nom'
        type='text'
        value={label}
        required
        onChange={(e) => setLabel(e.target.value)}
      />
      <Select
        label='Filtres spéciaux'
        options={specialsFilter?.map((specialFilter) => ({
          label: specialFilter.label,
          value: specialFilter._id,
        }))}
        value={specialFilterId}
        onChange={(e) => setSpecialFilterId(e.target.value)}
      />
    </Modal>
  );
};
