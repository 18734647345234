const listItem = [
	'familyId',
	'productId',
	'compositionId',
	'filterId',
	'specialFilterId',
];

export const helperResetStrategy = (item, strategy) => {
	const indexOfType = listItem.indexOf(item);


	for (let i = indexOfType + 1; i < listItem.length; i++) {
		strategy[listItem[i]] = '';
	}
};
