export const sortedList = (a, b, sortedBy, sortedByChild = '') => {
  if (sortedByChild.length > 0) {
    if (
      a[sortedBy][sortedByChild]?.toLowerCase() <
      b[sortedBy][sortedByChild]?.toLowerCase()
    ) {
      return -1;
    }
    if (
      a[sortedBy][sortedByChild]?.toLowerCase() >
      b[sortedBy][sortedByChild]?.toLowerCase()
    ) {
      return 1;
    }
    return 0;
  } else {
    if (a[sortedBy]?.toLowerCase() < b[sortedBy]?.toLowerCase()) {
      return -1;
    }
    if (a[sortedBy]?.toLowerCase() > b[sortedBy]?.toLowerCase()) {
      return 1;
    }
    return 0;
  }
};

export const sortByProperty = (a, b, property) => {
  const labelA = a[property] || '';
  const labelB = b[property] || '';

  if (labelA !== '' && labelB !== '') {
    return labelA.localeCompare(labelB);
  } else {
    return 0;
  }
};
