import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../hooks/useReduxHook';
import { viewFeedModalAction } from '../../../../redux/modals';
import { Modal } from '../../../atoms/Modal';
import { helperImageRoute } from '../../../../assets/utils/helperImageRoute';
import { useSelector } from 'react-redux';
import { PublishCardIcon } from '../../../../assets/svgs/PublishCardIcon';
import { useMemo } from 'react';

export const ViewFeedModal = () => {
  const dispatch = useDispatch();
  const role = useSelector((s) => s.app.role);
  const customer = useAppSelector((state) => state.customer.customer);

  const filteredPictures = useMemo(() => {
    return (
      customer?.subscriptions
        ?.flatMap((subscription) => subscription.diaries)
        .map((diary) => diary.picture)
        .filter((x) => !!x) || []
    );
  }, [customer?.subscriptions]);

  return (
    <Modal
      onClose={() => dispatch(viewFeedModalAction(false))}
      title='Publications'
      width='70%'
      left='25%'
      minHeight={filteredPictures?.length >= 20 ? '85%' : 'auto'}
      hiddenBtns
      Icon={PublishCardIcon}
    >
      <div className='viewFeedModal--container'>
        <div className='viewFeedModal--container--imageWrapper'>
          {filteredPictures?.length > 0 ? (
            <>
              {filteredPictures?.reverse()?.map((pic, index) => (
                <img
                  className='viewFeedModal--container--imageWrapper--imgItem'
                  key={index}
                  src={helperImageRoute(
                    pic?.image,
                    'picture',
                    true,
                    role === 'COMMERCIAL'
                  )}
                  alt={`FeedImage-${index + 1}`}
                />
              ))}
            </>
          ) : (
            <div className='viewFeedModal--container--imageWrapper--empty'>
              <img src='./insta-logo.png' alt='' />
              <h1>Aucune photo</h1>
            </div>
          )}
        </div>
        {filteredPictures?.map((pic, index) => (
          <div className='viewFeedModal--container--imageList' key={index}>
            <div className='viewFeedModal--container--imageWrapper'></div>
          </div>
        ))}
      </div>
    </Modal>
  );
};
