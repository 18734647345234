export const Plus2Icon = ({ onClick = () => {} }) => {
  return (
    <svg
      width='19'
      height='20'
      viewBox='0 0 19 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <path
        d='M19 8.70703H10.2917H8.70833H0V10.2904H8.70833H10.2917H19V8.70703Z'
        fill='#282828'
      />
      <path
        d='M10.293 19.2891L10.293 10.5807L10.293 8.9974L10.293 0.289062L8.70964 0.289062L8.70964 8.9974L8.70964 10.5807L8.70963 19.2891L10.293 19.2891Z'
        fill='#282828'
      />
    </svg>
  );
};
