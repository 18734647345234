import { axiosInstance } from '../assets/utils/axiosInstance';

export const createOneLog = async (body) => {
  const res = await axiosInstance.post('/log/create', body).catch((err) => {
    console.log('getAllByUserId', err);
  });
  return res;
};

export const getAllByUserId = async (id) => {
  const res = await axiosInstance
    .get(`/log/get-all/by-user-id/${id}`)
    .catch((err) => {
      console.log('getAllByUserId', err);
    });
  return res;
};

export const getAllByUserAndPictureId = async (params) => {
  const res = await axiosInstance
    .get(`/log/get-all/by-user-picture-id/${params.userId}/${params.pictureId}`)
    .catch((err) => {
      console.log('getAllByUserAndPictureId', err);
    });
  return res;
};
