export const ArrowLeftIcon = ({ color = '#a88e1b' }) => {
  return (
    <svg
      width='15'
      height='14'
      viewBox='0 0 15 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.5014 7.99997V5.99997L3.05636 6.03097L7.37836 1.70697L5.96436 0.292969L1.37836 4.87897C0.815946 5.44155 0.5 6.20448 0.5 6.99997C0.5 7.79546 0.815946 8.55839 1.37836 9.12097L5.96436 13.707L7.37836 12.293L3.11636 8.03097L14.5014 7.99997Z'
        fill={color}
      />
    </svg>
  );
};
