import { axiosInstance } from '../assets/utils/axiosInstance';
import { IQuiz } from '../redux/familyQuiz';

//CREATE
export const createQuiz = async (quiz) => {
  try {
    const res = await axiosInstance.post<{
      data: { suucess: boolean; message: string; data: IQuiz };
    }>('/quiz/create', quiz);
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: error };
  }
};

//READ
export const getOneQuiz = async (quizId: string) => {
  try {
    const res = await axiosInstance.get<{
      data: { suucess: boolean; data: IQuiz };
    }>(`/quiz/get-one-quiz/${quizId}`);
    return { error: false, data: res.data?.data };
  } catch (error) {
    return { error: true, data: error };
  }
};

//UPDATE
export const updateOneQuiz = async (quizId: string, body) => {
  try {
    const res = await axiosInstance.put<{
      data: { suucess: boolean };
    }>(`/quiz/update-quiz/${quizId}`, body);
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: error };
  }
};

//DELETE
export const deleteOneQuestion = async (questionId: string) => {
  try {
    const res = await axiosInstance.delete<{
      data: { suucess: boolean };
    }>(`/quiz/delete-question/${questionId}`);
    return { error: false, data: res.data };
  } catch (error) {
    return { error: true, data: error };
  }
};
