import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {compose} from 'recompose';
import {initUserForm} from '../../../assets/constants';
import {Button} from '../../../components/atoms/Buttons';
import {withNavigation} from '../../../hoc/withNavigation';
import {HeaderWithTabs} from '../../../components/organisms/Header';
import {InformationsPanel} from '../../../components/organisms/User/InformationsPanel';
import {HistoricalPanel} from '../../../components/organisms/User/HistoricalPanel';
import {Toast} from '../../../assets/utils/toast';
import {UserIcon} from '../../../assets/svgs/UserIcon';
// import {CardPayIcon} from '../../../assets/svgs/CardPayIcon';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {createOneUser} from '../../../api/users.api';

const tabsData = [
  {label: 'Informations', link: 'informations', icon: UserIcon},
  // {label: 'Historique', link: 'historical', icon: CardPayIcon},
];

const AddUserView = () => {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState('informations');
  const [userForm, setUserForm] = useState(initUserForm);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setUserForm({...userForm, [name]: value});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (confirmPassword === userForm.password) {
      setLoading(true);
      await createOneUser(userForm)
        .then((res) => {
          setLoading(false);
          navigate(-1);
          Toast('success', 'Utilisateur ajouté !');
        })
        .catch((err) => {
          setLoading(false);
          Toast('error', "Erreur lors de l'ajout !");
        });
      setLoading(false);
    }
  };

  return (
    <div className='pages-layout'>
      <HeaderWithTabs
        tabs={tabs}
        setTabs={setTabs}
        user={userForm}
        tabsData={tabsData}
        navigateTo={-1}
      />
      <form className='pages-layout--container user' onSubmit={handleSubmit}>
        {tabs === 'informations' && (
          <InformationsPanel
            setUserForm={setUserForm}
            userForm={userForm}
            handleChange={handleChange}
            setConfirmPassword={setConfirmPassword}
            confirmPassword={confirmPassword}
            loading={loading}
          />
        )}
        {tabs === 'historical' && <HistoricalPanel />}
        <div className='user--inputBlock' style={{marginTop: '2rem'}}>
          <Button
            onClick={() => navigate(-1)}
            variant='black'
            label='Annuler'
            width='45%'
          />
          <Button variant='yellow' label='Valider' width='45%' />
        </div>
      </form>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(AddUserView);
