import React from 'react';
import { Modal } from '../../atoms/Modal';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { CompositionCard } from '../../organisms/CompositionsGestion/CompositionCard';
import { IComposition } from '../../../redux/compositions';
import { NotFound } from '../../atoms/NotFound';
import { customerCompositionsModalAction } from '../../../redux/modals';
import {
  editCustomerInfoAction,
  formulaAction,
  updateCustomerStrategiesAction,
} from '../../../redux/customer';
import { copyObj } from '../../../assets/utils/copyObj';
import { useQuery } from 'react-query';
import { getAllCompositionsByFamilyId } from '../../../api/families.api';
import { helperFilterIngForCompose } from '../../../assets/utils/helperFilterIngForCompose';

export const CompositionsModal: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const customerStore = useAppSelector((state) => state.customer);

  const customerCompositions = customerStore?.customer?.univers?.families
    ?.map((_f) => _f?.products?.map((_p) => _p?.compositions))
    .flat(2)
    .filter((_c) => _c);

  const strategyModel = customerStore?.customer?.strategyModel;

  const strategyModelCompOpt = customerStore?.strategyModelCompOpt;

  const customer = customerStore?.customer;

  const subscriptions = customer?.subscriptions || [];

  const formula = customerStore?.formula;

  const currentSubscriptionIndex = customerStore?.currentSubscriptionIndex;

  const currentSubscription = subscriptions[currentSubscriptionIndex];

  const { index, month, from, label, strategyIndex } = strategyModelCompOpt;

  const strategies = copyObj(currentSubscription?.strategies);

  const formula_number = index + 1;

  const strategyFinded = strategies?.find(
    (strategy) =>
      strategy.month === month && strategy.formula_number === formula_number
  );

  const strategy =
    from === 'strategyModal'
      ? strategyModel?.[strategyModelCompOpt?.strategyIndex]?.[
          strategyModelCompOpt?.index || 0
        ]
      : from === 'strategyPanel'
      ? strategyFinded?.strategy
      : formula?.strategy;

  const findFamily = customer?.univers?.families?.find(
    (_f) => _f?._id === strategy?.familyId
  );

  const { data: allProdsCompsData, isLoading } = useQuery(
    ['all-compositions-products', findFamily?._id],
    () => getAllCompositionsByFamilyId(findFamily?._id),
    {
      enabled: !!findFamily?._id && strategy?.type === 'forCompose',
    }
  );

  const filteredCompositions = customerCompositions
    ?.filter(
      (_c) => (_c.productId?._id || _c.productId) === strategy?.productId
    )
    ?.filter((_c) => _c?.type === 'principal');

  const compositionsPrincipal = customerCompositions?.filter(
    (_c) => _c?.type === 'principal'
  );

  const compositionsForCompose = (allProdsCompsData?.data || [])
    ?.filter((comp: IComposition) => {
      return helperFilterIngForCompose(comp, findFamily);
    })
    ?.filter(
      (comp: IComposition) =>
        !compositionsPrincipal?.find((_c) => _c?._id === comp?._id)
    )
    ?.filter(
      (_c) => (_c.productId?._id || _c.productId) === strategy?.productId
    );
  // const compositionsForCompose = [];

  const compositions =
    strategy?.type === 'principal'
      ? filteredCompositions
      : compositionsForCompose;

  const handleSelectStrategyModel = (compId: string | null) => {
    const newStrategiesModel = copyObj(strategyModel);

    if (isNaN(index)) {
      if (newStrategiesModel[strategyIndex]) {
        newStrategiesModel[strategyIndex][0] = {
          ...newStrategiesModel[strategyIndex][0],
          [label]: compId,
        };
      } else {
        newStrategiesModel[strategyIndex] = [
          {
            ...newStrategiesModel[strategyIndex][0],
            [label]: compId,
          },
        ];
      }
    } else {
      newStrategiesModel[strategyIndex][index] = {
        ...newStrategiesModel[strategyIndex][index],
        [label]: compId,
      };
    }
    dispatch(
      editCustomerInfoAction({
        tab: 'strategyModel',
        value: newStrategiesModel,
      })
    );
  };

  const handleChange = (compId: string) => {
    if (strategyFinded) {
      if (compId === strategy.compositionId) {
        strategy.compositionId = null;
      } else {
        strategy.compositionId = compId;
      }
    } else {
      strategies.push({
        month,
        formula_number,
        strategy: {
          compositionId: compId,
        },
      });
    }
    dispatch(updateCustomerStrategiesAction(strategies));
  };

  const handleChangeFromImgAttrModal = (compId: string) => {
    const newFomula = copyObj(formula);
    if (compId === strategy.compositionId) {
      newFomula.strategy.compositionId = null;
    } else {
      newFomula.strategy.compositionId = compId;
    }
    dispatch(formulaAction(newFomula));
  };

  return (
    <Modal
      width='60%'
      onClose={() => dispatch(customerCompositionsModalAction(false))}
      title='Compositions'
      onValidate={() => dispatch(customerCompositionsModalAction(false))}
      btnValidateLabel='Terminer'
      left='30%'
      zIndex={1000}
    >
      <div
        className='w-100 flex-wrap'
        style={{ gap: '1rem', justifyContent: 'flex-start' }}
      >
        {isLoading ? (
          <div className='w-100 flex-row-center'>
            <p>Chargement...</p>
          </div>
        ) : !!compositions && compositions?.length > 0 ? (
          compositions.map((composition: IComposition, index: number) => {
            const selected = strategy?.compositionId === composition?._id;

            return (
              <CompositionCard
                width='48%'
                composition={composition}
                onSelectComposition={() =>
                  from === 'strategyModal'
                    ? handleSelectStrategyModel(
                        selected ? null : composition?._id
                      )
                    : from === 'strategyPanel'
                    ? handleChange(composition?._id)
                    : handleChangeFromImgAttrModal(composition?._id)
                }
                key={index}
                selected={selected}
              />
            );
          })
        ) : (
          <NotFound
            text={`Pas de composition ${
              strategy?.type === 'principal'
                ? 'pour le produit sélectionné'
                : ''
            }!`}
          />
        )}
      </div>
    </Modal>
  );
};
