import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  createOneSpecialFilter,
  updateOneSpecialFilter,
} from '../../../api/specialsFilters.api';
import { toast_loader } from '../../../assets/utils/toast';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { specialsFiltresModalAction } from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

//TODO Rendre autonome et vider les props
export const SpecialsFiltresModal = ({ bgWidth = '80%' }) => {
  const dispatch = useDispatch();

  const specialFilterSelected = useAppSelector(
    (state) => state.filtersSelected.specialFilter
  );

  const [specialFilter, setSpecialFilter] = useState(
    specialFilterSelected || {}
  );

  const { resetFilters } = useResetFilters();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSpecialFilter({ ...specialFilter, [name]: value });
  };

  const onClose = () => {
    dispatch(specialsFiltresModalAction(false));
    dispatch(resetFilteredSelectedItemAction('specialFilter'));
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) e.preventDefault();
    const loader = toast.loading('Veuillez patienter...');
    try {
      if (!specialFilter.label) {
        toast_loader(loader, 'Le label est obligatoire ! ❌', 'error');
        return;
      }

      let res;

      if (specialFilterSelected) {
        res = await updateOneSpecialFilter(
          specialFilterSelected?._id,
          specialFilter
        );
        toast_loader(loader, 'Filtre spécial modifié !', 'success');
      } else {
        res = await createOneSpecialFilter(specialFilter);
        toast_loader(loader, 'Filtre spécial ajouté !', 'success');
      }

      if (!res) {
        toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
        return;
      }

      resetFilters(['specialFilter', 'specialsFilters']);
      onClose();
    } catch (error) {
      toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
    }
  };
  return (
    <Modal
      bgWidth={bgWidth}
      zIndex={700}
      hiddenBtns
      onClose={onClose}
      title='Filtre spéciaux'
      onValidate={handleSubmit}
    >
      <form onSubmit={handleSubmit}>
        <Input
          label='Nom'
          type='text'
          name='label'
          value={specialFilter?.label}
          onChange={handleChange}
          required
        />
        <div className='modal--footer'>
          <Button
            label='Annuler'
            width={190}
            margin='0 .5rem'
            onClick={onClose}
          />
          <Button
            label='Valider'
            variant='yellow'
            width={190}
            margin='0 .5rem'
          />
        </div>
      </form>
    </Modal>
  );
};
