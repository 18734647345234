import { axiosInstance } from '../assets/utils/axiosInstance';

export const fetchPackagesByYear = async (year) => {
  const res = await axiosInstance
    .get(`/package/by-year/${year}`)
    .catch((err) => {
      console.log('fetchPackagesByYear', err);
    });
  return res;
};

export const updateOnePackages = async (packages) => {
  const res = await axiosInstance
    .put(`/package/${packages?._id}`, packages)
    .catch((err) => {
      console.log('updateOnePackages', err);
    });
  return res;
};
