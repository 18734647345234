import { useDispatch } from 'react-redux';
import { FacebookIcon } from '../../../assets/svgs/FacebookIcon';
import { InstagramIcon } from '../../../assets/svgs/InstagramIcon';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { editCustomerInfoAction } from '../../../redux/customer';
import {
  citiesListModalAction,
  packageCustomerModalAction,
} from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Input, InputLink } from '../../atoms/Fields';
import TextEditor from '../../atoms/TextEditor';

export const InformationsPanel = ({ add = false }) => {
  const dispatch = useDispatch();

  const customer = useAppSelector((state) => state.customer.customer);

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      dispatch(
        editCustomerInfoAction({
          tab: 'informations',
          key: name,
          value,
        })
      );
    } else {
      dispatch(
        editCustomerInfoAction({
          tab: 'informations',
          key: 'slogan',
          value: e,
        })
      );
    }
  };

  return (
    <>
      {add && <Package />}
      <Input
        label='Nom du restaurant'
        name='restaurant_name'
        value={customer?.informations?.restaurant_name}
        onChange={handleChange}
      />
      <div className='customer--inputBlock'>
        <Input
          label='Telephone du restaurant'
          variant='half'
          required
          name='restaurant_phone'
          type='number'
          value={customer?.informations?.restaurant_phone}
          onChange={handleChange}
        />
        <Input
          label='Adresse du restaurant'
          variant='half'
          required
          name='restaurant_address'
          value={customer?.informations?.restaurant_address}
          onChange={handleChange}
        />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <p className='customer--textData'>
          {customer?.informations?.restaurant_country ||
            'Aucun pays sélectionné'}
        </p>
        <p className='customer--textData'>
          {customer?.informations?.restaurant_city ||
            'Aucune ville sélectionnée'}
        </p>
        <Button
          variant='black'
          label='Pays et ville'
          onClick={() => dispatch(citiesListModalAction(true))}
          margin='0 0 0 auto'
        />
      </div>
      <InputLink
        label='Url Facebook'
        variant='full'
        Icon={FacebookIcon}
        name='url_facebook'
        value={customer?.informations?.url_facebook}
        onChange={handleChange}
      />
      <InputLink
        label='Url Instagram'
        variant='full'
        Icon={InstagramIcon}
        name='url_instagram'
        value={customer?.informations?.url_instagram}
        onChange={handleChange}
      />
      <TextEditor
        label='Slogan'
        name='slogan'
        value={customer?.informations?.slogan}
        onChange={handleChange}
        basicInput={true}
      />
      <div className='customer--responsable'>
        <div className='customer--inputBlock'>
          <Input
            label='Nom du responsable'
            variant='half'
            required
            name='lastname'
            value={customer?.informations?.lastname}
            onChange={handleChange}
          />
          <Input
            label='Prénom du responsable'
            variant='half'
            required
            name='firstname'
            value={customer?.informations?.firstname}
            onChange={handleChange}
          />
        </div>
        <Input
          label='Email du responsable'
          variant='third'
          required
          name='email'
          value={customer?.informations?.email}
          onChange={handleChange}
        />
        <div className='customer--inputBlock'>
          <Input
            label='Téléphone du responsable'
            variant='half'
            name='phone'
            type='number'
            value={customer?.informations?.phone}
            onChange={handleChange}
          />
          <Input
            label='Numéro whatsapp'
            variant='half'
            name='phone_whatsapp'
            type='number'
            value={customer?.informations?.phone_whatsapp}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

const Package = () => {
  const dispatch = useDispatch();
  const customer = useAppSelector((state) => state.customer.customer);

  return (
    <div className='w-100 flex-row-center customer--packageBlock'>
      <p className='customer--packageTitle'>Abonnement :</p>
      {customer?.subscriptions?.length > 0 ? (
        <>
          de
          <span className='customer--packageText'>
            {customer.subscriptions[0]?.startOn.month}{' '}
            {customer.subscriptions[0]?.startOn.year}
          </span>
          en
          <span className='customer--packageText'>
            {' '}
            {customer.subscriptions[0]?.endIt.month}{' '}
            {customer.subscriptions[0]?.endIt.year}
          </span>
          - formule de
          <span className='customer--packageText'>
            {customer.subscriptions[0].formula}
          </span>
        </>
      ) : (
        'Aucun forfait'
      )}
      <Button
        label='Modifier le forfait'
        margin='0 0 0 auto'
        variant='black'
        onClick={() => dispatch(packageCustomerModalAction(true))}
      />
    </div>
  );
};
