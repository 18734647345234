import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { fetchCustomerById, fetchCustomers } from '../../../api/customers.api';
import { colors } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import {
  currentSubscriptionIndexAction,
  customerDetailsAction,
  pictureToAttributeAction,
} from '../../../redux/customer';
import { initFiltersAction } from '../../../redux/filters';
import { initImagesFiltersAction } from '../../../redux/images';
import {
  customersListModalAction,
  editImgModalAction,
  similarsImagesModalAction,
} from '../../../redux/modals';
import { setFilteredByCustomerUnivers } from '../../../redux/picture';
import { Button } from '../../atoms/Buttons';
import { Input } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';
import { useAppSelector } from '../../../hooks/useReduxHook';

export const CustomersListModal = () => {
  //

  const dispatch = useDispatch();
  const filters = useAppSelector((state) => state.filters);
  const [customerSelectedId, setCustomerSelectedId] = useState(null);
  const [search, setSearch] = useState('');

  const { data: customers } = useQuery('customers', () => {
    return fetchCustomers();
  });

  const handleClose = () => {
    dispatch(customersListModalAction(false));
    dispatch(pictureToAttributeAction(null));
  };

  const handleSubmit = async () => {
    const data = await fetchCustomerById(customerSelectedId);
    const customer = data.customer;
    if (customer) {
      dispatch(
        customerDetailsAction({ ...customer, attributionPicture: true })
      );
      dispatch(setFilteredByCustomerUnivers(true));
      dispatch(
        initFiltersAction({ ...customer.univers, moods: [], ...filters })
      );
      dispatch(initImagesFiltersAction(customer.univers));
      dispatch(
        currentSubscriptionIndexAction((customer.subscriptions.length || 1) - 1)
      );
      dispatch(customersListModalAction(false));
      dispatch(editImgModalAction(false));
      dispatch(similarsImagesModalAction(false));
    }
  };

  return (
    <Modal
      onClose={handleClose}
      title='CHOISIR UN CLIENT'
      onValidate={null}
      btnValidateLabel='Valider'
      width={553}
      zIndex={600}
      bodyPadding='1rem '
      hiddenBtns
    >
      <Input
        placeholder='Rechercher un client'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          maxHeight: 300,
          overflowY: 'auto',
        }}
      >
        {customers &&
          copyObj(customers)
            .sort((a, b) =>
              sortedList(a?.informations, b?.informations, 'restaurant_name')
            )
            ?.map((customer) => {
              if (
                search &&
                !customer.informations.restaurant_name
                  .toLowerCase()
                  .includes(search.toLowerCase())
              )
                return null;

              return (
                <button
                  style={{
                    color: colors.dark,
                    borderColor:
                      customerSelectedId === customer._id
                        ? colors.yellow
                        : '#F4F4F4',
                    padding: '1rem',
                  }}
                  className='buttonSelect'
                  key={customer._id}
                  onClick={() => setCustomerSelectedId(customer._id)}
                >
                  {customer?.informations?.restaurant_name +
                    (customer?.informations?.restaurant_city
                      ? `-  ${customer?.informations?.restaurant_city}`
                      : '')}
                </button>
              );
            })}
      </div>
      <Button
        width={150}
        label='Valider'
        variant='yellow'
        margin='1rem 0 0 auto'
        onClick={handleSubmit}
      />
    </Modal>
  );
};
