import {createSlice} from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'actionsModals',
  initialState: {
    specialsFiltersActionsModals: null,
  },

  reducers: {
    specialsFiltersActionsModalsAction: (state, action) => {
      state.specialsFiltersActionsModals = action.payload;
    },
  },
});

export const {specialsFiltersActionsModalsAction} = slice.actions;
export default slice.reducer;
