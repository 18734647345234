export const CityIcon = ({ color = '#282828' }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.234375 18.3621C0.234375 17.9442 0.56915 17.6055 0.982117 17.6055H18.4294C18.8424 17.6055 19.1772 17.9442 19.1772 18.3621C19.1772 18.78 18.8424 19.1188 18.4294 19.1188H0.982117C0.56915 19.1188 0.234375 18.78 0.234375 18.3621Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.60058 12.0543C3.53175 12.0543 3.47595 12.1108 3.47595 12.1804V17.4769C3.47595 17.5466 3.53175 17.603 3.60058 17.603H5.34531C5.41413 17.603 5.46993 17.5466 5.46993 17.4769V12.1804C5.46993 12.1108 5.41413 12.0543 5.34531 12.0543H3.60058ZM1.98047 12.1804C1.98047 11.275 2.70582 10.541 3.60058 10.541H5.34531C6.24007 10.541 6.96541 11.275 6.96541 12.1804V17.4769C6.96541 18.3823 6.24007 19.1163 5.34531 19.1163H3.60058C2.70582 19.1163 1.98047 18.3823 1.98047 17.4769V12.1804Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.08886 2.34532C7.02003 2.34532 6.96423 2.40178 6.96423 2.47142V17.4782C6.96423 17.5478 7.02003 17.6043 7.08886 17.6043H15.8125C15.8813 17.6043 15.9371 17.5478 15.9371 17.4782V2.47142C15.9371 2.40178 15.8813 2.34532 15.8125 2.34532H7.08886ZM5.46875 2.47142C5.46875 1.56601 6.1941 0.832031 7.08886 0.832031H15.8125C16.7073 0.832031 17.4326 1.56601 17.4326 2.47142V17.4782C17.4326 18.3836 16.7073 19.1176 15.8125 19.1176H7.08886C6.1941 19.1176 5.46875 18.3836 5.46875 17.4782V2.47142Z'
        fill={color}
      />
      <path
        d='M8.83203 4.23828H10.5768V6.00378H8.83203V4.23828ZM12.3215 4.23828H14.0662V6.00378H12.3215V4.23828ZM8.83203 7.32791H10.5768V9.09341H8.83203V7.32791ZM12.3215 7.32791H14.0662V9.09341H12.3215V7.32791ZM12.3215 10.4175H14.0662V12.183H12.3215V10.4175ZM12.3215 13.5072H14.0662V15.2727H12.3215V13.5072Z'
        fill={color}
      />
    </svg>
  );
};
