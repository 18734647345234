import { months } from '../constants';
import { helperFormatNumber } from './funcsUtils';

export const helperFormatMonthStringToNumber = (monthString) => {
  const index =
    months?.findIndex(
      (month) => month?.toLowerCase() === monthString?.toLowerCase()
    ) + 1;

  return helperFormatNumber(index);
};

export const helperFormatSubscriptionDate = (subscription) => {
  if (!subscription) return '';

  const { startOn, endIt } = subscription;

  return `de ${helperFormatMonthStringToNumber(
    startOn?.month
  )}/${startOn?.year?.slice(-2)} en ${helperFormatMonthStringToNumber(
    endIt?.month
  )}/${endIt?.year.slice(-2)}`;
};
