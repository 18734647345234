import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createCustomerSubscription,
  updateCustomerSubscription,
} from '../../../api/customers.api';
import { months } from '../../../assets/constants';
import { Toast } from '../../../assets/utils/toast';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { editCustomerInfoAction } from '../../../redux/customer';
import { packageCustomerModalAction } from '../../../redux/modals';
import { ButtonRadio } from '../../atoms/Buttons';
import { Select } from '../../atoms/Fields';
import { Modal } from '../../atoms/Modal';

//TODO vider les props
export const PackageCustomerModal = () => {
  const date = new Date();
  const mois = date.getMonth() + 1;
  const annee = date.getFullYear();

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState({
    month: months[mois],
    year: String(annee),
  });

  const [endDate, setEndDate] = useState({
    month: months[mois],
    year: String(annee + 1),
  });
  const [formula, setFormula] = useState(6);

  const customer = useAppSelector((state) => state.customer.customer);

  const dispatch = useDispatch();

  const isUpdateSubscription = useAppSelector(
    (state) => state.customer.isUpdateSubscription
  );

  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );

  const handleUpdateSubscription = async (data) => {
    // TODO update subscription
    const res = await updateCustomerSubscription(
      customer.subscriptions[currentSubscriptionIndex]._id,
      data
    );
    return res;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const data = {
        startOn: {
          month: startDate.month,
          year: startDate.year,
        },
        endIt: {
          month: endDate.month,
          year: endDate.year,
        },
        formula: formula,
      };

      if (isUpdateSubscription) {
        await handleUpdateSubscription(data);
        Toast('success', 'Abonnement modifié avec succès');
        dispatch(
          editCustomerInfoAction({
            tab: 'subscriptions',
            value: customer?.subscriptions.map((sub, index) => {
              if (index === currentSubscriptionIndex) {
                return {
                  ...sub,
                  ...data,
                };
              }
              return sub;
            }),
          })
        );
      } else {
        if (customer?._id) {
          const res = await createCustomerSubscription(customer._id, data);

          dispatch(
            editCustomerInfoAction({
              tab: 'subscriptions',
              value: [...customer?.subscriptions, res.data],
            })
          );
          Toast('success', 'Abonnement ajouté avec succès');
        } else {
          dispatch(
            editCustomerInfoAction({
              tab: 'subscriptions',
              value: [data],
            })
          );
        }
      }

      setLoading(false);
      dispatch(packageCustomerModalAction(false));
    } catch (error) {
      setLoading(false);
      Toast('error', error.message);
    }
  };

  useEffect(() => {
    if (
      isUpdateSubscription ||
      (!customer?._id &&
        customer?.subscriptions &&
        customer?.subscriptions[currentSubscriptionIndex])
    ) {
      const subscription = customer?.subscriptions[currentSubscriptionIndex];

      if (subscription) {
        setStartDate({
          month: subscription?.startOn?.month,
          year: subscription?.startOn?.year,
        });
        setEndDate({
          month: subscription?.endIt.month,
          year: subscription?.endIt.year,
        });
        setFormula(subscription?.formula);
      }
    }
  }, [
    isUpdateSubscription,
    currentSubscriptionIndex,
    customer?._id,
    customer?.subscriptions,
  ]);

  const years = Array.from({ length: 10 }, (_, index) => annee + index);

  return (
    <Modal
      onClose={() => dispatch(packageCustomerModalAction(false))}
      title='Abonnement'
      onValidate={handleSubmit}
      loading={loading}
    >
      <div className='w-100 flex-row-center' style={{ margin: '1rem 0' }}>
        <ButtonRadio
          label='Formule de 6'
          active={formula === 6}
          margin='0 1rem 0 0'
          onClick={() => setFormula(6)}
        />
        <ButtonRadio
          label='Formule de 12'
          active={formula === 12}
          onClick={() => setFormula(12)}
        />
      </div>
      <div className='w-100 flex-row-center' style={{ margin: '1rem 0' }}>
        <span style={{ margin: '0 1rem 0 .5rem' }}>Début :</span>
        <Select
          width={150}
          margin='0 1rem 0 0'
          defaultValue={startDate.month}
          value={startDate.month}
          onChange={(e) =>
            setStartDate((prevDate) => ({ ...prevDate, month: e.target.value }))
          }
          options={months.map((month) => ({
            name: month?.toLowerCase(),
            value: month?.toLowerCase(),
          }))}
        />
        <Select
          width={150}
          margin='0 1rem 0 0'
          value={startDate.year}
          onChange={(e) =>
            setStartDate((prevDate) => ({ ...prevDate, year: e.target.value }))
          }
          options={years.map((year) => ({ name: year, value: year }))}
        />
      </div>
      <div className='w-100 flex-row-center' style={{ margin: '1rem 0' }}>
        <span style={{ margin: '0 2.4rem 0 .5rem' }}>Fin :</span>
        <Select
          width={150}
          margin='0 1rem 0 0'
          defaultValue={endDate.month}
          value={endDate.month}
          onChange={(e) =>
            setEndDate((prevDate) => ({ ...prevDate, month: e.target.value }))
          }
          options={months.map((month) => ({
            name: month?.toLowerCase(),
            value: month?.toLowerCase(),
          }))}
        />
        <Select
          width={150}
          margin='0 1rem 0 0'
          value={endDate.year}
          onChange={(e) =>
            setEndDate((prevDate) => ({ ...prevDate, year: e.target.value }))
          }
          options={years.map((year) => ({ name: year, value: year }))}
        />
      </div>
    </Modal>
  );
};
