import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast, toast_loader } from '../../../assets/utils/toast';
import {
  fetchCustomersByPictureAttribute,
  updateCustomerInfo,
} from '../../../api/customers.api';
import { createOneLog, getAllByUserAndPictureId } from '../../../api/logs.api';
import { uploadFile } from '../../../api/uploadFile.api';
import { copyObj } from '../../../assets/utils/copyObj';
import helperDate from '../../../assets/utils/helperDate';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { resetFiltersAction } from '../../../redux/filters';
import {
  imageAttributionsTabAction,
  resetImgAttrAction,
  updateImageAction,
} from '../../../redux/imageAttributions';
import {
  resetImageFormAction,
  infosMainProductsAction,
  infosSecondaryProductsAction,
} from '../../../redux/images';
import { editImgModalAction } from '../../../redux/modals';
import { ImageForm } from '../../../views/online/ImagesView/ImageForm';

import { Button } from '../../atoms/Buttons';
import { Input } from '../../atoms/Fields';
import { Tabs } from '../../atoms/Tabs';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';
import { fetchFamilies } from '../../../api/families.api';
import { updateOnePicture } from '../../../api/picture.api';
import { toast } from 'react-toastify';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { useCloneImage } from '../../../hooks/useCloneImage';

const tabsBtns = [
  { label: 'Modification', id: 'edit' },
  { label: 'Attribution', id: 'attribution' },
  // { label: 'Historique', id: 'historicals' },
];

const formTabsData = [
  { label: 'Informations', id: 'informations' },
  { label: 'Attribution', id: 'attribution' },
];

export const ImageInfosModal = () => {
  //

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const cloneParam = urlParams.get('clone');
  const isClone = cloneParam === 'true';
  const userData = useAppSelector((s) => s.app.userData);
  const role = useAppSelector((s) => s.app.role);
  const imagesSelected = useAppSelector((s) => s.images);
  const similarsImagesModal = useAppSelector(
    (s) => s.modals.similarsImagesModal
  );
  const queryClient = useQueryClient();
  const imageAttributions = useAppSelector((state) => state.imageAttributions);
  const imageFormData = useAppSelector((state) => state.images);
  const { count, mainCompCount, secondaryCompCount, compositionsAttr } =
    useAttributionsImgComposition();
  const { data: familiesData } = useQuery('families', () => fetchFamilies());

  const [tabs, setTabs] = useState('edit');
  const [formTabs, setFormTabs] = useState(
    imagesSelected.tab || 'informations'
  );
  const { handleCloneImage, loading } = useCloneImage();
  const { resetFilters } = useResetFilters();

  const handleCloseModal = () => {
    dispatch(editImgModalAction(false));
    dispatch(updateImageAction(false));
    if (!similarsImagesModal) {
      dispatch(resetFiltersAction());
      dispatch(resetImageFormAction());
    }

    navigate('/images-list');
  };

  const handleRedirect = (type) => {
    navigate(
      !isClone ? '/image-attributions' : '/image-attributions?clone=true'
    );
    dispatch(resetImgAttrAction());
    dispatch(imageAttributionsTabAction(type));
    dispatch(editImgModalAction(false));
    dispatch(updateImageAction(false));
  };

  const handleSubmit = async () => {
    if (!imagesSelected.image || imagesSelected?.image === '') {
      Toast('warning', 'Veuillez sélectionner une image !');
      return;
    }

    const action =
      imageAttributions.imageAttributionsTab === 'product-main'
        ? infosMainProductsAction
        : infosSecondaryProductsAction;

    const familiesSelected =
      imageAttributions.imageAttributionsTab === 'product-main'
        ? imageFormData?.infosMainProducts
        : imageFormData?.infosSecondaryProducts;

    if (JSON.stringify(familiesSelected) !== JSON.stringify(familiesData)) {
      familiesData.forEach((family) => {
        const formatedFamilies = familiesSelected?.map((f) =>
          f.familyId === family?.familyId ? family : f
        );
        dispatch(action(formatedFamilies));
      });
    }

    const hasSecondaryComp = secondaryCompCount > 0;
    const hasMainComp = mainCompCount > 0;

    if (hasSecondaryComp) {
      if (hasMainComp) {
        handleRedirect('product-main');
      } else {
        handleRedirect('product-secondary');
      }
      dispatch(updateImageAction(true));
    } else if (compositionsAttr.length > 0) {
      setFormTabs('attribution');
    } else {
      Toast('warning', 'Veuillez sélectionner au moins une famille !');
    }
  };

  const onAddAttribution = (edit) => {
    if (count > 0) {
      const familiesSelected =
        imageAttributions?.imageAttributionsTab === 'product-main'
          ? imageFormData?.infosMainProducts
          : imageFormData?.infosSecondaryProducts;

      const action =
        imageAttributions?.imageAttributionsTab === 'product-main'
          ? infosMainProductsAction
          : infosSecondaryProductsAction;

      for (let i = 0; i < familiesData.length; i++) {
        const family = familiesData[i];
        const formatedFamilies = familiesSelected?.map((f) => {
          if (f.familyId === family?.familyId) {
            return family;
          }
          return f;
        });
        dispatch(action(formatedFamilies));
      }

      if (!isClone) {
        navigate('/image-attributions');
      } else if (isClone) {
        navigate('/image-attributions?clone=true');
      }

      if (edit) {
        dispatch(editImgModalAction(false));
      }
    } else if (count === 0 && compositionsAttr.length > 0) {
      setFormTabs('attribution');
      return;
    } else {
      Toast('warning', 'Veuillez sélectionner au moins une famille !');
      return;
    }
  };

  const handleUpdate = async () => {
    const loader = toast.loading('Veuillez patienter...');

    const picture = imagesSelected.image;
    const data = { ...imagesSelected };

    if (!!picture?.name) {
      const fileName = await uploadFile(picture, 'picture');
      if (fileName) {
        data.image = fileName;
      }
    }

    const payload = {
      ...data,
      attrMainCompositions: data?.attrMainCompositions?.map((_el) => ({
        familyId: _el.familyId,
        productId: _el.productId?._id || _el?.productId,
        compositionId: _el.compositionId?._id || _el?.compositionId || _el._id,
      })),
      attrSecondaryCompositions: data?.attrSecondaryCompositions?.map(
        (_el) => ({
          familyId: _el.familyId,
          productId: _el.productId?._id || _el?.productId,
          compositionId:
            _el.compositionId?._id || _el?.compositionId || _el._id,
        })
      ),
    };

    const res = await updateOnePicture(imagesSelected?._id, payload);

    resetFilters('pictures');

    if (res.status === 200) {
      const logBody = {
        userId: userData?._id,
        pictureId: imagesSelected?._id,
        action: `Modification de l'image ${imagesSelected.name}`,
        from: 'image',
        createdAt: new Date(),
      };

      toast_loader(loader, 'Image modifiée !', 'success');
      handleCloseModal();
      queryClient.invalidateQueries('pictures-infinite');
      await createOneLog(logBody);
    } else {
      toast_loader(loader, "Une erreur s'est produite !", 'error');
    }
  };

  const updatingComposition = useMemo(() => {
    return (
      count === 0 && compositionsAttr.length > 0 && formTabs === 'attribution'
    );
  }, [count, compositionsAttr.length, formTabs]);

  useEffect(() => {
    if (window.location.href.includes('add-image')) {
      const selectImageTitle = document.querySelector('.image--sectionTitle');
      const modalImage = document.querySelector('.modalImage');
      let count = selectImageTitle.offsetTop - modalImage.scrollTop - 85;

      modalImage.scrollTo({
        top: count,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(editImgModalAction(false));
    };
  }, [dispatch]);

  return (
    <>
      <div
        onClick={() => dispatch(editImgModalAction(false))}
        className='modalImage--bg'
      />
      <div className='modalImage'>
        {!['COMMERCIAL', 'PUNCHLINER'].includes(role) && !isClone && (
          <Tabs tabs={tabs} setTabs={setTabs} buttons={tabsBtns} />
        )}

        {tabs === 'edit' && !['COMMERCIAL', 'PUNCHLINER'].includes(role) && (
          <Tabs tabs={formTabs} setTabs={setFormTabs} buttons={formTabsData} />
        )}

        <div className='modalImage--container'>
          {tabs === 'edit' && (
            <ImageForm
              tab={formTabs}
              edit
              isClone={isClone}
              onAddAttribution={onAddAttribution}
            />
          )}
          {tabs === 'attribution' && <Attribution />}
          {tabs === 'historicals' && <HistoricalPanel />}
        </div>
        <div className='image--footer'>
          <Button
            label='Annuler'
            margin='0 2rem 0 '
            onClick={handleCloseModal}
          />
          <Button
            loading={loading}
            label={
              !updatingComposition
                ? 'Continuer'
                : isClone && updatingComposition
                ? 'Enregistrer'
                : 'Enregistrer'
            }
            variant='yellow'
            onClick={
              !updatingComposition
                ? handleSubmit
                : isClone && updatingComposition
                ? handleCloneImage
                : handleUpdate
            }
          />
        </div>
      </div>
    </>
  );
};

const Attribution = React.memo(() => {
  const imagesSelected = useAppSelector((s) => s.images);

  const { data } = useQuery(
    ['attributions', imagesSelected?._id],
    () => fetchCustomersByPictureAttribute(imagesSelected?._id),
    {
      enabled: !!imagesSelected?._id,
    }
  );

  const [search, setSearch] = useState('');

  return (
    <>
      <Input
        label='Client'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder='Saisissez le nom du restaurant'
      />
      {data?.data?.map((attribution) => {
        const reg = new RegExp(`${search}`, 'ig');
        if (
          search?.length >= 3 &&
          !attribution?.customerId?.informations?.restaurant_name?.match(reg)
        ) {
          return null;
        }
        return <Trow key={attribution?._id} attribution={attribution} />;
      })}
    </>
  );
});

const Trow = React.memo(({ attribution }) => {
  return (
    <div className='table-trow'>
      <div
        className='table-trow-texts'
        style={{ flex: 4.5, marginLeft: '1rem' }}
      >
        <p
          className='table-trow-text'
          style={{
            fontFamily: 'Inter Bold',
            textTransform: 'uppercase',
            margin: '0 0 .5rem 0',
          }}
        >
          {attribution?.customerId?.informations?.restaurant_name} -{' '}
          {attribution?.customerId?.informations?.restaurant_city}
        </p>
        <p
          className='table-trow-text'
          style={{ textTransform: 'inherit', margin: 0 }}
        >
          {attribution?.diary?.month || ''}
        </p>
      </div>
    </div>
  );
});

const HistoricalPanel = React.memo(() => {
  const userData = useAppSelector((s) => s.app.userData);
  const imagesSelected = useAppSelector((s) => s.images);

  const { data } = useQuery(
    'logs',
    () =>
      getAllByUserAndPictureId({
        userId: userData?._id,
        pictureId: imagesSelected?._id,
      }),
    {
      enabled: !!imagesSelected?._id && !!userData?._id,
    }
  );

  return (
    <div className='modalImage--historicals'>
      <div className='modalImage--historicals-thead'>
        <span>Date</span>
        <span>Action</span>
        <span style={{ paddingLeft: '1rem' }}>Par</span>
      </div>
      {data?.data?.map((log, index) => (
        <div key={index} className='modalImage--historicals-trow'>
          <span>{helperDate(log?.createdAt, 'DD-MM-YYYY HH:mm')}</span>
          <span>{log.action}</span>
          <span style={{ paddingLeft: '1rem' }}>
            {log.userId?.lastname + ' ' + log?.userId.firstname}
          </span>
        </div>
      ))}
    </div>
  );
});
