import React from 'react';
import { colors } from '../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { productIdAction } from '../../../redux/compositions';
import { questionSelectedAction } from '../../../redux/familyQuiz';
import { filteredSelectedItemAction } from '../../../redux/filtersSelected';

export const ProductsList: React.FC = () => {
  const dispatch = useAppDispatch();
  const family = useAppSelector((s) => s.filtersSelected?.family);
  const product = useAppSelector((s) => s.filtersSelected?.product);
  const quiz = useAppSelector((s) => s.familyQuiz?.quiz);

  const products = family?.products;

  const onSelectProduct = (_product) => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'product',
        item: _product,
      })
    );
    dispatch(productIdAction(_product?._id));
    dispatch(questionSelectedAction(quiz?.questionsList[0]));
  };

  return (
    <>
      <p className='customerCompositionsView--productsAnswersBlock--title'>
        Pour quel famille de produit?
      </p>
      <div className='customerCompositionsView--productsAnswersBlock--list'>
        {products?.map((_product, index: number) => {
          return (
            <div
              style={{
                borderColor:
                  _product?._id === product?._id ? colors.yellow : colors.grey,
              }}
              className='customerCompositionsView--productsAnswersBlock--card'
              key={index}
              onClick={() => onSelectProduct(_product)}
            >
              {_product?.name}
            </div>
          );
        })}
      </div>
    </>
  );
};
