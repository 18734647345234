export const HashtagIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 2C2.22386 2 2 2.22386 2 2.5V17.5C2 17.7761 2.22386 18 2.5 18H17.5C17.7761 18 18 17.7761 18 17.5V2.5C18 2.22386 17.7761 2 17.5 2H2.5ZM0 2.5C0 1.11929 1.11929 0 2.5 0H17.5C18.8807 0 20 1.11929 20 2.5V17.5C20 18.8807 18.8807 20 17.5 20H2.5C1.11929 20 0 18.8807 0 17.5V2.5Z'
        fill='#282828'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 5C8.05228 5 8.5 5.44772 8.5 6V6.5H11.5V6C11.5 5.44772 11.9477 5 12.5 5C13.0523 5 13.5 5.44772 13.5 6V6.5H14C14.5523 6.5 15 6.94772 15 7.5C15 8.05228 14.5523 8.5 14 8.5H13.5V11.5H14C14.5523 11.5 15 11.9477 15 12.5C15 13.0523 14.5523 13.5 14 13.5H13.5V14C13.5 14.5523 13.0523 15 12.5 15C11.9477 15 11.5 14.5523 11.5 14V13.5H8.5V14C8.5 14.5523 8.05228 15 7.5 15C6.94772 15 6.5 14.5523 6.5 14V13.5H6C5.44772 13.5 5 13.0523 5 12.5C5 11.9477 5.44772 11.5 6 11.5H6.5V8.5H6C5.44772 8.5 5 8.05228 5 7.5C5 6.94772 5.44772 6.5 6 6.5H6.5V6C6.5 5.44772 6.94772 5 7.5 5ZM8.5 8.5V11.5H11.5V8.5H8.5Z'
        fill='#282828'
      />
    </svg>
  );
};
