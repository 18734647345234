import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { editCustomerInfoAction } from '../../../redux/customer';
import { PillsField, Pill } from '../../atoms/Fields';

export const HashtagPanel = () => {
  const dispatch = useAppDispatch();
  const customer = useAppSelector((state) => state.customer.customer);

  const onRemove = (item) => {
    const filterHashtags = customer?.hashtags.filter((el) => el !== item);
    dispatch(
      editCustomerInfoAction({
        tab: 'hashtags',
        value: filterHashtags,
      })
    );
  };
  const onAddHashtag = (hashtag) => {
    const arrFromHashtag = hashtag
      .trim()
      ?.split(' ')
      .filter((el) => el)
      ?.map((el) => {
        if (!el?.includes('#')) {
          return `#${el}`;
        }
        return el;
      });
    dispatch(
      editCustomerInfoAction({
        tab: 'hashtags',
        value: [...customer?.hashtags, ...arrFromHashtag],
      })
    );
  };
  return (
    <>
      <PillsField onAdd={onAddHashtag} />
      <div className='customer--tagsList'>
        {customer?.hashtags?.map((hashtag, index) => (
          <Pill pill={hashtag} key={index} onRemove={onRemove} />
        ))}
      </div>
    </>
  );
};
