import { copyObj } from '../../../assets/utils/copyObj';
import helperDate from '../../../assets/utils/helperDate';

export const HistoricalPanel = ({ logs = [] }) => {
  return (
    <div className='user--historicals'>
      <div className='user--historicals-thead'>
        <span>Date</span>
        <span>Action</span>
      </div>
      {copyObj(logs)
        ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        ?.map((log, index) => (
          <div key={index} className='user--historicals-trow'>
            <span>{helperDate(log?.createdAt, 'DD-MM-YYYY HH:mm')}</span>
            <span>{log.action}</span>
          </div>
        ))}
    </div>
  );
};
