import { useDispatch, useSelector } from 'react-redux';
import { deleteModalAction } from '../../../redux/modals';
import { Modal } from '../../atoms/Modal';

export const DeleteModal = ({ text, onDelete }) => {
  const dispatch = useDispatch();
  const deleteModal = useSelector((s) => s.modals.deleteModal);

  const handleClose = () => {
    dispatch(deleteModalAction(false));
  };

  if (!deleteModal) return null;

  return (
    <Modal onClose={handleClose} title='Attention' onValidate={onDelete}>
      <p>Vous êtes sur le point de supprimer {text} !</p>
    </Modal>
  );
};
