import { useQuery } from 'react-query';
import { fetchCustomerById } from '../api/customers.api';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  currentSubscriptionIndexAction,
  customerDetailsAction,
} from '../redux/customer';
import { initImagesFiltersAction } from '../redux/images';

export const useCustomerDetails = (
  customerId: string,
  enabled: boolean = true
) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useQuery(
    ['customer', customerId],
    () => fetchCustomerById(customerId),
    {
      enabled: !!customerId && enabled,
    }
  );

  useEffect(() => {
    if (data && enabled) {
      const universData = {
        ...data.customer?.univers,
        compositions: data.customer?.univers?.compositions?.map((c) => c._id),
      };

      dispatch(
        customerDetailsAction({ ...data.customer, univers: universData })
      );
      dispatch(initImagesFiltersAction(universData));
      if (data.customer.subscriptions.length > 0) {
        dispatch(
          currentSubscriptionIndexAction(data.customer.subscriptions.length - 1)
        );
      }
    }
  }, [data, enabled, dispatch]);

  return {
    data,
    isLoading,
  };
};
