import { axiosInstance } from '../assets/utils/axiosInstance';

export const fetchStyles = async (search = '') => {
  const res: any = await axiosInstance
    .get(`/styles?label=${search}`)
    .catch((err) => {
      console.log('fetchStyles', err);
    });
  return res.data;
};

export const createOneStyle = async (label) => {
  const res = await axiosInstance
    .post('/style/create', { label })
    .catch((err) => {
      console.log('createOneStyle', err);
    });
  return res;
};

export const updateOneStyle = async (id, label) => {
  const res = await axiosInstance
    .put(`/style/${id}`, { label })
    .catch((err) => {
      console.log('updateOneStyle', err);
    });
  return res;
};

export const deleteOneStyle = async (id) => {
  const res = await axiosInstance.put(`/style/delete/${id}`).catch((err) => {
    console.log('deleteOneStyle', err);
  });
  return res;
};
