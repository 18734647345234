import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFamily } from '../../components/organisms/Images/ImageProducts';
import { IComposition } from '../compositions';

interface IInitState {
  attributionMode: 'quiz' | 'product';
  imgCompositions: IComposition[] | [];
  imgProductId: string | null;
  imgCompositionsSelected?: IComposition[] | [];
  imageAttributionsTab: 'product-main' | 'product-secondary';
  familySelectedImgAttr: IFamilyExtended | null;
  familyChange: IFamilyExtended | null;
  families: IFamily[];
  imgCompositionIsSelected: boolean;
  filteredByIngredients: string[];
  updateImage?: boolean;
}

interface IFamilyExtended extends IFamily {
  quantity?: number;
  familyId?: string;
  checkedNum?: 0;
}

const initialState: IInitState = {
  familySelectedImgAttr: null,
  familyChange: null,
  attributionMode: 'quiz',
  imgCompositions: [],
  imgProductId: null,
  imgCompositionsSelected: [],
  imgCompositionIsSelected: false,
  imageAttributionsTab: 'product-main',
  filteredByIngredients: [],
  families: [],
  updateImage: false,
};

export const slice = createSlice({
  name: 'imageAttributions',
  initialState,
  reducers: {
    attributionModeAction: (
      state,
      action: PayloadAction<'quiz' | 'product'>
    ) => {
      state.attributionMode = action.payload;
    },
    imgCompositionsAction: (
      state,
      action: PayloadAction<IComposition[] | []>
    ) => {
      state.imgCompositions = action.payload;
    },
    imgProductIdAction: (state, action: PayloadAction<null | string>) => {
      state.imgProductId = action.payload;
    },
    imgCompositionsSelectedAction: (
      state,
      action: PayloadAction<IComposition[] | null>
    ) => {
      state.imgCompositionsSelected = action.payload;
    },

    imageAttributionsTabAction: (
      state,
      action: PayloadAction<'product-main' | 'product-secondary'>
    ) => {
      state.imageAttributionsTab = action.payload;
    },
    familySelectedImgAttrAction: (
      state,
      action: PayloadAction<IFamilyExtended | null>
    ) => {
      state.familySelectedImgAttr = action.payload;
    },
    familyChangeAction: (
      state,
      action: PayloadAction<IFamilyExtended | null>
    ) => {
      state.familyChange = action.payload;
    },
    familiesImgAttrAction: (state, action: PayloadAction<IFamily[]>) => {
      state.families = action.payload;
    },
    imgCompositionIsSelectedAction: (state, action: PayloadAction<boolean>) => {
      state.imgCompositionIsSelected = action.payload;
    },
    filteredByIngredientsAction: (state, action: PayloadAction<string[]>) => {
      state.filteredByIngredients = action.payload;
    },
    updateImageAction: (state, action: PayloadAction<boolean>) => {
      state.updateImage = action.payload;
    },
    resetImgCompositionsSelectedAction: (state) => {
      state.imgCompositionsSelected = [];
    },
    resetImgAttrAction: () => {
      return initialState;
    },
  },
});

export const {
  attributionModeAction,
  imgCompositionsAction,
  imgProductIdAction,
  imgCompositionsSelectedAction,
  imageAttributionsTabAction,
  familySelectedImgAttrAction,
  familiesImgAttrAction,
  imgCompositionIsSelectedAction,
  filteredByIngredientsAction,
  resetImgAttrAction,
  updateImageAction,
  familyChangeAction,
  resetImgCompositionsSelectedAction,
} = slice.actions;
export default slice.reducer;
