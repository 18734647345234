import {toast} from 'react-toastify';
import {deleteOneSubSpecialFilter} from '../../../api/subSpecialFilter.api';
import {toast_loader} from '../../../assets/utils/toast';
import {DeleteModal} from '../../../components/modals/DeleteModal';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {useResetFilters} from '../../../hooks/useResetFilters';
import {resetFilteredSelectedItemAction} from '../../../redux/filtersSelected';
import {deleteModalAction} from '../../../redux/modals';

export const DeleteSubSpecialFilter = () => {
  //
  const dispatch = useAppDispatch();
  const subSpecialFilterSelected = useAppSelector(
    (state) => state.filtersSelected.subSpecialFilter
  );

  const {resetFilters} = useResetFilters();

  const handleDeleteFilter = async () => {
    const loader = toast.loading('Veuillez patienter...');
    try {
      await deleteOneSubSpecialFilter(subSpecialFilterSelected?._id);
      resetFilters('specialFilter');
      dispatch(resetFilteredSelectedItemAction('subSpecialFilter'));
      toast_loader(loader, 'supprimé avec succès', 'success');
      dispatch(deleteModalAction(false));
    } catch (err) {
      toast_loader(loader, 'Une erreur est survenue', 'error');
    }
  };

  return (
    <DeleteModal
      onDelete={handleDeleteFilter}
      text={`le filtre spécial "${subSpecialFilterSelected?.label}"`}
    />
  );
};
