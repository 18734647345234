import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'app',
  initialState: {
    online: false,
    theme: null,
    inuptSearchValue: '',
    userData: null,
    styles: [],
    families: [],
    filters: [],
    specialsFilters: [],
    customers: [],
    moods: [],
    packages: [],
    refresh: false,
    role: null,
    connectionStatus: true,
  },

  reducers: {
    setOnlineAction: (state, action) => {
      state.online = action.payload;
    },
    setThemeAction: (state, action) => {
      state.theme = action.payload;
    },
    inuptSearchValueAction: (state, action) => {
      state.inuptSearchValue = action.payload;
    },
    getUserData: (state, action) => {
      state.userData = action.payload;
    },
    getStylesData: (state, action) => {
      state.styles = action.payload;
    },
    getFamiliesData: (state, action) => {
      state.families = action.payload;
    },
    getFiltersData: (state, action) => {
      state.filters = action.payload;
    },
    getSpecialsFiltersData: (state, action) => {
      state.specialsFilters = action.payload;
    },
    getCustomersData: (state, action) => {
      state.customers = action.payload;
    },
    getMoodsData: (state, action) => {
      state.moods = action.payload;
    },
    getPackagesData: (state, action) => {
      state.packages = action.payload;
    },
    onRefreshAction: (state, action) => {
      state.refresh = action.payload;
    },
    onGetUserRole: (state, action) => {
      state.role = action.payload;
    },
    setConnectionStatusAction: (state, action) => {
      state.connectionStatus = action.payload;
    },
  },
});

export const {
  setOnlineAction,
  setThemeAction,
  getUserData,
  getStylesData,
  getFamiliesData,
  getFiltersData,
  getSpecialsFiltersData,
  getCustomersData,
  onRefreshAction,
  getMoodsData,
  getPackagesData,
  onGetUserRole,
  setConnectionStatusAction,
} = slice.actions;
export default slice.reducer;
