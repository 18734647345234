import { axiosInstance } from '../assets/utils/axiosInstance';

export const createCutomer = async (data) => {
  const res = await axiosInstance.post('/customer/create', data);
  return res?.data;
};

export const fetchCustomers = async (search) => {
  const res = await axiosInstance.get(
    `customer/get-all?restoName=${search?.length >= 3 ? search : ''}`
  );

  return res?.data;
};

export const fetchCustomersByPictureAttribute = async (pictureId) => {
  const res: any = await axiosInstance
    .get(`/customer/by-picture-attribute/${pictureId}`)
    .catch((err) => {});
  return res;
};

export const fetchCustomerById = async (customerId) => {
  const res = await axiosInstance.get(`/customer/by-id/${customerId}`);
  return res?.data;
};

export const updateCustomerInfo = async (customerId, data) => {
  const res = await axiosInstance.put(`/customer/${customerId}`, data);
  return res?.data;
};

export const updateCustomerStrategyModel = async (customerId, data) => {
  const res = await axiosInstance.put(
    `/customer/strategyModel/${customerId}`,
    data
  );
  return res?.data;
};

export const createCustomerSubscription = async (customerId, data) => {
  const res = await axiosInstance.post(
    `/customer/create-subscription/${customerId}`,
    data
  );
  return res?.data;
};

export const updateCustomerSubscription = async (subscriptionId, data) => {
  const res = await axiosInstance.put(
    `/customer/subscription/${subscriptionId}`,
    data
  );
  return res?.data;
};

export const deleteCustomer = async (customerId) => {
  const res = await axiosInstance.put(`/customer/delete/${customerId}`);
  return res?.data;
};
