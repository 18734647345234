import { useDispatch } from 'react-redux';
import { copyObj } from '../../../assets/utils/copyObj';
import { useAppSelector } from '../../../hooks/useReduxHook';
import {
  formulaAction,
  removePictureIdFromAllAttributionAction,
  updateCustomerDiariesAction,
} from '../../../redux/customer';
import { confirmDeleteAttrModalAction } from '../../../redux/modals';
import { Modal } from '../../atoms/Modal';
import { helperFindSamePictures } from '../../../assets/utils/helperFindSamePictures';

export const ConfirmDeleteAttrModal = ({ bgWidth = '80%' }) => {
  const dispatch = useDispatch();
  const customer = useAppSelector((state) => state.customer.customer);
  const formula = useAppSelector((state) => state.customer.formula);

  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );

  const currentSubscription = customer?.subscriptions[currentSubscriptionIndex];

  const handleDeleteAttr = async () => {
    //TODO

    const newDiaries = copyObj(currentSubscription?.diaries);

    const attributionIndex = newDiaries.findIndex(
      (attr) =>
        attr.formula_number === formula?.formula_number &&
        attr.month === formula?.month
    );

    if (attributionIndex < 0) return;

    let pictureIds = [];

    const picture = newDiaries[attributionIndex]?.picture;
    if (picture?.parentId) {
      pictureIds = await helperFindSamePictures(
        picture.parentId?._id || picture.parentId
      );
    } else if (picture) {
      pictureIds = [
        picture._id,
        ...picture?.samePictures?.map((p) => p._id || p),
      ];
    }

    newDiaries.splice(attributionIndex, 1);

    dispatch(updateCustomerDiariesAction(newDiaries));
    dispatch(removePictureIdFromAllAttributionAction({ pictureIds }));

    handleCancel();
  };

  const handleCancel = () => {
    dispatch(formulaAction(null));
    dispatch(confirmDeleteAttrModalAction(false));
  };

  return (
    <Modal
      onClose={handleCancel}
      title='Attention'
      onValidate={handleDeleteAttr}
      bgWidth={bgWidth}
    >
      <p>Etes-vous sûr de supprimer l'attribution !</p>
    </Modal>
  );
};
