export const CheckIcon = () => {
  return (
    <svg
      width='41'
      height='41'
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_162_575)'>
        <rect x='3' y='3' width='35' height='35' rx='16' fill='#24A67B' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M27.2071 15.7929C27.5976 16.1834 27.5976 16.8166 27.2071 17.2071L19.2071 25.2071C18.8166 25.5976 18.1834 25.5976 17.7929 25.2071L13.7929 21.2071C13.4024 20.8166 13.4024 20.1834 13.7929 19.7929C14.1834 19.4024 14.8166 19.4024 15.2071 19.7929L18.5 23.0858L25.7929 15.7929C26.1834 15.4024 26.8166 15.4024 27.2071 15.7929Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_162_575'
          x='0'
          y='0'
          width='41'
          height='41'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feMorphology
            radius='3'
            operator='dilate'
            in='SourceAlpha'
            result='effect1_dropShadow_162_575'
          />
          <feOffset />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_162_575'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_162_575'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
