import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  IQuestion,
  questionSelectedAction,
  quizAction,
} from '../../../redux/familyQuiz';
import { confirmReplayQuizModalAction } from '../../../redux/modals';
import { Modal } from '../../atoms/Modal';
import { imgCompositionIsSelectedAction } from '../../../redux/imageAttributions';
import { compositionSelectedAction } from '../../../redux/compositions';
import { initComposition } from '../../../assets/constants';

export const ConfirmeReplayQuizModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const imageAttributions = useAppSelector((s) => s.imageAttributions);

  const onValidate = () => {
    const payload = {
      ...imageAttributions.familySelectedImgAttr?.quiz,
      questionsList:
        imageAttributions.familySelectedImgAttr?.quiz.questionsList.map(
          (q: IQuestion) => {
            return {
              ...q,
              answered: false,
              noAnswer: false,
            };
          }
        ),
    };
    dispatch(quizAction(payload));
    dispatch(questionSelectedAction(payload.questionsList[0]));
    dispatch(imgCompositionIsSelectedAction(false));
    dispatch(compositionSelectedAction(initComposition));
    dispatch(confirmReplayQuizModalAction(false));
  };

  return (
    <Modal
      onClose={() => dispatch(confirmReplayQuizModalAction(false))}
      title='Attention'
      onValidate={onValidate}
      bgWidth='100%'
      left='30%'
      btnValidateLabel='JE CONFIRME'
    >
      <p style={{ textAlign: 'center' }}>
        Vous n'avez pas sélectionné de composition pour ce quiz ! <br /> Vous
        voulez quand même refait le quiz ?
      </p>
    </Modal>
  );
};
