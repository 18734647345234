import { AppRouter } from './routers';

//REDUX
import { Provider } from 'react-redux';
import store from './redux/storeConfig';
//OTHERS MODULES
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useWindowDimensions } from './assets/utils/helperUseDimensions';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  //
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  if (
    useWindowDimensions().width < 1250 &&
    !window.location.pathname.includes('/modules/anamnes/') &&
    !window.location.pathname.includes('/validation-user-account')
  ) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          backgroundColor: '#2274a1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '2rem',
        }}
        onContextMenu={handleContextMenu}
      >
        <span style={{ fontSize: 30, color: 'white' }}>
          Oups! Nous vous prions de bien passer sur un écran large pour une
          bonne expérience utilisateur
        </span>
      </div>
    );
  }
  return (
    // <RightClickPreventionHOC>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AppRouter />
        <ToastContainer />
      </Provider>
    </QueryClientProvider>
    // </RightClickPreventionHOC>
  );
}

export default App;
