import React from 'react';
import { ImageAttributions } from '../../../components/organisms/Images/ImageAttributions';
import { ImageInformations } from '../../../components/organisms/Images/ImageInformations';

export const ImageForm: React.FC<{
  tab: string;
  edit?: boolean;
  isClone?: boolean;
  miniModal?: boolean;
  onAddAttribution?: (edit?: boolean, isClone?: boolean) => void;
}> = ({ miniModal, tab, edit, isClone, onAddAttribution }) => {
  return (
    <>
      {tab === 'informations' && (
        <ImageInformations
          miniModal={miniModal}
          edit={edit}
          isClone={isClone}
        />
      )}
      {tab === 'attribution' && (
        <ImageAttributions
          isClone={isClone}
          edit={edit}
          onAddAttribution={onAddAttribution}
        />
      )}
    </>
  );
};
