import { useDispatch } from 'react-redux';
import { colors, regexRemoveHtmlTag } from '../../../assets/constants';
import { useAppSelector } from '../../../hooks/useReduxHook';
import {
  editCustomerInfoAction,
  updateCustomerDeliveryAction,
} from '../../../redux/customer';
import TextEditor from '../../atoms/TextEditor';
import { useState } from 'react';

export const SignaturePanel = () => {
  const dispatch = useDispatch();
  const customer = useAppSelector((state) => state.customer.customer);

  const devilery = customer?.univers?.filters?.find(
    (d) => d.label.toLowerCase() === 'livraison'
  );

  const deliverieList = devilery?.filterValues?.map((d) => d.name);
  const [customerDelivery, setCustomerDelivery] = useState(
    customer?.informations?.restaurant_delivery || deliverieList?.join(',')
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setCustomerDelivery(value);
    dispatch(updateCustomerDeliveryAction(value));
  };

  const handleSelect = (name) => {
    let value = '';

    switch (name) {
      case 'address':
        value = customer?.informations?.restaurant_address;
        break;

      case 'slogan':
        value = customer?.informations?.slogan;
        break;

      case 'phone':
        value = customer?.informations?.restaurant_phone;
        break;

      case 'delivery':
        value = customerDelivery;
        break;

      default:
        break;
    }

    dispatch(
      editCustomerInfoAction({
        tab: 'signature',
        key: name,
        value: {
          ...customer?.signature?.[name],
          value,
          enabled: !customer?.signature?.[name]?.enabled,
        },
      })
    );
  };

  return (
    <>
      <p className='customer--signaturepanel-text'>Cocher / Decocher</p>
      <Field
        label='ADRESSE'
        name='address'
        value={customer.informations?.restaurant_address}
        enabled={customer?.signature?.address?.enabled}
        onSelect={() => handleSelect('address')}
      />
      <Field
        label='SLOGAN'
        name='slogan'
        value={customer.informations?.slogan?.replace(regexRemoveHtmlTag, '')}
        enabled={customer?.signature?.slogan?.enabled}
        onSelect={() => handleSelect('slogan')}
      />
      <Field
        label='TELEPHONE'
        name='phone'
        value={customer?.informations?.restaurant_phone}
        enabled={customer?.signature?.phone?.enabled}
        onSelect={() => handleSelect('phone')}
      />
      <Field
        label='LIVRAISON'
        name='delivery'
        value={customerDelivery}
        enabled={customer?.signature?.delivery?.enabled}
        onSelect={() => handleSelect('delivery')}
        onChange={(e) => handleChange(e)}
      />
    </>
  );
};

const Field = ({
  label,
  onSelect,
  onChange,
  name,
  value,
  enabled,
  textDynamic = false,
  setCustomerForm,
}) => {
  return (
    <div className='customer--signaturepanel-field'>
      <label className='customer--signaturepanel-field-label'>{label}</label>

      <div className='customer--signaturepanel-field-inputBlock'>
        {textDynamic ? (
          <TextEditor
            label=''
            onChange={(val) =>
              setCustomerForm((s) => ({
                ...s,
                signature: {
                  ...s?.signature,
                  text: { ...s?.signature?.text, value: val },
                },
              }))
            }
            minHeight='auto'
            value={value}
          />
        ) : (
          <input type='text' name={name} value={value} onChange={onChange} />
        )}

        <span
          onClick={() => onSelect(name)}
          className='customer--signaturepanel-field-check'
          style={{ backgroundColor: enabled ? colors.yellow : 'transparent' }}
        />
      </div>
    </div>
  );
};
