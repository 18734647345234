import { HashRouter, Routes, Route } from 'react-router-dom';
import StylesView from '../views/online/StylesView';
import FamilliesView from '../views/online/FamilliesView';
import CitiesView from '../views/online/CitiesView';
import MoodsView from '../views/online/MoodsView';
import FiltersView from '../views/online/FiltersView';
import SpecialsFiltersView from '../views/online/SpecialsFiltersView';
import ImagesView from '../views/online/ImagesView';
import CustomersView from '../views/online/CustomersView';
import PackagesView from '../views/online/PackagesView';
import UsersView from '../views/online/UsersView';
import AddUserView from '../views/online/UsersView/AddUserView';
import UpdateUserView from '../views/online/UsersView/UpdateUserView';
import FamilyProductView from '../views/online/FamilliesView/FamilyProductView';
import FilterValueView from '../views/online/FiltersView/FilterValue';
import AddCustomerView from '../views/online/CustomersView/AddCustomerView';
import ImagesListView from '../views/online/ImagesView/ImagesListView';
import AddImageView from '../views/online/ImagesView/AddImageView';
import EditCustomerView from '../views/online/CustomersView/EditCustomerView';
import UpdateCity from '../views/online/CitiesView/UpdateCity';
import { LoginView } from '../views/offline/LoginView';
import { ForgetPasswordView } from '../views/offline/ForgetPasswordView';
import { ResetPasswordView } from '../views/offline/ResetPasswordView';
import { useEffect } from 'react';
import { setConnectionStatusAction } from '../redux/app';
import { NoSignalView } from '../views/others/NoSignalView';
import GroupsView from '../views/online/GroupsView';
import { ModalsRender } from './ModalsRender';
import ProductIngredientsView from '../views/online/FamilliesView/ProductIngredientsView';
import SubSpecialsFiltersView from '../views/online/SpecialsFiltersView/SubSpecialsFiltersView';
import { useAppDispatch, useAppSelector } from '../hooks/useReduxHook';
import { SuperMarketView } from '../views/online/SupermarketView';
import { RayonIngredientView } from '../views/online/SupermarketView/RayonIngredient';
import { IngredientSpecialtyView } from '../views/online/SupermarketView/IngredientSpecialtyView';
import { FamilyQuizView } from '../views/online/FamilyQuizView';
import ProductCompositionsListView from '../views/online/FamilliesView/ProductCompositionsListView';
import { CompositionsGestionView } from '../views/online/FamilliesView/CompositionsGestionView';
import { CustomerCompositionsView } from '../views/online/CustomerCompositionsView';
import { ImageAttributionsView } from '../views/online/ImagesView/ImageAttributionsView';
import UserAuthRoute from './userAuthRoute';
import UserRoute from './userRoute';
import CloneImageView from '../views/online/ImagesView/CloneImageView';

export const AppRouter = () => {
  //
  const { connectionStatus } = useAppSelector((s) => s.app);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setConnectionStatusAction(navigator.onLine));
    window.ononline = () => {
      dispatch(setConnectionStatusAction(true));
    };
    window.onoffline = () => {
      dispatch(setConnectionStatusAction(false));
    };
  }, [dispatch]);

  if (!connectionStatus) {
    return <NoSignalView />;
  }
  return (
    <HashRouter>
      <Routes>
        <Route element={<UserAuthRoute />}>
          <Route path='/' element={<LoginView />} />
          <Route path='/forget-password' element={<ForgetPasswordView />} />
          <Route
            path='/reset-password/:token'
            element={<ResetPasswordView />}
          />
        </Route>
        <Route element={<UserRoute />}>
          <Route path='/images' element={<ImagesView />} />
          <Route path='/images-list' element={<ImagesListView />} />
          <Route path='/add-image' element={<AddImageView />} />
          <Route path='/clone-image' element={<CloneImageView />} />
          <Route
            path='/image-attributions'
            element={<ImageAttributionsView />}
          />
          <Route path='/customers' element={<CustomersView />} />
          <Route path='/customer/add' element={<AddCustomerView />} />
          <Route path='/customer/:customerId' element={<EditCustomerView />} />
          <Route
            path='/customer-univers-compose/:familyId/:customerId'
            element={<CompositionsGestionView forCompose />}
          />
          <Route path='/styles' element={<StylesView />} />
          <Route path='/groups' element={<GroupsView />} />
          <Route path='/families' element={<FamilliesView />} />
          <Route
            path='/family-products/:familyId'
            element={<FamilyProductView />}
          />
          <Route
            path='/family-product-ingredients/:familyId/:productId'
            element={<ProductIngredientsView />}
          />
          <Route
            path='/family-product-compositions/:familyId/:productId'
            element={<ProductCompositionsListView />}
          />
          <Route
            path='/family-product-compositions-gestion/:familyId/:productId'
            element={<CompositionsGestionView />}
          />
          <Route path='/family-quiz/:familyId' element={<FamilyQuizView />} />
          <Route path='/rayons' element={<SuperMarketView />} />
          <Route
            path='/rayon-ingredients/:rayonId'
            element={<RayonIngredientView />}
          />
          <Route
            path='/rayon-specialties/:rayonId/:ingredientId'
            element={<IngredientSpecialtyView />}
          />
          <Route path='/filters' element={<FiltersView />} />
          <Route
            path='/filter-values/:filterId'
            element={<FilterValueView />}
          />
          <Route path='/specials-filters' element={<SpecialsFiltersView />} />
          <Route
            path='/sub-specials-filters/:specialFilterId'
            element={<SubSpecialsFiltersView />}
          />
          <Route path='/moods' element={<MoodsView />} />
          <Route path='/cities' element={<CitiesView />} />
          <Route path='/cities/:cityId' element={<UpdateCity />} />
          <Route path='/packages' element={<PackagesView />} />
          <Route path='/users' element={<UsersView />} />
          <Route path='/user/add' element={<AddUserView />} />
          <Route path='/user/:userId' element={<UpdateUserView />} />
          <Route
            path='/customer-compositions/:familyId/:customerId'
            element={<CustomerCompositionsView />}
          />
        </Route>
      </Routes>
      <ModalsRender />
    </HashRouter>
  );
};
