import { memo, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useDispatch } from 'react-redux';
import { PlusIcon } from '../../../assets/svgs/PlusIcon';
import { RefreshIcon } from '../../../assets/svgs/RefreshIcon';
import { SearchCircleIcon } from '../../../assets/svgs/SearchBlackIcon';
import {
  downloadImgModalAction,
  replayDiaryModalAction,
  replayImgAttrModalAction,
  viewFeedModalAction,
  viewImageModalAction,
} from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { Collapse } from '../../atoms/Collapse';
import { HeaderSubscription } from './HeaderSubscription';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { checkMonthIsInSubscription } from '../../../assets/utils/funcsUtils';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import store from '../../../redux/storeConfig';
import {
  addPictureIdToAllAttributionAction,
  formulaAction,
  pictureToAttributeAction,
  updateCustomerDiariesAction,
} from '../../../redux/customer';
import { copyObj } from '../../../assets/utils/copyObj';
import { helperSubscriptionMonths } from '../../../assets/utils/helperSubscriptionMonths';
import { getPictureDisplayAction } from '../../../redux/picture';
import { helperFindSamePicturesIdByPicture } from '../../../assets/utils/helperFindSamePictures';
import { Toast } from '../../../assets/utils/toast';

export const DiaryPanel = memo(() => {
  const dispatch = useDispatch();
  const role = useAppSelector((state) => state.app.role);
  const customer = useAppSelector((state) => state.customer.customer);
  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );
  const currentSubscription = customer?.subscriptions?.[currentSubscriptionIndex];

  return (
    <>
      <div
        className='customer--diarypanel-header'
        style={{
          backgroundColor: 'white',
          marginBottom: '1rem',
        }}
      >
        <div className='customer--diarypanel-header-formulaInfos'>
          <HeaderSubscription />
        </div>

        <div className='customer--diarypanel-header-actionBtn'>
          {['ADMIN', 'SUPER-ADMIN', 'PROGRAMMEUR'].includes(role) && (
            <Button
              label='Jouer l’attribution d’image'
              variant='yellow'
              Icon={RefreshIcon}
              iconPosition='right'
              onClick={() => {
                if(currentSubscription){
                  dispatch(replayDiaryModalAction(true))
                }else{
                  Toast('error', 'Aucun abonnement actif')
                }
              }}
            />
          )}
          {['ADMIN', 'SUPER-ADMIN', 'PROGRAMMEUR'].includes(role) && (
            <Button
              label='Voir le fil'
              onClick={() => {
                if(currentSubscription){
                  dispatch(viewFeedModalAction(true))
                }else{
                  Toast('error', 'Aucun abonnement actif')
                }
              }}
            />
          )}
        </div>
      </div>
      <DiaryListMonths />
    </>
  );
});

const DiaryListMonths = () => {
  const role = useAppSelector((state) => state.app.role);
  const customer = useAppSelector((state) => state.customer?.customer);
  const subscriptions = customer?.subscriptions || [];

  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );

  const currentSubscription = subscriptions[currentSubscriptionIndex];

  const monthsSorted = helperSubscriptionMonths(currentSubscription);

  return (
    <div className='customer--diarypanel-listMonths'>
      {monthsSorted?.map((monthData, monthIndex) => {
        const isMonthValidInSubscription = checkMonthIsInSubscription(
          monthData.month,
          monthData.year,
          currentSubscription
        );

        return (
          <Collapse
            title={`${monthData.month?.toUpperCase()} ${monthData.year}`}
            bg='white'
            // width='100%'
            opened={isMonthValidInSubscription}
            key={monthIndex + `-${currentSubscription?.endIt?.month}`}
            className='customer--diarypanel-listMonths-collapse'
          >
            {new Array(currentSubscription?.formula || 6)
              .fill('')
              .map((_, index) => {
                return (
                  <FormulaItem
                    key={index}
                    index={index}
                    monthData={monthData}
                    monthIndex={monthIndex}
                    isMonthValidInSubscription={true}
                    currentSubscription={currentSubscription}
                    disabled={
                      role !== 'ADMIN' &&
                      role !== 'SUPER-ADMIN' &&
                      role !== 'PROGRAMMEUR'
                    }
                  />
                );
              })}
          </Collapse>
        );
      })}
    </div>
  );
};

const FormulaItem = ({
  disabled = false,
  monthData,
  index,
  isMonthValidInSubscription,
  currentSubscription,
}) => {
  const dispatch = useAppDispatch();
  const [loaded, setLoaded] = useState(false);
  const role = useAppSelector((state) => state.app.role);
  const isCommercial = role === 'COMMERCIAL';
  const formula_number = index + 1;

  const attribution = currentSubscription?.diaries?.find(
    (diary) =>
      diary.month === monthData.month &&
      diary.year === monthData.year &&
      diary.formula_number === formula_number
  );

  const strategy = currentSubscription?.strategies?.find(
    (strategy) =>
      strategy.month === monthData.month &&
      strategy.year === monthData.year &&
      strategy.formula_number === index + 1
  )?.strategy;

  const onLoadedImg = () => {
    setLoaded(true);
  };

  const handleOpenAttrModal = () => {
    if (disabled || !isMonthValidInSubscription) return;
    dispatch(
      formulaAction({
        month: monthData.month,
        year: monthData.year,
        formula_number,
        strategy,
      })
    );

    dispatch(replayImgAttrModalAction(true));
  };

  const handleClickFormula = async () => {
    if (disabled || !isMonthValidInSubscription) return;

    const picture = store.getState().customer.pictureToAttribute;

    if (picture) {
      // if (!checkPictureCompatibility(pictureToAttr, customer.univers)) {
      //   Toast('error', 'Cette image ne correspond pas à l’univers du client');
      //   dispatch(pictureToAttributeAction(null));
      //   return;
      // }

      // const picture = helperSelectPictureWithCustomerUnivers(
      //   pictureToAttr,
      //   customer.univers
      // );

      // if (!picture) {
      //   Toast('error', 'Cette image ne correspond pas au mood du client');
      //   dispatch(pictureToAttributeAction(null));
      //   return;
      // }

      const diaries = copyObj(currentSubscription?.diaries || []);

      const findAttribution = diaries.find(
        (attribution) =>
          attribution.formula_number === formula_number &&
          attribution.year === monthData.year &&
          attribution.month === monthData.month
      );

      const oldPicture = findAttribution?.picture;

      if (findAttribution) {
        findAttribution.picture = picture;
      } else {
        diaries.push({
          formula_number: formula_number,
          month: monthData.month,
          year: monthData.year,
          picture: picture,
        });
      }

      const { oldsPictureIds, pictureIds } =
        await helperFindSamePicturesIdByPicture({
          oldPicture,
          picture,
        });

      dispatch(
        addPictureIdToAllAttributionAction({
          oldsPictureIds,
          pictureIds,
        })
      );

      dispatch(updateCustomerDiariesAction(diaries));

      dispatch(pictureToAttributeAction(null));
    } else {
      handleOpenAttrModal();
    }
  };

  const handleClickFormulaImg = () => {
    dispatch(formulaAction(attribution));
    dispatch(downloadImgModalAction(true));
  };

  const onDisplayImg = (e) => {
    e.stopPropagation();
    dispatch(
      getPictureDisplayAction({
        picture: attribution?.picture?.image,
        folder: 'picture',
      })
    );
    dispatch(viewImageModalAction(true));
  };

  return (
    <div className='customer--diarypanel-formula2'>
      {attribution?.picture ? (
        <>
          <img
            onLoad={onLoadedImg}
            onClick={isCommercial ? () => {} : handleClickFormulaImg}
            src={
              attribution
                ? helperImageRoute(
                    attribution?.picture?.image,
                    'picture',
                    true,
                    null
                  )
                : ''
            }
            alt='pic'
          />
          {!loaded && (
            <div className='blurhash-diary'>
              <Blurhash
                hash='LKN]Rv%2Tw=w]~RBVZRi};RPxuwH'
                width={64}
                height={64}
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </div>
          )}
        </>
      ) : (
        <label
          htmlFor='formulaImg'
          onClick={handleClickFormula}
          className='customer--diarypanel-formula2-img'
        >
          <span>
            <PlusIcon />
          </span>
        </label>
      )}
      <span
        className='customer--diarypanel-formula-date'
        style={{
          padding: '.5rem .5rem 0',
          visibility: currentSubscription?.date ? 'visible' : 'hidden',
        }}
      >
        22/12/2022
      </span>
      <div
        className='w-100 flex-row-center'
        style={{ marginTop: '.5rem', padding: '0 .5rem .5rem' }}
      >
        <span className='customer--diarypanel-formula-number'>
          {formula_number}
        </span>
        <div
          className='customer--diarypanel-formula-btns'
          style={{ width: 'auto' }}
        >
          <button
            onClick={attribution?.picture?.image ? onDisplayImg : () => {}}
          >
            <SearchCircleIcon />
          </button>
          <button
            disabled={disabled || !isMonthValidInSubscription}
            onClick={handleOpenAttrModal}
          >
            <RefreshIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
