import { memo, useState } from 'react';
import { FilterFamiliesCollapse } from '../../molecules/FilterFamiliesCollapse';
import { FiltersCollapse } from '../../molecules/FiltersCollapse';
import { FiltersSpecialsCollapse } from '../../molecules/FilterSpecialsCollapse';
import { FiltersStylesCollapse } from '../../molecules/FilterStylesCollapse';

export const UniversFilters = memo(() => {
  const [showFilterCollapse, setShowFilterCollapse] = useState(null);

  return (
    <div className='w-100'>
      <FiltersStylesCollapse
        show={showFilterCollapse}
        setShow={setShowFilterCollapse}
      />
      <FilterFamiliesCollapse
        show={showFilterCollapse}
        setShow={setShowFilterCollapse}
      />
      <FiltersCollapse
        show={showFilterCollapse}
        setShow={setShowFilterCollapse}
      />
      <FiltersSpecialsCollapse
        show={showFilterCollapse}
        setShow={setShowFilterCollapse}
      />
    </div>
  );
});
