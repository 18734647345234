import { colors } from '../../../assets/constants';
import { PlusIcon } from '../../../assets/svgs/PlusIcon';
import { SearchCircleIcon } from '../../../assets/svgs/SearchBlackIcon';

export const Button = ({
  label = '',
  width,
  onClick = () => {},
  radius = true,
  variant = 'black',
  disabled,
  margin = '0',
  padding,
  Icon,
  iconPosition = 'left',
  align = 'center',
  color,
  uppercase,
  fixed = false,
  hidden = false,
  loading = false,
}: any) => {
  const variants = {
    red: {
      backgroundColor: colors.red,
      color: colors.white,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
    yellow: {
      backgroundColor: colors.yellow,
      color: colors.white,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
    dark: {
      backgroundColor: colors.dark,
      color: colors.white,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
    greyDark: {
      backgroundColor: colors.greyDark,
      color: colors.dark,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
    grey: {
      backgroundColor: colors.grey,
      color: colors.dark,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
    white: {
      backgroundColor: colors.white,
      color: colors.dark,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
    whiteBlue: {
      backgroundColor: colors.whiteBlue,
      color: colors.dark,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
    black: {
      backgroundColor: 'black',
      color: colors.white,
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    },
    neutre: {
      backgroundColor: 'transparent',
      padding: '9px 17px',
      color,
    },
  };
  return (
    <button
      onClick={onClick}
      style={{
        width,
        borderRadius: radius ? 6 : 0,
        margin,
        ...variants[variant],
        flexDirection: iconPosition === 'left' ? 'row' : 'row-reverse',
        justifyContent:
          align === 'start'
            ? 'flex-start'
            : align === 'end'
            ? 'flex-end'
            : align === 'between'
            ? 'space-between'
            : 'center',
        textTransform: uppercase ? 'uppercase' : 'none',
        display: hidden && 'none',
        padding,
      }}
      disabled={disabled}
      className={`button button--${variant} ${fixed && 'button--fixed'}`}
    >
      {loading && <div class='spinner'></div>}

      {Icon && (
        <Icon
          color={
            variant[variant] === 'neutre' ||
            (variant[variant] === 'neutre' && color)
              ? color
              : variants[variant].color
          }
        />
      )}
      <span
        style={{
          margin:
            Icon && iconPosition === 'left' && label !== ''
              ? '0  0 0 .5rem'
              : iconPosition === 'right' && Icon && label !== ''
              ? '0  .5rem 0 0'
              : label === '' && Icon && '0',
        }}
      >
        {label}
      </span>
    </button>
  );
};

export const ButtonAdd = ({
  onClick,
  label,
  height,
  width,
  margin = '0 1rem 1rem 0',
  bg = colors.grey,
  color = colors.dark,
  radius = 8,
  disabled = false,
}: {
  onClick?: () => void,
  label: string,
  height?: number,
  width?: string,
  margin?: string,
  bg?: string,
  color?: string,
  radius?: number,
  disabled?: boolean,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className='buttonAdd'
      style={{
        height,
        width,
        margin,
        backgroundColor: bg,
        borderRadius: radius,
      }}
    >
      <span>
        <PlusIcon />
      </span>
      <span style={{ color }}>{label}</span>
    </button>
  );
};

export const ButtonRadio = ({ label, onClick, margin, active }) => {
  return (
    <button className='buttonRadio' style={{ margin }} onClick={onClick}>
      <span
        style={{ backgroundColor: active ? colors?.yellow : 'transparent' }}
      />
      {label}
    </button>
  );
};

export const ButtonZoom = ({ onDisplayImg, small = false }) => {
  return (
    <button
      onClick={onDisplayImg}
      className={`images--container-img-zoom ${small ? 'small' : ''}`}
      style={{
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 100,
      }}
    >
      <SearchCircleIcon />
    </button>
  );
};
