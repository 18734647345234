import Logo from '../../../assets/images/logo.png';
import { Button } from '../../atoms/Buttons';
import { ImageIcon } from '../../../assets/svgs/ImageIcon';
import { CustomerIcon } from '../../../assets/svgs/CustomerIcon';
import { GridIcon } from '../../../assets/svgs/GridIcon';
import { SettingsIcon } from '../../../assets/svgs/SettingsIcon';
import { CityIcon } from '../../../assets/svgs/CityIcon';
import { CalendarIcon } from '../../../assets/svgs/CalendarIcon';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReloadIcon } from '../../../assets/svgs/ReloadIcon';
import { setFilteredByCustomerUnivers } from '../../../redux/picture';
import { MoodsFilters } from './MoodsFilters';
import { UniversFilters } from './UniversFilters';
import { ItemButton } from './ItemButton';
import { resetFiltersAction } from '../../../redux/filters';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { memo, useCallback } from 'react';

const Sidebar = () => {
  const role = useAppSelector((s) => s.app.role);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div className='sidebar'>
      <img src={Logo} alt='logo' className='sidebar--logo' />
      <Button
        Icon={ImageIcon}
        label='images'
        variant={pathname === '/images' ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() =>
          navigate(
            role !== 'SUPER-ADMIN' && role !== 'ADMIN'
              ? '/images-list'
              : '/images'
          )
        }
      />
      <Button
        Icon={CustomerIcon}
        label='clients'
        variant={pathname.includes('/customer') ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/customers')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={GridIcon}
        label='styles'
        variant={pathname === '/styles' ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/styles')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={GridIcon}
        label='familles'
        variant={
          pathname === '/families' ||
          pathname.includes('/family-products') ||
          pathname.includes('/family-product-ingredients')
            ? 'yellow'
            : 'neutre'
        }
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/families')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={GridIcon}
        label='Supermarché'
        variant={pathname.includes('/rayon') ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/rayons')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={SettingsIcon}
        label='filtres'
        variant={pathname.includes('/filter') ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/filters')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={SettingsIcon}
        label='filtres speciaux'
        variant={pathname === '/specials-filters' ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/specials-filters')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={SettingsIcon}
        label='moods'
        variant={pathname === '/moods' ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/moods')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={SettingsIcon}
        label='groupes'
        variant={pathname === '/groups' ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/groups')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={CityIcon}
        label='villes'
        variant={pathname === '/cities' ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/cities')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={CalendarIcon}
        label='forfaits'
        variant={pathname === '/packages' ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/packages')}
        hidden={role !== 'SUPER-ADMIN' && role !== 'ADMIN'}
      />
      <Button
        Icon={CalendarIcon}
        label='Utilisateurs'
        variant={pathname.includes('/user') ? 'yellow' : 'neutre'}
        width='100%'
        align='start'
        uppercase
        margin='.5rem 0'
        onClick={() => navigate('/users')}
        hidden={role !== 'SUPER-ADMIN'}
      />
    </div>
  );
};

const SidebarImgLayout = memo(({ showSidebar }) => {
  const dispatch = useAppDispatch();

  return (
    <div
      className='imagesLayout--sidebar'
      style={{ width: showSidebar ? 300 : 0 }}
    >
      <SidebarFilters />
      <MoodsFilters />
      <UniversFilters />
      <button
        className='imagesLayout--sidebar-btn'
        onClick={() => dispatch(resetFiltersAction())}
      >
        <ReloadIcon /> <span>Réinitialisation</span>
      </button>
    </div>
  );
});

const SidebarFilters = () => {
  const dispatch = useAppDispatch();
  const customer = useAppSelector((s) => s.customer.customer);

  const filteredByCustomerUnivers = useAppSelector(
    (s) => s.picture.filteredByCustomerUnivers
  );

  const onFilteredByCustomerUnivers = useCallback(() => {
    dispatch(setFilteredByCustomerUnivers(true));
  }, [dispatch]);

  const onRemoveFilteredByCustomerUnivers = useCallback(() => {
    dispatch(setFilteredByCustomerUnivers(false));
    dispatch(resetFiltersAction());
  }, [dispatch]);

  if (!customer) return null;

  return (
    <div className='imagesLayout--sidebar-restoFilters'>
      <ItemButton
        label='Filtres du restaurant'
        counter={false}
        onClick={onFilteredByCustomerUnivers}
        active={filteredByCustomerUnivers === true}
      />
      <ItemButton
        label='Tout les filtres'
        counter={false}
        onClick={onRemoveFilteredByCustomerUnivers}
        active={filteredByCustomerUnivers === false}
      />
    </div>
  );
};

export { SidebarImgLayout, Sidebar };
