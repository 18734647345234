import { axiosInstance } from '../assets/utils/axiosInstance';

//Filters api
export const fetchFilters = async (search?: any) => {
  const res: any = await axiosInstance
    .get(`/filters?label=${search}`)
    .catch((err) => {
      console.log('fetchFilters', err);
    });
  return res.data;
};

export const createOneFilter = async (label) => {
  const res = await axiosInstance
    .post('/filter/create', { label })
    .catch((err) => {
      console.log('createOneFilter', err);
    });
  return res;
};

export const updateOneFilter = async (id, label) => {
  const res = await axiosInstance
    .put(`/filter/${id}`, { label })
    .catch((err) => {
      console.log('updateOneFilter', err);
    });
  return res;
};

export const deleteOneFilter = async (id) => {
  const res = await axiosInstance.put(`/filter/delete/${id}`).catch((err) => {
    console.log('deleteOneFilter', err);
  });
  return res;
};

export const getOneFilterById = async (filterId) => {
  const res = await axiosInstance
    .get(`/filter/by-id/${filterId}`)
    .catch((err) => {
      console.log('getOneFilterById', err);
    });
  return res;
};

//Filter filterValues

export const createOneFilterValue = async (filterValue) => {
  const res = await axiosInstance
    .post(`/filter/add-filterValue/${filterValue?.filterId}`, {
      name: filterValue?.name,
      filterId: filterValue?.filterId,
    })
    .catch((err) => {
      console.log('createOneFilterValue', err);
    });
  return res;
};

export const updateOneFilterValue = async (filterValue, filterId) => {
  const res = await axiosInstance
    .put(`/filter/update-filterValue/${filterValue?._id}/${filterId}`, {
      name: filterValue?.name,
      filterId: filterValue?.filterId,
    })
    .catch((err) => {
      console.log('updateOneFilterValue', err);
    });
  return res;
};

export const deleteOneFilterValue = async (id) => {
  const res = await axiosInstance
    .put(`/filter/delete-filterValue/${id}`)
    .catch((err) => {
      console.log('deleteOneFilterValue', err);
    });
  return res;
};
