import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { ArrowLeftIcon } from '../../../assets/svgs/ArrowLeftIcon';
import { axiosInstance } from '../../../assets/utils/axiosInstance';
import { Toast, toast_loader } from '../../../assets/utils/toast';
import { Button } from '../../../components/atoms/Buttons';
import { PillsField, Pill } from '../../../components/atoms/Fields';
import { Loader } from '../../../components/atoms/Loader';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { withNavigation } from '../../../hoc/withNavigation';

const UpdateCity = () => {
  //
  const { cityId } = useParams();
  const navigate = useNavigate();
  const [city, setCity] = useState(null);
  const [loading, setLoading] = useState(false);

  const onRemove = (item) => {
    const filterHashtags = city?.hashtags.filter((el) => el !== item);
    setCity((s) => ({ ...s, hashtags: filterHashtags }));
  };

  const onAddHashtag = (hashtag, setHashtag) => {
    const arrFromHashtag = hashtag?.split(' ')?.map((el) => {
      if (!el?.includes('#')) {
        return `#${el}`;
      }
      return el;
    });
    setCity((s) => ({ ...s, hashtags: [...s.hashtags, ...arrFromHashtag] }));
    setHashtag('');
  };

  const getCity = useCallback(() => {
    setLoading(true);
    axiosInstance
      .get(`/city/by-id/${cityId}`)
      .then((res) => {
        setLoading(false);
        setCity(res.data);
      })
      .catch((err) => {
        setLoading(false);
        Toast('error', "Une erreur s'est produite !");
      });
    setLoading(false);
  }, [cityId]);

  const handleUpdateCity = (id) => {
    const loader = toast.loading('Veuillez patienter...');
    axiosInstance
      .put(`/city/${id}`, city)
      .then((res) => {
        toast_loader(loader, 'Ville modifiée !', 'success');
        navigate(-1);
        getCity();
      })
      .catch((err) => {
        toast_loader(loader, "Une erreur s'est produite ! ❌", 'error');
      });
  };

  useEffect(() => {
    getCity();
  }, [getCity]);

  if (loading) return <Loader />;

  return (
    <div className='cities'>
      <div style={{ marginBottom: '1rem' }} className='headerUser'>
        <div className='headerUser--header'>
          <div className='headerUser--headerLeft'>
            <Button
              variant='grey'
              Icon={ArrowLeftIcon}
              onClick={() => navigate(-1)}
            />
            <span style={{ textTransform: 'uppercase' }}>
              {city?.country?.toUpperCase()} - {city?.name}
            </span>
          </div>
        </div>
      </div>
      <div className='cities--tags' style={{ height: '84vh' }}>
        <PillsField onAdd={onAddHashtag} />
        <div className='cities--tagsList'>
          {city?.hashtags?.map((hashtag, index) => (
            <Pill pill={hashtag} key={index} onRemove={onRemove} />
          ))}
        </div>
        <Button
          label='Sauvegarder'
          variant='yellow'
          margin='auto 0 0 auto'
          onClick={() => handleUpdateCity(city?._id)}
        />
      </div>
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(UpdateCity);
