import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { editCustomerInfoAction } from '../../../redux/customer';
import { Gallery } from '../../atoms/Gallery';
import { UploadImg } from '../../atoms/UploadImg';

export const LogoCardPanel = () => {
  const dispatch = useAppDispatch();
  const customer = useAppSelector((state) => state.customer.customer);

  const handleChangeCustomerAvatar = (file) => {
    dispatch(
      editCustomerInfoAction({
        tab: 'logo_card',
        key: 'avatar',
        value: file,
      })
    );
  };

  const handleChangeCustomerPicture = (e) => {
    const name = e.target.name;

    const files = Array.from(e.target.files).filter(
      (file) =>
        customer.logo_card[name].find((f) => f.name === file.name) === undefined
    );

    dispatch(
      editCustomerInfoAction({
        tab: 'logo_card',
        key: name,
        value: [...customer.logo_card[name], ...files],
      })
    );
  };

  const handleRemoveCustomerPicture = (file, name) => {
    dispatch(
      editCustomerInfoAction({
        tab: 'logo_card',
        key: name,
        value: customer.logo_card[name].filter(
          (f) => (f.name && f.name !== file.name) || f !== file
        ),
      })
    );
  };

  return (
    <>
      <div className='customer--section'>
        <p className='customer--section-title'>Avatar</p>
      </div>
      <UploadImg
        image={customer?.logo_card?.avatar}
        onChange={handleChangeCustomerAvatar}
        folder='customer-avatar'
        margin='1rem 0 2rem'
      />
      <div className='customer--section'>
        <p className='customer--section-title'>Logo</p>
      </div>
      <Gallery
        onChange={handleChangeCustomerPicture}
        imgs={customer?.logo_card?.logo}
        handleDeleteFile={handleRemoveCustomerPicture}
        name='logo'
      />
      <div className='customer--section'>
        <p className='customer--section-title'>Carte</p>
      </div>
      <Gallery
        onChange={handleChangeCustomerPicture}
        imgs={customer?.logo_card?.card}
        handleDeleteFile={handleRemoveCustomerPicture}
        name='card'
      />
    </>
  );
};
