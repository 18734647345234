export const SearchCircleIcon = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.1352 3.36667C7.86415 3.36667 3.40182 7.829 3.40182 12.1C3.40182 16.371 7.86415 20.8333 12.1352 20.8333C16.4062 20.8333 20.8685 16.371 20.8685 12.1C20.8685 7.829 16.4062 3.36667 12.1352 3.36667ZM0.535156 12.1C0.535156 5.6935 5.72865 0.5 12.1352 0.5C18.5417 0.5 23.7352 5.6935 23.7352 12.1C23.7352 14.6053 22.941 16.9251 21.5906 18.8213L24.9022 22.1329C25.6572 22.8879 25.6572 24.1121 24.9022 24.8671C24.1472 25.6221 22.9231 25.6221 22.1681 24.8671L18.8565 21.5554C16.9602 22.9058 14.6404 23.7 12.1352 23.7C5.72865 23.7 0.535156 18.5065 0.535156 12.1Z'
        fill='white'
      />
    </svg>
  );
};
