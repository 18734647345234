import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPicturesByGroupId } from '../../../api/picture.api';
import { colors } from '../../../assets/constants';
import { ChevronBottomIcon } from '../../../assets/svgs/ChevronBottomIcon';
import { CloseIcon } from '../../../assets/svgs/CloseIcon';
import { setPictureFormAction } from '../../../redux/picture';
import {
  editImgModalAction,
  similarsImagesModalAction,
} from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { ImageRender } from '../../organisms/Images/ImageRender';
import { Plock } from 'react-plock';
import { fetchGroups } from '../../../api/groups.api';
import { useQuery } from 'react-query';
import { useAppSelector } from '../../../hooks/useReduxHook';

const breakpoints = [
  { size: 640, columns: 2 },
  { size: 768, columns: 3 },
  { size: 1024, columns: 4 },
  { size: 1280, columns: 5 },
];
export const ImagesSimilarsModal = () => {
  const dispatch = useDispatch();
  const images = useAppSelector((s) => s.images);
  const [groupSelected, setGroupSelected] = useState(null);

  const [filter, setFilter] = useState({
    label: '',
    page: 1,
    limit: 10,
  });

  const { data: imagesSimilars } = useQuery(
    ['samesPictures', groupSelected],
    () => {
      return groupSelected
        ? fetchPicturesByGroupId(groupSelected?._id || groupSelected)
        : null;
    }
  );

  const editPicture = (picture) => {
    // dispatch(getPictureSelectedAction(picture));
    dispatch(setPictureFormAction(picture));
    dispatch(similarsImagesModalAction(false));
    dispatch(editImgModalAction(true));
  };

  useEffect(() => {
    if (images) {
      setGroupSelected(images?.groupId);
    }
  }, [images]);

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(similarsImagesModalAction(false));
    };
  }, []);

  return (
    <>
      <div className='modalImagesSimilars'>
        <div className='modalImagesSimilars--header'>
          <p className='modalImagesSimilars--title'>Images Similaires</p>
          <Filter
            filter={filter}
            setFilter={setFilter}
            setGroupSelected={setGroupSelected}
            groupSelected={groupSelected}
          />
          <CloseIcon
            onClick={() => dispatch(similarsImagesModalAction(false))}
          />
        </div>
        {imagesSimilars?.data?.length > 0 ? (
          <Plock breakpoints={breakpoints} gap={10} style={{ width: '100%' }}>
            {imagesSimilars?.data
              .filter((img) => img._id !== images._id)
              ?.map((pic, index) => {
                return (
                  <ImageRender
                    key={index}
                    pic={pic}
                    onSelectPic={() => editPicture(pic)}
                  />
                );
              })}
          </Plock>
        ) : (
          <div className='modalImagesSimilars--container'>
            <p style={{ display: 'block', margin: 'auto' }}>
              Aucune image trouvée pour ce groupe !
            </p>
          </div>
        )}
        <div className='modalImagesSimilars--footer'>
          <Button
            label='Fermer'
            margin='0 2rem 0 '
            onClick={() => dispatch(similarsImagesModalAction(false))}
          />
          <Button
            label='Voir plus'
            variant='yellow'
            disabled={filter.currentPage === filter.totalPages}
            onClick={() => setFilter({ ...filter, limit: filter.limit + 10 })}
          />
        </div>
      </div>
    </>
  );
};

const Filter = ({ groupSelected, setGroupSelected, filter, setFilter }) => {
  const [open, setOpen] = useState(false);

  const params = new URLSearchParams([
    ['label', filter.label || ''],
    ['page', filter.page],
    ['limit', filter.limit],
  ]);
  const { data } = useQuery(['groups', filter.label, filter.limit], () =>
    fetchGroups(params)
  );

  useEffect(() => {
    if (data) {
      setFilter({
        ...filter,
        totalPages: data.totalPages,
        page: data.currentPage,
      });
    }
  }, [data]);

  return (
    <div className='modalImagesSimilars--filter'>
      <button
        className='modalImagesSimilars--filter-btn'
        onClick={() => {
          setOpen((s) => !s);
          setFilter({ ...filter, label: '' });
        }}
      >
        <span>
          {groupSelected?.label ||
            data?.data?.groups?.find((group) => group._id === groupSelected)
              ?.label}
        </span>
        <ChevronBottomIcon color={colors.greyDark} />
      </button>
      {open && (
        <div className='modalImagesSimilars--select'>
          <div className='modalImagesSimilars--searchBar'>
            <input
              type='text'
              placeholder='Rechercher un groupe...'
              value={filter.label}
              onChange={(e) => {
                setFilter({ ...filter, label: e.target.value });
              }}
            />
            <CloseIcon
              margin={'0 .5rem 0 1rem'}
              onClick={() => {
                setFilter({ ...filter, label: '' });
              }}
            />
          </div>
          {data?.data?.groups?.length > 0 ? (
            data?.data?.groups?.map((group, index) => (
              <button
                className='image--groupItem'
                style={{ padding: '1rem' }}
                key={index}
                onClick={() => {
                  setGroupSelected(group);
                  setOpen(false);
                }}
              >
                {group?.label}
              </button>
            ))
          ) : (
            <p>Pas de groupe trouvé...</p>
          )}
        </div>
      )}
    </div>
  );
};
