import { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchGroups } from '../../../api/groups.api';
import { colors } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { Button } from '../../../components/atoms/Buttons';
import { Collapse } from '../../../components/atoms/Collapse';
import { Input } from '../../../components/atoms/Fields';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { imageFormAction } from '../../../redux/images';
import { groupsModalAction } from '../../../redux/modals';

export const CollapseItemImgGroups = () => {
  //
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');

  const role = useAppSelector((s) => s.app.role);
  const groupSelectedId = useAppSelector((state) => state.images.groupId);

  const params = new URLSearchParams([
    ['label', search],
    ['page', 1],
    ['limit', 100],
  ]);
  const { data } = useQuery(['groups', search], () => fetchGroups(params));

  const handleSelectGroup = (group) => {
    if (role !== 'ADMIN' && role !== 'SUPER-ADMIN') return;

    dispatch(
      imageFormAction({
        key: 'groupId',
        value: group._id,
      })
    );
  };

  return (
    <Collapse
      title="GROUPE D'IMAGE"
      border
      margin='0 0 1rem'
      bg='white'
      maxHeight='400px'
    >
      <Input
        label=''
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder='Rechercher groupe...'
        margin='0 0 1rem'
      />
      {data?.data?.groups &&
        copyObj(data?.data?.groups)
          .sort((a, b) => sortedList(a, b, 'label'))
          ?.filter((group) =>
            group.label.toLowerCase().includes(search.toLowerCase())
          )
          .map((group) => {
            return (
              <button
                style={{
                  color: colors.dark,
                  borderColor:
                    groupSelectedId === group._id ? colors.yellow : '#F4F4F4',
                  backgroundColor: '#F4F4F4',
                }}
                key={group._id}
                className='buttonSelect'
                onClick={() => handleSelectGroup(group)}
              >
                {group.label}
              </button>
            );
          })}

      <Button
        label='Ajouter un groupe'
        variant='yellow'
        margin='1rem 0 0 auto'
        onClick={() => dispatch(groupsModalAction(true))}
      />
    </Collapse>
  );
};
