export const CalendarIcon = ({ color = '#282828' }) => {
  return (
    <svg
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.0987 3.3418C3.77782 3.3418 2.70703 4.41258 2.70703 5.73346V13.9001C2.70703 15.221 3.77782 16.2918 5.0987 16.2918H14.8987C16.2196 16.2918 17.2904 15.221 17.2904 13.9001V5.73346C17.2904 4.41258 16.2196 3.3418 14.8987 3.3418H5.0987ZM0.957031 5.73346C0.957031 3.44608 2.81132 1.5918 5.0987 1.5918H14.8987C17.1861 1.5918 19.0404 3.44608 19.0404 5.73346V13.9001C19.0404 16.1875 17.1861 18.0418 14.8987 18.0418H5.0987C2.81132 18.0418 0.957031 16.1875 0.957031 13.9001V5.73346Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.73333 0.25C7.0555 0.25 7.31667 0.511167 7.31667 0.833333V4.1C7.31667 4.42217 7.0555 4.68333 6.73333 4.68333C6.41117 4.68333 6.15 4.42217 6.15 4.1V0.833333C6.15 0.511167 6.41117 0.25 6.73333 0.25ZM13.2667 0.25C13.5888 0.25 13.85 0.511167 13.85 0.833333V4.1C13.85 4.42217 13.5888 4.68333 13.2667 4.68333C12.9445 4.68333 12.6833 4.42217 12.6833 4.1V0.833333C12.6833 0.511167 12.9445 0.25 13.2667 0.25ZM1.25 7.36667C1.25 7.0445 1.51117 6.78333 1.83333 6.78333H18.1667C18.4888 6.78333 18.75 7.0445 18.75 7.36667C18.75 7.68883 18.4888 7.95 18.1667 7.95H1.83333C1.51117 7.95 1.25 7.68883 1.25 7.36667Z'
        fill={color}
      />
    </svg>
  );
};
