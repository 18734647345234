import { fetchPictureById } from '../../api/picture.api';

export const helperFindSamePictures = async (pictureId) => {
  const pictureIds = [];
  const res = await fetchPictureById(pictureId);

  if (res?.picture) {
    pictureIds.push(res.picture._id);
    for (let samePicture of res.picture.samePictures) {
      if (samePicture?._id) {
        pictureIds.push(samePicture._id);
      } else {
        pictureIds.push(samePicture);
      }
    }
  }

  return pictureIds;
};

export const helperFindSamePicturesIdByPicture = async ({
  oldPicture,
  picture,
}) => {
  let pictureIds = [];
  let oldsPictureIds = [];

  if (oldPicture?.parentId) {
    const samesPicturesIds = await helperFindSamePictures(
      oldPicture.parentId?._id || oldPicture.parentId
    );
    if (oldsPictureIds.length > 0) {
      oldsPictureIds = samesPicturesIds;
    }
  } else if (oldPicture) {
    oldsPictureIds = [
      oldPicture._id,
      ...(oldPicture?.samePictures?.map((p) => p._id || p) || []),
    ];
  }

  if (picture?.parentId) {
    const samesPicturesIds = await helperFindSamePictures(
      picture.parentId?._id || picture.parentId
    );
    if (samesPicturesIds.length > 0) {
      pictureIds = samesPicturesIds;
    }
  } else if (picture) {
    pictureIds = [
      picture._id,
      ...(picture?.samePictures?.map((p) => p._id || p) || []),
    ];
  }

  return { pictureIds, oldsPictureIds };
};
