import { memo, useEffect, useMemo, useState } from 'react';
import { CustomerDiary } from './CustomerDiary';
import { Settings2Icon } from '../../../assets/svgs/Settings2Icon';
import { Tabs } from './Tabs';
import { LoaderTwo } from '../../atoms/Loader';
import { ImageRender } from './ImageRender';
import { Plock } from 'react-plock';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { useInfiniteQuery } from 'react-query';
import { fetchPicturesByFilters } from '../../../api/picture.api';
import _ from 'lodash';
import { setPageAction } from '../../../redux/filters';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

const breakpoints = [
  { size: 1024, columns: 4 },
  { size: 1280, columns: 4 },
  { size: 1320, columns: 4 },
  { size: 1430, columns: 4 },
  { size: 1520, columns: 4 },
  { size: 1620, columns: 4 },
  { size: 1720, columns: 5 },
  { size: 1820, columns: 5 },
  { size: 1910, columns: 5 },
];

export const ImagesList = memo(
  ({ setShowSidebar, search, disabledDiary, onSelectPicture }: unknown) => {
    //
    const dispatch = useDispatch();
    const customer = useAppSelector((s) => s.customer?.customer);
    const filters = useAppSelector((state) => state.filters);
    const [tabs, setTabs] = useState('not-attributed');
    const [page] = useState(1);
    const filteredByCustomerUnivers = useAppSelector(
      (s) => s.picture.filteredByCustomerUnivers
    );

    const attributed = useMemo(() => {
      return (
        customer?.subscriptions
          ?.flatMap((subscription) => subscription?.diaries)
          .map((diary) => diary?.picture?._id || diary?.picture)
          .filter((x) => !!x) || []
      );
    }, [customer?.subscriptions]);

    const {
      data: dataInfinite,
      fetchNextPage,
      refetch,
      isFetchingNextPage,
      isLoading,
      hasNextPage,
    } = useInfiniteQuery(
      [
        'pictures-infinite',
        filters,
        customer?.univers,
        filteredByCustomerUnivers,
        tabs,
        attributed,
        search,
      ],
      ({ pageParam = page, signal }) => {
        return fetchPicturesByFilters(
          {
            body: {
              ...filters,
              page: pageParam,
              tabs: customer && !filteredByCustomerUnivers ? tabs : '',
              attributed:
                customer && !filteredByCustomerUnivers
                  ? tabs === 'not-attributed'
                    ? customer?.allAttributions
                    : attributed
                  : false,
              search,
              customerId: customer?._id,
            },
            universResto: filteredByCustomerUnivers
              ? {
                  ...customer?.univers,
                  tabs,
                  attributed:
                    tabs === 'not-attributed'
                      ? customer?.allAttributions
                      : attributed,
                }
              : false,
          },
          signal
        );
      },
      {
        getNextPageParam: (lastPage) => {
          if (!lastPage) return undefined;
          const { currentPage, totalPages } = lastPage;
          return currentPage < totalPages ? currentPage + 1 : undefined;
        },
      }
    );

    const pictures =
      dataInfinite?.pages?.flatMap((page) => page?.pictures || []) || [];

    const picturesFilterred =
      tabs === 'not-attributed'
        ? pictures?.filter((pic) => pic && !attributed.includes(pic._id))
        : pictures?.filter((pic) => pic && attributed.includes(pic._id));

    useEffect(() => {
      dispatch(setPageAction(page));
    }, [dispatch, page]);

    const handleScroll = async () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    };

    useEffect(() => {
      if (customer?.attributionPicture && !disabledDiary) {
        setTabs('diary');
      }
    }, [customer?.attributionPicture, disabledDiary]);

    useEffect(() => {
      if (!customer && (tabs === 'diary' || !filteredByCustomerUnivers)) {
        setTabs('not-attributed');
      }
    }, [tabs, filteredByCustomerUnivers, customer]);

    const imagesListCounterRender = () => {
      const length = dataInfinite?.pages[0]?.totalItems || 0;
      const plural = length > 1 ? 's' : '';

      return (
        <span>{`${
          length > 0 ? `${length}` : ' aucune'
        } image${plural} trouvée${plural}`}</span>
      );
    };

    return (
      <div className='images' id='images'>
        {customer && (
          <Tabs tabs={tabs} setTabs={setTabs} disabledDiary={disabledDiary} />
        )}
        <div className='images--imgsLength'>
          <Settings2Icon onClick={() => setShowSidebar((s) => !s)} />
          {(search?.length < 3 || !search) && imagesListCounterRender()}
        </div>

        {customer && tabs === 'diary' ? (
          <CustomerDiary />
        ) : (
          <InfiniteScroll
            dataLength={pictures?.length} //This is important field to render the next data
            next={handleScroll}
            hasMore={hasNextPage}
            loader={<></>}
            scrollableTarget='images'
            // below props only if you need pull down functionality
            refreshFunction={refetch}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
          >
            <List
              pictures={picturesFilterred}
              search={search}
              onSelectPicture={onSelectPicture}
              loading={isLoading}
            />
          </InfiniteScroll>
        )}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.search === nextProps.search
);

const List = ({ pictures, loading, search, onSelectPicture }) => {
  const sortedPictures = pictures ? [...pictures] : [];

  return (
    <Plock
      className='imgGridLayout'
      breakpoints={breakpoints}
      style={{ width: '100%' }}
      gap={16}
      aria-sort='descending'
    >
      {sortedPictures &&
        sortedPictures.length > 0 &&
        sortedPictures.map((pic) => (
          <ImageRender
            key={pic._id}
            pic={pic}
            onSelectPicture={onSelectPicture}
          />
        ))}
      {loading && <LoaderTwo />}
    </Plock>
  );
};
