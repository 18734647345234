import { createSlice } from '@reduxjs/toolkit';
import { helperFiltersCompositionSelected } from '../filters/helper';

const initialState: any = {
  month: null,
  customer: null,
  customerSelected: null,
  filteredBy: 'customerUnivers',
  formula: null,
  pictureToAttribute: null,
  isUpdateSubscription: false,
  currentSubscriptionIndex: 0,
  strategyModelCompOpt: {
    strategyIndex: null,
    from: 'strategyModal', // strategyModal - strategyPanel - imgAttrModal
    index: null,
    value: '',
    label: 'compositionId',
    isStrategyModel: false,
  },
  tabs: 'informations',
  universTabs: 'compositions',
};
export const slice = createSlice({
  name: 'customer',
  initialState: initialState,

  reducers: {
    setGetStyleAction: (state, action) => {
      state.style = action.payload;
    },
    setStyleAction: (state, action) => {
      state.style = { ...state.style, label: action.payload };
    },
    setEmptyStyleAction: (state) => {
      state.style = { label: '', products: [] };
    },
    setStyleProductAction: (state, action) => {
      state.style = { ...state.style, products: action.payload };
    },
    setFamilyAction: (state, action) => {
      state.family = { ...state.family, label: action.payload };
    },
    setGetFamilyAction: (state, action) => {
      state.family = action.payload;
    },
    setEmptyFamilyAction: (state) => {
      state.family = { label: '', products: [] };
    },
    setFamilyProductAction: (state, action) => {
      state.family = { ...state.family, products: action.payload };
    },
    setFilterAction: (state, action) => {
      state.filter = { ...state.filter, label: action.payload };
    },
    setGetFilterAction: (state, action) => {
      state.filter = action.payload;
    },
    setEmptyFilterAction: (state) => {
      state.filter = { label: '', products: [] };
    },
    setFilterProductAction: (state, action) => {
      state.filter = { ...state.filter, products: action.payload };
    },
    setSpecialFilterAction: (state, action) => {
      state.specialFilter = {
        ...state.specialFilter,
        [action.payload?.name]: action.payload?.value,
      };
    },
    setGetSpecialFilterAction: (state, action) => {
      state.specialFilter = action.payload;
    },
    setEmptySpecialFilterAction: (state) => {
      state.specialFilter = {
        label: '',
        linkGoodBoard: '',
        linkWorkList: '',
        linkDrive: '',
      };
    },
    customerDetailsAction: (state, action) => {
      if (action.payload && state.customer?._id !== action.payload?._id) {
        state.customer = {
          ...action.payload,
          univers: {
            ...action?.payload?.univers,
            families: action?.payload.univers?.families?.filter((_f) =>
              _f.products?.every((p) => p?.compositions?.every((c) => !!c.type))
            ),
          },
        };
      } else if (!action.payload) {
        state.customer = null;
      }
    },
    updateCustomerDeliveryAction: (state, action) => {
      state.customer.informations.restaurant_delivery = action.payload;
    },
    editCustomerInfoAction: (state, action) => {
      const { tab, key, value } = action.payload;

      if (!state.customer) {
        state.customer = {};
      }

      if (tab in state.customer) {
        if (key) {
          state.customer[tab][key] = value;
        } else {
          state.customer[tab] = value;
        }
      } else {
        if (key) {
          state.customer[tab] = { [key]: value };
        } else {
          state.customer[tab] = value;
        }
      }
    },
    updateCustomerStrategiesAction: (state, action) => {
      if (state.customer.subscriptions.length > 0) {
        state.customer.subscriptions[
          state.currentSubscriptionIndex
        ].strategies = action.payload;
      }
    },
    updateCustomerDiariesAction: (state, action) => {
      if (state.customer.subscriptions.length > 0) {
        const updatedSubscriptions = [...state.customer.subscriptions];

        updatedSubscriptions[state.currentSubscriptionIndex] = {
          ...updatedSubscriptions[state.currentSubscriptionIndex],
          diaries: action.payload,
        };

        state.customer.subscriptions = updatedSubscriptions;
      }
    },

    updateCustomerAllAttributionsAction: (state, action) => {
      if (state.customer.allAttributions) {
        state.customer.allAttributions = action.payload;
      } else {
        state.customer.allAttributions = [];
      }
    },

    addPictureIdToAllAttributionAction: (state, action) => {
      const { oldsPictureIds, pictureIds } = action.payload;

      if (state.customer.allAttributions) {
        if (oldsPictureIds?.length > 0) {
          state.customer.allAttributions =
            state.customer.allAttributions.filter(
              (_pictureId) => !oldsPictureIds.includes(_pictureId)
            );
        }

        if (pictureIds?.length > 0) {
          state.customer.allAttributions.push(...pictureIds);
        }
      }
    },

    removePictureIdFromAllAttributionAction: (state, action) => {
      const { pictureIds } = action.payload;

      if (state.customer.allAttributions) {
        state.customer.allAttributions = state.customer.allAttributions.filter(
          (_pictureId) => !pictureIds?.includes(_pictureId)
        );
      }
    },

    updateSubscriptionStatusAction: (state, action) => {
      state.isUpdateSubscription = action.payload;
    },
    currentSubscriptionIndexAction: (state, action) => {
      state.currentSubscriptionIndex = action.payload;
    },
    formulaAction: (state, action) => {
      state.formula = action.payload;
    },
    setFilteredByAction: (state, action) => {
      state.filteredBy = action.payload;
    },
    setCustomerSelectedAction: (state, action) => {
      state.customerSelected = action.payload;
    },
    pictureToAttributeAction: (state, action) => {
      state.pictureToAttribute = action.payload;
    },
    getCustomerAction: (state, action) => {},
    strategyModelCompOptAction: (state, action) => {
      state.strategyModelCompOpt = action.payload;
    },
    universCompositionSelectedAction: (state, action) => {
      state.customer.univers = helperFiltersCompositionSelected(
        state.customer?.univers,
        action.payload
      );
    },

    universFamilySelectedForComposeAction: (state, action) => {
      const family = action.payload;
      const findFamily = state.customer?.univers?.families?.find(
        (item) => item._id === family._id
      );

      if (findFamily) {
        findFamily.forCompose = !findFamily.forCompose;

        if (!findFamily.forCompose) {
          if (findFamily.products.length === 0) {
            state.customer?.univers?.families?.splice(
              state.customer?.univers?.families?.findIndex(
                (item) => item._id === family._id
              ),
              1
            );
          } else {
            findFamily.ingredients = [];
          }
        }
      } else {
        state.customer?.univers?.families?.push({
          ...family,
          forCompose: true,
          products: [],
        });
      }
    },

    universFamilySelectedIngredientsAction: (state, action) => {
      const { familyId, ingredients } = action.payload;

      const findFamily = state.customer?.univers?.families?.find(
        (item) => item._id === familyId
      );

      if (findFamily) {
        findFamily.ingredients = ingredients;
      } else {
        state.customer?.univers?.families?.push({
          _id: familyId,
          ingredients: ingredients,
          forCompose: true,
          products: [],
        });
      }
    },
    customerTabsAction: (state, action) => {
      state.tabs = action.payload;
    },
    universTabsAction: (state, action) => {
      state.universTabs = action.payload;
    },
  },
});

export const {
  setStyleAction,
  setFamilyAction,
  setFamilyProductAction,
  setStyleProductAction,
  setEmptyStyleAction,
  setEmptyFamilyAction,
  setGetStyleAction,
  setGetFamilyAction,
  setFilterAction,
  setGetFilterAction,
  setEmptyFilterAction,
  setFilterProductAction,
  setSpecialFilterAction,
  setGetSpecialFilterAction,
  setEmptySpecialFilterAction,
  customerDetailsAction,
  editCustomerInfoAction,
  getCustomerAction,
  setFilteredByAction,
  setCustomerSelectedAction,
  formulaAction,
  pictureToAttributeAction,
  updateSubscriptionStatusAction,
  currentSubscriptionIndexAction,
  updateCustomerStrategiesAction,
  updateCustomerDiariesAction,
  strategyModelCompOptAction,
  universCompositionSelectedAction,
  updateCustomerDeliveryAction,
  updateCustomerAllAttributionsAction,
  addPictureIdToAllAttributionAction,
  removePictureIdFromAllAttributionAction,
  universFamilySelectedForComposeAction,
  universFamilySelectedIngredientsAction,
  customerTabsAction,
  universTabsAction,
} = slice.actions;
export default slice.reducer;
