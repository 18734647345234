import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  IQuestion,
  questionSelectedAction,
  quizAction,
} from '../../../redux/familyQuiz';
import { confirmSwitcheFamilyModalAction } from '../../../redux/modals';
import { Modal } from '../../atoms/Modal';
import {
  familyChangeAction,
  familySelectedImgAttrAction,
} from '../../../redux/imageAttributions';
import { compositionSelectedAction } from '../../../redux/compositions';
import { initComposition } from '../../../assets/constants';

export const ConfirmeSwitcheFamilyModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const family = useAppSelector((s) => s.imageAttributions.familyChange);

  const onValidate = () => {
    const quizPayload = {
      ...family?.quiz,
      questionsList: family?.quiz.questionsList.map((q: IQuestion) => {
        return {
          ...q,
          answered: false,
          noAnswer: false,
        };
      }),
    };
    dispatch(
      familySelectedImgAttrAction({
        ...family,
        checkedNum: !!family?.checkedNum ? family?.checkedNum : 0,
      })
    );
    dispatch(questionSelectedAction(quizPayload?.questionsList[0]));
    dispatch(quizAction(quizPayload));
    dispatch(familyChangeAction(null));
    dispatch(compositionSelectedAction(initComposition));
    dispatch(confirmSwitcheFamilyModalAction(false));
  };

  return (
    <Modal
      onClose={() => dispatch(confirmSwitcheFamilyModalAction(false))}
      title='Attention'
      onValidate={onValidate}
      bgWidth='100%'
      left='30%'
      btnValidateLabel='JE CONFIRME'
    >
      <p style={{ textAlign: 'center' }}>
        Vous n'avez pas sélectionné le nombre de composition demandé, vous
        risquez de perdre les éléments sélectionné en changeant de famille !
      </p>
    </Modal>
  );
};
