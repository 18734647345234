import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchSpecialFilters } from '../api/specialsFilters.api';
import { useAppSelector } from './useReduxHook';

export const useSpecialFilter = (defaultSpecialFilter, enabledFetch = true) => {
  const customer = useAppSelector((state) => state.customer.customer);
  const { data } = useQuery(['specialFilter'], () => fetchSpecialFilters(''), {
    enabled: enabledFetch,
  });

  const [specialFilter, setSpecialFilter] = useState(
    defaultSpecialFilter || null
  );

  const specialFiltersData = enabledFetch
    ? data
    : customer?.univers?.specialsFilters;

  const specialFilters =
    specialFiltersData?.map((item) => {
      return {
        name: item.label,
        value: item._id,
        _id: item._id,
        subSpecialFilters: item.subSpecialFilters,
      };
    }) || [];

  const specialFilterValues =
    specialFiltersData
      ?.find((item) => item._id === specialFilter)
      ?.subSpecialFilters.map((item) => {
        return {
          name: item.label,
          value: item._id,
          _id: item._id,
        };
      }) || [];

  const handleSelectSpecialFilter = (arg) => {
    if (typeof arg === 'string') {
      setSpecialFilter(arg);
    } else {
      setSpecialFilter(arg.target.value);
    }
  };

  useEffect(() => {
    if (defaultSpecialFilter) {
      setSpecialFilter(defaultSpecialFilter);
    }
  }, [defaultSpecialFilter]);

  return {
    specialFilters,
    specialFilterValues,
    handleSelectSpecialFilter,
  };
};
