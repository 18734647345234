export const CloseCircleIcon = ({
  onClick = () => {},
  position,
  left,
  right,
  top,
  bottom,
  zIndex,
  margin,
}) => {
  return (
    <svg
      width='66'
      height='66'
      viewBox='0 0 66 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      style={{
        position,
        left,
        right,
        top,
        bottom,
        zIndex,
        cursor: 'pointer',
        margin,
      }}
    >
      <circle cx='33' cy='33' r='33' fill='#DBDBDB' />
      <path
        d='M27.3125 22.2727L33.2287 31.9439H33.4219L39.3381 22.2727H43.6605L35.9574 34.6364L43.7088 47H39.3622L33.4219 37.4616H33.2287L27.2884 47H22.9418L30.826 34.6364L22.9901 22.2727H27.3125Z'
        fill='#A88E1B'
      />
    </svg>
  );
};
