import React, { useState } from 'react';
import { ArrowLeftIcon } from '../../../assets/svgs/ArrowLeftIcon';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { Button } from '../../../components/atoms/Buttons';
import {
  IngredientCard,
  NoAnswerCard,
} from '../../../components/organisms/CompositionsGestion/IngredientCard';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  answerSelectedAction,
  IAnswer,
  questionSelectedAction,
} from '../../../redux/familyQuiz';
import { familySelectedImgAttrAction } from '../../../redux/imageAttributions';

import { specialitiesModalAction } from '../../../redux/modals';
import { useQuiz } from '../../../hooks/useQuiz';
import { SearchIcon } from '../../../assets/svgs/SearchIcon';

export const AnswersList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { onPreviousQuestion, questionIndex } = useQuiz();
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);
  const [search, setSearch] = useState('');
  const compositionSelected = useAppSelector(
    (s) => s.compositions.compositionSelected
  );

  const answersFiltered = questionSelected?.answers?.filter((answer) =>
    answer?.ingredient_id?.label?.toLowerCase().includes(search.toLowerCase())
  );

  const onSelectAnswer = (answer: IAnswer) => {
    dispatch(answerSelectedAction(answer));
    dispatch(specialitiesModalAction(true));
  };

  const onCancelled = () => {
    if (questionIndex > 0) {
      onPreviousQuestion();
    } else {
      dispatch(familySelectedImgAttrAction(null));
      dispatch(questionSelectedAction(null));
    }
  };

  return (
    <div className='customerCompositionsView--ingredientsBlock'>
      <div className='w-100 flex-row-center-start'>
        <Button
          margin='0 1rem 0 0'
          variant='grey'
          Icon={ArrowLeftIcon}
          onClick={onCancelled}
        />
        <p className='customerCompositionsView--ingredientsBlock--title'>
          {questionSelected?.question}
        </p>
      </div>
      <div
        id='input'
        className='header--input'
        style={{
          width: '95%',
          margin: '0 auto',
        }}
      >
        <SearchIcon />
        <input
          type='text'
          placeholder='Search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className='customerCompositionsView--ingredientsBlock--ingredients'>
        {!!questionSelected &&
          copyObj(
            answersFiltered?.filter((_answer) => !!_answer?.ingredient_id)
          )
            ?.sort((a, b) => sortedList(a, b, 'ingredient_id', 'label'))
            ?.map((answer, index: number) => {
              const filteredIngredients =
                compositionSelected?.ingredients?.filter(
                  (_ingredient) =>
                    _ingredient?.ingredientId === answer?.ingredient_id?._id &&
                    _ingredient?.questionId === questionSelected?._id
                );

              return (
                <>
                  {index === 0 && <NoAnswerCard answer={answer} />}
                  <IngredientCard
                    key={index}
                    ingredient={answer?.ingredient_id}
                    onSelect={() => onSelectAnswer(answer)}
                    specialitiesInComp={filteredIngredients?.length}
                  />
                </>
              );
            })}
      </div>
    </div>
  );
};
