import { useDispatch } from 'react-redux';
import { setAttributedAction } from '../../../redux/picture';

export const Tabs = ({ tabs, setTabs, disabledDiary = false }) => {
  const dispatch = useDispatch();
  const filteredByAttributed = () => {
    dispatch(setAttributedAction(true));
    setTabs('attributed');
  };
  const filteredByNotAttributed = () => {
    dispatch(setAttributedAction(false));
    setTabs('not-attributed');
  };
  return (
    <div className='images--tabs'>
      <button
        onClick={filteredByNotAttributed}
        className={`images--tabs-btn images--tabs-btn-${
          tabs === 'not-attributed' ? 'active' : ''
        }`}
      >
        Non attribué
      </button>
      <button
        onClick={filteredByAttributed}
        className={`images--tabs-btn images--tabs-btn-${
          tabs === 'attributed' ? 'active' : ''
        }`}
      >
        Déjà attribué
      </button>
      <button
        disabled={disabledDiary}
        onClick={() => setTabs('diary')}
        className={`images--tabs-btn images--tabs-btn-${
          tabs === 'diary' ? 'active' : ''
        }`}
      >
        {disabledDiary ? '' : 'Agenda'}
      </button>
    </div>
  );
};
