import React from 'react';
import { LeftBlockContent } from './LeftBlockContent';
import { LeftBlockHeader } from './LeftBlockHeader';

export const LeftBlock: React.FC = () => {
  return (
    <div className='familyQuiz--leftBlock'>
      <LeftBlockHeader />
      <LeftBlockContent />
    </div>
  );
};
