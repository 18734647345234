import NoSignal from '../../assets/images/nosignal.png';

export const NoSignalView = () => {
  return (
    <div className='noSignal'>
      <img src={NoSignal} alt='no-signal' />
      <p>Nous avons constaté un souci de connexion !!!</p>
    </div>
  );
};
