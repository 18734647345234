import { DiaryPanel } from './DiaryPanel';
import { StrategyPanel } from './StrategyPanel';

export const DiaryComp = ({ tabs, diaryTabs }) => {
  return (
    <>
      {tabs === 'diary' && diaryTabs === 'diary' && <DiaryPanel />}
      {tabs === 'diary' && diaryTabs === 'strategy' && <StrategyPanel />}
    </>
  );
};
