import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks/useReduxHook';
import { fetchFamilies } from '../../../../api/families.api';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  universCompositionSelectedAction,
  universFamilySelectedForComposeAction,
} from '../../../../redux/customer';
import { filteredSelectedItemAction } from '../../../../redux/filtersSelected';
import { familyProductsModalAction } from '../../../../redux/modals';
import { Collapse } from '../../../atoms/Collapse';
import { colors } from '../../../../assets/constants';
import { ButtonAdd } from '../../../atoms/Buttons';
import { CompositionTrow } from '../../CompositionsGestion/CompositionTrow';
import { IComposition } from '../../../../redux/compositions';
import { MeasureIngredients } from './MeasureIngredients';
import { helperTotalCompositionByType } from '../../../../assets/utils/helperTotalCompositionByType';

export const Families = memo(({ search }: { search: string }) => {
  const customer = useAppSelector((state) => state.customer.customer);
  const universTabs = useAppSelector((s) => s.customer.universTabs);

  const univers = customer?.univers;
  const { data } = useQuery('families', () => fetchFamilies());

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSelectComposotion = ({
    comp,
    family,
    product,
    type,
  }: {
    comp: IComposition;
    family: any;
    product: any;
    type: string;
  }) => {
    dispatch(
      universCompositionSelectedAction({
        composition: { ...comp, type },
        parent: family,
        product,
      })
    );
    // const findComposition = customer?.univers?.compositions?.find(
    //   (_composition) => _composition === compositionId
    // );
    // if (!!findComposition) {
    //   const newCompositionsIds = customer?.univers?.compositions?.filter(
    //     (_composition) => _composition !== compositionId
    //   );
    //   dispatch(
    //     editCustomerInfoAction({
    //       tab: 'univers',
    //       key: 'compositions',
    //       value: newCompositionsIds,
    //     })
    //   );
    // } else {
    //   const newCompositionsIds = [
    //     ...customer?.univers?.compositions,
    //     compositionId,
    //   ];
    //   dispatch(
    //     editCustomerInfoAction({
    //       tab: 'univers',
    //       key: 'compositions',
    //       value: newCompositionsIds,
    //     })
    //   );
    // }
  };

  const onAddFamily = (family) => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'family',
        item: family,
      })
    );

    dispatch(familyProductsModalAction(true));
  };

  const onAddMeasureIngredient = (family) => {
    navigate('/customer-univers-compose/' + family._id + '/' + customer._id);
    // navigate
  };

  const onAddComposition = (family) => {
    navigate(`/customer-compositions/${family._id}/${customer._id}`);
  };

  const onSelectForCompose = (family) => {
    if (universTabs === 'measure') {
      return;
    }
    dispatch(universFamilySelectedForComposeAction(family));
  };

  const dataFilter = data
    ?.filter((family) => {
      if (!search) return true;

      return family?.label?.toLowerCase().includes(search.toLowerCase());
    })
    ?.filter((family) => {
      if (universTabs === 'compositions') return true;

      const findFamily = univers?.families?.find(
        (_f) => _f?._id === family?._id
      );

      if (universTabs === 'measure') {
        return findFamily?.forCompose;
      }

      if (universTabs === 'compPrimary') {
        let findComposPrincipal = findFamily?.products?.find((product) =>
          product?.compositions?.some((comp) => comp?.type === 'principal')
        );

        return !!findComposPrincipal;
      }

      if (universTabs === 'compSecondary') {
        let findComposSecondaire = findFamily?.products?.find((product) =>
          product?.compositions?.some((comp) => comp?.type === 'secondary')
        );

        return !!findComposSecondaire;
      }
    });

  return (
    <>
      {data?.length > 0 &&
        dataFilter.map((family, index) => {
          const findFamily = univers?.families?.find(
            (_f) => _f?._id === family?._id
          );

          const totalCompositionPrincipal = helperTotalCompositionByType(
            findFamily,
            'principal'
          );

          const totalCompositionSecondary = helperTotalCompositionByType(
            findFamily,
            'secondary'
          );

          const ingredientsByQuestion = findFamily?.ingredients?.reduce(
            (acc, ingredient) => {
              const questionId = ingredient?.questionId?._id;
              const findQuestion = acc.find(
                (_q) => _q?.questionId?._id === questionId
              );

              if (!!findQuestion) {
                findQuestion.ingredients.push(ingredient);
              } else {
                acc.push({
                  questionId: ingredient?.questionId,
                  ingredients: [ingredient],
                });
              }

              return acc;
            },
            []
          );

          return (
            <Collapse
              title={family?.label}
              key={index}
              border
              margin='0 0 1rem'
              bg={colors.grey}
              selected={!!findFamily}
              showCheckbox={['measure', 'compositions'].includes(universTabs)}
              activeForCompose={
                universTabs === 'measure' || findFamily?.forCompose
              }
              onSelectForCompose={() => onSelectForCompose(family)}
              direction={universTabs === 'measure' ? 'column' : 'row'}
              totalCompositionPrincipal={totalCompositionPrincipal}
              totalCompositionSecondary={totalCompositionSecondary}
            >
              <div className='flex-row-center' style={{ marginBottom: '1rem' }}>
                {universTabs === 'compositions' && (
                  <>
                    <ButtonAdd
                      label='Ajouter un produit'
                      bg={colors.dark}
                      color={colors.yellow}
                      width='200px'
                      onClick={() => onAddFamily(family)}
                    />
                    <ButtonAdd
                      label='Ajouter ou rechercher une composition'
                      bg={colors.yellow}
                      color={colors.white}
                      onClick={() => onAddComposition(family)}
                    />
                  </>
                )}

                {universTabs === 'measure' && (
                  <ButtonAdd
                    label='Gestion des ingrédients'
                    bg={colors.dark}
                    color={colors.yellow}
                    width='240px'
                    onClick={() => onAddMeasureIngredient(family)}
                  />
                )}
              </div>
              {universTabs !== 'measure' &&
                family?.products?.length > 0 &&
                family?.products.map((product, index: number) => {
                  const findProduct = findFamily?.products?.find(
                    (_p) => _p?._id === product?._id
                  );

                  if (['compPrimary', 'compSecondary'].includes(universTabs)) {
                    let findComposPrincipal = findProduct?.compositions?.some(
                      (comp) =>
                        comp?.type ===
                        (universTabs === 'compPrimary'
                          ? 'principal'
                          : 'secondary')
                    );
                    if (!findComposPrincipal) return null;
                  }

                  const totalCompositionPrincipal =
                    helperTotalCompositionByType(
                      {
                        products: [findProduct],
                      },
                      'principal'
                    );

                  const totalCompositionSecondary =
                    helperTotalCompositionByType(
                      {
                        products: [findProduct],
                      },
                      'secondary'
                    );

                  return (
                    <Collapse
                      title={product?.name}
                      border
                      margin='0 0 1rem'
                      bg={colors.white}
                      key={index}
                      selected={!!findProduct}
                      totalCompositionPrincipal={totalCompositionPrincipal}
                      totalCompositionSecondary={totalCompositionSecondary}
                    >
                      {product?.compositions?.length > 0 &&
                        product?.compositions.map(
                          (composition: IComposition, index: number) => {
                            const findComposition =
                              findProduct?.compositions?.find(
                                (_c) => _c?._id === composition?._id
                              );

                            if (
                              ['compPrimary', 'compSecondary'].includes(
                                universTabs
                              )
                            ) {
                              if (
                                findComposition?.type !==
                                (universTabs === 'compPrimary'
                                  ? 'principal'
                                  : 'secondary')
                              )
                                return null;
                            }

                            return (
                              <CompositionTrow
                                composition={composition}
                                key={index}
                                onSelect={(type) =>
                                  universTabs === 'compositions'
                                    ? onSelectComposotion({
                                        comp: composition,
                                        family,
                                        product,
                                        type,
                                      })
                                    : null
                                }
                                selected={findComposition}
                              />
                            );
                          }
                        )}
                    </Collapse>
                  );
                })}

              {universTabs === 'measure' &&
                ingredientsByQuestion?.length > 0 && (
                  <MeasureIngredients
                    ingredientsByQuestion={ingredientsByQuestion}
                  />
                )}
            </Collapse>
          );
        })}
    </>
  );
});
