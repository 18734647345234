import { useDispatch } from 'react-redux';
import { CloseCircleIcon } from '../../../assets/svgs/CloseCircleIcon';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { viewImageModalAction } from '../../../redux/modals';
import {
  getPictureDisplayAction,
  pictureDisplayWaterMarkedAction,
} from '../../../redux/picture';

export const DisplayImageModal = ({ width = '50%', height = 600 }) => {
  const dispatch = useDispatch();
  const role = useAppSelector((s) => s.app.role);
  const pictureDisplay = useAppSelector((s) => s.picture.pictureDisplay);

  const pictureWaterMarked = useAppSelector(
    (s) => s.picture.pictureWaterMarked
  );
  const onClose = () => {
    dispatch(getPictureDisplayAction({ picture: '', folder: '' }));
    dispatch(pictureDisplayWaterMarkedAction(false));

    dispatch(viewImageModalAction(false));
  };

  const imgSrc = helperImageRoute(
    pictureDisplay?.picture,
    pictureDisplay?.folder,
    false,
    role === 'COMMERCIAL' || pictureWaterMarked
  );

  return (
    <>
      <div
        onClick={onClose}
        className='modal--bg'
        style={{ zIndex: 510, width: '100%' }}
      />
      <div
        className='modal'
        style={{ minWidth: width, height, left: '30%', zIndex: 600 }}
      >
        <CloseCircleIcon
          position='absolute'
          right='1rem'
          top='1rem'
          zIndex={20}
          onClick={onClose}
        />
        <img
          src={imgSrc}
          alt={pictureDisplay?.picture + ''}
          className='modal--imgView'
        />
      </div>
    </>
  );
};
