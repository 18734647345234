import { colors } from '../../../assets/constants';
import { memo } from 'react';
import { usePictureCounters } from '../../../hooks/usePictureCounters';

export const Counters = memo(
  ({
    item,
    isParent = false,
    isChild,
    isSubChild,
    parentId,
    childrenId,
    subChildrenId,
    isComposition,
    compositionId,
  }: Partial<{
    item?: any,
    itemChild?: any,
    filterChildBy?: string,
    isParent?: boolean,
    isChild?: boolean,
    isSubChild?: boolean,
    childrenId?: string,
    subChildrenId?: string,
    parentId: string,
    isComposition?: boolean,
    compositionId?: string,
  }>) => {
    //
    const { pictures } = usePictureCounters();

    // const pictures = data?.pictures;
    let counter = 0;

    if (isParent) {
      if (parentId === 'families') {
        counter = pictures?.filter((p) => {
          return p?.attrMainCompositions?.length > 0;
        }).length;
      } else {
        counter = pictures?.filter((p) => {
          return p[parentId]?.length > 0;
        }).length;
      }
    }

    if (isChild) {
      if (parentId === 'families') {
        counter = pictures?.filter((p) => {
          return p?.attrMainCompositions?.find(
            (el) => el.familyId === item?._id
          );
        }).length;
      } else {
        counter = pictures?.filter((p) => {
          return !!p[parentId]?.find((el) => el._id === item?._id);
        }).length;
      }
    }

    if (isSubChild) {
      if (parentId === 'families') {
        counter = pictures?.filter((p) => {
          return !!p?.attrMainCompositions?.find((el) => {
            return el?.productId === item?._id;
          });
        }).length;
      } else {
        counter = pictures?.filter((p) => {
          return !!p[parentId]?.find((el) => {
            return !!el[childrenId]?.find((e) => e?._id === item?._id);
          });
        }).length;
      }
    }

    if (subChildrenId) {
      counter = pictures?.filter((p) => {
        return !!p[parentId].find((el) => {
          return !!el[childrenId]?.find((e) => {
            return !!e[subChildrenId]?.find((e) => e?._id === item?._id);
          });
        });
      }).length;
    }

    if (isComposition) {
      counter = pictures?.filter((p) => {
        return p?.attrMainCompositions?.find(
          (el) => el.compositionId?._id === compositionId
        );
      }).length;
    }

    return (
      <div
        style={{
          marginLeft: '.3rem',
          minWidth: 20,
          height: 20,
          padding: 8,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: counter > 0 ? colors.yellow : colors.greyDark,
          color: counter > 0 ? colors.white : 'black',
          fontWeight: 700,
          borderRadius: 4,
        }}
      >
        {counter || 0}
      </div>
    );
  }
);

export const CounterItem = ({ number }) => {
  //

  return (
    <div
      style={{
        marginLeft: '.3rem',
        minWidth: 20,
        height: 20,
        padding: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: number > 0 ? colors.yellow : colors.greyDark,
        color: number > 0 ? colors.white : 'black',
        fontWeight: 700,
        borderRadius: 4,
      }}
    >
      {number || 0}
    </div>
  );
};
