export const RefreshIcon = ({ color = 'white' }) => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M18.3472 9.36627L17.6366 8.5394C16.6648 7.5219 15.3874 6.84948 13.9985 6.6243C12.6096 6.39912 11.1852 6.6335 9.94169 7.29183C8.69818 7.95015 7.70359 8.99643 7.10904 10.2717C6.5145 11.5469 6.35251 12.9813 6.64769 14.357C6.94287 15.7328 7.67909 16.9745 8.74446 17.8935C9.80984 18.8126 11.1461 19.3587 12.5502 19.449C13.9544 19.5392 15.3495 19.1686 16.5238 18.3934C17.698 17.6183 18.5871 16.481 19.056 15.1544'
        stroke={color}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
      />
    </svg>
  );
};
