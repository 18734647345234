import { axiosInstance } from '../assets/utils/axiosInstance';
import { uploadFile } from './uploadFile.api';

//Families api
export const fetchRayons = async (search = '') => {
  const res: any = await axiosInstance
    .get(`/rayon/get-all?label=${search}`)
    .catch((err) => {
      console.log('fetchFamilies', err);
    });
  return res.data;
};

export const fetchAllRayons = async (search = '') => {
  const res: any = await axiosInstance
    .get(`/rayon/get-all-lists?label=${search}`)
    .catch((err) => {
      console.log('fetchFamilies', err);
    });
  return res.data;
};

export const createOneRayon = async (label) => {
  const res = await axiosInstance
    .post('/rayon/create', {
      label,
    })
    .catch((err) => {
      console.log('createOneRayon', err);
    });
  return res;
};

export const updateOneRayon = async (rayonId, label) => {
  const res = await axiosInstance
    .put(`/rayon/${rayonId}`, {
      label,
    })
    .catch((err) => {
      console.log('updateOneRayon', err);
    });
  return res;
};

export const deleteOneRayon = async (id) => {
  const res = await axiosInstance.put(`/rayon/delete/${id}`).catch((err) => {
    console.log('deleteOneRayon', err);
  });
  return res;
};

// interface IIgredient {
//   image: string;
//   label: string;
//   rayonId: string;
//   specialties: [];
//   _id: string;
// }
// interface IRayon {
//   _id: string;
//   createdAt: string;
//   ingredients: IIgredient[];
//   label: string;
// }

export const getOneRayonById = async (rayonId) => {
  const res: any = await axiosInstance
    .get(`/rayon/by-id/${rayonId}`)
    .catch((err) => {
      console.log('getOneRayonById', err);
    });
  return res;
};

//rayon products

export const createRayonOneIngredient = async (rayonId, data) => {
  let image = '';
  if (data?.image) {
    image = await uploadFile(data?.image, 'picture');
  }

  const res = await axiosInstance
    .post(`/rayon/add-ingredient/${rayonId}`, {
      ...data,
      image,
      rayonId,
    })
    .catch((err) => {
      console.log('createOneIngredient', err);
    });
  return res;
};

export const updateRayonOneIngredient = async (ingredientId, rayonId, data) => {
  let image = data?.image;
  if (data.image?.name) {
    image = await uploadFile(data.image, 'picture');
  }

  const payload = { ...data, image };

  const res = await axiosInstance
    .put(`/rayon/update-ingredient/${ingredientId}/${rayonId}`, payload)
    .catch((err) => {
      console.log('updateOneIngredient', err);
    });
  return res;
};

export const deleteRayonOneIngredient = async (id) => {
  const res = await axiosInstance
    .put(`/rayon/delete-ingredient/${id}`)
    .catch((err) => {
      console.log('deleteOneIngredient', err);
    });
  return res;
};

// rayon products ingredients

//rayon products

export const createRayonOneIngredientSpecialty = async (rayonId, data) => {
  let image = '';
  if (data.image) {
    image = await uploadFile(data.image, 'picture');
  }

  const res = await axiosInstance
    .post(`/rayon/add-ingredient-specialty/${rayonId}`, {
      ...data,
      image,
    })
    .catch((err) => {
      console.log('createOneIngredientSpecialty', err);
    });
  return res;
};

export const updateRayonOneIngredientSpecialty = async (
  specialId,
  ingredientId,
  rayonId,
  data
) => {
  let image = data?.image;
  if (data.image?.name) {
    image = await uploadFile(data.image, 'picture');
  }

  const res = await axiosInstance
    .put(
      `/rayon/update-ingredient-specialty/${specialId}/${ingredientId}/${rayonId}`,
      { ...data, image, specialId }
    )
    .catch((err) => {
      console.log('updateOneIngredientSpecialty', err);
    });
  return res;
};

export const deleteRayonOneIngredientSpecialty = async (id) => {
  const res = await axiosInstance
    .delete(`/rayon/delete-ingredient-specialty/${id}`)
    .catch((err) => {
      console.log('deleteOneIngredientSpecialty', err);
    });
  return res;
};
