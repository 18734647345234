import { axiosInstance } from '../assets/utils/axiosInstance';

export const userAuth = async (form) => {
  const res = await axiosInstance
    .post('/user/auth', {
      email: form.email.toLowerCase(),
      password: form.password,
    })
    .catch((err) => {
      console.log('userAuth', err);
    });
  return res;
};

export const resetPassword = async (userId, password) => {
  const res = await axiosInstance
    .put(`/user/password/${userId}`, { password })
    .catch((err) => {
      console.log('resetPassword', err);
    });
  return res;
};
export const sendEmailResetPassword = async (email) => {
  const res = await axiosInstance
    .post(`/user/send-email-reset-password`, {
      email,
    })
    .catch((err) => {
      console.log('sendEmailResetPassword', err);
    });
  return res;
};
