import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { fetchCustomerById, fetchCustomers } from '../../../api/customers.api';
import { CloseIcon } from '../../../assets/svgs/CloseIcon';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { Toast } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  currentSubscriptionIndexAction,
  customerDetailsAction,
} from '../../../redux/customer';
import { resetFiltersAction } from '../../../redux/filters';
import {
  initImagesFiltersAction,
  resetImageFormAction,
} from '../../../redux/images';
import { setFilteredByCustomerUnivers } from '../../../redux/picture';
import Loader from '../Loader';

const CustomerInfo = React.memo(({ customerData, handleResetCustomer }) => {
  return (
    <>
      <input
        disabled
        type='text'
        value={customerData?.informations?.restaurant_name}
      />
      <CloseIcon
        margin={
          customerData?.logo_card?.avatar?.length > 0
            ? '0 auto 0 1rem'
            : '0 1rem 0 auto'
        }
        onClick={handleResetCustomer}
      />
      {customerData?.logo_card?.avatar?.length > 0 && (
        <img
          className='customer-avatar'
          src={helperImageRoute(
            customerData?.logo_card?.avatar,
            'customer-avatar'
          )}
          alt='customer logo'
          loading='lazy'
          width={55}
        />
      )}
    </>
  );
});

export const FilterCustomer = () => {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector((s) => s.app);
  const { customer: customerData } = useAppSelector((s) => s.customer);

  const [customer, setCustomer] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const key = useMemo(
    () => (search ? ['customers', search] : ['customers']),
    [search]
  );
  const { data: customers } = useQuery(key, () => fetchCustomers(search), {
    enabled: Boolean(search),
  });

  const filteredByCustomerId = async () => {
    if (customer) {
      setLoading(true);

      try {
        const res = await fetchCustomerById(customer?._id);

        if (res?.customer) {
          dispatch(customerDetailsAction(res?.customer));
          dispatch(initImagesFiltersAction(res?.customer.univers));
          dispatch(
            currentSubscriptionIndexAction(
              (res?.customer.subscriptions.length || 1) - 1
            )
          );
          dispatch(setFilteredByCustomerUnivers(true));
        }
        setLoading(false);
      } catch (err) {
        Toast('error', 'Client introuvable !');
      }
    }
  };

  const handleSelectCustomer = (c) => {
    setCustomer(c);
    setSearch('');
  };

  const handleResetCustomer = () => {
    dispatch(customerDetailsAction(null));
    dispatch(resetFiltersAction());
    dispatch(resetImageFormAction());
    dispatch(setFilteredByCustomerUnivers(false));
  };

  return (
    <div className='imagesLayout--header-filter' style={{ margin: '0 1rem' }}>
      {customerData && (
        <CustomerInfo
          customerData={customerData}
          handleResetCustomer={handleResetCustomer}
        />
      )}
      {!customerData && (
        <>
          <div className='imagesLayout--header-filterBlock'>
            <input
              type='text'
              value={
                customer ? customer?.informations?.restaurant_name : search
              }
              disabled={role === 'PUNCHLINER'}
              onChange={(e) => setSearch(e.target.value)}
              placeholder='Client'
            />
            {customer && (
              <CloseIcon
                margin={'0 0 0 auto'}
                onClick={() => setCustomer(null)}
              />
            )}

            {!customer && search?.length >= 3 && (
              <div className='imagesLayout--header-filterBlock-list'>
                {customers?.map((c) => {
                  return (
                    <p onClick={() => handleSelectCustomer(c)} key={c?._id}>
                      {c?.informations?.restaurant_name +
                        (c?.informations?.restaurant_city
                          ? `-  ${c?.informations?.restaurant_city}`
                          : '')}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
          <button
            disabled={role === 'PUNCHLINER' || loading}
            onClick={filteredByCustomerId}
            style={{
              position: 'relative',
            }}
          >
            {loading ? <Loader /> : 'Filtrer'}
          </button>
        </>
      )}
    </div>
  );
};
