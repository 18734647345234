import { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchStyles } from '../../../api/styles.api';
import { colors } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { ButtonAdd } from '../../../components/atoms/Buttons';
import { Collapse } from '../../../components/atoms/Collapse';
import { AccordionImg } from '../../../components/organisms/Images/AccordionImg';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { stylesModalAction } from '../../../redux/modals';

export const CollapseItemImgStyles = () => {
  //
  const dispatch = useAppDispatch();
  const role = useAppSelector((s) => s.app.role);

  const { data } = useQuery('styles', () => fetchStyles());

  const [open, setOpen] = useState(null);

  const toggleOpen = (index) => {
    if (index === open) return setOpen(null);
    setOpen(index);
  };

  const onClickAddNew = () => {
    dispatch(stylesModalAction(true));
  };

  const disabled =
    role === 'COMMERCIAL' || role === 'PUNCHLINER' || role === 'PROGRAMMEUR';

  return (
    <Collapse
      title={'C’EST QUOI SON/SES STYLE(S)'}
      border
      margin='0 0 1rem'
      bg='white'
    >
      {copyObj(data)
        ?.sort((a, b) => sortedList(a, b, a.label ? 'label' : 'name'))
        ?.map((el, index) => {
          return (
            <AccordionImg
              key={index}
              index={index}
              open={open}
              onOpen={() => toggleOpen(index)}
              disabled={disabled}
              data={el}
              parentKey={'styles'}
            />
          );
        })}
      <ButtonAdd
        bg={colors.dark}
        label={'Ajouter un style'}
        height={55}
        width='48%'
        disabled={disabled}
        onClick={onClickAddNew}
      />
    </Collapse>
  );
};
