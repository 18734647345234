import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../assets/constants';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { questionSelectedAction } from '../../../redux/familyQuiz';
import { attributionModeAction } from '../../../redux/imageAttributions';
import { Button } from '../../atoms/Buttons';
import { AnswersList } from './AnswersList';
import { CompositionsListCenter } from './CompositionsList';
import { FamilySelectBlock } from './FamilySelectBlock';
import { ProductsList } from './ProductsList';
import { useQuiz } from '../../../hooks/useQuiz';

export const CenterBlock: React.FC = () => {
  //
  const navigate = useNavigate();

  const role = useAppSelector((s) => s.app.role);
  const {
    familySelectedImgAttr,
    imgProductId,
    imageAttributionsTab,
    attributionMode,
  } = useAppSelector((state) => state.imageAttributions);

  const imageStore = useAppSelector((s) => s.images);
  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);

  const { onPreviousQuestion, onNextQuestion } = useQuiz();

  const familiesSelected =
    imageAttributionsTab === 'product-main'
      ? imageStore?.infosMainProducts
      : imageStore?.infosSecondaryProducts || [];

  if (familiesSelected?.length === 0) {
    navigate('/add-image');
    return;
  }

  return (
    <div className='imageAttributions--centerBlock'>
      {!!!familySelectedImgAttr && <FamilySelectBlock />}

      {(role === 'ADMIN' || role === 'SUPER-ADMIN') &&
        !!familySelectedImgAttr && <Tabs />}
      {!!familySelectedImgAttr &&
        !!!imgProductId &&
        attributionMode === 'product' && <ProductsList />}

      {attributionMode === 'quiz' && !!questionSelected && <AnswersList />}

      {attributionMode === 'product' && !!imgProductId && (
        <CompositionsListCenter />
      )}

      {attributionMode === 'quiz' && !!questionSelected && (
        <div
          className='w-100 flex-row-center-between'
          style={{ marginTop: 'auto' }}
        >
          <Button label='Question precedente' onClick={onPreviousQuestion} />
          <Button
            label='Question suivante'
            variant='yellow'
            onClick={onNextQuestion}
          />
        </div>
      )}
    </div>
  );
};

const Tabs: React.FC = () => {
  const dispatch = useAppDispatch();
  const { attributionMode, familySelectedImgAttr } = useAppSelector(
    (state) => state.imageAttributions
  );
  return (
    <div className='imageAttributions--tabs'>
      <button
        className='imageAttributions--tab'
        onClick={() => {
          dispatch(attributionModeAction('quiz'));
          dispatch(
            questionSelectedAction(
              familySelectedImgAttr?.quiz?.questionsList[0]
            )
          );
        }}
        style={{
          borderColor: attributionMode === 'quiz' ? colors.yellow : colors.grey,
        }}
      >
        Faire le questionnaire
      </button>
      <button
        className='imageAttributions--tab'
        onClick={() => dispatch(attributionModeAction('product'))}
        style={{
          borderColor:
            attributionMode === 'product' ? colors.yellow : colors.grey,
        }}
      >
        Je reconnais le produit
      </button>
    </div>
  );
};
