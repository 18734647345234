import { useQuery } from 'react-query';
import { fetchMoods } from '../../../api/moods.api';
import { colors } from '../../../assets/constants';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { filtersMoodsAction } from '../../../redux/filters';
import { memo } from 'react';

export const MoodsFilters = memo(() => {
  const dispatch = useAppDispatch();

  const moodsSelected = useAppSelector((s) => s.filters.moods);
  const { data: moods } = useQuery('moods', () => fetchMoods(''));

  const handleSelectMood = (moodLabel) => {
    dispatch(filtersMoodsAction(moodLabel));
  };

  return (
    <div className='imagesLayout--sidebar-moods'>
      {moods?.map((mood, index) => (
        <button
          className='imagesLayout--sidebar-mood'
          key={index}
          onClick={(e) => {
            handleSelectMood(mood.label);
            e.stopPropagation();
          }}
        >
          {mood?.image && (
            <img
              src={helperImageRoute(mood?.image, 'mood')}
              alt={mood.label}
              width={35}
              style={{
                borderColor: moodsSelected?.includes(mood?.label)
                  ? colors.yellow
                  : 'transparent',
              }}
            />
          )}
          <p>{mood?.label}</p>
        </button>
      ))}
    </div>
  );
});
