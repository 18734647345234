import React, { useEffect } from 'react';
import { colors } from '../../../assets/constants';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  imageAttributionsTabAction,
  resetImgCompositionsSelectedAction,
} from '../../../redux/imageAttributions';
import {
  attrMainCompositionsAction,
  attrSecondaryCompositionsAction,
  imageFormAction,
} from '../../../redux/images';
import { ButtonAdd } from '../../atoms/Buttons';
import { Input } from '../../atoms/Fields';
import { Tabs } from '../../atoms/Tabs';
import { CompositionTrow } from '../CompositionsGestion/CompositionTrow';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';

const tabsData = [
  { label: 'Produit principal', id: 'product-main' },
  { label: 'Produit secondaire', id: 'product-secondary' },
];

export const ImageAttributions = ({
  isClone = false,
  edit = false,
  onAddAttribution,
}) => {
  //
  const dispatch = useAppDispatch();
  const { count, compositionsAttr } = useAttributionsImgComposition();

  const { imageAttributionsTab } = useAppSelector(
    (state) => state.imageAttributions
  );

  const {
    attrMainCompositions,
    attrSecondaryCompositions,
    longName,
    shortName,
  } = useAppSelector((s) => s.images);

  const action =
    imageAttributionsTab === 'product-main'
      ? attrMainCompositionsAction
      : attrSecondaryCompositionsAction;

  const onDelete = (_composition) => {
    const newCompositions = compositionsAttr.filter(
      (_c) => _c?._id !== _composition?._id
    );
    dispatch(action(newCompositions));
  };

  useEffect(() => {
    const compositionsAttrCombine = [
      ...attrMainCompositions,
      ...attrSecondaryCompositions,
    ]
      .map((x) => x.compositionId || x)
      .filter((x) => x);

    const longNameValueFormated = compositionsAttrCombine
      ?.flatMap((comp) =>
        copyObj(comp.ingredients)
          ?.sort((a, b) => sortedList(a, b, 'name'))
          ?.map((ingredient, index) => ingredient?.specialityId?.label)
      )
      ?.join(' - ');

    const shortNameValueFormated = copyObj(compositionsAttrCombine)
      ?.map((comp) => comp.short_name)
      ?.join(' - ');
    dispatch(
      imageFormAction({
        key: 'longName',
        value: longNameValueFormated,
      })
    );
    dispatch(
      imageFormAction({
        key: 'shortName',
        value: shortNameValueFormated,
      })
    );
  }, [attrMainCompositions, attrSecondaryCompositions, dispatch]);

  const handleTab = (tabId) => {
    dispatch(imageAttributionsTabAction(tabId));
    dispatch(resetImgCompositionsSelectedAction());
  };

  return (
    <>
      <div className='image--attributionNames'>
        <Input
          label='Nom Long'
          value={longName}
          onChange={(e) =>
            dispatch(
              imageFormAction({
                key: 'longName',
                value: e.target.value,
              })
            )
          }
          disabled
        />
        <Input
          label='Nom Court'
          value={shortName}
          onChange={(e) =>
            dispatch(
              imageFormAction({
                key: 'shortName',
                value: e.target.value,
              })
            )
          }
          disabled
        />
      </div>

      <div className='image--attributions'>
        <Tabs
          buttons={tabsData}
          tabs={imageAttributionsTab}
          setTabs={(tabId) => handleTab(tabId)}
        />
        <div
          className='w-100 flex-row-center-between'
          style={{ padding: '1rem 2rem' }}
        >
          <ButtonAdd
            label='Ajouter une attribution'
            bg={colors.dark}
            color={colors.yellow}
            onClick={() => onAddAttribution(edit, isClone)}
          />
          <div className='image--attributions-count'>
            {`${count} restante${count > 1 ? 's' : ''}`}{' '}
          </div>
        </div>
        <div className='image--attributions-list'>
          {compositionsAttr.length > 0 ? (
            compositionsAttr.map((comp, i) => (
              <CompositionTrow
                composition={comp}
                key={i}
                withDelete
                onDelete={() => onDelete(comp)}
              />
            ))
          ) : (
            <div className='image--attributions-list-empty'>
              Aucune attribution
            </div>
          )}
        </div>
      </div>
    </>
  );
};
