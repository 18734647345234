import { useState } from 'react';
import { compose } from 'recompose';
import { useNavigate } from 'react-router-dom';
import { DotsCircleIcon } from '../../../assets/svgs/DotsCircleIcon';
import { ActionModal } from '../../../components/atoms/ActionModal';
import { TableHeader } from '../../../components/atoms/TableHeader';
import { withNavigation } from '../../../hoc/withNavigation';
import { deleteModalAction, rayonsModalAction } from '../../../redux/modals';
import { Loader } from '../../../components/atoms/Loader';
import { HeaderWithSearch } from '../../../components/organisms/Header';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { sortedList } from '../../../assets/utils/sortedList';
import { toast_loader } from '../../../assets/utils/toast';
import { deleteOneRayon, fetchAllRayons } from '../../../api/rayons.api';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
} from '../../../redux/filtersSelected';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { copyObj } from '../../../assets/utils/copyObj';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';

const SuperMarket = ({ user }) => {
  const [search, setSearch] = useState('');
  const dispatch = useAppDispatch();
  const [showActionModal, setShowActionModal] = useState(null);

  const { data: rayons, isLoading: loadingRayons } = useQuery('rayons', () =>
    fetchAllRayons('')
  );

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  const handleAddFamily = () => {
    dispatch(resetFilteredSelectedItemAction('rayon'));
    dispatch(rayonsModalAction(true));
  };

  return (
    <>
      <DeleteRayonModal />

      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`Rayons (${rayons?.length ?? ''})`}
            onAdd={handleAddFamily}
          />
          <div className='table'>
            {loadingRayons ? (
              <Loader />
            ) : (
              copyObj(rayons)
                ?.sort((a, b) => sortedList(a, b, 'label'))
                .filter((rayon) => rayon.label.toLowerCase().includes(search))
                .map((rayon, index) => (
                  <Trow
                    key={index}
                    rayon={rayon}
                    onOpen={onOpen}
                    showActionModal={showActionModal}
                  />
                ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const DeleteRayonModal = () => {
  const dispatch = useAppDispatch();
  const familySelected = useAppSelector((s) => s.filtersSelected.rayon);

  const { resetFilters } = useResetFilters();

  const handleDeleteFamily = async () => {
    const loader = toast.loading('Veuillez patienter...');
    await deleteOneRayon(familySelected._id);
    toast_loader(loader, 'Rayon supprimé', 'success');
    resetFilters('rayons');
    dispatch(resetFilteredSelectedItemAction('rayon'));
    dispatch(deleteModalAction(false));
  };

  return (
    <DeleteModal
      onDelete={handleDeleteFamily}
      text={`le Rayon "${familySelected?.label}"`}
    />
  );
};

const Trow = ({ rayon, showActionModal, onOpen }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSelectFamily = () => {
    dispatch(
      filteredSelectedItemAction({
        filter: 'rayon',
        item: rayon,
      })
    );
  };

  const onShowModal = () => {
    handleSelectFamily();
    dispatch(rayonsModalAction(true));
  };
  const onShowModalDelete = () => {
    handleSelectFamily();
    dispatch(deleteModalAction(true));
  };
  return (
    <div className='table-trow'>
      <span
        style={{ cursor: 'pointer', flex: 1 }}
        className='table-trow-text'
        onClick={() => navigate(`/rayon-ingredients/${rayon?._id}`)}
      >
        {rayon?.label}
      </span>
      <div className='table-trow-action'>
        {showActionModal === rayon?._id && (
          <ActionModal onEdit={onShowModal} onDelete={onShowModalDelete} />
        )}
        <DotsCircleIcon onClick={() => onOpen(rayon?._id)} />
      </div>
    </div>
  );
};

export const SuperMarketView = compose(
  withNavigation,
  withCheckAuth
)(SuperMarket);
