import { toast } from 'react-toastify';
import { deleteRayonOneIngredient } from '../../../api/rayons.api';
import { toast_loader } from '../../../assets/utils/toast';
import { DeleteModal } from '../../../components/modals/DeleteModal';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { useResetFilters } from '../../../hooks/useResetFilters';
import { resetFilteredSelectedItemAction } from '../../../redux/filtersSelected';
import { deleteModalAction } from '../../../redux/modals';

export const DeleteRayonIngredient = () => {
  const dispatch = useAppDispatch();
  const ingredientSelected = useAppSelector(
    (s) => s.filtersSelected.rayonIngredient
  );
  const { resetFilters } = useResetFilters();

  const handleDeleteProduct = async () => {
    const loader = toast.loading('Veuillez patienter...');
    const { data } = await deleteRayonOneIngredient(ingredientSelected?._id);
    if (data?.status) {
      toast_loader(loader, `${data?.message}`, 'success');
      resetFilters('rayon');
      dispatch(resetFilteredSelectedItemAction('rayonIngredient'));
      dispatch(deleteModalAction(false));
    } else {
      toast_loader(loader, `${data?.message}`, 'error');
    }
  };

  return (
    <DeleteModal
      onDelete={handleDeleteProduct}
      text={`l'ingrédient  "${ingredientSelected?.label ?? ''}"`}
    />
  );
};
