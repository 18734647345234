import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  updateCustomerInfo,
  updateCustomerSubscription,
} from '../api/customers.api';
import { uploadFile } from '../api/uploadFile.api';
import { toast_loader } from '../assets/utils/toast';
import { customerTabsAction, editCustomerInfoAction } from '../redux/customer';
import store from '../redux/storeConfig';
import { useAppDispatch, useAppSelector } from './useReduxHook';
import { useQueryClient } from 'react-query';

export const useCustomer = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const tabs = useAppSelector((s) => s.customer.tabs);
  const queryClient = useQueryClient();
  // const [tabs, setTabs] = useState('informations');

  const handleUpdateCustomer = async () => {
    const loader = toast.loading('Enregistrement en cours...');

    try {
      setLoading(true);

      const state = store.getState();
      const customer = state.customer?.customer;
      const avatar = customer?.logo_card.avatar;
      const logos = customer?.logo_card.logo;
      const cards = customer?.logo_card.card;

      const updatedCustomer = JSON.parse(JSON.stringify(customer));

      if (tabs === 'diary') {
        const currentSubscriptionIndex =
          state.customer.currentSubscriptionIndex;
        const currentSubscription =
          customer.subscriptions[currentSubscriptionIndex];
        const allAttributions = customer?.allAttributions;

        const data = {
          strategies: currentSubscription.strategies,
          diaries: currentSubscription.diaries?.map((diary) => {
            return {
              ...diary,
              picture: diary?.picture?._id || null,
            };
          }),
        };

        await updateCustomerSubscription(currentSubscription._id, data);
        await updateCustomerInfo(customer._id, {
          allAttributions,
        });
      } else {
        const { styles, filters, specialsFilters, moods } = state.images;

        const univers = {
          styles,
          filters,
          families: customer?.univers?.families,
          specialsFilters,
          moods,
        };

        if (avatar?.name) {
          const avatarFileName = await uploadFile(avatar, 'customer-avatar');
          updatedCustomer.logo_card.avatar = avatarFileName;
        }

        if (logos?.some((logo) => logo.name)) {
          for (let [index, logo] of logos.entries()) {
            if (logo.name) {
              const logoFileName = await uploadFile(logo, 'customer-logo');
              updatedCustomer.logo_card.logo[index] = logoFileName;
            }
          }
        }

        if (cards?.some((card) => card.name)) {
          for (let [index, card] of cards.entries()) {
            if (card.name) {
              const cardFileName = await uploadFile(card, 'customer-card');
              updatedCustomer.logo_card.card[index] = cardFileName;
            }
          }
        }

        const newCustomer = {
          ...updatedCustomer,
          informations: {
            ...updatedCustomer?.informations,
            restaurant_name:
              updatedCustomer?.informations?.restaurant_name?.trim(),
            restaurant_city:
              updatedCustomer?.informations?.restaurant_city?.trim(),
            restaurant_country:
              updatedCustomer?.informations?.restaurant_country?.trim(),
          },
        };

        await updateCustomerInfo(updatedCustomer._id, {
          ...newCustomer,
          univers,
        });

        dispatch(
          editCustomerInfoAction({
            tab: 'univers',
            value: univers,
          })
        );
      }
      queryClient.invalidateQueries('customers');
      queryClient.invalidateQueries('customer');
      toast_loader(loader, 'Enregistrement effectué avec succès ✅', 'success');
    } catch (error) {
      toast_loader(loader, 'Une erreur est survenue !! ❌', 'error');
    } finally {
      setLoading(false);
    }
  };

  const setTabs = (tab) => {
    dispatch(customerTabsAction(tab));
  };

  return {
    loading,
    tabs,
    setTabs,
    handleUpdateCustomer,
  };
};
