import { useQueryClient } from 'react-query';

export const useResetFilters = () => {
  const clientQuery = useQueryClient();

  const resetFilters = (filter: string | string[]) => {
    if (Array.isArray(filter)) {
      filter.forEach((f) => clientQuery.invalidateQueries(f));
      return;
    }
    clientQuery.invalidateQueries(filter);
  };

  return {
    resetFilters,
  };
};
