import React from 'react';
import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { IFamily } from '../Images/ImageProducts';
import { FamilyCard } from './FamilyCard';
import { ZoomPicture } from '../../atoms/ZoomPicture';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';
// import { useAttributionsImgComposition } from '../../../hooks/useCompositions';

export const LeftBlock: React.FC = () => {
  //
  const imageAttributions = useAppSelector((state) => state.imageAttributions);
  const imageFormData = useAppSelector((state) => state.images);
  const { compositionsAttr } = useAttributionsImgComposition();

  //formated families selected by tab
  const familiesSelected =
    imageAttributions?.imageAttributionsTab === 'product-main'
      ? imageFormData?.infosMainProducts
      : imageFormData?.infosSecondaryProducts;

  return (
    <div className='imageAttributions--leftBlock'>
      <div className='imageAttributions--leftBlock-imgBlock'>
        {!!imageFormData?.image && (
          <ZoomPicture
            picture={
              imageFormData?.image?.name
                ? URL.createObjectURL(imageFormData?.image)
                : helperImageRoute(imageFormData?.image, 'picture', false)
            }
          />
        )}
      </div>
      <p className='imageAttributions--title' style={{ margin: 0 }}>
        {imageAttributions?.imageAttributionsTab === 'product-main'
          ? 'Produit principal'
          : 'Produit sécondaire'}
      </p>
      <div className='imageAttributions--leftBlock-cards'>
        {!!imageAttributions?.families &&
          imageAttributions?.families.map((family: IFamily, index: number) => {
            const findFamily = familiesSelected?.find(
              (_f) => _f.familyId === family?._id
            );
            const familyComps = compositionsAttr?.filter(
              (el) => el.familyId === findFamily?.familyId
            );

            const formatedFamily = {
              ...family,
              ...{
                ...findFamily,
                checkedNum: findFamily?.checkedNum || familyComps?.length,
              },
            };


            if (findFamily) {
              return (
                <FamilyCard
                  key={index}
                  family={formatedFamily}
                  flex={0.5}
                  selected={
                    imageAttributions?.familySelectedImgAttr?.familyId ===
                    family?._id
                  }
                />
              );
            }
            return null;
          })}
      </div>
    </div>
  );
};
