import {useState} from 'react';
import {TableHeader} from '../../../components/atoms/TableHeader';
import {withNavigation} from '../../../hoc/withNavigation';
import {subSpecialsFiltersModalAction} from '../../../redux/modals';
import {Loader} from '../../../components/atoms/Loader';
import {HeaderWithSearch} from '../../../components/organisms/Header';
import {compose} from 'recompose';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {TableSimpleRow} from '../../../components/atoms/TableSimpleRow';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {fetchOneSpecialFilter} from '../../../api/specialsFilters.api';
import {useEffect} from 'react';
import {filteredSelectedItemAction} from '../../../redux/filtersSelected';
import {DeleteSubSpecialFilter} from './DeleteSubSpecialFilter';
import {sortedList} from '../../../assets/utils/sortedList';
import {copyObj} from '../../../assets/utils/copyObj';
import {useAppDispatch} from '../../../hooks/useReduxHook';

const SubSpecialsFiltersView = ({user}) => {
  //
  const dispatch = useAppDispatch();

  const {specialFilterId} = useParams();

  const [showActionModal, setShowActionModal] = useState(null);
  const [search, setSearch] = useState('');

  const {data, isLoading} = useQuery(['specialFilter', specialFilterId], () =>
    fetchOneSpecialFilter(specialFilterId)
  );

  const specialFilter = data?.specialFilter;

  const onOpen = (index) => {
    if (showActionModal === index) return setShowActionModal(null);
    setShowActionModal(index);
  };

  useEffect(() => {
    if (data) {
      dispatch(
        filteredSelectedItemAction({
          filter: 'specialFilter',
          item: data?.specialFilter,
        })
      );
    }
  }, [dispatch, data]);

  if (isLoading) return <Loader />;

  const subSpecialFiltersFiltered =
    specialFilter &&
    copyObj(specialFilter.subSpecialFilters)
      ?.sort((a, b) => sortedList(a, b, 'label'))
      .filter((specialFilter) =>
        specialFilter.label?.toLowerCase().includes(search.toLowerCase())
      );

  return (
    <>
      <DeleteSubSpecialFilter />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            goBack
            title={`Filtre spécial '${specialFilter?.label ?? ''}'`}
            onAdd={() => {
              dispatch(subSpecialsFiltersModalAction(true));
            }}
          />
          <div className='table'>
            {subSpecialFiltersFiltered?.map((subSpecialFilter) => (
              <TableSimpleRow
                onOpen={onOpen}
                key={subSpecialFilter._id}
                item={subSpecialFilter}
                filter='subSpecialFilter'
                showActionModal={showActionModal}
                onOpenModal={() =>
                  dispatch(subSpecialsFiltersModalAction(true))
                }
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withNavigation, withCheckAuth)(SubSpecialsFiltersView);
