import {useState} from 'react';
import {TableHeader} from '../../../components/atoms/TableHeader';
import {withNavigation} from '../../../hoc/withNavigation';
import {specialsFiltresModalAction} from '../../../redux/modals';
import {Loader} from '../../../components/atoms/Loader';
import {HeaderWithSearch} from '../../../components/organisms/Header';
import {compose} from 'recompose';
import {withCheckAuth} from '../../../hoc/withCheckAuth';
import {sortedList} from '../../../assets/utils/sortedList';
import {fetchSpecialFilters} from '../../../api/specialsFilters.api';
import {useQuery} from 'react-query';
import {DeleteSpecialFilter} from './DeleteSpecialFilter';
import {TableRow} from '../../../components/atoms/TableRow';
import {resetFilteredSelectedItemAction} from '../../../redux/filtersSelected';
import {copyObj} from '../../../assets/utils/copyObj';
import {useAppDispatch} from '../../../hooks/useReduxHook';

const SpecialsFiltersView = ({user}) => {
  //
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const [showActionModal, setShowActionModal] = useState(null);

  const {data: specialsFilters, isLoading} = useQuery('specialsFilters', () =>
    fetchSpecialFilters('')
  );

  const onOpenActionModal = (id: string) => {
    setShowActionModal(id);
  };

  const handleOpenFiltersModal = () => {
    dispatch(resetFilteredSelectedItemAction('specialFilter'));
    dispatch(specialsFiltresModalAction(true));
  };

  const specialsFiltersFiltered = copyObj(specialsFilters)
    ?.sort((a, b) => sortedList(a, b, 'label'))
    .filter((specialFilter) =>
      specialFilter.label.toLowerCase().includes(search.toLowerCase())
    );

  return (
    <>
      <DeleteSpecialFilter />
      <div className='pages-layout'>
        <HeaderWithSearch user={user} search={search} setSearch={setSearch} />
        <div className='pages-layout--container'>
          <TableHeader
            title={`filtres spéciaux (${
              specialsFiltersFiltered?.length ?? ''
            })`}
            onAdd={handleOpenFiltersModal}
          />
          <div className='table'>
            {isLoading ? (
              <Loader />
            ) : (
              specialsFiltersFiltered?.map((specialFilter, index) => (
                <TableRow
                  key={index}
                  item={specialFilter}
                  filter='specialFilter'
                  route='sub-specials-filters'
                  onOpenActionModal={onOpenActionModal}
                  showActionModal={showActionModal}
                  onOpenModal={() => dispatch(specialsFiltresModalAction(true))}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default compose(withNavigation, withCheckAuth)(SpecialsFiltersView);
