import {useState} from 'react';
import {useQuery} from 'react-query';
import {fetchSpecialFilters} from '../../../api/specialsFilters.api';
import {colors} from '../../../assets/constants';
import {copyObj} from '../../../assets/utils/copyObj';
import {sortedList} from '../../../assets/utils/sortedList';
import {ButtonAdd} from '../../../components/atoms/Buttons';
import {Collapse} from '../../../components/atoms/Collapse';
import {AccordionImg} from '../../../components/organisms/Images/AccordionImg';
import {useAppDispatch, useAppSelector} from '../../../hooks/useReduxHook';
import {
  filteredSelectedItemAction,
  resetFilteredSelectedItemAction,
} from '../../../redux/filtersSelected';
import {
  specialsFiltresModalAction,
  subSpecialsFiltersModalAction,
} from '../../../redux/modals';

export const CollapseItemImgSpecialsFilters = () => {
  //
  const dispatch = useAppDispatch();
  const role = useAppSelector((s) => s.app.role);
  const {data} = useQuery('specialsFilters', () => fetchSpecialFilters(''));

  const [open, setOpen] = useState(null);

  const toggleOpen = (index) => {
    if (index === open) return setOpen(null);
    setOpen(index);
  };

  const onClickAddNew = () => {
    dispatch(resetFilteredSelectedItemAction('specialFilter'));
    dispatch(specialsFiltresModalAction(true));
  };

  const onClickAddSubItem = (item) => {
    dispatch(resetFilteredSelectedItemAction('subSpecialFilter'));
    dispatch(
      filteredSelectedItemAction({
        filter: 'specialFilter',
        item,
      })
    );
    dispatch(subSpecialsFiltersModalAction(true));
  };

  const disabled =
    role === 'COMMERCIAL' || role === 'PUNCHLINER' || role === 'PROGRAMMEUR';

  return (
    <Collapse title={'FILTRES SPECIAUX'} border margin='0 0 1rem' bg='white'>
      {copyObj(data)
        ?.sort((a, b) => sortedList(a, b, a.label ? 'label' : 'name'))
        ?.map((item, index) => {
          return (
            <AccordionImg
              key={index}
              index={index}
              open={open}
              onOpen={() => toggleOpen(index)}
              disabled={disabled}
              data={item}
              onClickAddSubItem={() => onClickAddSubItem(item)}
              parentKey={'specialsFilters'}
              itemsKey={'subSpecialFilters'}
              addSubItemBtnLabel={'Ajouter un sous filtre'}
            />
          );
        })}
      <ButtonAdd
        bg={colors.dark}
        label={'Ajouter un filtre spécial'}
        height={55}
        width='48%'
        disabled={disabled}
        onClick={onClickAddNew}
      />
    </Collapse>
  );
};
