import { ArchiveIcon } from '../../../assets/svgs/ArchiveIcon';
import { EditIcon } from '../../../assets/svgs/EditIcon';
import { TrashIcon } from '../../../assets/svgs/TrashIcon';

export const ActionModal = ({
  onEdit,
  onDelete,
  onToggleArchive = () => {},
  showArchiveBtn = false,
  disabledDelete = false,
  isArchived = false,
}) => {
  return (
    <div className='actionModal scale-up-center'>
      <button onClick={onEdit}>
        <EditIcon margin='0 .5rem 0 0' /> Modifier
      </button>
      {showArchiveBtn && (
        <button onClick={onToggleArchive}>
          <ArchiveIcon margin='0 .5rem 0 0' />{' '}
          {isArchived ? 'Désarchiver' : 'Archiver'}
        </button>
      )}
      <button onClick={onDelete} disabled={disabledDelete}>
        <TrashIcon margin='0 .5rem 0 0' /> Supprimer
      </button>
    </div>
  );
};
