import { useMemo, useState } from 'react';
import { colors } from '../../../assets/constants';
import { ArrowSmallBottom } from '../../../assets/svgs/ArrowSmallBottom';
import { ArrowSmallTop } from '../../../assets/svgs/ArrowSmallTop';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { useAppDispatch } from '../../../hooks/useReduxHook';
import {
  filtersCompositionSelectedAction,
  filtersParentItemSelectedAction,
} from '../../../redux/filters';
import { Counters } from '../../atoms/Counter';
import { CompositionCard } from '../CompositionsGestion/CompositionCard';

export const ItemButton = ({
  itemsSelected,
  item,
  parent,
  label,
  parentKey,
  itemsKey,
  product,
  composition,
  active,
  onClick,
  counter = true,
}: {
  itemsSelected: any,
  item: any,
  parent: any,
  parentKey?: any,
  itemsKey?: any,
  label?: string,
  product?: any,
  composition?: boolean,
  active?: boolean,
  onClick?: () => void,
  counter?: boolean,
}) => {
  const dispatch = useAppDispatch();
  const itemActive = itemsSelected?.find((el) => el._id === item._id) || active;
  const [open, setOpen] = useState(false);

  const isSelected = useMemo(() => {
    if (item?.compositions?.length > 0 || itemActive?.selected) {
      return (
        itemActive?.compositions?.length === item?.compositions?.length ||
        itemActive?.selected
      );
    }
    return null;
  }, [item, itemActive]);

  const handleSelect = () => {
    dispatch(
      filtersParentItemSelectedAction({
        key: parentKey,
        itemsKey,
        value: itemsKey === 'products' ? { ...item, compositions: [] } : item,
        parent,
        selected: isSelected,
        addSelected: true,
        itemsLength: item.compositions?.length || 0,
      })
    );
  };

  const handleSelectComposition = (comp) => {
    dispatch(
      filtersCompositionSelectedAction({
        composition: comp,
        parent,
        addSelected: true,
        product: item,
      })
    );
  };

  if (item?.compositions?.length) {
    return (
      <div
        className='accordionFilters'
        style={{
          width: '100%',
          margin: '0 0',
        }}
      >
        <div className='accordionFilters--btn'>
          <span
            className='accordionFilters--check'
            style={{
              backgroundColor:
                itemActive?.compositions?.length === item.compositions.length ||
                itemActive?.selected
                  ? colors.yellow
                  : 'transparent',
            }}
            onClick={handleSelect}
          />
          <button
            className='accordionFilters--title'
            onClick={() => setOpen(!open)}
          >
            {composition ? item?.short_name + ' ' : item?.name + ' '}
            <Counters
              parentId={parentKey}
              childrenId={itemsKey}
              item={item}
              isSubChild
            />
            {itemActive?.compositions?.length > 0 && (
              <span className='accordion--badge' />
            )}
            {item.compositions.length > 0 && open ? (
              <ArrowSmallTop />
            ) : (
              item.compositions.length > 0 && !open && <ArrowSmallBottom />
            )}
          </button>
        </div>

        {!!item.compositions && open && (
          <div
            className={`accordionFilters--content`}
            style={{
              maxHeight: !!item.compositions && open ? 9999 : 0,
              transition:
                !!item.compositions && open
                  ? 'all 0.5s cubic-bezier(1, 0, 1, 0) '
                  : 'all 0.5s cubic-bezier(0, 1, 0, 1)',
            }}
          >
            {item.compositions &&
              copyObj(item.compositions)
                ?.sort((a, b) =>
                  sortedList(
                    a,
                    b,
                    a?.label ? 'label' : a?.name ? 'name' : 'short_name'
                  )
                )
                ?.map((comp, index) => {
                  const findComp = itemActive?.compositions?.find(
                    (el) => el._id === comp._id
                  );
                  return (
                    <CompositionCard
                      composition={comp}
                      key={index}
                      backgroundColor={colors.white}
                      onSelectComposition={() => handleSelectComposition(comp)}
                      selected={!!findComp}
                      isFilter
                      counter
                    />
                  );
                })}
            <button
              className='accordionFilters--close'
              onClick={() => setOpen(null)}
            >
              <ArrowSmallTop margin='0' color={colors.yellow} />
              <span>Fermer {item?.name}</span>
              <ArrowSmallTop margin='0' color={colors.yellow} />
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <button
      style={{
        borderColor: itemActive ? colors.yellow : colors.white,
        padding: '.5rem 1rem',
      }}
      className='customer--universpanel-btnRadio'
      onClick={onClick || handleSelect}
    >
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {item?.name || item?.label || label}

        {counter && (
          <Counters
            parentId={parentKey}
            childrenId={itemsKey}
            subChildrenId={composition ? 'compositions' : null}
            item={item}
            isSubChild
          />
        )}
      </span>
      <span
        style={{
          backgroundColor: itemActive ? colors.yellow : 'transparent',
        }}
      />
    </button>
  );
};
