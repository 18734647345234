import { PencilBlackIcon } from '../../../assets/svgs/PencilBlackIcon';
import { helperFormatSubscriptionDate } from '../../../assets/utils/helperFormatDate';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  currentSubscriptionIndexAction,
  updateSubscriptionStatusAction,
} from '../../../redux/customer';
import { packageCustomerModalAction } from '../../../redux/modals';

export const HeaderSubscription = () => {
  const subscriptions =
    useAppSelector((state) => state.customer.customer?.subscriptions) || [];
  const role = useAppSelector((state) => state.app.role);

  const currentSubscriptionIndex = useAppSelector(
    (state) => state.customer.currentSubscriptionIndex
  );

  const dispatch = useAppDispatch();

  const handleClickNewSubscription = () => {
    dispatch(updateSubscriptionStatusAction(false));
    dispatch(packageCustomerModalAction(true));
  };

  const handleClickUpdateSubscription = () => {
    dispatch(updateSubscriptionStatusAction(true));
    dispatch(packageCustomerModalAction(true));
  };

  const handleChangeCurrentSubscription = (type) => {
    if (type === 'next') {
      dispatch(currentSubscriptionIndexAction(currentSubscriptionIndex + 1));
    } else {
      dispatch(currentSubscriptionIndexAction(currentSubscriptionIndex - 1));
    }
  };

  const buttonStyle = {
    backgroundColor: 'black',
    color: 'white',
    border: 'none',
    padding: '0 1.2rem',
    height: '45px',
    borderRadius: '5px',
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        // width: '100%',
      }}
    >
      {currentSubscriptionIndex > 0 && subscriptions.length > 1 && (
        <button
          style={buttonStyle}
          onClick={() => handleChangeCurrentSubscription('prev')}
        >
          &lt;
        </button>
      )}
      <div className='customer--diarypanel-header-strategyInfos'>
        <div className='customer--diarypanel-header-formulaTexts'>
          <p>
            Formule de{' '}
            {subscriptions[currentSubscriptionIndex]?.formula === 6
              ? 'SIX'
              : 'DOUZE'}
          </p>
          <p>
            {!!subscriptions[currentSubscriptionIndex] &&
              helperFormatSubscriptionDate(
                subscriptions[currentSubscriptionIndex]
              )}
          </p>
        </div>
        {(role === 'ADMIN' || role === 'SUPER-ADMIN') && (
          <PencilBlackIcon onClick={handleClickUpdateSubscription} />
        )}
      </div>
      {currentSubscriptionIndex < subscriptions.length - 1 && (
        <button
          style={buttonStyle}
          onClick={() => handleChangeCurrentSubscription('next')}
        >
          &gt;
        </button>
      )}
      {(role === 'ADMIN' || role === 'SUPER-ADMIN') && (
        <button
          style={{
            ...buttonStyle,
            backgroundColor: '#D44F4D',
            padding: '0 1rem',
            cursor: 'pointer',
          }}
          onClick={handleClickNewSubscription}
        >
          Nouvel abo
        </button>
      )}
    </div>
  );
};
