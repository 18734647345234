import { useState } from 'react';
import { CardPayIcon } from '../../../assets/svgs/CardPayIcon';
import { PencilIcon } from '../../../assets/svgs/PencilIcon';
import { UniversIcon } from '../../../assets/svgs/UniversIcon';
import { UserIcon } from '../../../assets/svgs/UserIcon';
import { Button } from '../../../components/atoms/Buttons';
import { InformationsPanel } from '../../../components/organisms/Customer/InformationsPanel';
import { LogoCardPanel } from '../../../components/organisms/Customer/LogoCardPanel';
import { SignaturePanel } from '../../../components/organisms/Customer/SignaturePanel';
import { UniversPanel } from '../../../components/organisms/Customer/UniversPanel';
import { HeaderWithTabs } from '../../../components/organisms/Header';
import { withNavigation } from '../../../hoc/withNavigation';
import { compose } from 'recompose';
import { withCheckAuth } from '../../../hoc/withCheckAuth';
import { UsersIcon } from '../../../assets/svgs/UsersIcon';
import { DiaryComp } from '../../../components/organisms/Customer/DiaryComp';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components/atoms/Loader';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import { universTabsAction } from '../../../redux/customer';

import { useCustomer } from '../../../hooks/useCustomer';
import { useCustomerDetails } from '../../../hooks/useCustomerDetails';

const tabsData = [
  { label: 'Informations', link: 'informations', icon: UserIcon },
  { label: 'Logo/Carte', link: 'logo/card', icon: CardPayIcon },
  { label: 'Agenda', link: 'diary', icon: UsersIcon },
  // { label: 'Hashtag', link: 'hashtag', icon: HashtagIcon },
  { label: 'Univers', link: 'univers', icon: UniversIcon },
  { label: 'Signature', link: 'signature', icon: PencilIcon },
];

const AddCustomerView = () => {
  //

  // const [universTabs, setUniversTabs] = useState('compositions');

  const [diaryTabs, setDiaryTabs] = useState('diary');

  const { customerId } = useParams();

  const { isLoading } = useCustomerDetails(customerId);

  const { handleUpdateCustomer, tabs, loading, setTabs } = useCustomer();

  if (isLoading) return <Loader />;

  return (
    <>
      <div className='pages-layout'>
        <HeaderWithTabs
          tabs={tabs}
          setTabs={setTabs}
          tabsData={tabsData}
          navigateTo={`/customers`}
          isCustomer
        >
          {tabs === 'diary' && (
            <DiaryTabs diaryTabs={diaryTabs} setDiaryTabs={setDiaryTabs} />
          )}
          {tabs === 'univers' && (
            <UniversTabs
            // universTabs={universTabs}
            // setUniversTabs={setUniversTabs}
            />
          )}
        </HeaderWithTabs>
        <div
          className='pages-layout--container customer'
          style={{
            backgroundColor: tabs === 'diary' ? 'transparent' : 'white',
            border: tabs === 'diary' && 'inherit',
            paddingTop: tabs === 'diary' && 0,
            paddingBottom: tabs === 'diary' && 0,
          }}
        >
          {tabs === 'informations' && <InformationsPanel />}
          {tabs === 'logo/card' && <LogoCardPanel />}
          {tabs === 'diary' && <DiaryComp tabs={tabs} diaryTabs={diaryTabs} />}
          {/* {tabs === 'hashtag' && <HashtagPanel />} */}

          {tabs === 'univers' && <UniversPanel />}
          {tabs === 'signature' && <SignaturePanel />}
        </div>
        <div className='customer--footer'>
          <Button
            label={`Enregister${loading ? '...' : ''}`}
            variant='yellow'
            onClick={handleUpdateCustomer}
            disabled={loading}
          />
        </div>
      </div>
    </>
  );
};

const DiaryTabs = ({ diaryTabs, setDiaryTabs }) => {
  return (
    <div className='headerUser--tabs'>
      <button
        onClick={() => setDiaryTabs('diary')}
        className={`headerUser--tabs-btn headerUser--tabs-btn-${
          diaryTabs === 'diary' ? 'active' : ''
        }`}
      >
        Agenda
      </button>
      <button
        onClick={() => setDiaryTabs('strategy')}
        className={`headerUser--tabs-btn headerUser--tabs-btn-${
          diaryTabs === 'strategy' ? 'active' : ''
        }`}
      >
        Stratégie
      </button>
    </div>
  );
};

const UniversTabs = () => {
  const dispatch = useAppDispatch();
  const universTabs = useAppSelector((s) => s.customer.universTabs);
  const handleSelect = (value) => {
    dispatch(universTabsAction(value));
  };

  const data = [
    {
      label: 'Compositions',
      value: 'compositions',
    },
    {
      label: 'Composition principale',
      value: 'compPrimary',
    },
    {
      label: 'Composition secondaire',
      value: 'compSecondary',
    },
    {
      label: 'Sur mesure',
      value: 'measure',
    },
    {
      label: 'Filtres',
      value: 'filters',
    },
  ];
  return (
    <div className='headerUser--tabs'>
      {data.map((d, i) => (
        <button
          key={i}
          onClick={() => handleSelect(d.value)}
          className={`headerUser--tabs-btn headerUser--tabs-btn-${
            universTabs === d.value ? 'active' : ''
          }`}
        >
          {d.label}
        </button>
      ))}
    </div>
  );
};

export default compose(withNavigation, withCheckAuth)(AddCustomerView);
