import React, { useState } from 'react';
import { copyObj } from '../../../assets/utils/copyObj';
import { sortedList } from '../../../assets/utils/sortedList';
import { Toast } from '../../../assets/utils/toast';
import { useAppDispatch, useAppSelector } from '../../../hooks/useReduxHook';
import {
  answerSelectedAction,
  IAnswer,
  questionSelectedAction,
} from '../../../redux/familyQuiz';
import { specialitiesModalAction } from '../../../redux/modals';
import { Button } from '../../atoms/Buttons';
import { IngredientCard, NoAnswerCard } from './IngredientCard';
import { useQuery } from 'react-query';
import { getOneRayonById } from '../../../api/rayons.api';

export const IngredientsBlock: React.FC = () => {
  //
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');

  const questionSelected = useAppSelector((s) => s.familyQuiz.questionSelected);
  const { data } = useQuery(
    ['rayonSelected', questionSelected?.rayonId],
    () =>
      questionSelected?.rayonId
        ? getOneRayonById(questionSelected?.rayonId)
        : null,
    {
      enabled: !!questionSelected?.rayonId,
    }
  );
  const questionsList = useAppSelector(
    (s) => s.familyQuiz?.quiz?.questionsList
  );
  const compositionSelected = useAppSelector(
    (s) => s.compositions.compositionSelected
  );

  const findIndex = questionsList?.findIndex((_question) => {
    return _question._id === questionSelected?._id;
  });

  const onNextQuestion = () => {
    const nextQuestion = questionsList[findIndex + 1];
    if (questionsList.length === findIndex + 1) {
      Toast('warning', 'Vous avez atteint la dernière question !');
    } else {
      dispatch(questionSelectedAction(nextQuestion));
    }
  };

  const onPreviousQuestion = () => {
    const previousQuestion = questionsList[findIndex - 1];
    if (findIndex === 0) {
      Toast('warning', 'Vous avez atteint la première question !');
    } else {
      dispatch(questionSelectedAction(previousQuestion));
    }
  };

  const onSelectAnswer = (answer: IAnswer) => {
    dispatch(answerSelectedAction(answer));
    dispatch(specialitiesModalAction(true));
  };

  return (
    <div className='productCompositions--ingredientsBlock'>
      <p className='productCompositions--ingredientsBlock--title'>
        {questionSelected?.question}
      </p>
      <div className='productCompositions--ingredientsBlock--inputSearch'>
        <label>Rechercher votre ingredient</label>
        <input
          type='text'
          placeholder='Recherche'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className='productCompositions--ingredientsBlock--ingredients'>
        {!!questionSelected &&
          copyObj(
            questionSelected?.answers?.filter(
              (_answer) => !!_answer?.ingredient_id
            )
          )
            ?.sort((a, b) => sortedList(a, b, 'ingredient_id', 'label'))
            ?.map((answer, index: number) => {
              const filteredIngredients =
                compositionSelected?.ingredients?.filter(
                  (_ingredient) =>
                    _ingredient?.ingredientId === answer?.ingredient_id?._id &&
                    _ingredient?.questionId === questionSelected?._id
                );

              const reg = new RegExp(`${search}`, 'ig');
              if (
                search.length > 0 &&
                !answer?.ingredient_id?.label?.match(reg)
              ) {
                return null;
              }
              return (
                <>
                  {index === 0 && <NoAnswerCard answer={answer} />}
                  <IngredientCard
                    key={index}
                    ingredient={answer?.ingredient_id || answer}
                    onSelect={() => onSelectAnswer(answer)}
                    specialitiesInComp={filteredIngredients?.length}
                  />
                </>
              );
            })}
      </div>
      <div className='productCompositions--ingredientsBlock--btns'>
        <Button label='Question precedente' onClick={onPreviousQuestion} />
        <Button
          label='Question suivante'
          variant='yellow'
          onClick={onNextQuestion}
        />
      </div>
    </div>
  );
};
