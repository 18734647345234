import { useDispatch } from 'react-redux';
import { CloseCircleIcon } from '../../../assets/svgs/CloseCircleIcon';
// import { helperImageRoute } from '../../../assets/utils/helperImageRoute';
import { displayImgModalAction } from '../../../redux/modals';
import FakeImg from '../../../assets/images/diaryimg.png';

export const DisplayImgModal = ({
  width = 400,
  height = 500,
  bgWidth = '80%',
}) => {
  const dispatch = useDispatch();
  // const { role } = useSelector((s) => s.app);
  return (
    <>
      <div
        onClick={() => dispatch(displayImgModalAction(false))}
        className='modal--bg'
        style={{ width: bgWidth }}
      />
      <div className='modal' style={{ width, height }}>
        <CloseCircleIcon
          position='absolute'
          right='1rem'
          top='1rem'
          zIndex={20}
          onClick={() => dispatch(displayImgModalAction(false))}
        />
        <img src={FakeImg} alt='pic' className='modal--imgView' />
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, qui
        pariatur inventore expedita doloremque perspiciatis quasi omnis unde
        consequatur architecto, amet, totam itaque. Reprehenderit aut, suscipit
        facere facilis excepturi atque?
        {/* <img
          src={helperImageRoute(
            'image a inserer'
            'picture',
            false,
            role === 'COMMERCIAL'
          )}
          alt={''}
          className='modal--imgView'
        /> */}
      </div>
    </>
  );
};
