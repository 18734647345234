import { roles } from '../../../assets/constants';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { Button } from '../../atoms/Buttons';
import { Input, Select } from '../../atoms/Fields';
import { UploadImg } from '../../atoms/UploadImg';

export const InformationsUpdatePanel = ({
  setUserForm,
  handleChange,
  userForm,
  setConfirmPassword,
  confirmPassword,
  handleUpdateInfos,
  loading,
}) => {
  //
  const role = useAppSelector((s) => s.app.role);

  return (
    <>
      <UploadImg
        image={userForm?.avatar}
        onChange={(file) => setUserForm({ ...userForm, avatar: file })}
        margin='1rem 0'
        folder='avatar'
      />
      <div className='user--inputBlock'>
        <Input
          label='Nom'
          variant='half'
          onChange={handleChange}
          name='lastname'
          value={userForm['lastname']}
        />
        <Input
          label='Prénom'
          variant='half'
          onChange={handleChange}
          name='firstname'
          value={userForm['firstname']}
        />
      </div>
      <div className='user--inputBlock'>
        <Input
          label='E-mail'
          variant='half'
          onChange={handleChange}
          name='email'
          value={userForm['email']}
        />
        <Input
          label='Téléphone'
          variant='half'
          onChange={handleChange}
          name='phone'
          value={userForm['phone']}
        />
      </div>
      {/* {role !== 'COMMERCIAL' && role !== 'PUNCHLINER' && ( */}
      <Select
        label='Rôle'
        variant='half'
        options={roles}
        margin='.5rem auto .5rem 0'
        onChange={handleChange}
        name='role'
        value={userForm['role']}
      />
      {/* )} */}
      <Button
        label={loading ? 'Valider...' : 'Valider'}
        width='100%'
        variant='yellow'
        onClick={() => handleUpdateInfos()}
        margin='1rem 0'
        disabled={loading}
      />
      <Input
        label='Mot de passe'
        type='password'
        variant='full'
        onChange={handleChange}
        name='password'
        value={userForm['password']}
      />
      <Input
        label='Confirmé mot de passe'
        type='password'
        variant='full'
        onChange={(e) => setConfirmPassword(e.target.value)}
        value={confirmPassword}
      />
    </>
  );
};
