import React from 'react';
import { useAppSelector } from '../../../hooks/useReduxHook';
import { IFamily } from '../Images/ImageProducts';
import { FamilyCard } from './FamilyCard';
import { useAttributionsImgComposition } from '../../../hooks/useCompositions';

export const FamilySelectBlock: React.FC = () => {
  //
  const imageAttributions = useAppSelector((state) => state.imageAttributions);
  const imageFormData = useAppSelector((state) => state.images);
  const { compositionsAttr } = useAttributionsImgComposition();

  const familiesSelected =
    imageAttributions?.imageAttributionsTab === 'product-main'
      ? imageFormData?.infosMainProducts
      : imageFormData?.infosSecondaryProducts;

  return (
    <div className='w-100 flex-col-start-start'>
      <p className='imageAttributions--title'>Pour quel famille de produit?</p>
      <div className='w-100 flex-wrap' style={{ gap: '1rem' }}>
        {!!imageAttributions?.families &&
          imageAttributions?.families.map((family: IFamily, index: number) => {
            const findFamily = familiesSelected?.find(
              (_f) => _f.familyId === family?._id
            );
            const familyComps = compositionsAttr?.filter(
              (el) => el.familyId === findFamily?.familyId
            );

            const formatedFamily = {
              ...family,
              ...{
                ...findFamily,
                checkedNum: findFamily?.checkedNum || familyComps?.length,
              },
            };
            if (findFamily)
              return (
                <FamilyCard
                  key={index}
                  family={formatedFamily}
                  width='31%'
                  selected={
                    imageAttributions?.familySelectedImgAttr?.familyId ===
                    family?._id
                  }
                />
              );
            return null;
          })}
      </div>
    </div>
  );
};
